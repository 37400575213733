import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getTaxGroupList = (context, payload) => {
  const url = URL_UTILITY.getTaxGroupListUrl;
  return http.getApi(url, payload);
};

export default {
  getTaxGroupList
};
