import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getMasterProjectList = (context, payload) => {
  const url = URL_UTILITY.getMasterProjectListUrl;
  return http.getApi(url, payload);
};

const getCrmMasterProjectLov = () => {
  const url = URL_UTILITY.getCrmMasterProjectLovUrl;
  return http.getApi(url);
};

const addAndEditMasterProject = (context, payload) => {
  const url = URL_UTILITY.addAndEditMasterProjectUrl;
  return http.postApi(url, payload);
};

const getFmsSectorList = (context, payload) => {
  const url = URL_UTILITY.getFmsSectorListUrl;
  return http.getApi(url, payload);
};

const getCrmSectorLov = (context, payload) => {
  const url = URL_UTILITY.getCrmSectorLovUrl;
  return http.getApi(url, payload);
};

const addAndEditFmsSector = (context, payload) => {
  const url = URL_UTILITY.addAndEditFmsSectorUrl;
  return http.postApi(url, payload);
};

const getFmsPocketList = (context, payload) => {
  const url = URL_UTILITY.getFmsPocketListUrl;
  return http.getApi(url, payload);
};

const getCrmPocketLov = (context, payload) => {
  const url = URL_UTILITY.getCrmPocketLovUrl;
  return http.getApi(url, payload);
};

const addAndEditPocket = (context, payload) => {
  const url = URL_UTILITY.addAndEditPocketUrl;
  return http.postApi(url, payload);
};
const getFmsTowerList = (context, payload) => {
  const url = URL_UTILITY.getFmsTowerListUrl;
  return http.getApi(url, payload);
};

const getCrmTowerLov = (context, payload) => {
  const url = URL_UTILITY.getCrmTowerLovUrl;
  return http.getApi(url, payload);
};

const addAndEditTower = (context, payload) => {
  const url = URL_UTILITY.addAndEditTowerUrl;
  return http.postApi(url, payload);
};

const getFmsFlooerList = (context, payload) => {
  const url = URL_UTILITY.getFmsFlooerListUrl;
  return http.getApi(url, payload);
};

const getCrmFloorLov = (context, payload) => {
  const url = URL_UTILITY.getCrmFloorLovUrl;
  return http.getApi(url, payload);
};
const addAndEditFmsFloor = (context, payload) => {
  const url = URL_UTILITY.addAndEditFmsFloorUrl;
  return http.postApi(url, payload);
};

const getFmsProjectList = (context, payload) => {
  const url = URL_UTILITY.getFmsProjectListUrl;
  return http.getApi(url, payload);
};

const addAndEditFmsProject = (context, payload) => {
  const url = URL_UTILITY.getFmsProjectListUrl;
  return http.postApi(url, payload);
};

const getFmsUnitGridList = (context, payload) => {
  const url = URL_UTILITY.getFmsUnitGridListUrl;
  return http.getApi(url, payload);
};

const getFmsSubUnitGridList = (context, payload) => {
  const url = URL_UTILITY.getFmsSubUnitGridListUrl;
  return http.getApi(url, payload);
};

const getFmsUnitOriginalArea = (context, payload) => {
  const url = URL_UTILITY.getFmsUnitOriginalAreaUrl.replace(
    '{fmsUnitId}',
    payload.fmsUnitId
  );
  return http.getApi(url);
};

const getFmsSubUnitRevisedArea = (context, payload) => {
  const url = URL_UTILITY.getFmsSubUnitRevisedAreaUrl.replace(
    '{fmsSubUnitId}',
    payload.fmsSubUnitId
  );
  return http.getApi(url);
};

const getFmsSubUnitSpecification = (context, payload) => {
  const url = URL_UTILITY.getFmsSubUnitSpecificationUrl.replace(
    '{fmsSubUnitId}',
    payload.fmsSubUnitId
  );
  return http.getApi(url);
};

const getFmsSubUnitCustomerList = (context, payload) => {
  const url = URL_UTILITY.getFmsSubUnitCustomerListUrl.replace(
    '{fmsSubUnitId}',
    payload.fmsSubUnitId
  );
  return http.getApi(url);
};

const addEditFmsUnit = (context, payload) => {
  const url = URL_UTILITY.getFmsUnitGridListUrl;
  return http.postApi(url, payload);
};

const getFmsUnitOwnershipData = (context, payload) => {
  const url = URL_UTILITY.getFmsUnitOwnershipDataUrl.replace(
    '{fmsUnitId}',
    payload.fmsUnitId
  );
  return http.getApi(url, payload);
};

const addEditFmsUnitOwnershipData = (context, payload) => {
  const url = URL_UTILITY.getFmsUnitOwnershipDataUrl.replace(
    '{fmsUnitId}',
    payload.fmsUnitId
  );
  return http.postApi(url, payload.unitCustomer);
};

const addEditFmsUnitOriginalArea = (context, payload) => {
  const url = URL_UTILITY.getFmsUnitOriginalAreaUrl.replace(
    '{fmsUnitId}',
    payload.fmsUnitId
  );
  return http.postApi(url, payload.unitAreaDetail);
};

const addEditFmsSubUnit = (context, payload) => {
  const url = URL_UTILITY.addEditFmsSubUnitUrl;
  return http.postApi(url, payload);
};

const addEditFmsSubUnitRevisedArea = (context, payload) => {
  const url = URL_UTILITY.addEditFmsSubUnitRevisedAreaUrl.replace(
    '{fmsSubUnitId}',
    payload.fmsSubUnitId
  );
  return http.postApi(url, payload.fms_sub_unit_area_details);
};

const addEditFmsSubUnitSpecification = (context, payload) => {
  const url = URL_UTILITY.getFmsSubUnitSpecificationUrl.replace(
    '{fmsSubUnitId}',
    payload.fmsSubUnitId
  );
  return http.postApi(url, payload.subUnitSpec);
};

const addEditFmsSubUnitCustomer = (context, payload) => {
  const url = URL_UTILITY.getFmsSubUnitCustomerListUrl.replace('{fmsSubUnitId}', payload.fmsSubUnitId);
  return http.postApi(url, payload.subUnitCustomer)
}

const createSubunitFromUnitId = (context, fmsUnitId) => {
  const url = URL_UTILITY.createSubunitFromUnitIdUrl.replace('{fmsUnitId}', fmsUnitId);
  return http.postApi(url);
} 

const getSubUnitDetailsById = (context, fmsSubUnitId) => {
  const url = URL_UTILITY.getSubUnitDetailsByIdUrl.replace('{fmsSubUnitId}', fmsSubUnitId);
  return http.getApi(url);
}

const getFmsSubUnitAreaDetails = (context, fms_unit_id) => {
  const url = URL_UTILITY.getFmsSubUnitDetailsByUnitIdUrl.replace('{fmsUnitId}', fms_unit_id);
  return http.getApi(url);
}
const getBankDetailsById =  (context, payload) => {
  const url= URL_UTILITY.getBankDetailsByIdUrl + '/' + payload.ar_proj_receipt_method_mpng_id;
  return http.getApi(url);
};
export default {
  getMasterProjectList,
  getCrmMasterProjectLov,
  addAndEditMasterProject,
  getFmsSectorList,
  addAndEditFmsSector,
  getFmsPocketList,
  getFmsTowerList,
  getFmsFlooerList,
  addAndEditFmsFloor,
  getCrmSectorLov,
  getCrmPocketLov,
  addAndEditPocket,
  getFmsProjectList,
  addAndEditFmsProject,
  getCrmTowerLov,
  addAndEditTower,
  getCrmFloorLov,
  getFmsUnitGridList,
  getFmsSubUnitGridList,
  getFmsUnitOriginalArea,
  getFmsSubUnitRevisedArea,
  getFmsSubUnitSpecification,
  getFmsSubUnitCustomerList,
  addEditFmsUnit,
  getFmsUnitOwnershipData,
  addEditFmsUnitOwnershipData,
  addEditFmsUnitOriginalArea,
  addEditFmsSubUnit,
  addEditFmsSubUnitRevisedArea,
  addEditFmsSubUnitSpecification,
  addEditFmsSubUnitCustomer,
  createSubunitFromUnitId,
  getSubUnitDetailsById,
  getFmsSubUnitAreaDetails,
  getBankDetailsById
};
