import { ModelSelect, BasicSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import DatePicker from 'vue2-datepicker';
import commonHelper from '../../../../utility/mixin';
export default {
  name: 'partyDetails',
  mixins: [commonHelper],
  components: {
    ModelSelect,
    BasicSelect,
    Datepicker,
    DatePicker
  },
  data() {
    return {
      unsubscribe: null,
      isDobValid: true,
      editMode: false,
      loader: false,
      showBankModal: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      partyId: null,
      lovType: appStrings.VALUESETTYPE.PARTY_TYPE,
      partyDetails: {},
      editCreatePartyForm: {
        selectedParty: {
          value: null,
          text: null
        },
        selectedCateory: {
          value: null,
          text: null
        },
        selectedSalutation: {
          value: null,
          text: null
        },
        short_name: null,
        pan_no: null,
        first_name: null,
        middle_name: null,
        active : false,
        last_name: null,
        date_of_birth: null,
        is_foreign: null,
        party_name: null,
        party_id: null,
        employee_id: null,
        date_of_incorporation: null,
        cin_no: null,
        aadhar_no: null
      },
      patyTypeArr: [
        {
          value: null,
          text: null
        }
      ],
      partyCategory: [
        {
          value: null,
          text: null
        }
      ],

      salutationArr: [
        {
          value: null,
          text: null
        }
      ]
    };
  },

  mounted() {
    this.eventBus.$off('partyDetails');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.editParty();
        }
      }
    });
    this.eventBus.$on('partyDetails', partyDetails => {
      if (partyDetails) {
        this.partyId = partyDetails.partyId;
        this.partyDetails = partyDetails.partyDetails;
        this.setPartyDetailData(partyDetails.partyDetails);
        this.selectedPartyTypeFun({
          value: this.partyDetails.party_type_vset,
          text: this.partyDetails.party_type
        });
        this.getSalutation();
      }
    });
    this.getLOVBySetType();
  },
  methods: {
    selectedPartyTypeFun(val) {
      this.lovType = appStrings.VALUESETTYPE.PARTY_CAT;
      this.editCreatePartyForm.selectedParty = val;
      this.getLOVBySetType();
    },
    setPartyDetailData(partyData) {
      this.editCreatePartyForm = {
        selectedParty: {
          value: partyData.party_type_vset,
          text: partyData.party_type
        },

        selectedCateory: {
          value: partyData.party_cat_vset,
          text: partyData.party_cat
        },
        selectedSalutation: {
          value: partyData.salutation_lookup,
          text: partyData.salutation
        },
        short_name: partyData.short_name,
        pan_no: partyData.pan_no,
        first_name: partyData.first_name,
        middle_name: partyData.middle_name,
        last_name: partyData.last_name,
        date_of_birth: partyData.date_of_birth,
        is_foreign: partyData.is_foreign,
        party_name: partyData.party_name,
        party_type: partyData.party_type,
        party_cat: partyData.party_cat,
        party_id: partyData.party_id,
        employee_id: partyData.employee_id,
        date_of_incorporation: partyData.date_of_incorporation,
        cin_no: partyData.cin_no,
        aadhar_no: partyData.aadhar_no,
        active:partyData.active
      };
    },
    getLOVBySetType() {
      this.loader = true;
      const findPartyObj =
        this.partyTypeDataResponse &&
        this.partyTypeDataResponse.find(
          partyObj =>
            partyObj.value_code === this.editCreatePartyForm.selectedParty.value
        );
      const payload = {
        lovType: this.lovType,
        parent_value_set_id: findPartyObj ? findPartyObj.value_set_dtl_id : null
      };
      this.$store
        .dispatch('party/getLOVBySetType', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data) {
            const result = response.data.data;
            const partyTypeData = result.map(type => {
              if (this.lovType === appStrings.VALUESETTYPE.PARTY_TYPE) {
                return {
                  value: type.value_code,
                  text: type.value_meaning
                };
              } else if (this.lovType === appStrings.VALUESETTYPE.PARTY_CAT) {
                return {
                  value: type.value_code,
                  text: type.value_meaning
                };
              }
            });
            if (this.lovType === appStrings.VALUESETTYPE.PARTY_TYPE) {
              this.partyTypeDataResponse = response.data.data;
              this.patyTypeArr = partyTypeData;
            } else if (this.lovType === appStrings.VALUESETTYPE.PARTY_CAT) {
              this.partyCategory = partyTypeData;
            }
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    getSalutation() {
      this.$store
        .dispatch(
          'shared/getDataFromLookupsByLookupType',
          appStrings.LOOKUPTYPE.SALUTATION
        )
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const salutationArr = result.map(type => {
              return {
                value: type.lookup_code,
                text: type.meaning
              };
            });
            this.salutationArr = salutationArr;
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    editParty() {
      const payload = {
        ediPartyFormData: {
          party_type_vset: this.editCreatePartyForm.selectedParty.value,
          party_cat_vset: this.editCreatePartyForm.selectedCateory.value,
          party_short_name: this.editCreatePartyForm.short_name
            ? this.editCreatePartyForm.short_name
            : null,
          pan_no: this.editCreatePartyForm.pan_no
            ? this.editCreatePartyForm.pan_no
            : null,
          salutation_code: this.editCreatePartyForm.selectedSalutation.value,
          first_name: this.editCreatePartyForm.first_name
            ? this.editCreatePartyForm.first_name
            : null,
          middle_name: this.editCreatePartyForm.middle_name
            ? this.editCreatePartyForm.middle_name
            : null,
          last_name: this.editCreatePartyForm.last_name
            ? this.editCreatePartyForm.last_name
            : null,
          party_name: this.editCreatePartyForm.party_name
            ? this.editCreatePartyForm.party_name
            : null,
          date_of_birth: this.editCreatePartyForm.date_of_birth
            ? moment(this.editCreatePartyForm.date_of_birth).format(
                appStrings.DATEFORMAT
              )
            : null,
          date_of_incorporation: this.editCreatePartyForm.date_of_incorporation
            ? moment(this.editCreatePartyForm.date_of_incorporation).format(
                appStrings.DATEFORMAT
              )
            : null,
          aadhar_no: this.editCreatePartyForm.aadhar_no
            ? this.editCreatePartyForm.aadhar_no
            : null,
          cin_no: this.editCreatePartyForm.cin_no
            ? this.editCreatePartyForm.cin_no
            : null,
          active: this.editCreatePartyForm.active,
          is_nri: this.editCreatePartyForm.is_foreign,
          employee_id: 0
        },
        party_id: this.partyId
      };
      this.$store
        .dispatch('party/editParty', payload)
        .then(response => {
          this.editMode = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(error => {
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = error.message;
        });
    },
    validateDate(date) {
      this.isDobValid = true;
      if (!this.validateDob(date)) {
        this.isDobValid = false;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
