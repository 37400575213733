import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getSQLConnect = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstUrl + '/sql-connect',
    payload
  );
};

export default {
  getSQLConnect
}
