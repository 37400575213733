import appStrings from '@/app/utility/string.utility';
import { ModelSelect, BasicSelect } from 'vue-search-select';
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'contactDetails',
  components: {
    ModelSelect,
    BasicSelect
  },
  data() {
    return {
      unsubscribe: null,
      contactDetailIndex: null,
      communicationIndex: null,
      partyId: null,
      loader: false,
      showContactModal: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      salutationList: [],
      partyContactDetails: [],
      contactPurposeList: [],
      contactTypeList: [],
      contactForm: {
        partyContactPersonId: 0,
        selectedSalutation: {
          value: null,
          text: null
        },
        selectedContactPurpose: {
          value: null,
          text: null
        },
        first_name: null,
        middle_name: null,
        last_name: null,
        contact_purpose: null,
        start_date: null,
        active: false
      },
      editMode: false,
      contactDetailFields: [
        {
          key: 'communication'
        },
        {
          key: 'salutation'
        },
        {
          key: 'first_name'
        },
        {
          key: 'middle_name'
        },
        {
          key: 'last_name'
        },
        {
          key: 'contact_purpose'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'active'
        }
      ],
      communicationList: [
        {
          contact_type: null,
          area_code: null,
          country_code: null,
          contact_number: null,
          email: null,
          comm_active: false
        }
      ],
      communicationListFieldsList: [
        {
          key: 'contact_type_vset'
        },
        {
          key: 'area_code'
        },
        {
          key: 'country_code'
        },
        {
          key: 'contact_number'
        },
        {
          key: 'email'
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        }
      ]
    };
  },
  validations: {
    contactForm: {
      first_name: { required },
      selectedContactPurpose: {
        value: { required },
        text: { required }
      }
    }
  },
  mounted() {
    this.eventBus.$off('contactDetils');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          if (this.showContactModal) {
            this.addEditContact();
          } else if (this.contactDetailIndex !== null) {
            this.addEditCommunication();
          }
        }
        if (actionName === 'add') {
          this.showContactModal = true;
          this.contactForm.partyContactPersonId = 0;
          this.contactForm.active = false;
          this.contactForm.selectedSalutation.text = null;
          this.contactForm.selectedSalutation.value = null;
          this.contactForm.selectedContactPurpose.text = null;
          this.contactForm.selectedContactPurpose.value = null;
          this.contactForm.first_name = null;
          this.contactForm.middle_name = null;
          this.contactForm.last_name = null;
          this.editMode = true;
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    this.eventBus.$on('contactDetils', contactDetils => {
      this.partyId = contactDetils.partyId;
      this.loader = true;
      this.$store
        .dispatch('party/getPartyDetailsById', this.partyId)
        .then(response => {
          this.loader = false;
          const contactdetailList = response.data.data.party_contact_details.map(
            contact => {
              if (contact.communication_details.length === 0) {
                contact.communication_details.push({
                  comm_active: false,
                  area_code: null,
                  comm_type: null,
                  contact_num: null,
                  contact_type_vset: null,
                  country_code: null,
                  email: null,
                  party_contact_comm_id: 0
                });
              }
              return contact;
            }
          );
          this.partyContactDetails = contactdetailList;
        });
    });
    this.getSalutation();
    this.getContactPurpose();
    this.getContactType();
  },
  methods: {
    addEditContact() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          partyId: this.partyId,
          contactPayload: {
            party_contact_communications: [
              {
                party_contact_person_id: this.contactForm.partyContactPersonId,
                first_name: this.contactForm.first_name,
                middle_name: this.contactForm.middle_name,
                last_name: this.contactForm.last_name,
                salutation_lookup: this.contactForm.selectedSalutation.value,
                contact_purpose_vset: this.contactForm.selectedContactPurpose
                  .value,
                active: this.contactForm.active,
                start_date: moment(new Date()).format(appStrings.DATEFORMAT),
                end_date: null,
                party_contact_comm_id: null,
                contact_type_vset: null,
                country_code: null,
                area_code: null,
                contact_num: null,
                email: null,
                comm_active: null
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/addPartyContactDetails', payload)
          .then(response => {
            this.loader = false;
            this.showContactModal = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              const contactdetailList = response.data.data.party_contact_details.map(
                contact => {
                  if (contact.communication_details.length === 0) {
                    contact.communication_details.push({
                      comm_active: null,
                      area_code: null,
                      comm_type: null,
                      contact_num: null,
                      contact_type_vset: null,
                      country_code: null,
                      email: null,
                      party_contact_comm_id: 0
                    });
                  }
                  return contact;
                }
              );
              this.partyContactDetails = contactdetailList;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.data.message;
            }
          });
      }
    },
    addEditCommunication() {
      const contactCommunication = [];
      this.partyContactDetails[
        this.contactDetailIndex
      ].communication_details.forEach(contact => {
        {
          contactCommunication.push({
            party_contact_person_id: this.partyContactDetails[
              this.contactDetailIndex
            ].party_contact_person_id,
            first_name: this.partyContactDetails[this.contactDetailIndex]
              .first_name,
            middle_name: this.partyContactDetails[this.contactDetailIndex]
              .middle_name,
            last_name: this.partyContactDetails[this.contactDetailIndex]
              .last_name,
            salutation_lookup: this.partyContactDetails[this.contactDetailIndex]
              .salutation_lookup,
            contact_purpose_vset: this.partyContactDetails[
              this.contactDetailIndex
            ].contact_purpose_vset,
            active: this.partyContactDetails[this.contactDetailIndex].active,
            start_date: this.partyContactDetails[this.contactDetailIndex]
              .start_date,
            end_date: this.partyContactDetails[this.contactDetailIndex]
              .end_date,
            party_contact_comm_id: contact.party_contact_comm_id,
            contact_type_vset: contact.contact_type_vset,
            country_code: contact.country_code,
            area_code: contact.area_code,
            contact_num: contact.contact_num,
            email: contact.email,
            comm_active: contact.comm_active
          });
        }
      });
      const payload = {
        partyId: this.partyId,
        contactPayload: {
          party_contact_communications: contactCommunication
        }
      };
      this.loader = true;
      this.$store
        .dispatch('party/addPartyContactDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.contactDetailIndex = null;
            this.communicationIndex = null;
            const contactdetailList = response.data.data.party_contact_details.map(
              contact => {
                if (contact.communication_details.length === 0) {
                  contact.communication_details.push({
                    comm_active: false,
                    area_code: null,
                    comm_type: null,
                    contact_num: null,
                    contact_type_vset: null,
                    country_code: null,
                    email: null,
                    party_contact_comm_id: 0
                  });
                }
                return contact;
              }
            );
            this.partyContactDetails = contactdetailList;
          }
        });
    },
    getSalutation() {
      this.$store
        .dispatch(
          'shared/getDataFromLookupsByLookupType',
          appStrings.LOOKUPTYPE.SALUTATION
        )
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const salutationArr = result.map(type => {
              return {
                value: type.lookup_code,
                text: type.meaning
              };
            });
            this.salutationList = salutationArr;
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    getContactPurpose() {
      this.$store
        .dispatch('shared/getLOVBySetType', 'CONTACT_PURPOSE')
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const salutationArr = result.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.contactPurposeList = salutationArr;
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    getContactType() {
      this.$store
        .dispatch('shared/getLOVBySetType', 'CONTACT_TYPE')
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const typeList = result.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.contactTypeList = typeList;
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    addNewRow(index) {
      // this.contactDetailIndex = index;
      // this.communicationIndex = commIndex;
      this.partyContactDetails[index].communication_details.push({
        comm_active: false,
        area_code: null,
        comm_type: null,
        contact_num: null,
        contact_type_vset: null,
        country_code: null,
        email: null,
        party_contact_comm_id: 0
      });
    },
    removeRow(index, commIndex) {
      this.partyContactDetails[index].communication_details.splice(
        commIndex,
        1
      );
    },
    enableInputs(index, commIndex) {
      this.contactDetailIndex = index;
      this.communicationIndex = commIndex;
    },
    selectContact(index) {
      this.contactDetailIndex = index;
    },
    hideContactModel(flag) {
      this.showContactModal = flag;
    },
    rowSelected(item) {
      this.editMode = false;
      this.contactForm.partyContactPersonId = item.party_contact_person_id;
      this.contactForm.active = item.active;
      this.contactForm.selectedSalutation.text = item.salutation;
      this.contactForm.selectedSalutation.value = item.salutation_lookup;
      this.contactForm.selectedContactPurpose.text = item.contact_purpose;
      this.contactForm.selectedContactPurpose.value = item.contact_purpose_vset;
      this.contactForm.first_name = item.first_name;
      this.contactForm.middle_name = item.middle_name;
      this.contactForm.last_name = item.last_name;
      this.hideContactModel(true);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
