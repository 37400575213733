const isLoggedIn = state => !!state.token;
const authStatus= state => state.status;
const isLoading= state => state.loading;
const errorMessage= state => state.message;
const token = state => state.token;
const userData = state => state.userDtl

export default {
  isLoggedIn,
  authStatus,
  isLoading,
  errorMessage,
  token,
  userData
};