import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getTaxSectionList = (context, payload) => {
  const url = URL_UTILITY.getTaxSectionListUrl;
  return http.getApi(url, payload);
}
const addEditTaxSection = (context, payload) => {
  const url = URL_UTILITY.addEditTaxSectionUrl;
  return http.postApi(url, payload);
}
const getTaxSectionById = (context, payload) => {
  const url = URL_UTILITY.addEditTaxSectionUrl + '/mpng/' + payload.tax_section_id;
  return http.getApi(url,payload.pagination);
}
const addEditTaxSectionDetails = (context, payload) => {
  const url = URL_UTILITY.addEditTaxSectionUrl + '/mpng';
  return http.postApi(url, payload);
}
export default {
  getTaxSectionList,
  addEditTaxSection,
  getTaxSectionById,
  addEditTaxSectionDetails
};
