import http from '../../../../app/services/httpServices';
import URL_UTILITY from '../../../../app/utility/url.utility';

const getEmaillerDtls = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/email_dash', payload);
};
const getvenHoldRelDtls = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstUrl + '/gst_hold_unhlod_dashboard',
    payload
  );
};

export default {
  getEmaillerDtls,
  getvenHoldRelDtls
};
