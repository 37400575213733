import commonHelper from '@/app/utility/common.helper.utility';
// import URL_UTILITY from '../../../../../../utility/url.utility';
export default {
  props: [
    'docDtlId',
    'authToken',
    'uploadedFileName',
    'data',
    'currentPageIndex'
  ],
  data() {
    return {
      loader: false,
      srcDoc: null,
      fileExtension: null,
      routeName: this.$route.name,
      perPage: 1,
      totalRows: null,
      currentPage: this.currentPageIndex,
      specIndex: null
    };
  },
  mounted() {
    if (this.data) {
      this.totalRows = this.data.length;
      this.specIndex = this.currentPageIndex;
    }
    this.previewOpenDoc();
    if (this.routeName !== 'partyOnBoard') {
      document.addEventListener('keydown', this.handleKeyDown);
      document.oncontextmenu = function() {
        return false;
      };
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'newTab') {
          this.openDocumentNewTab();
        }
      }
    });
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     this.$refs.paginationRef.$el.querySelector('button').focus();
    //   }, 100);
    // });
  },
  methods: {
    openDocumentNewTab() {
      this.fileExtension = commonHelper.getFileExtension(
        this.data[this.specIndex - 1].original_file_name
      );

      if (this.fileExtension === 'xlsx') {
        alert('Not Allowed');
      } else {
        const payload = {
          docDetailId: this.data[this.specIndex - 1].document_det_id,
          token: this.authToken,
          fileExtension: this.fileExtension
        };
        this.loader = true;
        commonHelper.previewImgDocParty(payload);
        this.loader = false;
      }
    },
    getPageIndex(item) {
      this.specIndex = item;
      this.newPreviewOpenDoc(
        this.data[item - 1].original_file_name,
        this.data[item - 1].document_det_id
      );
    },
    previewOpenDoc() {
      this.fileExtension = commonHelper.getFileExtension(this.uploadedFileName);
      const payload = {
        docDetailId: this.docDtlId,
        token: this.authToken,
        fileExtension: this.fileExtension
      };
      this.loader = true;
      commonHelper.previewImgDoc(payload, () => (this.loader = false));
    },
    newPreviewOpenDoc(uploadedFileName, docDtlId) {
      this.fileExtension = commonHelper.getFileExtension(uploadedFileName);
      // this.eventBus.$emit('changeFileName', uploadedFileName);
      const payload = {
        docDetailId: docDtlId,
        token: this.authToken,
        fileExtension: this.fileExtension
      };
      this.loader = true;
      commonHelper.previewImgDoc(payload, () => (this.loader = false));
    },
    autoClickNextPage() {
      const nextPage = this.currentPage + 1;
      if (nextPage <= this.totalRows / this.perPage) {
        this.currentPage = nextPage;
        this.getPageIndex(nextPage);
      }
    },
    autoClickPrevPage() {
      const prevPage = this.currentPage - 1;
      if (prevPage > 0) {
        this.currentPage = prevPage;
        this.getPageIndex(prevPage);
      }
    },
    handleKeyDown(event) {
      if (
        event.keyCode == '17' ||
        event.keyCode == '91' ||
        event.keyCode == '92' ||
        event.keyCode == '16' ||
        event.keyCode == '83'
      ) {
        event.preventDefault();
        alert('Not Allowed');
      } else if (event.keyCode == '39') {
        this.autoClickNextPage();
      } else if (event.keyCode == '37') {
        this.autoClickPrevPage();
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown);
    this.unsubscribe();
  }
};
