import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const internalWorkflow = {
  verifyOtpObj: {}
};

export default {
  namespaced: true,
  internalWorkflow,
  actions,
  getters,
  mutations
};
