import http from '../../../../app/services/httpServices';
import URL_UTILITY from '../../../../app/utility/url.utility';

const getGstrThreeBSummaryTabData = (context, payload) => {
  const url = URL_UTILITY.getGstrThreeBSummaryTabDataUrl;
  return http.getApi(url, payload);
};
const getFinancialYearList = () => {
  const url = URL_UTILITY.getFinancialYearDataUrl;
  return http.getApi(url);
};
const getGstrThreeBTabsData = (context, payload) => {
  const url = URL_UTILITY.getGstrThreeBTabsDataUrl;
  return http.getApi(url, payload);
};

const addEditGstrThreeBTabsData = (context, payload) => {
  const url = URL_UTILITY.getGstUrl + '/gstr3b-summary';
  return http.postApi(url, payload);
};
const getInterStateSupplyDetlsData = (context, payload) => {
  const url = URL_UTILITY.getGstUrl + '/gstr3b-summary-detail-scr1';
  return http.getApi(url, payload);
};
const getCompTaxPayableDtls = (context, payload) => {
  const url = URL_UTILITY.getGstUrl + '/gstr3b-summary-detail-1';
  return http.getApi(url, payload);
};
const getUpdateTaxDtls = (context, payload) => {
  const url = URL_UTILITY.getGstUrl + '/gstr3b-summary-detail-ecl';
  return http.getApi(url, payload);
};
const updateTaxDtls = (context, payload) => {
  const url = URL_UTILITY.getGstUrl + '/gstr3b-summary-detail-1';
  return http.postApi(url, payload);
};
export default {
  getGstrThreeBSummaryTabData,
  getFinancialYearList,
  getGstrThreeBTabsData,
  addEditGstrThreeBTabsData,
  getInterStateSupplyDetlsData,
  getCompTaxPayableDtls,
  getUpdateTaxDtls,
  updateTaxDtls
};
