import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getArApplyReceiptTransaction = (context, payload) => {
  const url = URL_UTILITY.getArApplyReceiptTransactionUrl;
  return http.postApi(
    `${url}?_page=${payload.pagination._page}&_limit=${payload.pagination._limit}`,
    payload.searchParams
  );
};
const getApplyReceiptTransactionHdrById = (context, payload) => {
  const url =
    URL_UTILITY.getArApplyReceiptTransactionUrl + '/' + payload.receipt_hdr_id;
  return http.getApi(url, payload.params);
};
const getAppliedDetailsId = (context, payload) => {
  const url = URL_UTILITY.getArApplyReceiptTransactionUrl;
  return http.getApi(
    url +
      '/applied-details/' +
      payload.receipt_hdr_id +
      '/' +
      payload.application_mode_vset
  );
};
// const getUnAppliedDetailsById = (context, payload) => {
//   const url = URL_UTILITY.getArApplyReceiptTransactionUrl;
//   return http.getApi(url + '/unapplied-details', payload);
// };
const getModuleBasedPeriodDate = (context, payload) => {
  return http.getApi(URL_UTILITY.getPeriodDefaultDateUrl, payload);
};
const getApplyUnapplyById = (context, payload) => {
  const url = URL_UTILITY.getArApplyReceiptTransactionUrl;
  return http.postApi(url + '/unapplied-details', payload);
};

const saveAdvSearchFilters = (context, payload) => {
  context.commit('updateAdvSearchFilters', payload);
};
const getInvoiceAccountingList = (context, receiptHdrId) => {
  const url = URL_UTILITY.getTransactionAccountingListUrl.replace(
    '{receiptHdrId}',
    receiptHdrId
  );
  return http.getApi(url);
};
const getActivityDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getActivityDetailsUrl + '/activity', payload);
};
const addEditActivityDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.getActivityDetailsUrl + '/activity', payload);
};
const getActivityDetailsById = (context, activityId) => {
  return http.getApi(
    URL_UTILITY.getActivityDetailsUrl + '/' + activityId + '/activity'
  );
};
const getUnAppliedDetailsByIdForSecAdv = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getArApplyReceiptTransactionUrl + '/unapplied-details/sec-adv',
    payload
  );
};
const getArCofData = (context, payload) => {
  return http.getApi(URL_UTILITY.getArCofDataUrl, payload);
};
const addEditArCofData = (context, payload) => {
  return http.postApi(URL_UTILITY.getArCofDataUrl + '/manual-upload', payload);
};
const wayBillData = (context, payload) => {
  return http.postApi(URL_UTILITY.getArCofDataUrl, payload);
};
const excelUploadData = (context, excelImportId) => {
  return http.postApi(
    URL_UTILITY.getArCofDataUrl + '/' + excelImportId + '/' + 'excel-upload'
  );
};
const cancelData = (context, payload) => {
  return http.postApi(URL_UTILITY.getArCofDataUrl + '/cancel', payload);
};
const workFlowData = (context, payload) => {
  return http.postApi(URL_UTILITY.postBulkWorkflow, payload);
};
const deleteData = (context, payload) => {
  return http.putApi(URL_UTILITY.getArCofDataUrl + '/delete', payload);
};

const getAccountingList =(context, payload) => {
  const url = URL_UTILITY.getArApplyReceiptTransactionUrl;
  return http.getApi(
    url +
      '/applied-accounting/' +
      payload.receiptHdrId +
      '/' +
      payload.applicationModeVset
  );
};
const getOsTrxAmt =(context, trxHdrId) => {
  const url = URL_UTILITY.getArApplyReceiptTransactionUrl;
  return http.getApi( url + '/trx-amount-os/' +trxHdrId);
};
const getUnAppliedTransactionDetails = (context, payload) => {
  const url = URL_UTILITY.getArApplyReceiptTransactionUrl;
  return http.getApi(url + '/unapplied-trx-dtls', payload);
};
export default {
  getArApplyReceiptTransaction,
  getApplyReceiptTransactionHdrById,
  getAppliedDetailsId,
  // getUnAppliedDetailsById,
  getModuleBasedPeriodDate,
  getApplyUnapplyById,
  saveAdvSearchFilters,
  getInvoiceAccountingList,
  getActivityDetails,
  addEditActivityDetails,
  getActivityDetailsById,
  getUnAppliedDetailsByIdForSecAdv,
  getArCofData,
  addEditArCofData,
  wayBillData,
  excelUploadData,
  cancelData,
  workFlowData,
  deleteData,
  getAccountingList,
  getOsTrxAmt,
  getUnAppliedTransactionDetails
};
