import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'SearchModule',
  watch: {
    currentPage: function() {
      this.getModulesList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getModulesList();
    }
  },
  props: ['receipt_flag'],
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      loader: false,
      name: '',
      data: [],
      fields: [
        {
          key: 'module_id',
          class: 'd-none'
        },
        {
          key: 'module_name'
        }
      ]
    };
  },
  mounted() {
    this.getModulesList();
  },
  methods: {
    rowSelected(item) {
      this.$emit('selectedSearchModule', item);
      this.$emit('selectedModule', item);
    },
    clearModule() {
      this.name = null;
      this.getModulesList();
    },
    getModulesList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_name: this.name,
        receipt_flag: this.receipt_flag ? true : false
      };
      this.loader = true;
      this.$store.dispatch('resp/getModulesList', payload).then(response => {
        this.loader = false;
        this.data = response.data.data.page;
        this.totalRows = response.data.data.total_elements;
        if (this.totalRows === 1) {
          this.$emit('selectedModule', this.data[0]);
        }
      })
      .catch(() => {
        this.loader = false;
      });
    }
  }
};
