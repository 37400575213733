import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import RunRequest from './runRequest';
import store from '../../../../store';
import appStrings from '@/app/utility/string.utility';
import moment from 'moment';
import URL_UTILITY from '../../../utility/url.utility';
import User from '../../admin/user';
import viewReqDetail from './viewReqDetail';

export default {
  name: 'SubmittedRequest',
  components: {
    DatePicker,
    ModelSelect,
    RunRequest,
    User,
    viewReqDetail
  },
  watch: {
    currentPage: function() {
      this.searchFields();
    },
    perPage: function() {
      this.currentPage = 1;
      this.searchFields();
    }
  },
  props: ['rowData', 'requestFlag'],
  data() {
    return {
      setTimeForMenuTitle: null,
      routeRequestId: null,
      dowloadReport: URL_UTILITY.getDownloadReportUrl,
      downloadLog: URL_UTILITY.getDownloadLOGUrl,
      unsubscribe: null,
      showRunRequestModal: false,
      loader: false,
      payload: {},
      editMode: false,
      showAlert: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      setTimeFormActions: null,
      requestId: null,
      name: null,
      submittedDate: null,
      completedDate: null,
      selectedStatus: {},
      statusType: [
        {
          value: null,
          text: null
        }
      ],
      requestor: null,
      requestorId: null,
      numOfDays: 1,
      data: [],
      fields: [
        {
          key: 'request_id',
          label: 'Request ID',
          class: 'text-center'
        },
        {
          key: 're_run_request',
          class: 'text-center'
        },
        {
          key: 'LOG',
          class: 'text-center'
        },
        {
          key: 'Output',
          class: 'text-center'
        },
        {
          key: 'name',
          label: 'Name',
          class: 'text-center'
        },
        {
          key: 'parent',
          label: 'Parent',
          class: 'text-center'
        },
        {
          key: 'status',
          label: 'Status',
          class: 'text-center'
        },
        {
          key: 'parameter',
          label: 'Parameter',
          class: 'text-center'
        },
        {
          key: 'start_date',
          label: 'Start Date',
          class: 'text-center'
        },
        {
          key: 'end_date',
          // class: 'd-none',
          class: 'text-center d-none'
        },
        {
          key: 'output_format_meaning',
          label: 'Output Format',
          class: 'text-center'
        },
        {
          key: 'invalidate',
          class: 'text-center'
        },
        {
          key: 'view_details',
          class: 'text-center'
        }
      ],
      showAccessRights: false,
      responseMsg: '',
      isSuccess: false,
      showUserModal: false,
      userType: this.$store.state.auth.userDtl.user_type,
      showViewReqModal: false,
      reqDetails: null,
      routeName: null,
      retryDtls: {},
      showPromptModal: false,
      showPromptDialog: false,
      reRunReq: null
    };
  },
  mounted() {
    this.routeName = this.$route.name;
    if (this.rowData) {
      this.requestId = this.rowData.request_num;
      this.numOfDays = null;
      this.searchFields();
    }
    this.routeRequestId = null;
    if (this.$route.params.request_id) {
      this.routeRequestId = this.$route.params.request_id;
      this.showRunRequestModal = true;
      this.setTimeForMenuTitle = setTimeout(() => {
        this.eventBus.$emit('menutTitle', 'Submitted Request');
      }, 0);
      this.showAccessRights = true;
    }
    this.requestor = this.getUserName();
    this.getRequestStatus();
    this.searchFields();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.reRunReq = null;
          this.showRunRequestModal = true;
        }
        if (this.showRunRequestModal) {
          this.setTimeFormActions = setTimeout(() => {
            this.eventBus.$emit('createFormActions', actionName);
          }, 0);
        }
        if (actionName === 'download' && !this.showRunRequestModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'template/getSubmitRequestList',
            'submitted-request',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    showHidePrompModal(flag, item) {
      this.reRunReq = flag ? 'runReqPrompt' : null;
      this.showPromptDialog = flag;
      this.retryDtls = item;
    },
    withWithaoutPara(type) {
      if (type === 'Yes') {
        this.retryDtls.withPara = 'Yes';
      } else if (type === 'No') {
        this.retryDtls.withPara = 'No';
      }
      this.showPromptModal = false;
      this.showHideRetryModal(true);
      this.showPromptDialog = false;
    },
    getUserName() {
      const tempName = store.state.auth.userName;
      const name = tempName.slice(tempName.lastIndexOf('-') + 1);
      return name;
    },
    showHideUserModal(flag) {
      this.showUserModal = flag;
    },
    selectedUser(item) {
      this.requestor = item.name;
      this.requestorId = item.user_id;
      this.showUserModal = false;
    },
    showHideViewReqModal(flag, item) {
      this.showViewReqModal = flag;
      this.reqDetails = item;
    },
    showHideRetryModal(flag) {
      this.showRunRequestModal = flag;
    },
    resetModal() {
      this.editMode = false;
      if (this.$route.params.request_id) {
        this.$router.push('/dashboard/request/submittedRequest');
      }
    },
    hideRunReqModal() {
      this.showRunRequestModal = false;
    },
    searchFields() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        request_id: this.requestId,
        name: this.name,
        interval: this.numOfDays,
        start_date: this.submittedDate
          ? moment(this.submittedDate).format(appStrings.DATEFORMAT)
          : null,
        end_date: this.completedDate,
        status: this.selectedStatus.text,
        user_id: this.requestorId
      };
      this.loader = true;
      this.$store
        .dispatch('template/getSubmitRequestList', this.payload)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.data = response.data.data.page;
            this.data = this.data.map(key => {
              key.invalidate =
                key.parent && key.output_format_code !== 'SBLANK'
                  ? true
                  : false;
              return key;
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRequestStatus() {
      this.loader = true;
      this.$store
        .dispatch('template/getLOVBySetType', 'REQUEST_STATUS')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.statusType = getValueAndText;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(rowData) {
      this.$emit('selectedRequestNum', rowData);
    },
    updateRequestList() {
      this.searchFields();
    },
    clearFields() {
      if (this.userType === 'ADMIN') {
        this.requestor = null;
      }
      this.requestId = null;
      this.name = null;
      this.numOfDays = null;
      this.submittedDate = null;
      this.completedDate = null;
      this.selectedStatus = {
        value: null,
        text: null
      };
      this.searchFields();
    },
    truncateString(str) {
      const num = 35;
      if (str.length > num) {
        return str.slice(0, num) + '...';
      } else {
        return str;
      }
    },
    invalidateReqeust(requestId) {
      this.loader = true;
      this.$store
        .dispatch('template/invalidateReqeustByRequestId', requestId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = `${response.data.message} - ${response.data.data}`;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
  },
  destroyed() {
    clearTimeout(this.setTimeFormActions);
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeForMenuTitle);
  }
};
