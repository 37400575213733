import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getProcessedPaymentDetails = (context, payload) => {
    return http.getApi(URL_UTILITY.getProcessedPaymentDetailsUrl, payload)
};
const getErrorPaymentDetails = (context, payload) => {
    return http.getApi(URL_UTILITY.getErrorPaymentDetailsUrl, payload)
};
const reprocessErrorPaymentById = (context, payload) => {
    return http.postApi(URL_UTILITY.getErrorPaymentDetailsUrl, payload)
};
const getHostToHostBankTransferPaymentGrid = (context, payload) => {
    return http.getApi(URL_UTILITY.getHostToHostBankTransferGridUrl, payload);
};
const getFilePendingList = (context, payload) => {
    return http.getApi(URL_UTILITY.getHostToHostBankTransferGridUrl + '-new', payload);
};
const getProcessedList = (context, payload) => {
    return http.getApi(URL_UTILITY.getHostToHostBankTransferGridUrl + '-process', payload);
};
const getApprovedList = (context, payload) => {
    return http.getApi(URL_UTILITY.getHostToHostBankTransferGridUrl + '-approved', payload);
};
const getBankBatchLov = (context, payload) => {
    return http.getApi(URL_UTILITY.getBankBatchLovUrl, payload)
};
const getPaymentBatchLov = (context, payload) => {
    return http.getApi(URL_UTILITY.getPaymentBatchLovUrl, payload)
};
const getPaymentDetailsHdrById = (context, payload) => {
    return http.getApi(URL_UTILITY.getHostToHostBankTransferGridUrl + '/' + payload.bankBatchId + '/payments');
};
const getRejectingPaymentById = (context, payload) => {
    return http.postApi(URL_UTILITY.getHostToHostBankTransferGridUrl + '/payments/reject', payload.h2HPaymentDetails);
};
// const addBankPaymentDetails = (context, payload) => {
//     return http.postApi(URL_UTILITY.getHostToHostBankTransferGridUrl, payload);
// };
const addBankPaymentDetails = (context, payload) => {
    return http.postApi(URL_UTILITY.getHostToHostBankTransferGridUrl + '/direct', payload);
};

const saveAdvSearchFilters = (context, payload) => {
    context.commit('updateAdvSearchFilters', payload);
  }
export default {
    getHostToHostBankTransferPaymentGrid,
    getBankBatchLov,
    getPaymentBatchLov,
    getPaymentDetailsHdrById,
    getRejectingPaymentById,
    addBankPaymentDetails,
    getProcessedPaymentDetails,
    getErrorPaymentDetails,
    reprocessErrorPaymentById,
    saveAdvSearchFilters,
    getFilePendingList,
    getProcessedList,
    getApprovedList
};