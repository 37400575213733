import http from '../../../../app/services/httpServices';
import URL_UTILITY from '../../../../app/utility/url.utility';

const getGstrNineData = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gstr9-summary', payload);
};

const getGstrNineTabsData = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gstr9-summary-detail', payload);
};

export default {
  getGstrNineData,
  getGstrNineTabsData
};
