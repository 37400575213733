const setLoading = (state, loader) => {
  state.loading = loader;
};
const setLoadingFlag = (state, loader) => {
  state.loadingFlag = loader;
};
const setscr2ModalFlag = (state, flag) => {
  state.scr2ModalFlag = flag;
};
const setscr2Modal2Flag = (state, flag) => {
  state.scr2Modal2Flag = flag;
};

export default {
  setLoading,
  setLoadingFlag,
  setscr2ModalFlag,
  setscr2Modal2Flag
};
