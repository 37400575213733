import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getCurrencyPagination = async (context, payload) => {
  const url = URL_UTILITY.getCurrencyDataPaginationUrl
    .replace('{page}', payload.currentPage)
    .replace('{limit}', payload.perPage)
    .replace('{key}', payload.search);
  const response = http.getApi(url);
  return response;
};

const updateCurrencyData = (context, payload) => {
  const url = URL_UTILITY.updateCurrencyDataUrl.replace(
    '{currencyId}',
    payload.currencyId
  );
  const response = http.putApi(url, payload.currencyData);
  return response;
};

const getCurrencyById = (context, currencyId) => {
  const url = URL_UTILITY.updateCurrencyDataUrl.replace(
    '{currencyId}',
    currencyId
  );
  return http.getApi(url);
};

const addCurrencyData = (context, payload) => {
  const url = URL_UTILITY.addCurrencyUrl;
  const response = http.postApi(url, payload.currencyData);
  return response;
};

export default {
  getCurrencyPagination,
  updateCurrencyData,
  addCurrencyData,
  getCurrencyById
};
