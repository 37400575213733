import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getTaxRegimeAccount = (context, payload) => {
  const url = URL_UTILITY.getTaxRegimeAccountUrl;
  return http.getApi(url, payload);
};

export default {
    getTaxRegimeAccount
};