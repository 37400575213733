import store from '../../../../../../store';
import Address from '../../../address';
export default {
  name: 'address',
  components: {
    Address
  },
  data() {
    return {
      unsubscribe: null,
      timeout: null,
      addressIndex: null,
      showAddAddress: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      editMode: false,
      loader: false,
      addressList: [
        {
          active: true,
          address_id: null,
          address: null,
          employee_id: null,
          emp_address_sequence: null,
          address_type: null
        }
      ],
      addressFields: [
        {
          key: 'address'
        },
        {
          key: 'address_type'
        },
        {
          key: 'active'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.getAddressList(store.state.shared.empId);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditAddress();
        } else if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditAddress() {
      const addressDetail = this.addressList.map(address => {
        return {
          active: address.active,
          address_id: address.address_id,
          emp_address_sequence: address.emp_address_sequence
        };
      });
      const payload = {
        address_details: addressDetail,
        employee_id: store.state.shared.empId
      };
      this.loader = true;
      this.$store
        .dispatch('hrms/addEditAddress', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getAddressList(empId) {
      this.loader = true;
      this.$store
        .dispatch('hrms/getAddressList', empId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length > 0) {
            this.addressList = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.addressList.push({
          active: true,
          address_id: null,
          address: null,
          employee_id: null,
          emp_address_sequence: null,
          address_type: null
        });
      }
    },
    removeRow(index) {
      this.addressList.splice(index, 1);
    },
    resetAddressModal() {
      this.showAddAddress = false;
    },
    openAddressModal(index) {
      this.addressIndex = index;
      this.showAddAddress = true;
      this.timeout = setTimeout(() => {
        this.eventBus.$emit('createPartyAddress', 0);
      }, 0);
    },
    getAddressObj(address) {
      this.showAddAddress = false;
      this.addressList[this.addressIndex].address =
        address.address_1 +
        ' ' +
        address.address_2 +
        ' ' +
        address.address_line;
      this.addressList[this.addressIndex].address_id = address.address_id;
      this.addressList[this.addressIndex].address_type =
        address.address_type.value;
    },
    rowSelected() {}
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.timeout);
  }
};
