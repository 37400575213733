import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getCostCenterGridData = (context, payload) => {
    return http.getApi(URL_UTILITY.getCostCenterGridDataUrl, payload);
};
const getCostCenterDataByHdrId = (context, payload) => {
    const url = URL_UTILITY.getCostCenterDataByHdrIdUrl + '/' + payload.rgpHdrId + '/requests';
    return http.getApi(url);
};
const getWareHouseGridData = (context, payload) => {
    return http.getApi(URL_UTILITY.getWareHouseGridDataUrl, payload)
};
const getWareHouseDataByHdrId = (context, payload) => {
    const url = URL_UTILITY.getWareHouseDataByHdrIdUrl + '/' + payload.rgpHdrId + '/requests';
    return http.getApi(url);
};

const addEditCostCenterData= (context, payload) => {
    return http.postApi(URL_UTILITY.getCostCenterGridDataUrl, payload);
  };
  const addEditWarehouseGridData= (context, payload) => {
    return http.postApi(URL_UTILITY.getWareHouseGridDataUrl, payload);
  };
  const updateCostCenterDetails=(context, payload)=>{
    return http.putApi(URL_UTILITY.getCostCenterDataByHdrIdUrl+'/'+payload.modifiedStatus+'/'+payload.rgpHdrId+'/request')
  }
export default {
    getCostCenterGridData,
    getCostCenterDataByHdrId,
    getWareHouseGridData,
    getWareHouseDataByHdrId,
    addEditCostCenterData,
    addEditWarehouseGridData,
    updateCostCenterDetails
};
