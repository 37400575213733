import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import AddEmployee from './addEmployee';
import EmployeeDetails from './employeeDetails';
import hrOrgList from '../../onlineApproval/internalWorkFlow/addInternalWorkflow/department';
import ConcatSegment from './employeeDetails/concatSegment';
export default {
  name: 'Employee',
  components: {
    ModelSelect,
    DatePicker,
    AddEmployee,
    EmployeeDetails,
    hrOrgList,
    ConcatSegment
  },
  props: { showEmployeeModal: Boolean },
  watch: {
    currentPage: function() {
      this.getEmployeeLis();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getEmployeeLis();
    }
  },
  data() {
    return {
      payload: {},
      unsubscribe: null,
      actionName: null,
      empName: '',
      showEmployeeDetails: false,
      loader: false,
      showAlert: false,
      showAddEmpModal: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      setTime: null,
      stickyHeader: true,
      totalRows: null,
      selectFilter: false,
      searchForm: {
        emp_status: null,
        joining_from: null,
        employee_num: null,
        start_date_end_date: null,
        legalEntity: {
          value: null,
          text: null
        },
        hrms_org_key_flexfield_comb_id: null,
        hrms_org_key_flexfield_comb_text: null,
        joining_to: null,
        le_id: null,
        employee_name: null,
        designation_vset: null,
        supervisor_num: null
      },
      selectedEmpStatus: {
        value: null,
        text: null
      },
      showSegmentMondal: false,
      showValueSetModal: false,
      vsetCode: null,
      empStatusList: [],
      employeesList: [],
      employeeFields: [
        {
          key: 'employee_num',
          label: 'Emp No.'
        },
        {
          key: 'name'
        },
        {
          key: 'flex_value',
          class: 'd-none'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'emp_status',
          label: 'Status'
        }
      ]
    };
  },
  mounted() {
    this.getLookupData(appStrings.LOOKUPTYPE.EMPLOYMENT_STATUS);
    // this.getEmployeeLis();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddEmpModal = true;
        }

        if (
          actionName === 'download' &&
          !this.showAddEmpModal &&
          !this.showEmployeeDetails
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'hrms/getEmployeeList',
            'hrms/employee',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getEmployeeLis() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        emp_status: this.selectedEmpStatus.value,
        joining_from: this.searchForm.joining_from,
        employee_num: this.searchForm.employee_num,
        hrms_org_key_flexfield_comb_id: this.searchForm
          .hrms_org_key_flexfield_comb_id,
        joining_to: this.searchForm.joining_to,
        le_id: this.searchForm.legalEntity.value,
        employee_name: this.searchForm.employee_name,
        designation_vset: null,
        supervisor_num: this.searchForm.supervisor_num
      };
      this.loader = true;
      this.$store
        .dispatch('hrms/getEmployeeList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.employeesList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    applySearch() {
      this.currentPage = 1;
      this.getEmployeeLis();
    },
    clearSearch() {
      this.searchForm = {
        emp_status: null,
        joining_from: null,
        employee_num: null,
        legalEntity: {
          value: null,
          text: null
        },
        hrms_org_key_flexfield_comb_id: null,
        hrms_org_key_flexfield_comb_text: null,
        joining_to: null,
        le_id: null,
        employee_name: null,
        designation_vset: null,
        supervisor_num: null
      };
      this.selectedEmpStatus = {
        value: null,
        text: null
      };
      this.employeesList = [];
      // this.getEmployeeLis();
    },
    rowSelected(item) {
      this.empName = item.name;
      this.$emit('selectedUserName', item);
      this.$store.dispatch('shared/setEmpId', item.employee_id);
      if (this.showEmployeeDetails || this.showEmployeeModal) {
        this.$emit('selectedSuvervisor', item);
      } else {
        this.showEmployeeDetails = true;
      }
    },
    hideAddEmployeeModal() {
      this.showAddEmpModal = false;
    },
    closeEmployeeDetail() {
      this.showEmployeeDetails = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.searchForm.legalEntity.text = item.org_name;
        this.searchForm.legalEntity.value = item.org_id;
      }
      this.$store.dispatch(
        'shared/setUnsetOrgTypeId',
        this.searchForm.legalEntity
      );
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    openSegment(index) {
      if (!this.searchForm.legalEntity.text) {
        return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
      }
      this.assignmentListIndex = index;
      this.showSegmentMondal = true;
    },
    concatSegDetails(segment) {
      this.searchForm.hrms_org_key_flexfield_comb_text =
        segment.concatenated_segment_meaning;
      this.searchForm.hrms_org_key_flexfield_comb_id =
        segment.hr_org_key_flexfield_comb_id;
      this.showSegmentMondal = false;
    },
    closeSegmentModal() {
      this.showSegmentMondal = false;
    },
    getLookupData(lookupCode) {
      this.$store
        .dispatch('shared/getDataFromLookupsByLookupType', lookupCode)
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const salutationArr = result.map(type => {
              return {
                value: type.lookup_code,
                text: type.meaning
              };
            });
            this.empStatusList = salutationArr;
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.searchForm.legalEntity.text = null;
        this.searchForm.legalEntity.value = null;
      } 
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
