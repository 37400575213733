const updateAdvSearchFilters = (state, payload) => {
    state.advSearchFilters = payload;
}
const downloadFlag = (state, payload) => {
    state.downloadFlag = payload;
}

export default {
    updateAdvSearchFilters,
    downloadFlag
}