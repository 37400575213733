import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getFlexFieldAccounting = (context, paylaod) => {
  return http.getApi(URL_UTILITY.getFlexfieldAccountingUrl, paylaod);
};

const getInvestorRecovery = (context, payload) => {
  return http.getApi(URL_UTILITY.getLeaseInvestorRecoveryUrl, payload);
};

const getInvestorRecoveryDetails = (context, leaseInvestorRecoveryBatchId) => {
  return http.getApi(
    URL_UTILITY.getLeaseInvestorRecoveryUrl + '/' + leaseInvestorRecoveryBatchId
  );
};

const createInvestoryRecoveryBatch = (context, payload) => {
  return http.postApi(URL_UTILITY.getLeaseInvestorRecoveryUrl, payload);
};
const getTenantRecovery = (context, payload) => {
  return http.getApi(URL_UTILITY.getLeaseTenantRecoveryUrl, payload);
};

const getProjectBasedLocation = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProjectBasedLocationUrl +
      '/' +
      payload.prjType +
      '/' +
      payload.prjId
  );
};
const getTenantRecoveryDetails = (context, leaseTenantRecoveryBatchId) => {
  return http.getApi(
    URL_UTILITY.getLeaseTenantRecoveryUrl + '/' + leaseTenantRecoveryBatchId
  );
};
const addEditLeaseTenantRecovery = (context, payload) => {
  return http.postApi(URL_UTILITY.getLeaseTenantRecoveryUrl, payload);
};

const getInvestorRecoveryInvoiceByIdDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getInvestorRecoveryBatchInvoiceById.replace(
      '{batchId}',
      payload.batchId
    ),
    payload.searchParams
  );
};

const getInvestorRecoveryProcessByIdDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getInvestorRecoveryBatchProcessById.replace(
      '{batchId}',
      payload.batchId
    ),
    payload.searchParams
  );
};

const getInvestorRecoveryUnProcessByIdDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getInvestorRecoveryBatchUnProcessById.replace(
      '{batchId}',
      payload.batchId
    ),
    payload.searchParams
  );
};
const getTenantRecoveryInvoiceByIdDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getTenantRecoveryBatchInvoiceById.replace(
      '{batchId}',
      payload.batchId
    ),
    payload.searchParams
  );
};
//
const getTenantRecoveryProcessByIdDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.processTenantRecoveryDetailsUrl.replace(
      '{batchId}',
      payload.batchId
    ),
    payload.searchParams
  );
};

const getTenantRecoveryUnProcessByIdDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getTenantRecoveryBatchUnProcessById.replace(
      '{batchId}',
      payload.batchId
    ),
    payload.searchParams
  );
};
//
const invoiceTenantRecoveryDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseTenantRecoveryUrl +
      '/' +
      payload.lease_tenant_recovery_batch_id +
      '/process',
    payload
  );
};

const invoiceInvestorRecoveryDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseInvestorRecoveryUrl +
      '/' +
      payload.lease_investor_recovery_batch_id +
      '/process',
    payload
  );
};

const processTenantRecoveryDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseTenantRecoveryUrl +
      '/' +
      payload.lease_tenant_recovery_batch_id +
      '/approve',
    payload
  );
};

const processInvestorRecoveryDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseInvestorRecoveryUrl +
      '/' +
      payload.lease_investor_recovery_batch_id +
      '/approve',
    payload.approve_details
  );
};

const processInvestorRecoveryRequest = (context, payload) => {
  return http.postApi(
    URL_UTILITY.processInvestorRecoveryRequestUrl.replace(
      '{type}',
      payload.type
    ),
    payload.requestBody
  );
};

const getProcessRecoveryDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getLeaseTenantRecoveryUrl + '/' + payload.batchId + '/process'
  );
};
const getInvestorProcessRecoveryDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getLeaseInvestorRecoveryUrl +
      '/' +
      payload.batchId +
      '/process',
    payload.pagination
  );
};

const getCalculatedLeaseRecoveryAmount = (context, payload) => {
  return http.getApi(URL_UTILITY.getCalculatedLeaseRecoveryAmountUrl, payload);
};

const getLeasePrjDetailByFmsPrjId = (context, fmsPrjId) => {
  return http.getApi(URL_UTILITY.getLeaseProjectsListUrl + `/fms/${fmsPrjId}`);
};

const saveAdvSearchFilters = (context, payload) => {
  context.commit('updateAdvSearchFilters', payload);
};
const deleteInvestorRecoveryBill = (context, payload) => {
  return http.postApi(URL_UTILITY.getLeaseInvestorRecoveryUrl + '/' + 'deleteInvestorBill ', payload.invoiceBill);
};
export default {
  getFlexFieldAccounting,
  getInvestorRecovery,
  getTenantRecovery,
  getProjectBasedLocation,
  createInvestoryRecoveryBatch,
  addEditLeaseTenantRecovery,
  getTenantRecoveryDetails,
  getInvestorRecoveryDetails,
  getInvestorRecoveryInvoiceByIdDetails,
  getInvestorRecoveryProcessByIdDetails,
  getTenantRecoveryInvoiceByIdDetails,
  getTenantRecoveryProcessByIdDetails,
  invoiceTenantRecoveryDetails,
  processInvestorRecoveryRequest,
  invoiceInvestorRecoveryDetails,
  processTenantRecoveryDetails,
  processInvestorRecoveryDetails,
  getProcessRecoveryDetails,
  getInvestorProcessRecoveryDetails,
  getCalculatedLeaseRecoveryAmount,
  getLeasePrjDetailByFmsPrjId,
  getInvestorRecoveryUnProcessByIdDetails,
  getTenantRecoveryUnProcessByIdDetails,
  saveAdvSearchFilters,
  deleteInvestorRecoveryBill
};
