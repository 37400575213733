import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getlegalEntityList = (context, payload) => {
  return http.getApi(URL_UTILITY.getCodUrl + '/legal-entity', payload);
};

const getBankAccNumList = (context, payload) => {
  return http.getApi(URL_UTILITY.getCodUrl + '/bank-account', payload);
};

const getCodRecoData = (context, payload) => {
  return http.getApi(URL_UTILITY.getCodUrl + '/bank-statement', payload);
};

const uploadBankStmt = (context, payload) => {
  return http.postApi(URL_UTILITY.getCodUrl + '/upload-bank-stmt', payload);
};

const uploadMisDoc = (context, payload) => {
  return http.postApi(URL_UTILITY.getCodUrl + '/upload-mis', payload);
};

export default {
  getlegalEntityList,
  getBankAccNumList,
  getCodRecoData,
  uploadBankStmt,
  uploadMisDoc
};
