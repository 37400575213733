import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getTwoSixASInvoiceList = (context, payload) => {
  const url = URL_UTILITY.getTwoSixASInvoiceUrl;
  return http.getApi(url, payload);
};

const getTwoSixASInvoiceDetailsById = (context, customerTrxNumber) => {
  return http.getApi(
    URL_UTILITY.getTwoSixASInvoiceUrl +
      'LineDtls?customerTrxNumber=' +
      customerTrxNumber
  );
};

const getTwoSixASChargeDetailsById = (context, customerTrxNumber) => {
  return http.getApi(
    URL_UTILITY.getTwoSixASInvoiceUrl +
      'ChbkDtls?customerTrxNumber=' +
      customerTrxNumber
  );
};

const getPeriodStartList = (context, payload) => {
  const url = URL_UTILITY.getPeriodStartListUrl;
  return http.getApi(
    url +
      '?qtr=' +
      payload.qtr +
      '&fy_start_date=' +
      payload.fy_start_date +
      '&fy_end_date=' +
      payload.fy_end_date
  );
};

const addUploadExcelList = (context, payload) => {
  const url = URL_UTILITY.addUploadExcelListUrl;
  return http.postApi(url, payload);
};

const getFinYearList = () => {
  const url = URL_UTILITY.getFinYearListUrl;
  return http.getApi(url);
};

const getPanNumForTwoSixUpload = (context, payload) => {
  const url = URL_UTILITY.getPanNumForTwoSixUploadUrl;
  return http.getApi(url + '?le_id=' + payload);
};

const getMappingColumnsForExcelDetail = (context, payload) => {
  const url = URL_UTILITY.getMappingColumnsForExcelDetailUrl;
  return http.getApi(url, payload);
};

const getMappingColumnsDataListForExcelDetail = (context, payload) => {
  const url = URL_UTILITY.getMappingColumnsDataListForExcelDetailUrl;
  return http.getApi(url, payload);
};

const getTempDataList = (context, payload) => {
  const url = URL_UTILITY.getTempDataUrl;
  return http.getApi(url, payload);
};

const postTempDataList = (context, payload) => {
  const url = URL_UTILITY.postTempDataUrl;
  return http.postApi(
    url +
      '?excel_import_hdr_id=' +
      payload.excel_import_hdr_id +
      '&menu_id=' +
      payload.menu_id +
      '&sub_menu_id=' +
      payload.sub_menu_id
  );
}



const getCustomerTanData = (context, payload) => {
  return http.getApi(URL_UTILITY.getCustomerTanDataUrl, payload);
};

const postTempDataExcel = (context, payload) => {
  const url = URL_UTILITY.postTempDataExcelUrl;
  return http.postApi(
    url +
      '?le_id=' +
      payload.le_id +
      '&pan_no=' +
      payload.pan_no +
      '&financial_start_date=' +
      payload.financial_start_date +
      '&financial_end_date=' +
      payload.financial_end_date +
      '&quarter=' +
      payload.quarter +
      '&period_start_date=' +
      payload.period_start_date +
      '&period_end_date=' +
      payload.period_end_date
  );
};

const getRowDataById = (context, payload) => {
  const url = URL_UTILITY.getRowDataByIdUrl;
  return http.getApi(url, payload);
};

const postMappedColumnsForExcel = (context, payload) => {
  const url = URL_UTILITY.postMappedColumnsForExcelUrl;
  return http.postApi(url, payload);
};

const getTanRateDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getTanRateDetailsUrl, payload);
};

const getTwoSixASList = (context, payload) => {
  return http.getApi(URL_UTILITY.getTwoSixASListUrl + `?_page=${payload._page}&_limit=${payload._limit}&le_id=${payload.le_id}&pan_no=${payload.pan_no}&financial_start_date=${payload.financial_start_date}&financial_end_date=${payload.financial_end_date}`)
}
export default {
  getTwoSixASInvoiceList,
  getTwoSixASInvoiceDetailsById,
  getTwoSixASChargeDetailsById,
  getPeriodStartList,
  getFinYearList,
  getPanNumForTwoSixUpload,
  addUploadExcelList,
  getMappingColumnsForExcelDetail,
  getMappingColumnsDataListForExcelDetail,
  postMappedColumnsForExcel,
  getTempDataList,
  postTempDataExcel,
  getRowDataById,
  getCustomerTanData,
  getTanRateDetails,
  getTwoSixASList,
  postTempDataList
};
