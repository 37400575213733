import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getTaxRegimeList = (context, payload) => {
  const url = URL_UTILITY.getTaxRegimeListUrl;
  return http.getApi(url, payload);
}

const addEditTaxRegime = (context, payload) => {
  const url = URL_UTILITY.addEditTaxRegimeUrl;
  return http.postApi(url, payload);
}

export default {
  getTaxRegimeList,
  addEditTaxRegime
};
