import store from '../../../../../../store/';
import { BasicSelect } from 'vue-search-select';
import CreateReceiptMethod from '../createReceiptPaymentMethod/';
export default {
  name: 'receiptMethods',
  components: { BasicSelect, CreateReceiptMethod },
  data() {
    return {
      unsubscribe: null,
      selectedRowData: {},
      showMethod: '',
      branchIndex: 0,
      accountIndex: 0,
      bankAccountId: null,
      partyId: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      selectedBranch: {
        value: null,
        text: null
      },
      branchList: [],
      dropDownBranchList: [],
      branchAccountList: [],
      accountReceiptMethodList: [],

      tableFields: [
        {
          key: 'bank_account_id',
          class: 'd-none'
        },
        {
          key: 'bank_branch_id',
          class: 'd-none'
        },
        {
          key: 'party_receipt_method_id',
          class: 'd-none'
        },
        {
          key: 'branch_name'
        },
        {
          key: 'account_name'
        },
        {
          key: 'module_name'
        },
        {
          key: 'project_name'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'receipt_methods_name',
          label: 'Methods'
        },
        {
          key: 'receipt_methods_description',
          label: 'Description'
        },
        {
          key: 'cash_ccid',
          label: 'Cash CCID'
        },
        {
          key: 'cash_code',
          class: 'd-none'
        },
        {
          key: 'cash_meaning',
          class: 'd-none'
        },
        {
          key: 'cash_clearing_ccid',
          label: 'Cash Clearing CCID'
        },
        {
          key: 'clear_code',
          class: 'd-none'
        },
        {
          key: 'clear_meaning',
          class: 'd-none'
        },
        {
          key: 'bank_charges_ccid',
          label: 'Bank Charges CCID'
        },
        {
          key: 'bank_code',
          class: 'd-none'
        },
        {
          key: 'bank_meaning',
          class: 'd-none'
        },
        {
          key: 'bank_error_ccid',
          label: 'Bank Error CCID'
        },
        {
          key: 'error_code',
          class: 'd-none'
        },
        {
          key: 'error_meaning',
          class: 'd-none'
        },
        {
          key: 'exchange_charges_ccid',
          label: 'Exchange Charges CCID',
          class: ''
        },
        {
          key: 'exchange_code',
          class: 'd-none'
        },
        {
          key: 'exchange_meaning',
          class: 'd-none'
        },
        {
          key: 'unapplied_code',
          label: 'Unapplied CCID'
        },
        {
          key: 'unidentified_code',
          label: 'Unidentified CCID'
        },
        {
          key: 'active'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showMethod = 'show';
        }
      }
    });
    this.partyId = store.state.party.partyId;
    this.getReceiptMethodsList();
  },
  methods: {
    getReceiptMethodsList() {
      this.loader = true;
      this.$store
        .dispatch('party/getReceiptMethodsList', this.partyId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.branchList = response.data.data.bank_branches;
            const bankBranchesArr = response.data.data.bank_branches.map(
              (bank, index) => {
                return {
                  text: bank.branch_name,
                  value: index
                };
              }
            );
            this.dropDownBranchList = bankBranchesArr;
            this.selectedBranch.value = bankBranchesArr[0].index;
            this.selectedBranch.text = bankBranchesArr[0].text;
            this.branchAccountList = this.branchList[0].bank_accounts;
            this.bankAccountId = this.branchList[
              this.branchIndex
            ].bank_accounts[this.accountIndex].bank_account_id;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedBranchFun(branch) {
      this.branchIndex = branch.value;
      this.branchAccountList = this.branchList[branch.value].bank_accounts;
      this.selectedBranch.value = branch.value;
      this.selectedBranch.text = branch.text;
    },
    selectedBranchIndex(index) {
      this.accountIndex = index;
      this.bankAccountId = this.branchList[this.branchIndex].bank_accounts[
        this.accountIndex
      ].bank_account_id;
    },
    accountRowSelected(item) {
      this.showMethod = 'show';
      this.eventBus.$emit('selectedRowData', item);
    },
    showHideCreateMethod() {
      this.showMethod = 'hide';
    },
    getMethodDataResponse(response) {
      this.showMethod = 'hide';
      this.showAlert = true;
      this.isSuccess = true;
      this.responseMsg = response.data.message;
      const branchList = response.data.data.bank_branches;
      this.branchAccountList = branchList[this.branchIndex].bank_accounts;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
