import { ModelSelect, BasicSelect } from 'vue-search-select';

export default {
  name: 'flexfieldDetails',
  components: {
    ModelSelect,
    BasicSelect
  },
  watch: {
    'flexfieldForm.selectedModule': function(){
      if(this.flexfieldForm.selectedModule.value) {
        this.getFlexfieldMasterLovList();
      }
    },
    'flexfieldForm.selectedFlexFieldMaster': function(){
      if(this.flexfieldForm.selectedFlexFieldMaster.value) {
        this.getFlexfieldSegmentLovList();
      }
    }
    
  },
  data() {
    return {
      
      picked: '',
      selected: '',
      segCatArr: [],
      valueSetArr: [],
      moduleArr: [],
      flexfieldArr: [],
      moduleList: '',
      responseMsg: null,
      showAlert: false,
      isSuccess: false,
      editMode: false,
      showFlexFieldModal: false,
      flexfieldDetails: null,
      loader: false,
      data: [],

      flexfieldForm: {
        auto_insertion: false,
        flexfield_id:0,
        flexfield_master_id: null,
        flexfield_name: null,
        freeze_flag: false,
        flexfield_segments: null,
        selectedModule: {
          value: null,
          text: null
        },
        selectedFlexFieldMaster: {
          value: null,
          text: null
        },
        
      },
      fields: [
        {
          key: 'select_box',
          label: 'Select Box'
        },
        {
          key: 'segment',
          label: 'Segments'
        },
        {
          key: 'segment_name',
          label: 'Segment Name'
        },
        {
          key: 'segment_category',
          label: 'Segment Category'
        },
        {
          key: 'value_set_name',
          label: 'Value Set'
        },
        {
          key: 'segment_sequence',
          label: 'Sequence'
        }
      ]
    };
  },

  mounted() {
    this.eventBus.$off('segAdd');
    this.eventBus.$on('segAdd', () => {
      if(this.data.length < 7){
        this.segmentAdd();
      }
      });
    this.getFlexfieldValueSetLovList();
    this.getFlexfieldModuleLovList();
    this.eventBus.$off('ffDetails');
    this.eventBus.$on('ffDetails', obj => {
      this.editMode = false;
      this.getflexfieldHeader(obj.flexfield_id);
    });
    this.eventBus.$off('flexfieldFormActions');
    this.eventBus.$on('flexfieldFormActions', actionName => {
      if (actionName === 'add') {
        this.editMode = true;
        this.flexfieldForm.flexfield_segment_id = null;
      }
      if (actionName === 'edit' || actionName === 'update') {
        this.editMode = true;
      }
      if (actionName === 'save' && this.editMode) {
        this.addEditFlexfield();
      }
      if (actionName === 'upload') {
        this.eventBus.$emit('commonUpload', { id: this.flexfieldForm.flexfieldId });
      }
    });
  },
  methods: {
    segmentAdd() {
      for (let i = 1; i <= 7; i += 1) {
        this.data.push({
          segment: 'SEGMENT' + i,
          segment_name: null,
          segment_category: {
            value:null,
            text:null
          },
          value_set_name: {
            value:null,
            text:null
          },
          segment_sequence: i,
          flexfield_segment_id: 0
        });
      }
    },
    getFlexfieldSegmentLovList() {
      const flexField_master_id = this.flexfieldForm.selectedFlexFieldMaster.value
      this.loader = true;
      this.$store
        .dispatch('flexfield/getFlexfieldSegmentLovList', flexField_master_id)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.flexfield_segment_master_id,
                text: type.segment_category
              };
            });
            this.segCatArr = getValueAndText;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getFlexfieldValueSetLovList() {
      this.loader = true;
      this.$store
        .dispatch('flexfield/getFlexfieldValueSetLovList')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_set_hdr_id,
                text: type.value_set_name
              };
            });
            this.valueSetArr = getValueAndText;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getFlexfieldMasterLovList() {
      const module_id = this.flexfieldForm.selectedModule.value
      this.loader = true;
      this.$store
        .dispatch('flexfield/getFlexfieldMasterLovList', module_id)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.flexfield_master_id,
                text: type.flexfield_master_name
              };
            });
            this.flexfieldArr = getValueAndText;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getFlexfieldModuleLovList() {
      this.loader = true;
      this.$store
        .dispatch('flexfield/getFlexfieldModuleLovList')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.module_id,
                text: type.module_name
              };
            });
            this.moduleArr = getValueAndText;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getflexfieldHeader(flexfieldId) {
      this.loader = true;
      this.$store
        .dispatch('flexfield/getFlexfieldHeaderList', flexfieldId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            const detail = response.data.data.details;
            this.flexfieldForm.flexfield_name = result.flexfield_name;
            this.flexfieldForm.freeze_flag = result.freezeflag == '0'? false: true;
            this.flexfieldForm.auto_insertion = result.auto_insertion == '0'? false: true;
            this.flexfieldForm.flexfield_id = result.flexfield_id;
            this.flexfieldForm.flexfield_master_id = result.flexfield_master_id;
            this.flexfieldForm.selectedModule = {
              value: result.module_id,
              text: result.module_name
            };
            this.flexfieldForm.selectedFlexFieldMaster = {
              value: result.flexfield_master_id,
              text: result.flexfield_master_name
            };
            
            
            if (detail && detail.length > 0) {
              const detailList = detail.map(segObj => {
                return {
                  select_box: false,
                  segment: segObj.segment ,
                  segment_name: segObj.segment_name,
                  segment_category: {
                    value:segObj.flexfileld_segment_master_id,
                    text:segObj.segment_category}, 
                  value_set_name: {
                    value:segObj.value_set_hdr_id,
                    text:segObj.value_set_name}, 
                  segment_sequence: segObj.segment_sequence,
                  flexfield_segment_id: segObj.flexfield_segment_id
                };
              });
              this.data = detailList;
            }
            if( detail.length<7){
              
              for (let i = detail.length+1; i <= 7; i += 1) {
                this.data.push({
                  segment: 'SEGMENT' + i,
                  segment_name: null,
                  segment_category: {
                    value:null,
                    text:null
                  },
                  value_set_name: {
                    value:null,
                    text:null
                  },
                  segment_sequence: i,
                  flexfield_segment_id: 0
                  
                  
                });
              }
            }
            
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    resetModal() {},

    addEditFlexfield() {
     
      const filteredSegmentDetail = this.data.filter(flexfield=> flexfield.segment_name !==null 
        && flexfield.segment!==null  && flexfield.value_set_hdr_id!==null  && flexfield.value_set_name!==null  && flexfield.sequence!==null )
        
          const details = filteredSegmentDetail.map(flexfieldDetails => {
            return {
              flexfield_segment_id: flexfieldDetails.flexfield_segment_id,
              segment: flexfieldDetails.segment,
              flexfield_segment_master_id: flexfieldDetails.segment_category.value,
              segment_category:flexfieldDetails.segment_category.text,
              segment_name: flexfieldDetails.segment_name,
              sequence: flexfieldDetails.segment_sequence,
              value_set_hdr_id: flexfieldDetails.value_set_name.value,
              value_set_name:flexfieldDetails.value_set_name.text
  
            };
          });
        const payload = {
          flexfield_name: this.flexfieldForm.flexfield_name,
          freeze_flag: this.flexfieldForm.freeze_flag,
          auto_insertion: this.flexfieldForm.auto_insertion,
          flexfield_id: this.flexfieldForm.flexfield_id,
          flexfield_master_id: this.flexfieldForm.selectedFlexFieldMaster.value,
          flexfield_segments: details ? details : null
        };
        this.loader = true;
        this.$store
          .dispatch('flexfield/addEditFlexfield', payload)
          .then(response => {
            this.$emit('updateFlexfieldList');
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.flexfieldForm.flexfield_segment_id =
                response.data.data.flexfield_segment_id;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.editMode = true;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
        .catch(() => {
          this.loader = false;
        });
      
    }
  }
};
