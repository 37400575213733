<template>
  <div>
    <Header v-if="showHeader" />
    <div class="bodyWrapper">
      <router-view />
    </div>
    <reports v-if="this.$route.name !== 'home'" />
    <vendorPortal
      v-if="
        this.$route.name !== 'home'"
    />
    <Footer />
  </div>
</template>

<script>
import Header from './components/shared/header';
import Footer from './components/shared/footer';
import reports from './components/reports';
import vendorPortal from './components/vendorPortal';

export default {
  components: {
    Header,
    Footer,
    reports,
    vendorPortal
  },
  data() {
    return {
      showHeader: true
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.user;
    }
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setStandAloneSummary') {
        this.showHeader = state.payload;
      }
    });
  }
};
</script>
