<template>
  <div
    class="btn-action"
    style="position: absolute; right: 40px; z-index: 1; top: -2px"
  >
    <b-row class="justify-content-end" style="font-size: 1rem">
      <div class="p-1" v-if="accessRights.addAllow">
        <b-button block title="Show Hide" class="p-1" @click="showHideModal()"
          >Show/Hide
        </b-button>
      </div>
      <div class="p-1" v-if="accessRights.addAllow">
        <b-button block title="Add" class="p-1" @click="usersAction('add')">
          <b-icon icon="plus" aria-hidden="true"></b-icon>
        </b-button>
      </div>

      <div class="p-1" v-if="accessRights.editAllow">
        <b-button
          block
          title="Update"
          class="p-1"
          @click="usersAction('update')"
        >
          <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
        </b-button>
      </div>

      <div class="p-1" v-if="accessRights.addAllow">
        <b-button block title="Save" class="p-1" @click="usersAction('save')">
          <b-icon icon="check" aria-hidden="true"></b-icon>
        </b-button>
      </div>

      <div class="p-1" v-if="accessRights.deleteAllow">
        <b-button
          block
          title="Delete"
          class="p-1"
          @click="usersAction('delete')"
        >
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </b-button>
      </div>

      <div class="p-1" v-if="accessRights.uploadAllow">
        <b-button
          block
          title="Upload"
          class="p-1"
          @click="usersAction('upload')"
        >
          <b-icon icon="upload" aria-hidden="true"></b-icon>
        </b-button>
      </div>

      <div class="p-1" v-if="accessRights.downloadAllow">
        <b-button
          block
          title="Download"
          class="p-1"
          @click="usersAction('download')"
        >
          <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
        </b-button>
      </div>
      <div class="p-1" v-if="accessRights.printAllow">
        <b-button block title="Print" class="p-1" @click="usersAction('print')">
          <b-icon icon="printer" aria-hidden="true"></b-icon>
        </b-button>
      </div>
      <div class="p-1" v-if="accessRights.approvalAllow">
        <b-button
          block
          title="Action"
          class="p-1"
          @click="usersAction('approval')"
        >
          <b-icon icon="arrow-up-circle" aria-hidden="true"></b-icon>
        </b-button>
      </div>
      <div class="p-1" v-if="accessRights.newTab">
        <b-button
          block
          title="Open New Window"
          class="p-1"
          @click="usersAction('newTab')"
        >
          <b-icon icon="arrow-up-right-square" aria-hidden="true"></b-icon>
        </b-button>
      </div>
    </b-row>
  </div>
</template>
<script>
import store from '../../../store/';
import { getAccessRightDataByMenuId } from '@/app/services/api';
export default {
  name: 'accessRightButton',
  watch: {
    '$route.name': function() {
      if (this.$router.currentRoute.name === 'dashboard') {
        this.activeResponsibility = 'Administrator';
      } else {
        this.activeResponsibility = '';
      }
    }
  },
  props: ['accessButtons'],
  data() {
    return {
      accessRights: {},
      activeResponsibility: 'Administrator',
      isCurrentPath: false,
      unsubscribe: null
    };
  },
  mounted() {
    this.checkActionName();
    if (store.state.shared.roleMappingId) {
      this.getAccessRightsData(store.state.shared.roleMappingId);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        this.changeModalBorder(actionName);
      }
    });
  },
  methods: {
    usersAction(actionName) {
      this.$store.dispatch('shared/setActionName', actionName);
      this.$emit('formActions', actionName);
    },
    /**
     * @method: getAccessRightsData(menuId: number)
     * @desc: Fetch users access rights for the add/edit/delete/ actions
     * @param: menuId: string
     */
    getAccessRightsData(menuId) {
      getAccessRightDataByMenuId(menuId)
        .then(response => {
          if (response && response.data && response.data.data) {
            const res = response.data.data;
            this.accessRights = {
              deleteAllow: res.delete_allow,
              downloadAllow: res.download_allow,
              uploadAllow: res.upload_allow,
              editAllow: res.edit_allow,
              addAllow: res.add_allow,
              printAllow: res.print_allow,
              approvalAllow: res.approval_allow
            };
            if (this.accessButtons) {
              this.accessRights = this.accessButtons;
            }
          }
        })
        .catch(err => {
          this.error = err;
        });
    },
    showHideModal() {
      let element = document.getElementsByClassName('modal-body');
      if (element[0].style.display === 'none') {
        element[0].style.display = '';
      } else {
        element[0].style.display = 'none';
      }
    },
    changeModalBorder(actionName) {
      let element = document.getElementsByClassName('modal-body');

      let cardElement = document.querySelector('div.col-md-12>div.card');

      if (element.length) {
        if (actionName === 'update' || actionName === 'add') {
          element[0].style.border = '3px solid red';
        } else {
          element[0].style.border = '5px solid lightgreen';
        }
      } else if (cardElement) {
        if (actionName === 'update' || actionName === 'add') {
          cardElement.style.border = '3px solid red';
        } else {
          cardElement.style.border = '1px solid rgba(0, 0, 0, 0.125)';
        }
      }
    },
    checkActionName() {
      const actionName = store.state.shared.actionName;
      this.changeModalBorder(actionName);
    }
  },
  beforeCreate() {
    let element = document.getElementById('access-menu-id');
    if (element && element.style) {
      element.style.display = 'none';
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$store.dispatch('shared/setActionName', null);
  },
  destroyed() {
    let element = document.getElementById('access-menu-id');
    if (element && element.style) {
      element.style.display = '';
    }
  }
};
</script>
