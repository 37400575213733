const setPartyId = (state, partyId) => {
  state.partyId = partyId;
};
const partyCustomerTabValue = (state, tabValue) => {
  state.customerTabValue = tabValue;
};
const partyProfileType = (state, profile) => {
  state.partyProfile = profile;
};
const setPartyname = (state, partyName) => {
  state.partyName = partyName;
};
const setPartyCustomerId = (state, customerId) => {
  state.customerId = customerId;
};
const setPartyType = (state, partyType) => {
state.partyType = partyType;
}
const setPartyShortName = (state, shortName) => {
  state.shortName = shortName;
}
export default {
  setPartyId,
  partyCustomerTabValue,
  partyProfileType,
  setPartyname,
  setPartyType,
  setPartyShortName,
  setPartyCustomerId
};

