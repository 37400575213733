import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getInventoryLocationMappingList = (context, payload) => {
  const url =URL_UTILITY.getInventoryLocationMappingListUrl;
  return http.getApi(url, payload);
}
const getInventoryLocationById = (context, payload) => {
  const url = URL_UTILITY.getInventoryLocationByIdUrl.replace('{inventoryLocMapId}', payload);
  return http.getApi(url);
}
const addEditInventoryLocation = (context, payload) => {
  const url = URL_UTILITY.getInventoryLocationByIdUrl.replace('{inventoryLocMapId}', payload.inventory_id);
  return http.postApi(url, payload.inventory_location_mapping_details);
}
export default {
  getInventoryLocationMappingList,
  getInventoryLocationById,
  addEditInventoryLocation
};
