import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getCrmMasterProjectList = (context, payload) => {
  const url = URL_UTILITY.getCrmMasterProjectListUrl;
  return http.getApi(url, payload);
};

const addEditCrmMasterProject = (context, payload) => {
  const url = URL_UTILITY.addEditCrmMasterProjectUrl;
  return http.postApi(url, payload);
};

const getCrmSectorList = (context, payload) => {
  const url = URL_UTILITY.getCrmSectorListUrl;
  return http.getApi(url, payload);
};

const addEditCrmSector = (context, payload) => {
  const url = URL_UTILITY.addEditCrmSectorUrl;
  return http.postApi(url, payload);
};

const getCrmPocketList = (context, payload) => {
  const url = URL_UTILITY.getCrmPocketListUrl;
  return http.getApi(url, payload);
};

const getCrmUnitList = (context, payload) => {
  const url = URL_UTILITY.getCrmUnitListUrl;
  return http.getApi(url, payload);
};

const addEditCrmUnit = (context, payload) => {
  const url = URL_UTILITY.addEditCrmUnitUrl;
  return http.postApi(url, payload);
};

// const getProjectUnitSpec = (context, payload) => {
//   const url = URL_UTILITY.getProjectUnitSpecUrl.replace('{prjUnitId}', payload.prjUnitId);
//   return http.getApi(url);
// };

const addEditCrmPocket = (context, payload) => {
  const url = URL_UTILITY.addEditCrmPocketUrl;
  return http.postApi(url, payload);
};
const getCrmTowerList = (context, payload) => {
  const url = URL_UTILITY.getCrmTowerListUrl;
  return http.getApi(url, payload);
};

// const getProjectUnitOtherSpec = (context, payload) => {
//     const url = URL_UTILITY.getProjectUnitOtherSpecUrl.replace('{prjUnitId}', payload.prjUnitId);
//     return http.getApi(url);
// }

const addEditCrmTower = (context, payload) => {
  const url = URL_UTILITY.addEditCrmTowerUrl;
  return http.postApi(url, payload);
};

const getCrmFloorList = (context, payload) => {
  const url = URL_UTILITY.getCrmFloorListUrl;
  return http.getApi(url, payload);
};

const addEditCrmProjectLicense = (context, payload) => {
  const url = URL_UTILITY.addEditCrmProjectLicenseUrl;
  return http.postApi(url, payload);
};
const addEditCrmFloor = (context, payload) => {
  const url = URL_UTILITY.addEditCrmFloorUrl;
  return http.postApi(url, payload);
};

const getCrmProjectList = (context, payload) => {
  const url = URL_UTILITY.getCrmProjectListUrl;
  return http.getApi(url, payload);
};

const addEditCrmProject = (context, payload) => {
  const url = URL_UTILITY.addEditCrmProjectUrl;
  return http.postApi(url, payload);
};

const getCrmProjectLicense = (context, payload) => {
  const url = URL_UTILITY.getCrmProjectLicenseUrl.replace(
    '{crmProjectId}',
    payload.crmProjectId
  );
  return http.getApi(url);
};

const addEditCrmProjectArea = (context, payload) => {
  const url = URL_UTILITY.addEditCrmProjectAreaUrl;
  return http.postApi(url, payload);
};

const getCrmProjectOtherSpec = (context, payload) => {
  const url = URL_UTILITY.getCrmProjectOtherSpecUrl.replace(
    '{crmProjectId}',
    payload.crmProjectId
  );
  return http.getApi(url);
};

const getCrmProjectArea = (context, payload) => {
  const url = URL_UTILITY.getCrmProjectAreaUrl.replace(
    '{crmProjectId}',
    payload.crmProjectId
  );
  return http.getApi(url);
};

const addEditCrmProjectOtherSpec = (context, payload) => {
  const url = URL_UTILITY.addEditCrmProjectOtherSpecUrl;
  return http.postApi(url, payload);
};

// const addEditProjectUnit = (context, payload) => {
//   const url = URL_UTILITY.addEditProjectUnitUrl;
//   return http.postApi(url, payload);
// };

// const addEditProjectUnitArea = (context, payload) => {
//   const url = URL_UTILITY.addEditProjectUnitAreaUrl;
//   return http.postApi(url, payload);
// }

// const addEditProjectUnitSpec = (context, payload) => {
//   const url = URL_UTILITY.addEditProjectUnitSpecUrl;
//   return http.postApi(url, payload);
// }

// const addEditProjectUnitOtherSpec = (context, payload) => {
//   const url = URL_UTILITY.addEditProjectUnitOtherSpecUrl;
//   return http.postApi(url, payload);
// }

export default {
  getCrmMasterProjectList,
  addEditCrmMasterProject,
  getCrmSectorList,
  addEditCrmSector,
  getCrmPocketList,
  addEditCrmPocket,
  getCrmProjectList,
  getCrmProjectLicense,
  getCrmProjectOtherSpec,
  getCrmProjectArea,
  getCrmTowerList,
  getCrmFloorList,
  addEditCrmTower,
  addEditCrmFloor,
  addEditCrmProject,
  addEditCrmProjectLicense,
  addEditCrmProjectArea,
  addEditCrmProjectOtherSpec,
  getCrmUnitList,
  addEditCrmUnit
};
