import Branch from './branch/';
import BankAccount from './bankAccount/';
import ReceiptMethods from './receiptMethods/';
import PaymentMethods from './paymentMethods/';
import store from '../../../../../store/';
export default {
  name: 'partyBank',
  components: {
    Branch,
    BankAccount,
    ReceiptMethods,
    PaymentMethods
  },
  data() {
    return {
      activeTab: 'branch',
      partyName: '',
      partyType: '',
      shortName: ''
    };
  },
  mounted() {
    this.partyName = store.state.party.partyName;
    this.partyType = store.state.party.partyType;
    this.shortName = store.state.party.shortName;
  },
  methods: {
    selectedTab(tab) {
      this.activeTab = tab;
      // this.eventBus.$off('customerSite');
      this.$store.dispatch('party/setUnsetPartyCustomerTabValue', tab);
      setTimeout(() => {
        this.eventBus.$emit(tab, this.customerSiteList);
      }, 0);
    },
    closePartyBankTabs() {
      this.$emit('closePartyBankTabs');
    }
  }
};
