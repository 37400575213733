import Datepicker from 'vuejs-datepicker';
import { ModelSelect } from 'vue-search-select';
export default {
  name: 'RuntimeModal',
  components: {
    Datepicker,
    ModelSelect
  },
  watch: {
    'respForm.selected': function () {
      const findJob = this.options.find(
        option => option.value === this.respForm.selected
      );
      this.respForm.selectedJob = findJob;
    },
    'respForm.startDate': function () {
      if (this.respForm.startDate !== null) {
        this.runtimeValidation.startDateVal = false;
        this.validate = true;
      }
    },
    'respForm.endDate': function () {
      if (this.respForm.endDate !== null) {
        this.runtimeValidation.endDateVal = false;
      }
    },
    'respForm.selectedDay': function () {
      if (this.respForm.selectedDay !== null) {
        this.respForm.selectedDate = null;
      }
      if(this.respForm.selectedDate == null){
        this.runtimeValidation.dayVal = false;
      }
    },
    'respForm.selectedDate': function () {
      if (this.respForm.selectedDate !== null) {
        this.respForm.selectedDay = null;
      }
      if(this.respForm.selectedDay == null){
        this.runtimeValidation.dayVal = false;
      }
    },
    'respForm.selectedTimeDuration': function () {
      if (this.respForm.selectedTimeDuration.value !== null) {
        this.runtimeValidation.resubmitIntervalVal = false;
      }
    },
    'respForm.repeatRun': function () {
      if (this.respForm.repeatRun !== null) {
        this.runtimeValidation.resubmitVal = false;
        if(isNaN(this.respForm.repeatRun)){
          this.runtimeValidation.numberVal = true ;
        }
        else{
          this.runtimeValidation.numberVal = false;
        }
      }
    }
  },
  data() {
    return {
      validate: false,
      runtimeValidation:
      {
        startDateVal: false,
        endDateVal: false,
        resubmitVal: false,
        resubmitIntervalVal: false,
        dayVal: false,
        numberVal: false
      },
      intervalList: [
        {
          value: null,
          text: null,
          detail_id: null
        }
      ],
      timeIntervalList: [
        {
          value: null,
          text: null
        }
      ],
      options: [
        { value: 'asap', text: 'Immediate' },
        { value: 'once', text: 'Once' },
        { value: 'periodically', text: 'Periodically' },
        { value: 'onspecific', text: 'On Specific Days' }
      ],
      optionsForInterval: [
        { text: 'From the Start of the Prior Run', value: '0' },
        { text: 'From the Completion of the Prior Run', value: '1' }
      ],
      daysOfWeek: [
        { value: 'monday', text: 'Monday' },
        { value: 'tuesday', text: 'Tuesday' },
        { value: 'wednesday', text: 'Wednesday' },
        { value: 'thursday', text: 'Thursday' },
        { value: 'friday', text: 'Friday' },
        { value: 'saturday', text: 'Saturday' },
        { value: 'sunday', text: 'Sunday' }
      ],
      datesOfMonth1: [],
      datesOfMonth2: [],
      respForm: {
        selectedInterval: {
          value: null,
          text: null,
          detail_id: null
        },
        selectedTimeDuration: {
          value: null,
          text: null
        },
        selectedJob: {
          value: null,
          text: null
        },
        selected: null,
        selectedRadio: null,
        startDate: null,
        endDate: null,
        repeatRun: null,
        selectedDay: null,
        selectedDate: null
      }
    };
  },
  mounted() {
    this.datesOfMonth();
    this.getTimeClass();
    this.eventBus.$on('runtimeStatus', status => {
      this.respForm.selected = status;
    });
  },
  methods: {
    saveDetails() {
      this.validation();
      if (!this.validate) {
        this.$emit('schedule', this.respForm);
      }
    },
    getTimeClass() {
      this.$store
        .dispatch('template/getLOVBySetType', 'UOM_CLASS')
        .then(response => {
          if (response.status === 200) {
            const filterResult = response.data.data;
            const getValueAndText = filterResult.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning,
                detail_id: type.value_set_dtl_id
              };
            });
            this.intervalList = getValueAndText;
            this.respForm.selectedInterval = getValueAndText.filter(item => item.value === "TIME");
            this.getTimeUnit(this.respForm.selectedInterval[0].detail_id);
          }
        });
    },
    getTimeUnit(parent_value_set_id) {
      const payload = {
        setType: 'UOM',
        PARENT_VALUE_SET_ID: parent_value_set_id
      };
      this.$store.dispatch('template/getTimeUnit', payload).then(response => {
        if (response.status === 200) {
          const filterResult = response.data.data;
          const getValueAndText = filterResult.map(type => {
            return {
              value: type.value_code,
              text: type.value_meaning
            };
          });
          this.timeIntervalList = getValueAndText;
        }
      });
    },
    validation: function () {
      switch (this.respForm.selected) {
        case 'asap':
          this.validate = false;
          break;
        case 'once':
          if (this.respForm.startDate == null) {
            this.runtimeValidation.startDateVal = true;
            this.validate = true;
          } else {
            this.validate = false;
          }
          break;
        case 'periodically':
          if (this.respForm.startDate == null) {
            this.runtimeValidation.startDateVal = true;
            this.validate = true;
          }
          else if(isNaN(this.respForm.repeatRun)){
            this.runtimeValidation.numberVal = true;
            this.validate = true;
          }
          else if (this.respForm.repeatRun === null || this.respForm.repeatRun === '') {
            this.runtimeValidation.resubmitVal = true;
            this.validate = true;
          }
          else if (this.respForm.selectedTimeDuration.value === null) {
            this.runtimeValidation.resubmitIntervalVal = true;
            this.validate = true;
          }
          else {
            this.validate = false;
          }
          break;
        case 'onspecific':
          if (this.respForm.startDate == null) {
            this.runtimeValidation.startDateVal = true;
            this.validate = true;
          }
          else if (this.respForm.endDate == null) {
            this.runtimeValidation.endDateVal = true;
            this.validate = true;
          }
          else if (this.respForm.selectedDate === null &&
            this.respForm.selectedDay === null) {
              this.runtimeValidation.dayVal = true;
                this.validate = true;
          }
          else {
            this.validate = false;
          }
          break;
      }
    },
    datesOfMonth: function () {
      let myArray = [];
      for (let i = 1; i < 17; i++) {
        myArray.push({ value: i, text: i });
      }
      this.datesOfMonth1 = myArray;
      myArray = [];
      for (let i = 17; i <= 32; i++) {
        myArray.push({ value: i, text: i });
        if(i === 32){
          myArray.push({
            value: 'last_day',
            text: 'Last_Day'
          });
        }
      }
      this.datesOfMonth2 = myArray;
    }
  },
  destroyed(){
    this.eventBus.$off('scheduleAction');
    this.eventBus.$off('runtimeStatus');
  }
};
