import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getLeaseFloorList = (context, payload) => {
  const url = URL_UTILITY.getLeaseFloorListUrl;
  return http.getApi(url, payload);
};

const addEditLeaseFloorList = (context, payload) => {
  const url = URL_UTILITY.addEditLeaseFloorListUrl;
  return http.postApi(url, payload);
};

export default {
  getLeaseFloorList,
  addEditLeaseFloorList
};
