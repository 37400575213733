import OpenKmAuthentication from '../../onlineApproval/internalWorkFlow/openKmAuthentication';
import UploadDocument from '../../onlineApproval/internalWorkFlow/history/uploadDocument';
export default {
  name: 'commonUploadDoc',
  components: {
    OpenKmAuthentication,
    UploadDocument
  },
  data() {
    return {
      showModal: false,
      isAuthValid: false,
      loader: false,
      openkmAuthToken: null,
      selectedGridDataId: null,
      selectedGridData: {
        le_id: null,
        le_name: null,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      },
      urlData: null
    };
  },
  mounted() {
    if (this.$route.params.wfType === 'upload-doc') {
      if (this.$route.params.summaryData) {
        const decodUrl = JSON.parse(
          Buffer.from(this.$route.params.summaryData, 'base64').toString(
            'ascii'
          )
        );
        sessionStorage.setItem('token', decodUrl.token);
        this.urlData = decodUrl;
      }
      this.checkOpenKmPasswordSaved();
      this.showModal = true;
    }
    this.eventBus.$off('commonUpload');
    this.eventBus.$on('commonUpload', uploadObj => {
      this.selectedGridDataId = uploadObj.id;
      this.checkOpenKmPasswordSaved();
    });
  },
  methods: {
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showModal = true;
              this.isAuthValid = false;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showModal = true;
            this.openkmAuthToken = response.data.data.token;
            this.isAuthValid = true;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setOpenKmToken(token) {
      this.openkmAuthToken = token;
      this.isAuthValid = true;
    },
    hideModal() {
      if (this.$route.params.wfType === 'upload-doc') {
        this.isAuthValid = false;
      } else {
        this.showModal = false;
        this.$emit('hideUploadDocModal');
      }
    }
  },
  destroyed() {
    this.eventBus.$off('commonUpload');
  }
};
