import pieChart1 from '../../gstAdmin/gst/complainceDashboard/pieChart/pieChart1';
import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'expenseReport',
  components: {
    pieChart1
  },
  watch: {
    currentPage: function() {
      if (
        this.periodType === 'PERIOD_FROM' ||
        this.periodType === 'PERIOD_TO'
      ) {
        this.getPeriodList();
      } else {
        this.getCentreList();
      }
    },
    perPage: function() {
      this.currentPage = 1;
      if (
        this.periodType === 'PERIOD_FROM' ||
        this.periodType === 'PERIOD_TO'
      ) {
        this.getPeriodList();
      } else {
        this.getCentreList();
      }
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      showStateValueSetModal: false,
      showCentreValueSetModal: false,
      loader: false,
      totalBalance: 0,
      state: null,
      stateList: [],
      stateFields: [
        {
          key: 'flex_value',
          label: 'State Code'
        },
        {
          key: 'description',
          label: 'State'
        }
      ],
      centre: null,
      centreList: [],
      centreFields: [
        {
          key: 'parent_flex_value_low',
          label: 'Centre Code'
        },
        {
          key: 'flex_value',
          label: 'Centre'
        }
      ],
      centreSearch: null,
      periodType: null,
      periodFrom: null,
      periodTo: null,
      periodList: [],
      periodFields: [
        {
          key: 'period_name'
        }
      ],
      showPeriodListModal: false,
      chartData: [],
      data: [],
      fields: [
        {
          key: 'EXPENSE_CATEGORY'
        },
        {
          key: 'BALANCE',
          label: 'Amount',
          class: 'text-right'
        }
      ],
      showExpenseDetailModal: false,
      modalTitle: null,
      totalCategoryBalance: 0,
      expenseData: [],
      expenseFields: [
        {
          key: 'ACCOUNT_CODE'
        },
        {
          key: 'ACCOUNT_DESC'
        },
        {
          key: 'BALANCE',
          label: 'Amount',
          class: 'text-right'
        }
      ]
    };
  },
  mounted() {},
  methods: {
    getExpenseDetails() {
      const payload = {
        InputParameters: {
          state: this.state,
          cost_center: this.centre,
          period_from: this.periodFrom,
          period_to: this.periodTo
        }
      };
      this.loader = true;
      this.totalBalance = 0;
      this.$store
        .dispatch('intWorkflow/getExpenseDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.data = resp.data.data.data.OutputParameters;
            this.chartData = this.data.map(item => [
              item.EXPENSE_CATEGORY,
              item.BALANCE
            ]);
            this.totalBalance = this.formattedCurrency(
              this.data
                .map(item => parseFloat(item.BALANCE))
                .reduce((acc, curr) => acc + curr)
                .toFixed(2)
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getExpenseDetailsById(category) {
      const payload = {
        InputParameters: {
          state: this.state,
          cost_center: this.centre,
          expense_category: category,
          period_from: this.periodFrom,
          period_to: this.periodTo
        }
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getExpenseDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.expenseData = resp.data.data.data.OutputParameters;
            this.totalCategoryBalance = this.formattedCurrency(
              this.expenseData
                .map(item => parseFloat(item.BALANCE))
                .reduce((acc, curr) => acc + curr)
                .toFixed(2)
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.expenseData = [];
      this.showExpenseDetailModal = true;
      this.modalTitle = item.EXPENSE_CATEGORY;
      this.getExpenseDetailsById(item.EXPENSE_CATEGORY);
    },
    showHideExpenseDetailModal(flag) {
      this.showExpenseDetailModal = flag;
    },
    getStateList() {
      const payload = {
        _page: 0,
        _limit: 40
      };
      this.loader = true;
      this.totalBalance = 0;
      this.$store
        .dispatch('intWorkflow/getStateList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.stateList = response.data.data.data.page;
            this.showStateValueSetModal = true;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCentreList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        parent_flex_value_low: this.state,
        parent_flex_value: this.centreSearch
      };
      this.loader = true;
      this.totalBalance = 0;
      this.$store
        .dispatch('intWorkflow/getCentreList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.centreList = response.data.data.data.page;
            this.totalRows = response.data.data.data.total_elements;
            this.showCentreValueSetModal = true;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPeriodList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loader = true;
      this.totalBalance = 0;
      this.$store
        .dispatch('intWorkflow/getPeriodList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.periodList = response.data.data.data.page;
            this.totalRows = response.data.data.data.total_elements;
            this.showPeriodListModal = true;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideStateValueSetModal(flag) {
      if (flag) {
        this.getStateList();
      }
    },
    showHideCentreValueSetModal(flag) {
      if (flag && this.state) {
        this.getCentreList();
      } else if (flag) {
        alert('Please Select State as it is mandatory for Centre.');
      }
    },
    showHidePeriodListModal(flag, type) {
      this.periodType = type;
      if (flag) {
        this.getPeriodList();
      }
    },
    stateSelected(item) {
      this.state = item.flex_value;
      this.centre = null;
      this.showStateValueSetModal = false;
    },
    centreSelected(item) {
      this.centre = item.flex_value;
      this.showCentreValueSetModal = false;
    },
    periodSelected(item) {
      if (this.periodType === 'PERIOD_FROM') {
        this.periodFrom = item.period_name;
      } else if (this.periodType === 'PERIOD_TO') {
        this.periodTo = item.period_name;
      }
      this.showPeriodListModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'STATE') {
        this.state = null;
        this.centre = null;
      } else if (vsetCode === 'CENTRE') {
        this.centre = null;
      } else if (vsetCode === 'PERIOD_FROM') {
        this.periodFrom = null;
      } else if (vsetCode === 'PERIOD_TO') {
        this.periodTo = null;
      }
    },
    formattedCurrency(value) {
      return commonHelper.formatCurrencyAmount(value);
    },
    clearTaxRule() {
      this.state = null;
      this.centre = null;
      this.periodFrom = null;
      this.periodTo = null;
      this.totalBalance = 0;
      this.data = null;
      this.totalRows = null;
    },
    clearCentreSearch() {
      this.centreSearch = null;
    }
  }
};
