import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'OrganizationMaster',
  props: ['orgType'],
  data() {
    return {
      loader: false,
      orgNameSearch: '',
      organizationListData: [],
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      pageOptions: commonHelper.getPageOption(),
      showOrgMaster: false,
      fields: [
        {
          key: 'org_type',
          label: 'Organization Type'
        },
        {
          key: 'org_name',
          label: 'Organization Name'
        },
        {
          key: 'org_short_name',
          class: 'd-none'
        },
        {
          key: 'parent_name',
          class: 'd-none'
        },
        {
          key: 'org_description',
          class: 'd-none'
        },
        {
          key: 'address_name',
          class: 'd-none'
        },
        {
          key: 'address',
          class: 'd-none'
        },
        {
          key: 'org_id',
          class: 'd-none'
        }
      ]
    };
  },
  mounted() {
    this.showOrgMaster = true;
    this.getOrganizationList();
  },
  methods: {
    getOrganizationList() {
      this.loader = true;
      const queryParams = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        org_type: this.orgType,
        org_short_name: null,
        org_name: this.orgNameSearch,
        parent_org_name: null
      };
      this.$store
        .dispatch('organization/getOrganizationList', queryParams)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.organizationListData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
        this.$emit('selectedOrg', item);
        this.showOrgMaster = false;
    },
    clearOrgSearchFields() {
      this.orgNameSearch = null;
    },
    hideOrgMaster() {
      this.showOrgMaster = false;
      this.$emit('closeOrgMaster',this.showOrgMaster);
    }
  }
};
