import store from '../../../../../../store';
import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'bank',
  components: {
    DatePicker,
    ModelSelect
  },
  props: {
    actionName: String
  },
  data() {
    return {
      unsubscribe: null,
      bankListIndex: null,
      vsetCode: null,
      setTimeVsetCode: null,
      showValueSetModal: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      editMode: false,
      loader: false,
      bankList: [
        {
          active: true,
          start_date: null,
          end_date: null,
          employee_id: store.state.shared.empId,
          bank_name: null,
          ifsc_code: null,
          account_num: null,
          employee_bank_account_id: 0,
          bank_name_vset: null,
          account_type_vset: null
        }
      ],
      bankFields: [
        {
          key: 'bank_name'
        },
        {
          key: 'account_type_vset',
          label: 'Account Type'
        },
        {
          key: 'account_num',
          label: 'Account No.'
        },
        {
          key: 'ifsc_code',
          label: 'IFSC Code'
        },
        {
          key: 'start_date_end_date',
          label: 'Start Date - End Date'
        },
     
        {
          key: 'active'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.getBankList(store.state.shared.empId);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const action = state.shared.actionName;
        if (action === 'save' && this.editMode) {
          this.addEditBank();
        } else if (action === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getBankList(empId) {
      this.loader = true;
      this.$store
        .dispatch('hrms/getBankList', empId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length > 0) {
            this.bankList = resp.data.data.map(detail => {
              detail.start_date_end_date = [
                  new Date(detail.start_date),
                  new Date(detail.end_date)
                ];
              return detail;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditBank() {
      const bankDetails = this.bankList.map(bank => {
        return {
          account_num: bank.account_num,
          account_type_vset: bank.account_type_vset,
          active: bank.active,
          bank_name_vset: bank.bank_name_vset,
          employee_bank_account_id:
            bank.employee_bank_account_id === 0
              ? 0
              : bank.employee_bank_account_id,
          ifsc_code: bank.ifsc_code,
          start_date: commonHelper.formattedDate(bank.start_date_end_date[0]),
          end_date: commonHelper.formattedDate(bank.start_date_end_date[1]),
         
        };
      });
      const payload = {
        bank_details: bankDetails,
        employee_id: store.state.shared.empId
      };
      this.loader = true;
      this.$store
        .dispatch('hrms/addEditBank', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.bankList.push({
          active: true,
          start_date: null,
          end_date: null,
          start_date_end_date: null,
          employee_id: store.state.shared.empId,
          bank_name: null,
          ifsc_code: null,
          account_num: null,
          employee_bank_account_id: 0,
          bank_name_vset: null,
          account_type_vset: null
        });
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'ACCOUNT_TYPE') {
        this.bankList[this.bankListIndex].account_type_vset = item.value_code;
      } else if (this.vsetCode === 'BANK_LIST') {
        this.bankList[this.bankListIndex].bank_name = item.value_meaning;
        this.bankList[this.bankListIndex].bank_name_vset = item.value_code;
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.bankListIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    removeRow(index) {
      this.bankList.splice(index, 1);
    },
    rowSelected() {}
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
