import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getPeriodsLov = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGeneralPeriodsUrl + '/lov/' + payload.searchType,
    payload
  );
};

const getPeriodList = (context, payload) => {
  return http.getApi(URL_UTILITY.getGeneralPeriodsUrl, payload);
};
const getGlOpenClosePeriodDtl = (context, periodDtlId) => {
  const url = URL_UTILITY.getGeneralPeriodsUrl + '/' + periodDtlId;
  return http.getApi(url);
};

const updatePeriodDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.getUpdatePeriodDetailUrl, payload);
};
const savePeriodHdr = (context, payload) => {
  return http.postApi(URL_UTILITY.getGeneralPeriodsUrl, payload);
};
const prefillSearchDataByLeId = (context, leId) => {
  const url = URL_UTILITY.getOpenCloseFiltersDataByLeId.replace('{leId}', leId);
  return http.getApi(url);
};
const lockGstr3B = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getGstUrl +
      '/gst_3b_log/' +
      payload.entity_name +
      '/' +
      payload.period_name
  );
};
export default {
  getPeriodsLov,
  getPeriodList,
  getGlOpenClosePeriodDtl,
  updatePeriodDetails,
  savePeriodHdr,
  prefillSearchDataByLeId,
  lockGstr3B
};
