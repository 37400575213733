import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getSalesAgreementList = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/sales-agreements';
  return http.getApi(url, payload);
};
const getSalesAgreementDetailsById = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/sales-agreements/' + payload;
  return http.getApi(url, payload);
};
const addEditSalesAgreementDetails = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/sales-agreements';
  return http.postApi(url, payload);
};
const getSalesAgreementCompDetailsById = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/sales-agreements/' + payload + "/sales-agreement-comp-dtl";
  return http.getApi(url, payload);
};
const saveSalesAgreementCompDetails = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/sales-agreements/agmt-comp-dtl';
  return http.postApi(url, payload);
};
const getSalesAgmtConsumption = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/sa-consumptions';
  return http.getApi(url, payload);
};
const getSalesAgmtConsumptionDetails = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl+ '/sa-consumptions/'+ payload.saCompHdrId+ '/sa-consumptions-dtl';
  return http.getApi(url, payload.requestPayload);
};
const saveSalesAgmtConsumptionDetails = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/sa-consumptions';
  return http.postApi(url, payload);
};
const salesAgreementDefaultPlan = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/sales-agreements-default-plan';
  return http.postApi(url, payload);
};
const getMeterDetails = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/sales-agreements/meter_dtls/unitId/'+ payload.unit_id+'/unitType/'+payload.unit_type;
  return http.getApi(url, payload);
};

export default {
  getSalesAgreementList,
  getSalesAgreementDetailsById,
  addEditSalesAgreementDetails,
  getSalesAgreementCompDetailsById,
  saveSalesAgreementCompDetails,
  getSalesAgmtConsumption,
  getSalesAgmtConsumptionDetails,
  saveSalesAgmtConsumptionDetails,
  salesAgreementDefaultPlan,
  getMeterDetails
}