import http from '../../../../app/services/httpServices';
import URL_UTILITY from '../../../../app/utility/url.utility';

const getItcSummaryReport = (context, payload) => {
  const url = URL_UTILITY.getItcSummaryReportUrl;
  return http.getApi(url, payload);
}
const getItcSupplierSummary = (context, payload) => {
  return http.getApi(URL_UTILITY.getSupplierSummaryUrl, payload);
}
const getItsSummaryRecords = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gst-summary_periodwise', payload);
}

export default {
    getItcSummaryReport,
    getItcSupplierSummary,
    getItsSummaryRecords
};
