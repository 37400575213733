import CryptoJS from 'crypto-js';

export default {
  name: 'vendorPortal',
  components: {},
  data() {
    return {
      vendorPortalUrl: null,
      isPasswordExpired: false,
      status: null,
      title: null,
      showAlert: false,
      isSuccess: false,
      responseMsg: null,
      token: null,
      envUrl: process.env.VUE_APP_API_URL,
      userDatas:null
    };
  },
  mounted() {},
  methods: {
    redirectToVendorPortal() {
      const url = process.env.VUE_APP_API_URL;
      const secretKey = 'CON_DSL_123';
      const token = this.$store.getters['auth/token']; 
      this.userDatas =  this.$store.getters['auth/userData'];
      if(this.userDatas.internal_admin=="PARTY_REQ_ADMIN" && this.userDatas.user_type=="EMP"){
          const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
      if (url === 'https://realappdev.delhivery.com:8080/realapp/') {
        this.vendorPortalUrl = 'https://vendordev.delhivery.com/';
      } else if (url === 'https://realappuat.delhivery.com:8080/realapp/') {
        this.vendorPortalUrl = 'https://vendoruat.delhivery.com/';
      } else {
        this.vendorPortalUrl = 'https://vendor.delhivery.com/';
      }
        // window.location.href = `${this.vendorPortalUrl}?token=${encodeURIComponent(encryptedToken)}`;
        window.open(`${this.vendorPortalUrl}?token=${encodeURIComponent(encryptedToken)}`)
      }else{
        this.$bvToast.toast("You are not authorized to access Vendor Portal, Kindly contact system administrator", {
          title: 'Alert',
          toaster:'b-toaster-top-center',
          variant: 'danger',
          solid: true,
          noFade:true
        });
      }
    
    }
  }
};



