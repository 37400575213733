import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getInvPaymentList = (context, payload) => {
    return http.getApi(URL_UTILITY.getInvestorPaymentUrl, payload);
  };
  const getInvPayDetails = (context, payload) => {
    return http.getApi(URL_UTILITY.getInvestorPaymentUrl + '/details', payload);
  };
export default {
    getInvPaymentList,
    getInvPayDetails
};
