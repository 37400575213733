// const setRfpData=(state,data)=>{

//     state.rfpData = data
  
//   }
const setStateRfp=(state,stateRfp)=>{
    state.rfpState = stateRfp
  }

  export default {
    // setRfpData
    setStateRfp
  }