import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'department',
  watch: {
    currentPage: function() {
      this.getCodeComb();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCodeComb();
    },
    wfLegalEntityId: function() {
      this.getCodeComb(this.wfLegalEntityId);
    }
  },
  props: { editMode: Boolean, wfLegalEntityId: String, wfHeaderId: Number },
  data() {
    return {
      showCodeCombModal: false,
      loader: false,
      departmentIndex: null,
      setTimeVsetCode: null,
      parent_value_set_id: null,
      showValueSetModal: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      departmentList: [
        {
          concatenated_segment_meaning: null,
          concatenated_segment: null,
          key_flexfield_comb_id: null
        }
      ],
      departmentFeilds: [
        {
          key: 'concatenated_segment_meaning',
          label: 'Department'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      codeCobList: [],
      codeCombFields: [
        {
          key: 'concatenated_segment'
        },
        {
          key: 'concatenated_segment_meaning'
        }
      ]
    };
  },
  mounted() {
    if (this.wfHeaderId) {
      this.getDepartmentList(this.wfHeaderId);
    }
    this.eventBus.$on('department', wfHeaderId => {
      if (wfHeaderId) {
        this.getDepartmentList(wfHeaderId);
      }
    });
  },
  methods: {
    getDepartmentList(wfHeaderId) {
      this.loader = true;
      const payload = {
        tabType: 'departments',
        wfHeaderId: wfHeaderId
      };
      this.$store
        .dispatch('intWorkflow/getWfTabsDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length > 0) {
            this.departmentList = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCodeComb() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.wfLegalEntityId
      };
      this.$store
        .dispatch('intWorkflow/getCodeComb', payload)
        .then(response => {
          if (response.status === 200) {
            this.codeCobList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.departmentList.push({
          concatenated_segment_meaning: null,
          concatenated_segment: null,
          key_flexfield_comb_id: null
        });
      }
    },
    removeRow(index) {
      this.departmentList.splice(index, 1);
    },
    showHideCodeCombModal(flag, index) {
      if (!this.wfLegalEntityId) {
        alert('Please select Legal Entity');
      } else {
        this.departmentIndex = index;
        this.showCodeCombModal = flag;
      }
    },
    codeCombSelected(item) {
      this.departmentList[this.departmentIndex].concatenated_segment_meaning =
        item.concatenated_segment_meaning;
      this.departmentList[this.departmentIndex].concatenated_segment =
        item.concatenated_segment;
      this.departmentList[this.departmentIndex].key_flexfield_comb_id =
        item.hr_org_key_flexfield_comb_id;
      this.showCodeCombModal = false;
    },
    rowSelected() {}
  },
  beforeDestroy() {
    this.eventBus.$off('department');
    clearTimeout(this.setTimeVsetCode);
  }
};
