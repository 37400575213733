import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getOrganizationList = async (context, queryParams) => {
  const url = URL_UTILITY.getOrgStructureUrl;

  const response = http.getApi(url, queryParams);
  return response;
};
const getAddressType = () => {
  const url = URL_UTILITY.getAddressTypesLookupUrl;
  return http.getApi(url);
};
const getParentOrgType = (context, orgTypeLookup) => {
  const url = URL_UTILITY.getOrgParentTypeNameUrl.replace(
    '{orgTypeLookup}',
    orgTypeLookup
  );
  return http.getApi(url);
};
const addEditOrganization = (context, payload) => {
  const url = URL_UTILITY.addEditOrganizationUrl;
  return http.postApi(url, payload);
};
const getOrgDetailsById = (context, orgId) => {
  const url = URL_UTILITY.getOrganizationDetailsUrl.replace('{orgId}', orgId);
  return http.getApi(url);
};

export default {
  getOrganizationList,
  getAddressType,
  getParentOrgType,
  addEditOrganization,
  getOrgDetailsById
};
