import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getCalendarList = (context, payload) => {
  return http.getApi(URL_UTILITY.getCalendarListUrl, payload);
};
const getCalendarDetail = (context, calHdrId) => {
  const url = URL_UTILITY.getCalendarDetailByIdUrl.replace(
    '{calHdrId}',
    calHdrId
  );
  return http.getApi(url);
};

const addEditCalendar = (context, payload) => {
  return http.postApi(URL_UTILITY.getCalendarListUrl, payload);
};

const getCalendarPeriodDetails = (context, calDtlId) => {
  return http.getApi(
    URL_UTILITY.getCalendarPeriodDetailUrl.replace('{calDtlId}', calDtlId)
  );
};
const addEditCalendarPeriod = (context, payload) => {
  return http.postApi(URL_UTILITY.getCalendarAddEditUrl, payload);
};

export default {
  getCalendarList,
  getCalendarDetail,
  addEditCalendar,
  getCalendarPeriodDetails,
  addEditCalendarPeriod
};
