import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getCustomerBalances = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl + '-customer-balances/form1',
    payload
  );
};

const getCustomerBalancesData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl + '-customer-balances/form2',
    payload
  );
};

const getCustomerBalancesReceipt = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl + '-customer-balances/form3receipt',
    payload
  );
};

const getCustomerBalancesCreditMemo = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-customer-balances/form3creditmemo',
    payload
  );
};

const getCustomerBalancesInvoice = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl + '-customer-balances/form3invoice',
    payload
  );
};

const getCustomerBalancesTransactionsId = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-customer-balances/form2transactionid',
    payload
  );
};

const customerBalancesUnapply = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-customer-balances/form3receiptunapply',
    payload
  );
};

const customerBalancesReceiptMethod = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-customer-balances/form2receiptformreceiptmethod',
    payload
  );
};

const customerBalancesCustomerSite = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-customer-balances/form2receiptformcustomersite',
    payload
  );
};

const customerBalancesCurrency = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-customer-balances/form2receiptcurrencyreceiptform',
    payload
  );
};

const customerBalancesDisputeDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-customer-balances/form3disputedtl/' +
      payload.disputeDtlId
  );
};

const customerBalancesDisputeInsertion = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-customer-balances/form3disputeinsertion',
    payload
  );
};

const customerBalancesCreateReceipt = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-customer-balances/form3receiptcreate',
    payload
  );
};

const customerBalancesTransactionNumber = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-customer-balances/transactionnumber-receiptcreationform',
    payload
  );
};

const customerBalancesApplyReceipt = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-customer-balances/form3receiptapply',
    payload
  );
};

const customerBalancesDisputeData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-customer-balances/form3disputehdr',
    payload
  );
};

const customerBalancesRemarks = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-customer-balances/dispute-remarks-history',
    payload
  );
};

const customerBalancesDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-customer-balances/dispute-dtl-details',
    payload
  );
};

export default {
  getCustomerBalances,
  getCustomerBalancesData,
  getCustomerBalancesReceipt,
  getCustomerBalancesCreditMemo,
  getCustomerBalancesInvoice,
  getCustomerBalancesTransactionsId,
  customerBalancesUnapply,
  customerBalancesReceiptMethod,
  customerBalancesCustomerSite,
  customerBalancesCurrency,
  customerBalancesDisputeDetails,
  customerBalancesDisputeInsertion,
  customerBalancesCreateReceipt,
  customerBalancesTransactionNumber,
  customerBalancesApplyReceipt,
  customerBalancesDisputeData,
  customerBalancesRemarks,
  customerBalancesDetails
};
