import store from '../../../../../../store';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'planning',
  watch: {
    currentPage: function() {
      this.getEmployeeLis();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getEmployeeList();
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      showSuperVisorModal: null,
      planningIndex: null,
      unsubscribe: null,
      timeout: null,
      addressIndex: null,
      showAddAddress: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      editMode: false,
      loader: false,
      planningList: [
        {
          employee_id: null,
          active: false,
          succession_planning_id: null,
          successor_id: null,
          successor_meaning: null,
          comments: null
        }
      ],
      planningFields: [
        {
          key: 'successor_meaning',
          label: 'Employee'
        },
        {
          key: 'comments'
        },
        {
          key: 'active'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      employeesList: [],
      employeeFields: [
        {
          key: 'employee_num',
          label: 'Emp No.'
        },
        {
          key: 'name'
        },
        {
          key: 'flex_value',
          class: 'd-none'
        },
        {
          key: 'le_name'
        },
        {
          key: 'emp_status',
          label: 'status'
        }
      ]
    };
  },
  mounted() {
    this.getEmployeeList();
    this.getPlanningList(store.state.shared.empId);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditPlanning();
        } else if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditPlanning() {
      const planningDetail = this.planningList.map(planning => {
        return {
          active: planning.active,
          comments: planning.comments,
          succession_planning_id: planning.succession_planning_id,
          successor_id: planning.successor_id
        };
      });
      const payload = {
        Succession_planning_details: planningDetail,
        employee_id: store.state.shared.empId
      };

      this.loader = true;
      this.$store
        .dispatch('hrms/addEditPlanning', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEmployeeList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        emp_status: null,
        joining_from: null,
        employee_num: null,
        hrms_org_key_flexfield_comb_id: null,
        joining_to: null,
        le_id: null,
        employee_name: null,
        designation_vset: null,
        supervisor_num: null
      };
      this.loader = true;
      this.$store
        .dispatch('hrms/getEmployeeList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.employeesList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPlanningList(empId) {
      this.loader = true;
      this.$store
        .dispatch('hrms/getPlanning', empId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length > 0) {
            this.planningList = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.planningList.push({
          employee_id: null,
          active: false,
          succession_planning_id: null,
          successor_id: null,
          successor_meaning: null,
          comments: null
        });
      }
    },
    removeRow(index) {
      this.planningList.splice(index, 1);
    },
    resetSupervisorModal(flag, index) {
      this.showSuperVisorModal = flag;
      this.planningIndex = index;
    },
    selectedEmp(item) {
      this.planningList[this.planningIndex].successor_meaning = item.name;
      this.planningList[this.planningIndex].successor_id = item.employee_id;
      this.showSuperVisorModal = false;
    },
    rowSelected() {}
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.timeout);
  }
};
