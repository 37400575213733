import appStrings from '@/app/utility/string.utility';
import { ModelSelect } from 'vue-search-select';
import SkipSeries from '../paymentSeries/skipSeries';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'paymentSeries',
  components: { ModelSelect, DatePicker, SkipSeries },
  props: ['accountDetails'],
  data() {
    return {
      showValueSetModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showSkipModal: false,
      paymentSeriesDetails: null,
      accountName: {
        value: null,
        text: null
      },
      shortName: {
        value: null,
        text: null
      },
      accountNum: {
        value: null,
        text: null
      },
      currency: {
        value: null,
        text: null
      },
      legalEntity: {
        value: null,
        text: null
      },
      active: false,
      paymentData: [
        {
          bank_account_id: null,
          payment_series_id: 0,
          payment_series_name: null,
          paper_stock_meaning: null,
          paper_stock_code: null,
          payment_mode_meaning: null,
          payment_mode: null,
          payment_format: null,
          first_series_number: null,
          last_series_number: null,
          last_issued_number: null,
          end_date: null,
          status: false
        }
      ],
      paymentFields: [
        {
          key: 'payment_series_name',
          label: 'Payment Series'
        },
        {
          key: 'paper_stock_meaning',
          label: 'Paper Stock'
        },
        {
          key: 'payment_mode_meaning',
          label: 'Payment Mode'
        },
        {
          key: 'payment_format'
        },
        {
          key: 'first_series_number'
        },
        {
          key: 'last_series_number'
        },
        {
          key: 'last_issued_number'
        },
        {
          key: 'end_date'
        },
        {
          key: 'status'
        },
        {
          key: 'skip_series'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.fillRecordsforBankAccount();
    this.getPaymentSeriesDetails(this.accountDetails.bank_account_id);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && !this.showSkipModal) {
          this.addEditPaymentSeriesList(this.accountDetails.bank_account_id);
        }
      }
    });
  },
  methods: {
    fillRecordsforBankAccount() {
      this.accountName.text = this.accountDetails.account_name;
      this.accountName.value = this.accountDetails.bank_account_id;
      this.shortName.text = this.accountDetails.short_name;
      this.accountNum.text = this.accountDetails.account_num;
      this.currency.text = this.accountDetails.currency;
      this.currency.value = this.accountDetails.currency_id;
      this.legalEntity.text = this.accountDetails.le;
      this.legalEntity.value = this.accountDetails.le_id;
      this.active = this.accountDetails.active;
    },
    getPaymentSeriesDetails(bankAccountId) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getPaymentSeriesList', bankAccountId)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length > 0) {
            this.paymentData = response.data.data;
            this.paymentData = this.paymentData.map(data => {
              data.skip_series = 'Details';
              return data;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditPaymentSeriesList(bankAccountId) {
      const paymentSeries = this.paymentData.map(elem => {
        return {
          payment_series_id: elem.payment_series_id,
          bank_series_name: elem.payment_series_name,
          paper_stock_code: elem.paper_stock_code,
          payment_mode: elem.payment_mode,
          payment_format: elem.payment_format,
          series_status: elem.status,
          end_date: elem.end_date,
          first_series_number: elem.first_series_number,
          last_series_number: elem.last_series_number
        };
      });
      const payload = {
        bank_acc_id: bankAccountId,
        payment_series: paymentSeries ? paymentSeries : null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/addEditPaymentSeriesList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    openSkipModal(flag, item) {
      this.showSkipModal = flag;
      this.paymentSeriesDetails = item;
    },
    addNewRow() {
      if (this.editMode) {
        this.paymentData.push({
          bank_account_id: null,
          payment_series_id: 0,
          payment_series_name: null,
          paper_stock_meaning: null,
          paper_stock_code: null,
          payment_mode_meaning: null,
          payment_mode: null,
          payment_format: null,
          first_series_number: null,
          last_series_number: null,
          last_issued_number: null,
          end_date: null,
          status: false
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.paymentData.splice(index, 1);
      }
    },
    validateLastIssued(rowDetails) {
      if (rowDetails.last_series_number < rowDetails.first_series_number) {
        rowDetails.last_series_number = null;
        return alert(
          'Last Series Number should be greater than First Series Number'
        );
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'PAYMENT_MODE') {
        this.paymentData[this.selectedIndex].payment_mode_meaning =
          item.value_meaning;
        this.paymentData[this.selectedIndex].payment_mode = item.value_code;
      } else if (this.vsetCode === 'PAPER_STOCK_TYPE') {
        this.paymentData[this.selectedIndex].paper_stock_meaning =
          item.value_meaning;
        this.paymentData[this.selectedIndex].paper_stock_code = item.value_code;
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.selectedIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
