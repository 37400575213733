import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getCustomerQueryValueSet = (context, payload) => {
  const url = URL_UTILITY.getCustomerQueryValueSet.replace(
    '{moduleId}',
    payload.moduleId
  );
  return http.getApi(url, payload.catType);
};
const getQueryAssignmentGridList = (context, payload) => {
  return http.getApi(URL_UTILITY.getQueryAssignmentUrl, payload);
};
const getQueryAssignmentHdrDetailsById = (
  context,
  fmsQueryAssignMasterHdrId
) => {
  return http.getApi(
    URL_UTILITY.getQueryAssignmentUrl + `/${fmsQueryAssignMasterHdrId}`
  );
};
const getAssignQueryDtlById = (context, fmsQueryAssignMasterHdrId) => {
  return http.getApi(
    URL_UTILITY.getQueryAssignmentUrl +
      `/${fmsQueryAssignMasterHdrId}` +
      '/assign'
  );
};

const getCustomerQueryList = (context, payload) => {
  return http.getApi(URL_UTILITY.getCustomerQueryListUrl, payload);
};

const getModuleBasedValuesetNameForEntity = (context, moduleId) => {
  const url = URL_UTILITY.getModuleBasedValuesetNameUrl.replace(
    '{moduleId}',
    moduleId
  );
  return http.getApi(url);
};

const addCustomerQuery = (context, payload) => {
  return http.postApi(URL_UTILITY.getCustomerQueryListUrl, payload);
};

const getCustomerQueryEmployeeList = (context, payload) => {
  const url = URL_UTILITY.getCustomerQueryEmployeeListUrl.replace(
    '{ccQueryId}',
    payload.ccQueryId
  );
  return http.getApi(url, payload);
};
const getCustomerQueryAssignedList = (context, payload) => {
  const url = URL_UTILITY.getCustomerQueryAssignedListUrl.replace(
    '{ccQueryId}',
    payload.ccQueryId
  );
  return http.getApi(url, payload);
};

const addEditTaskCreation = (context, payload) => {
  return http.postApi(URL_UTILITY.getCustomerQueryListUrl + '/task', payload);
};

const getTaskCreationDetails = (context, ccQueryId) => {
  return http.getApi(
    URL_UTILITY.getCustomerQueryListUrl + '/' + ccQueryId + '/task'
  );
};

const addEditAssignCurrentTask = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCustomerQueryListUrl + '/task/assignee',
    payload
  );
};

const getCustomerQueryTaskAssignedList = (context, payload) => {
  const url = URL_UTILITY.getCustomerQueryTaskAssignedListUrl.replace(
    '{queryTaskId}',
    payload.queryTaskId
  );
  return http.getApi(url, payload);
};

const getCustomerQueryRaisedBy = (context, payload) => {
  return http.getApi(URL_UTILITY.getCustomerQueryRaisedByUrl, payload);
};

const addEditCustomerAssignQuery = (context, payload) => {
  return http.postApi(URL_UTILITY.getQueryAssignmentUrl, payload);
};

const addEditCustomerAssignCategory = (context, payload) => {
  return http.postApi(URL_UTILITY.getQueryAssignmentUrl + '/assign', payload);
};

const getVendorDetailsByUnit = (context, unitId) => {
  return http.getApi(URL_UTILITY.getVendorDetailsByUnitUrl + '/' + unitId);
};

const getCustomerDetailsByUnit = (context, unitId) => {
  return http.getApi(URL_UTILITY.getCustomerDetailsByUnitUrl + '/' + unitId);
};

const saveEmailInTempTable = (context, payload) => {
  return http.postApi(
    URL_UTILITY.saveEmailInTempTableUrl + '/query/comm',
    payload
  );
};
const getTempEmailByCommId = (context, queryId) => {
  return http.getApi(
    URL_UTILITY.saveEmailInTempTableUrl + '/query/' + queryId + '/comm'
  );
};
const getEmailDetailsByCommId = (context, commId) => {
  return http.getApi(
    URL_UTILITY.saveEmailInTempTableUrl + '/query/' + commId + '/comm'
  );
};
const discardEmailById = (context, payload) => {
  return http.deleteApi(
    URL_UTILITY.saveEmailInTempTableUrl +
      '/delete/query/' +
      payload.queryId +
      '/comm/' +
      payload.commId
  );
};
const addUploadDocument = (context, payload) => {
  return http.postApi(
    URL_UTILITY.saveEmailInTempTableUrl + '/comm/upload-doc',
    payload
  );
};
const getUploadDocumentQuery = (context, payload) => {
  return http.getApi(
    URL_UTILITY.saveEmailInTempTableUrl + '/comm/doc-detail/' + payload.queryId
  );
};
const deleteUploadDocument = (context, payload) => {
  return http.deleteApi(
    URL_UTILITY.saveEmailInTempTableUrl +
      '/comm/delete-doc?query_id=' +
      payload.query_id +
      '&comm_id=' +
      payload.comm_id +
      '&doc_dtl_id=' +
      payload.doc_dtl_id
  );
};

export default {
  getCustomerQueryValueSet,
  getQueryAssignmentGridList,
  getQueryAssignmentHdrDetailsById,
  getAssignQueryDtlById,
  getCustomerQueryList,
  getModuleBasedValuesetNameForEntity,
  addCustomerQuery,
  getCustomerQueryEmployeeList,
  getCustomerQueryAssignedList,
  addEditTaskCreation,
  getTaskCreationDetails,
  addEditAssignCurrentTask,
  getCustomerQueryTaskAssignedList,
  getCustomerQueryRaisedBy,
  addEditCustomerAssignQuery,
  addEditCustomerAssignCategory,
  getVendorDetailsByUnit,
  getCustomerDetailsByUnit,
  saveEmailInTempTable,
  getTempEmailByCommId,
  getEmailDetailsByCommId,
  discardEmailById,
  addUploadDocument,
  getUploadDocumentQuery,
  deleteUploadDocument
};
