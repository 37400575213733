import store from '../../../../../../store';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { format } from 'date-fns';
export default {
  name: 'EduQualification',
  components: {
    DatePicker
  },
  data() {
    return {
      unsubscribe: null,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      setTimeVsetCode: null,
      quaIndex: null,
      vsetCode: null,
      showValueSetModal: false,
      editMode: false,
      loader: false,
      eduQualificationList: [
        {
          employee_qual_id: 0,
          seq_order: null,
          highest_qualification: false,
          qualification: null,
          institute_vset: null,
          institution: null,
          other_institute: null,
          course_cat_vset: null,
          course: null,
          degree_vset: null,
          degree: null,
          stream_vset: null,
          stream: null,
          start_program: null,
          end_program: null,
          start_month: null,
          start_year: null,
          end_month: null,
          end_year: null
        }
      ],
      eduQualificationFields: [
        {
          key: 'course'
        },
        {
          key: 'degree'
        },
        {
          key: 'stream'
        },
        {
          key: 'institution'
        },

        {
          key: 'highest_qualification'
        },
        {
          key: 'start_year'
        },
        {
          key: 'end_year'
        },
        {
          key: 'seq_order'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.getQualification(store.state.shared.empId);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const action = state.shared.actionName;
        if (action === 'save') {
          this.addEditQualification();
        } else if (action === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditQualification() {
      const educationData = this.eduQualificationList.map(qualifi => {
        return {
          course_cat_vset: qualifi.course_cat_vset,
          degree_vset: qualifi.degree_vset,
          employee_qual_id:
            qualifi.employee_qual_id === 0 ? null : qualifi.employee_qual_id,
          end_month: qualifi.end_program
            ? format(new Date(qualifi.end_program), 'M')
            : null,
          end_year: qualifi.end_program
            ? format(new Date(qualifi.end_program), 'yyyy')
            : null,
          highest_qualification: qualifi.highest_qualification,
          institute_vset: qualifi.institute_vset,
          other_institute: qualifi.other_institute,
          seq_order: qualifi.seq_order,
          start_month: qualifi.end_program
            ? format(new Date(qualifi.end_program), 'M')
            : null,
          start_year: qualifi.start_program
            ? format(new Date(qualifi.start_program), 'yyyy')
            : null,
          stream_vset: qualifi.stream_vset
        };
      });
      const payload = {
        employee_id: store.state.shared.empId,
        education_data: educationData
      };
      this.loader = true;
      this.$store
        .dispatch('hrms/addEditEduQualification', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getQualification(empId) {
      this.loader = true;
      this.$store
        .dispatch('hrms/getEduQualification', empId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length > 0) {
            const education = resp.data.data.map(edu => {
              const splitStart = edu.start_program.split('-');
              const splitEnd = edu.end_program.split('-');
              edu.start_program = new Date(splitStart[1], splitStart[0]);
              edu.end_program = new Date(splitEnd[1], splitEnd[0]);
              return edu;
            });
            this.eduQualificationList = education;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'COURSE_CAT') {
        this.eduQualificationList[this.quaIndex].course = item.value_meaning;
        this.eduQualificationList[this.quaIndex].course_cat_vset =
          item.value_code;
      } else if (this.vsetCode === 'DEGREE') {
        this.eduQualificationList[this.quaIndex].degree = item.value_meaning;
        this.eduQualificationList[this.quaIndex].degree_vset = item.value_code;
      } else if (this.vsetCode === 'STREAM') {
        this.eduQualificationList[this.quaIndex].stream = item.value_meaning;
        this.eduQualificationList[this.quaIndex].stream_vset = item.value_code;
      } else if (this.vsetCode === 'INSTITUTE') {
        this.eduQualificationList[this.quaIndex].institution =
          item.value_meaning;
        this.eduQualificationList[this.quaIndex].institute_vset =
          item.value_code;
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.quaIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.eduQualificationList.push({
          employee_qual_id: 0,
          seq_order: null,
          highest_qualification: false,
          qualification: null,
          institute_vset: null,
          institution: null,
          other_institute: null,
          course_cat_vset: null,
          course: null,
          degree_vset: null,
          degree: null,
          stream_vset: null,
          stream: null,
          start_program: null,
          end_program: null,
          start_month: null,
          start_year: null,
          end_month: null,
          end_year: null
        });
      }
    },
    removeRow(index) {
      this.eduQualificationList.splice(index, 1);
    },
    rowSelected() {},
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'COURSE_CAT') {
        this.eduQualificationList[index].course = null;
        this.eduQualificationList[index].course_cat_vset = null;
      } else if (vsetCode === 'DEGREE') {
        this.eduQualificationList[index].degree = null;
        this.eduQualificationList[index].degree_vset = null;
      } else if (vsetCode === 'STREAM') {
        this.eduQualificationList[index].stream = null;
        this.eduQualificationList[index].stream_vset = null;
      } else if (vsetCode === 'INSTITUTE') {
        this.eduQualificationList[index].institution = null;
        this.eduQualificationList[index].institute_vset = null;
      }
    }
  },
  destroyed() {
    clearTimeout(this.setTimeVsetCode);
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
