import appStrings from '@/app/utility/string.utility';
export default {
  name: 'cusDirectTaxDetails',
  components: {},
  props: [
    'bankSiteRowDetail',
    'customerDtlId',
    'customerSiteDtlList',
    'siteType',
    'vendorDtlId',
    'vendorSiteDtlList'
  ],
  data() {
    return {
      loader: false,
      unsubscribe: null,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      editMode: false,
      bank: null,
      accType: null,
      accNo: null,
      bankBranch: null,
      ifscCode: null,
      primaryBank: false,
      active: false,
      partyProfileType: ''
    };
  },
  mounted() {
    if (this.bankSiteRowDetail) {
      this.fillRecordFromDirectTaxParent(this.bankSiteRowDetail);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.editBankSiteDetail();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    fillRecordFromDirectTaxParent(item) {
      this.bank = item.bank;
      this.accType = item.account_type;
      this.accNo = item.account_num;
      this.bankBranch = item.bank_branch;
      this.ifscCode = item.ifsc_code;
      this.primaryBank = item.primary_bank;
      this.active = item.active;
    },
    editBankSiteDetail(item) {
      if (this.siteType === 'customer') {
        const payload = {
          customerId: this.customerDtlId,
          customerSiteBank: {
            customer_site_banks: [
              {
                entity_type: 'Customer',
                customer_site_id: this.customerSiteDtlList.customer_site_id,
                customer_site_bank_id: this.bankSiteRowDetail
                  .customer_site_bank_id,
                party_bank_id: this.bankSiteRowDetail.party_bank_id,
                active: this.active,
                primary_bank: this.primaryBank
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/getAddEdiCustomerSiteBank', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.$emit('updateListCustomer');
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else if (this.siteType === 'vendor') {
        this.addEditVendorBank(item);
      }
    },
    addEditVendorBank() {
      this.loader = true;
      const payload = {
        vendorId: this.vendorDtlId,
        vendorSiteData: {
          vendor_site_banks: [
            {
              entity_type: 'Vendor',
              vendor_site_bank_id: this.bankSiteRowDetail.vendor_site_bank_id,
              vendor_site_id: this.vendorSiteDtlList.vendor_site_id,
              party_bank_id: this.bankSiteRowDetail.party_bank_id,
              active: this.active,
              primary_bank: this.primaryBank
            }
          ]
        }
      };
      this.$store
        .dispatch('party/addEditVendorBank', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.$emit('updateListVendor');
            this.customerSiteList = response.data.data.vendor_sites_banks;
            this.showPartyBankModel = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
