import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getCollectionDashboard = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-collections-dashboard/form1/collections/dashboard',
    payload
  );
};
const getLegalEntity = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-collections-dashboard/form1/legal/entity',
    payload
  );
};
const getOperatingUnit = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getCollectionMgmtSystemUrl +
      '-collections-dashboard/form1/operatingunit',
    payload
  );
};
const getBankACcounts = (context, payload) => {
    return http.postApi(
      URL_UTILITY.getCollectionMgmtSystemUrl +
        '-collections-dashboard/form1/bank/accounts',
      payload
    );
  };
  const getCustomers = (context, payload) => {
    return http.postApi(
      URL_UTILITY.getCollectionMgmtSystemUrl +
        '-collections-dashboard/form1/customers',
      payload
    );
  };

export default {
  getCollectionDashboard,
  getLegalEntity,
  getOperatingUnit,
  getBankACcounts,
  getCustomers
};
