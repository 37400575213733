import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'SearchRole',
  watch: {
    currentPage: function() {
      this.getRoles();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRoles();
    }
  },
  data() {
    return {
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      status: false,
      loader: false,
      options: [
        { item: 'true', status_type: 'Active' },
        { item: 'false', status_type: 'Inactive' }
      ],
      name: '',
      data: [],
      fields: [
        {
          key: 'role_id',
          class: 'd-none'
        },
        {
          key: 'role_name'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ]
    };
  },
  mounted() {
    this.getRoles();
  },

  methods: {
    clearRole() {
      this.name = null;
    },
    getRoles() {
      const payload = {
        role_name: this.name,
        status: this.status,
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loader = true;
      this.$store.dispatch('resp/getRolesList', payload).then(response => {
        this.loader = false;
        this.data = response.data.data.page;
        this.totalRows = response.data.data.total_elements;
      })
      .catch(() => {
        this.loader = false;
      });
    },
    rowSelected(item) {
      this.$emit('selectedRoleName', item);
    }
  }
};
