import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import moment from 'moment';
import PartyDetails from '../partyDetails/';
import Address from '../../../admin/address/';
import Bank from '../bank';
import ConatactDetails from '../contactDetails/';
import IndirectTax from '../indirectTax/';
import DirectTax from '../directTax/';
import store from '../../../../../store/';
import DatePicker from 'vue2-datepicker';
import editAddress from './editAddess'

export default {
  name: 'ViewEditParty',
  props: {
    partyId: Number,
    showPartyDetails: Boolean
  },
  components: {
    Address,
    Bank,
    ConatactDetails,
    IndirectTax,
    DirectTax,
    PartyDetails,
    DatePicker,
    editAddress
  },
  watch: {
    partyId: function(id) {
      if (id) {
        this.editMode = false;
        this.getPartyDetails(id);
      }
    }
  },
  data() {
    return {
      unsubscribe: null,
      enableEndDate: null,
      partyName: '',
      showBankModal: false,
      isDateEnable: '',
      showAddAddress: false,
      showUpdateAddress: false,
      addressId: null,
      activeTab: 'party',
      partyDetails: {},
      addressDetails: [],
      bankDetilsItems: {},
      partyContactDetails: [],
      partyDirectTaxList: [],
      partyIndirectTaxList: [],
      loader: false,
      search: '',
      stickyHeader: true,
      totalRows: null,
      showModal: false,
      editMode: false,
      editState: false,
      responseMsg: '',
      isSuccess: false,
      isFailed: false,
      update: false,
      showAlert: false,
      lovType: appStrings.VALUESETTYPE.PARTY_TYPE,
      partyTypeDataResponse: null,
      updateType: null,
      addressIndex: null,
      payload: null,
      addressFields: [
        {
          key:'address_id'
        },
        {
          key: 'state'
        },
        {
          key: 'city'
        },
        {
          key: 'pincode'
        },
        {
          key: 'address'
        },
        {
          key: 'address_type'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'address_id',
          class: 'd-none'
        },
        {
          key: 'party_address_id',
          class: 'd-none'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (!this.showBankModal) {
          if (
            this.activeTab === 'address' &&
            actionName === 'save' &&
            !this.showModal &&
            !this.showAddAddress &&
            !this.showUpdateAddress
          ) {
            this.editPartyAddress();
          }
          if (actionName === 'edit' || actionName === 'update') {
            this.showAlert = false;
            this.editMode = true;
            this.update = true;
          }

          if (actionName === 'add') {
            this.showModal = true;
            this.update = false;
            if (this.activeTab === 'address') {
              this.openAddressModal(null);
            }
          }
          if (actionName === 'upload' && this.partyId) {
            this.eventBus.$emit('commonUpload', { id: this.partyId });
          }
        }
      }
    });
    this.partyName = store.state.party.partyName;
  },
  methods: {
    closeTabs() {
      this.$emit('showHidePartyView');
    },
    getPartyDetails(id) {
      this.loader = true;
      this.$store
        .dispatch('party/getPartyDetailsById', id)
        .then(response => {
          this.loader = false;
          if (response.data.data) {
            const result = response.data.data;
            this.partyDetails = result.party_details;
            this.addressDetails = result.party_addresses;
            this.bankDetilsItems = result.party_drawee_banks;
            this.partyContactDetails = result.party_contact_details;
            this.partyDirectTaxList = result.direct_tax_details;
            this.partyIndirectTaxList = result.indirect_tax_details;
            setTimeout(() => {
              this.eventBus.$emit('partyDetails', {
                partyId: this.partyId,
                partyDetails: this.partyDetails
              });
            }, 500);
          }
        })
        .catch(error => {
          alert(error.message);
          this.loader = false;
        });
    },

    selectedTab(tab) {
      this.activeTab = tab;
      this.showAlert = false;
      this.eventBus.$off('partyDetails');
      this.eventBus.$off('bankList');
      this.eventBus.$off('createPartyBank');
      this.eventBus.$off('contactDetils');
      if (tab === 'party') {
        setTimeout(() => {
          this.eventBus.$emit('partyDetails', {
            partyId: this.partyId,
            partyDetails: this.partyDetails
          });
        }, 500);
      } else if (tab === 'bank') {
        setTimeout(() => {
          this.eventBus.$emit('bankList', this.bankDetilsItems);
        }, 500);
        setTimeout(() => {
          this.eventBus.$emit('createPartyBank', this.partyId);
        }, 500);
      } else if (tab === 'contact') {
        setTimeout(() => {
          this.eventBus.$emit('contactDetils', {
            partyId: this.partyId,
            partyContactDetails: this.partyContactDetails
          });
        }, 500);
      } else if (tab === 'directTax') {
        setTimeout(() => {
          this.eventBus.$emit('directTaxDetils', {
            partyId: this.partyId,
            partyDirectTaxList: this.partyDirectTaxList
          });
        }, 500);
      } else if (tab === 'indirectTax') {
        setTimeout(() => {
          this.eventBus.$emit('indirectTaxDetils', {
            partyId: this.partyId,
            partyIndirectTaxList: this.partyIndirectTaxList
          });
        }, 500);
      }
    },
    editPartyAddress() {
      const editAddressArr = this.addressDetails.map(address => {
        return {
          active: true,
          address_id: address.address_id,
          end_date:
          address.end_date !== null
              ? moment(new Date(address.end_date)).format(appStrings.DATEFORMAT)
              : null,
          start_date:address.start_date !== null
          ? moment(new Date(address.start_date)).format(appStrings.DATEFORMAT)
          : moment(new Date()).format(appStrings.DATEFORMAT),
          party_address_id: address.party_address_id
        };
      });
      const payload = {
        partyId: this.partyId,
        addressPayload: {
          party_addresses: editAddressArr
        }
      };
      this.loader = true;
      this.$store
        .dispatch('party/addUpdatePartyAddress', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          this.enableEndDate = null;
          if (response.status === 200) {
            this.addressDetails = response.data.data.party_addresses;
            this.isSuccess = true;
          } else {
            this.isSuccess = false;
          }
          this.responseMsg = response.data.message;
        })
        .catch(error => {
          this.loader = false;
          this.isSuccess = false;
          this.responseMsg = error.message;
        });
    },
    getAddressObj(address) {
      if (address) {
        this.showAddAddress = false;
        if (!this.update && this.addressIndex === null) {
          this.payload = {
            partyId: this.partyId,
            addressPayload: {
              party_addresses: [
                {
                  active: true,
                  address_id: address.address_id,
                  end_date: null,
                  start_date: moment(new Date()).format(appStrings.DATEFORMAT),
                  party_address_id: 0
                }
              ]
            }
          };
          this.loader = true;
          this.$store
            .dispatch('party/addUpdatePartyAddress', this.payload)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.addressDetails = response.data.data.party_addresses;
                this.isSuccess = true;
              } else {
                this.isSuccess = false;
              }
              this.responseMsg = response.data.message;
            })
            .catch(error => {
              this.loader = false;
              this.isSuccess = false;
              this.responseMsg = error.message;
            });
        } else if (this.update && this.addressIndex !== null) {
          this.addressDetails[this.addressIndex].address = address.completeAddress;
          this.addressDetails[this.addressIndex].address_id = address.address_id;
          this.addressDetails[this.addressIndex].address_type = address.address_type.text;
          this.addressDetails[this.addressIndex].address_type_lookup = address.address_type.value;
        }
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.pageOptions[5].value = filteredItems.length;
    },
    resetModal() {
      this.$emit('clicked', 'view');
      this.editMode = false;
      this.update = false;
      this.showAddAddress = false;
    },
    rowSelected: function(item) {
      this.showModal = true;
      this.update = true;
      this.showAlert = false;
      this.cityId = item.city_id;
    },
    editEndDate(partyAddressId) {
      this.enableEndDate = partyAddressId;
    },
    openAddressModal(index) {
      this.addressIndex = index;
      if(this.addressIndex !== null) {
        this.update = true;
      }
      this.showAddAddress = true;
      setTimeout(() => {
        this.eventBus.$emit('createPartyAddress', this.partyId);
      }, 1000);
    },
    updateAddress(index) {
      this.showUpdateAddress = true;
      this.addressId = this.addressDetails[index].address_id;
    },
    hideUpdateAddress() {
      this.showUpdateAddress = false;
      this.getPartyAddress(this.partyId);
    },
    getPartyAddress(partyId) {
      this.$store
        .dispatch('party/getPartyAddressByPartyId', partyId)
        .then(response => {
          this.addressDetails = response.data.data;
        })
        .catch(error => {
          alert(error.message);
        });
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.addressDetails[0].start_date
      );
    },
  },
  created() {},
  beforeDestroy() {
    this.unsubscribe();
  }
};
