import { required } from 'vuelidate/lib/validators';
import store from '../../../../../../store/';
import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility.js';
import ModulesList from '../../../../admin/responsibility/searchModule';
export default {
  name: 'createReceiptPaymentMethod',
  props: {
    showMethod: String,
    bankAccountId: Number,
    methodType: String
  },
  components: {
    ModelSelect,
    ModulesList
  },
  watch: {
    showMethod: function(showMethodForm) {
      this.resetMedthoForm();
      this.createMethodModel = showMethodForm === 'show' ? true : false;
    }
  },
  data() {
    return {
      unsubscribe: null,
      leName: {
        value: null,
        text: null
      },
      leNameList: [],
      tab: null,
      selectedModule: {
        name: null,
        id: null
      },
      selectedProject: {
        value: null,
        text: null
      },
      inputValueName: null,
      meaning: null,
      code: null,
      showCcidModel: false,
      showModuleList: false,
      showValueSetModal: false,
      createMethodModel: false,
      editMode: true,
      loader: false,
      showBankModal: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showAlertCcid: false,
      isSuccessCcid: false,
      responseMsgCcid: '',
      partyId: null,
      branchForm: {
        branch_name: null,
        ifsc_code: null,
        short_name: null,
        bank_branch_id: 0,
        active: true
      },
      methodForm: {
        party_receipt_method_id: 0,
        bank_account_id: null,
        bank_charges_ccid: null,
        error_code: null,
        error_meaning: null,

        bank_code: null,
        bank_meaning: null,
        bank_error_ccid: null,

        cash_ccid: null,
        cash_code: null,
        cash_meaning: null,

        cash_clearing_ccid: null,
        clearing_code: null,
        clearing_meaning: null,

        exchange_charges_ccid: null,
        exchange_code: null,
        exchange_meaning: null,

        unapplied_ccid: null,
        unapplied_code: null,
        unapplied_meaning: null,

        unidentified_ccid: null,
        unidentified_code: null,
        unidentified_meaning: null,

        receipt_methods_description: null,
        receipt_methods_name: null,
        active: true
      },
      ccidList: [],
      ccidFields: [
        {
          key: 'segment_code'
        },
        {
          key: 'segment_meaning'
        },
        {
          key: 'ccid',
          class: 'd-none'
        }
      ],
      parent_value_set_id: null,
      isBusy: false,
      branch: {
        value: null,
        text: null
      },
      account: {
        value: null,
        text: null
      },
      defaultValue: {
        text: null,
        value: null
      },
    };
  },
  validations: {
    methodForm: {
      receipt_methods_name: { required },
      receipt_methods_description: { required },
      cash_meaning: { required },
      // clearing_meaning: { required },
      // bank_meaning: { required },
      // error_meaning: { required },
      // exchange_meaning: { required },
      // unapplied_meaning: {required},
      // unidentified_meaning: {required}
    }
  },
  mounted() {
    this.getLegalEntityList();
    this.eventBus.$off('selectedRowData');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.submitCreateMethodForm();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });

    this.eventBus.$on('selectedRowData', selectedRowData => {
      this.createMethodModel = true;
      this.editMode = false;
      setTimeout(() => {
        this.setMethodFormsValue(selectedRowData);
      }, 0);
    });
  },
  methods: {
    getLegalEntityList() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST,
        dependentVariable: {}
      };
      payload.dependentVariable.user_id = store.state.auth.userId;
      payload.dependentVariable.res_id = store.state.shared.responsibilityId;

      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.value_set_dtl_id,
                text: type.value_code
              };
            });
            this.leNameList = leagalEntity;
            this.leName =
              leagalEntity && leagalEntity.length > 0
                ? leagalEntity[0]
                : this.leName;
          }
        });
    },
    submitCreateMethodForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.methodType === 'payment') {
          this.partyId = store.state.party.partyId;
          const payload = {
            partyId: this.partyId,
            methodData: {
              bank_payment_methods: [
                {
                  module_id: this.selectedModule.id,
                  project_id: this.selectedProject.value,
                  party_payment_method_id: this.methodForm
                    .party_receipt_method_id,
                  bank_account_id: this.bankAccountId,
                  bank_charges_ccid: this.methodForm.bank_charges_ccid,
                  bank_error_ccid: this.methodForm.bank_error_ccid,
                  cash_ccid: this.methodForm.cash_ccid,
                  cash_clearing_ccid: this.methodForm.cash_clearing_ccid,
                  exchange_charges_ccid: this.methodForm.exchange_charges_ccid,
                  payment_methods_description: this.methodForm
                    .receipt_methods_description,
                  payment_methods_name: this.methodForm.receipt_methods_name,
                  active: this.methodForm.active
                }
              ]
            }
          };
          this.loader = true;
          this.$store
            .dispatch('party/addEditPaymentMethod', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.$emit('getMethodDataResponse', response);
              }
            });
        } else {
          this.partyId = store.state.party.partyId;
          const payload = {
            partyId: this.partyId,
            methodData: {
              bank_receipt_methods: [
                {
                  module_id: this.selectedModule.id,
                  project_id: this.selectedProject.value,
                  party_receipt_method_id: this.methodForm
                    .party_receipt_method_id,
                  bank_account_id: this.account.value,
                  bank_charges_ccid: this.methodForm.bank_charges_ccid,
                  bank_error_ccid: this.methodForm.bank_error_ccid,
                  cash_ccid: this.methodForm.cash_ccid,
                  cash_clearing_ccid: this.methodForm.cash_clearing_ccid,
                  exchange_charges_ccid: this.methodForm.exchange_charges_ccid,
                  receipt_methods_description: this.methodForm
                    .receipt_methods_description,
                  receipt_methods_name: this.methodForm.receipt_methods_name,
                  unapplied_ccid: this.methodForm.unapplied_ccid,
                  unidentified_ccid: this.methodForm.unidentified_ccid,
                  active: this.methodForm.active
                }
              ]
            }
          };
          this.loader = true;
          this.$store
            .dispatch('party/addEditBankReceiptMethod', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.$emit('getMethodDataResponse', response);
              }
            });
        }
      }
    },
    hideCreateMethodModel() {
      this.showAlert = false;
      this.createMethodModel = false;
      this.$emit('showHideCreateMethod');
    },
    showHideCcidModel(flag, inputValueName) {
      this.showCcidModel = flag;
      this.inputValueName = inputValueName;
    },
    getBankAccountCcidDetails() {
      this.loader = true;
      const payload = {
        // bankAccountId: this.bankAccountId,
        search: {
          le_id: this.leName.value,
          segment_code: this.code,
          segment_meaning: this.meaning
        }
      };
      this.isBusy = true;
      this.$store
        .dispatch('party/getCustomerCcidDetils', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.ccidList = response.data.data;
            this.isBusy = false;
          } else {
            this.isBusy = false;
            this.showAlertCcid = true;
            this.isSuccessCcid = false;
            this.responseMsgCcid = response.response.data.message;
          }
        })
        .catch(error => {
          alert(error.message);
          this.isBusy = false;
          this.loader = false;
        });
    },
    selectedCcidRow(item) {
      this.showCcidModel = false;
      if (this.inputValueName === 'cash_meaning') {
        this.methodForm.cash_ccid = item.ccid;
        this.methodForm.cash_code = item.segment_code;
        this.methodForm.cash_meaning = item.segment_meaning;
      } else if (this.inputValueName === 'clearing_meaning') {
        this.methodForm.cash_clearing_ccid = item.ccid;
        this.methodForm.clearing_code = item.segment_code;
        this.methodForm.clearing_meaning = item.segment_meaning;
      } else if (this.inputValueName === 'bank_meaning') {
        this.methodForm.bank_charges_ccid = item.ccid;
        this.methodForm.bank_code = item.segment_code;
        this.methodForm.bank_meaning = item.segment_meaning;
      } else if (this.inputValueName === 'error_meaning') {
        this.methodForm.bank_error_ccid = item.ccid;
        this.methodForm.error_code = item.segment_code;
        this.methodForm.error_meaning = item.segment_meaning;
      } else if (this.inputValueName === 'exchange_meaning') {
        this.methodForm.exchange_charges_ccid = item.ccid;
        this.methodForm.exchange_code = item.segment_code;
        this.methodForm.exchange_meaning = item.segment_meaning;
      } else if (this.inputValueName === 'unapplied_meaning') {
        this.methodForm.unapplied_ccid = item.ccid;
        this.methodForm.unapplied_code = item.segment_code;
        this.methodForm.unapplied_meaning = item.segment_meaning;
      } else if (this.inputValueName === 'unidentified_meaning') {
        this.methodForm.unidentified_ccid = item.ccid;
        this.methodForm.unidentified_code = item.segment_code;
        this.methodForm.unidentified_meaning = item.segment_meaning;
      }
    },
    resetMedthoForm() {
      this.selectedModule = {
        value: null,
        text: null
      };
      this.selectedProject = {
        value: null,
        text: null
      };
      this.leName = {
        value: null,
        text: null
      };
      this.methodForm = {
        party_receipt_method_id: 0,
        bank_account_id: null,
        bank_charges_ccid: null,
        bank_code: null,
        bank_meaning: null,
        bank_error_ccid: null,
        error_code: null,
        error_meaning: null,
        cash_ccid: null,
        cash_code: null,
        cash_meaning: null,
        cash_clearing_ccid: null,
        clearing_code: null,
        clearing_meaning: null,
        exchange_charges_ccid: null,
        exchange_code: null,
        exchange_meaning: null,
        unapplied_ccid: null,
        unapplied_code: null,
        unapplied_meaning: null,
        unidentified_ccid: null,
        unidentified_code: null,
        unidentified_meaning: null,
        receipt_methods_description: null,
        receipt_methods_name: null,
        active: true
      };
    },
    setMethodFormsValue(formData) {
      if (this.methodType === 'payment') {
        this.selectedModule = {
          id: formData.module_id,
          name: formData.module_name
        };
        this.selectedProject = {
          value: formData.project_id,
          text: formData.project_name
        };
        this.leName.text = formData.le_name;
        this.leName.value = formData.le_id;
        this.branch.text = formData.branch_name;
        this.branch.value = formData.bank_branch_id;
        this.account.text = formData.account_name;
        this.account.value = formData.bank_account_id;
        this.methodForm = {
          party_receipt_method_id: formData.party_payment_method_id,
          bank_account_id: formData.bank_account_id,
          bank_charges_ccid: formData.bank_charges_ccid,
          bank_code: formData.bank_code,
          bank_meaning: formData.bank_meaning,
          bank_error_ccid: formData.bank_error_ccid,
          error_code: formData.error_code,
          error_meaning: formData.error_meaning,
          cash_ccid: formData.cash_ccid,
          cash_code: formData.cash_code,
          cash_meaning: formData.cash_meaning,
          cash_clearing_ccid: formData.cash_clearing_ccid,
          clearing_code: formData.clear_code,
          clearing_meaning: formData.clear_meaning,
          exchange_charges_ccid: formData.exchange_charges_ccid,
          exchange_code: formData.exchange_code,
          exchange_meaning: formData.exchange_meaning,
          receipt_methods_description: formData.payment_methods_description,
          receipt_methods_name: formData.payment_methods_name,
          active: formData.active
        };
      } else {
        this.branch = {
          text: formData.branch_name,
          value: formData.bank_branch_id
        };
        this.account = {
          text: formData.account_name,
          value: formData.bank_account_id
        };
        this.selectedModule = {
          id: formData.module_id,
          name: formData.module_name
        };
        this.selectedProject = {
          value: formData.project_id,
          text: formData.project_name
        };
        this.leName.text = formData.le_name;
        this.leName.value = formData.le_id;
        this.branch.text = formData.branch_name;
        this.branch.value = formData.bank_branch_id;
        this.account.text = formData.account_name;
        this.account.value = formData.bank_account_id;
        this.methodForm = {
          party_receipt_method_id: formData.party_receipt_method_id,
          bank_account_id: formData.bank_account_id,
          bank_charges_ccid: formData.bank_charges_ccid,
          bank_code: formData.bank_code,
          bank_meaning: formData.bank_meaning,
          bank_error_ccid: formData.bank_error_ccid,
          error_code: formData.error_code,
          error_meaning: formData.error_meaning,
          cash_ccid: formData.cash_ccid,
          cash_code: formData.cash_code,
          cash_meaning: formData.cash_meaning,
          cash_clearing_ccid: formData.cash_clearing_ccid,
          clearing_code: formData.clear_code,
          clearing_meaning: formData.clear_meaning,
          exchange_charges_ccid: formData.exchange_charges_ccid,
          exchange_code: formData.exchange_code,
          exchange_meaning: formData.exchange_meaning,
          unapplied_ccid: formData.unapplied_ccid,
          unapplied_code: formData.unapplied_code,
          unapplied_meaning: formData.unapplied_meaning,
          unidentified_ccid: formData.unidentified_ccid,
          unidentified_code: formData.unidentified_code,
          unidentified_meaning: formData.unidentified_meaning,
          receipt_methods_description: formData.receipt_methods_description,
          receipt_methods_name: formData.receipt_methods_name,
          active: formData.active
        };
      }
    },
    openShowModuleModal(flag, component) {
      this.showModuleList = flag;
      this.tab = component;
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.selectedProject.text = null;
      this.selectedProject.value = null;
      this.showModuleList = false;
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST
      ) {
        this.selectedProject.text = item.value_code;
        this.selectedProject.value = item.value_set_dtl_id;
        this.parent_value_set_id = this.leName.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BRANCHES_BY_PARTY) {
        this.branch.text = item.value_code;
        this.branch.value = item.value_set_dtl_id;
        this.account.text = null;
        this.account.value = null;
        this.leName.text = null;
        this.leName.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ACCOUNTS_BY_BRANCH) {
        this.account.text = item.value_code;
        this.account.value = item.value_set_dtl_id;
        this.leName.text = item.le;
        this.leName.value = item.le_id;
      }
      // else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
      //   this.leName.text = item.value_code;
      //   this.leName.value = item.value_set_dtl_id;
      // }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST
      ) {
        this.parent_value_set_id = this.leName.value;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.BRANCHES_BY_PARTY) {
        this.parent_value_set_id = store.state.party.partyId;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.ACCOUNTS_BY_BRANCH) {
        this.parent_value_set_id = this.branch.value;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    openModuleBasedProject(moduleName) {
      if (this.selectedModule.name == null) {
        return alert('Please Select Module');
      } else if (moduleName === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else if (moduleName === 'Lease Management') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      }
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
