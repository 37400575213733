import { required } from 'vuelidate/lib/validators';
import responsibilityDetails from './responsibilityDetails';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import moment from 'moment';

export default {
  name: 'responsibility',
  components: {
    responsibilityDetails,
    DatePicker
  },
  watch: {
    currentPage: function() {
      this.getResponsibility();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getResponsibility();
    }
  },
  data() {
    return {
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      responsibilityDetails: null,
      showResponsibility: false,
      showModuleForm: false,
      showRoleForm: false,
      showReportGroupForm: false,
      showSecurityGroupForm: false,
      createRespModal: false,
      loader: false,
      name: '',
      description: '',
      startDate: '',
      page: null,
      form: {
        name: null,
        description: null,
        startDate: null,
        endDate: null,
        moduleName: null,
        roleName: null,
        securityGroupName: null,
        reportGroupName: null
      },
      data: [],
      fields: [
        {
          key: 'responsibility_hdr_id',
          class: 'd-none'
        },
        {
          key: 'responsibility_name'
        },
        {
          key: 'responsibility_desc',
          label: 'Description'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ]
    };
  },
  validations: {
    form: {
      name: { required },
      description: { required },
      startDate: { required },
      moduleName: { required },
      roleName: { required },
      securityGroupName: { required },
      reportGroupName: { required }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showResponsibility = true;
        }
        if (this.showResponsibility) {
          this.setTimeAction = setTimeout(() => {
            this.eventBus.$emit('respFormActions', actionName);
          }, 0);
        }
        if (actionName === 'download' && !this.showResponsibility) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'resp/getResponsibilityList',
            'responsibility',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    updateResponsibilityList() {
      this.getResponsibility();
    },
    clearResponsibility() {
      this.name = null;
      this.description = null;
      this.startDate = null;
      this.endDate = null;
      this.data = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    resetModal() {},
    rowSelected(rowData) {
      this.showResponsibility = true;
      setTimeout(() => {
        this.eventBus.$emit('respDetails', rowData);
      }, 0);
      this.responsibilityDetails = rowData;
      this.$emit('selectedResponsibility', rowData);
    },
    getResponsibility() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        responsibility_name: this.name,
        responsibility_desc: this.description,
        start_date: this.startDate
          ? moment(this.startDate).format(appStrings.DATEFORMAT)
          : null
      };
      this.loader = true;
      this.$store
        .dispatch('resp/getResponsibilityList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.data = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeAction);
    this.unsubscribe();
  }
};
