import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getTenantBatchList = (context, payload) => {
  const url = URL_UTILITY.getLeaseGenerateTenantBatchUrl;
  return http.getApi(url, payload);
};
const getTxnTypeList = (context, moduleId) => {
  const url = URL_UTILITY.getTxnTypeListUrl.replace('{moduleId}', moduleId);
  return http.getApi(url);
};
const createRentaldetails = (context, payload) => {
  return http.postApi(URL_UTILITY.getLeaseSchTemplateUrl, payload);
};
const updateInvoiceData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseGenerateTenantBatchUrl +
      '/' +
      payload.leaseTenantScheduleHdrId,
    payload.data
  );
};
const processTenantInvoice = (context, payload) => {
  return http.postApi(URL_UTILITY.getLeaseProcessInvoiceUrl, payload);
};
const processInvestorInvoice = (context, payload) => {
  return http.postApi(URL_UTILITY.getLeaseProcessInvoiceForInvestorUrl, payload);
}
const getInvestorInvoiceList = (context, payload) => {
  return http.getApi(URL_UTILITY.getProcessInvoiceInvestorUrl, payload);
};
const updateInvestorInvoiceData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getProcessInvoiceInvestorUrl +
      '/' +
      payload.leaseInvestorScheduleHdrId,
    payload.data
  );
};

const saveAdvSearchFilters = (context, payload) => {
  context.commit('updateAdvSearchFilters', payload);
};
const clearSelectedGlDates = (context, payload) => {
  return http.postApi(URL_UTILITY.getLeaseGenerateTenantBatchUrl + '/clear-gl-date', payload);
};
export default {
  getTenantBatchList,
  getTxnTypeList,
  createRentaldetails,
  updateInvoiceData,
  processTenantInvoice,
  processInvestorInvoice,
  getInvestorInvoiceList,
  updateInvestorInvoiceData,
  saveAdvSearchFilters,
  clearSelectedGlDates
};
