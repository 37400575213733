import appStrings from '@/app/utility/string.utility';
import store from '../../../../../store';
import { ModelSelect } from 'vue-search-select';
import { required, minLength, email, numeric } from 'vuelidate/lib/validators';
export default {
  name: 'AddEmployee',
  components: {
    ModelSelect
  },
  data() {
    return {
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      unsubscribe: null,
      editMode: false,
      selectedSalutation: {
        value: null,
        text: null
      },
      salutationList: [],
      selectedLegalEntity: {
        value: null,
        text: null
      },
      selectedEmpType: {
        value: null,
        text: null
      },
      employeeTypeList: [],
      selectedGender: {
        value: null,
        text: null
      },
      genderList: [],
      legalEntityList: [],
      employeForm: {
        country_code: 91,
        date_of_group_joining: null,
        date_of_joining: null,
        dob: null,
        emp_status_lookup: null,
        employee_type_lookup: null,
        employee_id: 0,
        employee_num: null,
        extension: 0,
        first_name: null,
        gender_lookup: null,
        last_name: null,
        le_id: null,
        marital_status_lookup: null,
        middle_name: null,
        official_mobile: null,
        office_phone: null,
        official_email_id: null,
        position_criticality_lookup: null,
        salutation_lookup: null
      }
    };
  },
  validations: {
    employeForm: {
      employee_num: {
        required
      },
      first_name: {
        required
      },
      official_email_id: { required, email },
      official_mobile: {
        required,
        minLength: minLength(10),
        numeric
      }
    }
  },
  mounted() {
    this.getSalutation();
    this.getEmpType();
    this.getGenderType();
    this.getLegalEntity();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addEditEmployee();
        } else if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', {
            id: this.employeForm.employee_id
          });
        }
      }
    });
  },
  methods: {
    getSalutation() {
      this.$store
        .dispatch(
          'shared/getDataFromLookupsByLookupType',
          appStrings.LOOKUPTYPE.SALUTATION
        )
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const salutationArr = result.map(type => {
              return {
                value: type.lookup_code,
                text: type.meaning
              };
            });
            this.salutationList = salutationArr;
            this.selectedSalutation = salutationArr && salutationArr[0];
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    getLegalEntity() {
      const payload = store.state.shared.responsibilityId;
      this.$store
        .dispatch('receipt/getOrganizationLov', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.org_name
              };
            });
            this.legalEntityList = leagalEntity;
            this.selectedLegalEntity =
              leagalEntity && leagalEntity.length
                ? leagalEntity[0]
                : this.selectedLegalEntity;
          }
        });
    },
    addEditEmployee() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          country_code: 91,
          date_of_group_joining: this.employeForm.date_of_group_joining,
          date_of_joining: this.employeForm.date_of_joining,
          dob: this.employeForm.dob,
          emp_status_lookup:
            this.employeForm.employee_id === 0
              ? 'ACT'
              : this.employeForm.emp_status_lookup,
          employee_type_lookup: this.selectedEmpType.value,
          employee_id: this.employeForm.employee_id,
          employee_num: this.employeForm.employee_num,
          extension: this.employeForm.extension,
          first_name: this.employeForm.first_name,
          last_name: this.employeForm.last_name,
          gender_lookup: this.employeForm.gender_lookup,
          le_id: this.selectedLegalEntity.value,
          marital_status_lookup: this.employeForm.marital_status_lookup,
          middle_name: this.employeForm.middle_name,
          official_mobile: this.employeForm.official_mobile,
          office_phone: this.employeForm.office_phone,
          official_email_id: this.employeForm.official_email_id,
          position_criticality_lookup: this.employeForm
            .position_criticality_lookup,
          salutation_lookup: this.selectedSalutation.value
        };
        this.loader = true;
        this.$store
          .dispatch('hrms/addEditEmpPersonalInfo', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.isSuccess = true;
              this.respMessage = response.data.message;
              this.$emit('updateEmpList');
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getEmpType() {
      this.$store
        .dispatch(
          'shared/getDataFromLookupsByLookupType',
          appStrings.LOOKUPTYPE.EMPLOYEE_TYPE
        )
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const salutationArr = result.map(type => {
              return {
                value: type.lookup_code,
                text: type.meaning
              };
            });
            this.employeeTypeList = salutationArr;
            this.selectedEmpType = salutationArr && salutationArr[0];
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    getGenderType() {
      this.$store
        .dispatch(
          'shared/getDataFromLookupsByLookupType',
          appStrings.LOOKUPTYPE.GENDER
        )
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const salutationArr = result.map(type => {
              return {
                value: type.lookup_code,
                text: type.meaning
              };
            });
            this.genderList = salutationArr;
            this.selectedGender = salutationArr && salutationArr[0];
          }
        })
        .catch(error => {
          alert(error.message);
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
