import appStrings from '@/app/utility/string.utility';
export default {
  name: 'dependentValueSetModal',
  props: ['valueSetData'],
  watch: {
    currentPage: function() {
      this.getLOVBySetType(this.valueSetData);
    }
  },
  data() {
    return {
      valueSetName: null,
      multiSelect: false,
      showModal: false,
      loader: false,
      showAlert: false,
      responseMsg: '',
      isSuccess: false,
      searchText: null,
      valueSetList: [],
      valueSetFields: [
        {
          key: 'multiselect',
          class: 'd-none'
        }
      ],
      multiSelectList: [],
      totalRows: null,
      currentPage: 1,
      perPage: null
    };
  },
  mounted() {
    // if (this.valueSetData) {

    this.getLOVBySetType(this.valueSetData);
    // }
    this.eventBus.$off('valueSetCode');
    this.eventBus.$on('valueSetCode', valueObj => {
      this.valueSetName = valueObj.valueSetName;
      this.multiSelect = valueObj.multiFlag ? valueObj.multiFlag : null;
      if (!this.isParentLOVSelected(this.valueSetName)) {
        this.alertByValuesetType(this.valueSetName);
      } else {
        this.showModal = true;
        this.getLOVBySetType(this.valueSetName);
      }
    });
  },
  methods: {
    getLOVBySetType(valueSetData) {
      this.showModal = true;
      this.loader = true;
      const payload = {};
      if (valueSetData) {
        payload.valueSetName = valueSetData.valueSetName;
        payload.dependentVariable = valueSetData.dependeParams;
        payload.dependentVariable.search_key = this.searchText;
        payload.dependentVariable._page = this.currentPage - 1;
      }

      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.valueSetFields = [];
            const result = response.data.data;
            const firstObj =
              result.data && result.data.length > 0
                ? result.data[0]
                : result.page && result.page.length > 0 ? result.page[0] : [];
            if (result.columns) {
              const tabelFields = result.columns.map(column => {
                return {
                  key: column.name,
                  class: !column.display ? 'd-none' : ''
                };
              });
              this.valueSetFields = tabelFields;
              if (this.multiSelect) {
                this.valueSetFields.unshift({
                  key: 'multiselect'
                });
              }
              const newResult = result.data.map(data => {
                data.multiSelect = false;
                return data;
              });
              this.valueSetList = newResult;
              this.totalRows = result.total_elements;
            } else {
              for (let val in firstObj) {
                if (typeof firstObj[val] === 'string') {
                  this.valueSetFields.push({ key: val });
                } else {
                  this.valueSetFields.push({ key: val, class: 'd-none' });
                }
              }
              this.valueSetList = result.page;
              this.totalRows = result.total_elements;
            }
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    isParentLOVSelected(valueSetName) {
      return ((valueSetName === appStrings.VALUESETTYPE.OU_LIST ||
        valueSetName === appStrings.VALUESETTYPE.GL_PERIOD ||
        valueSetName === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_TOWER ||
        (valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_PROJECT &&
          this.isDependent) ||
        valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_FLOOR ||
        valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_UNIT ||
        valueSetName === appStrings.VALUESETTYPE.CRM_SECTOR ||
        valueSetName === appStrings.VALUESETTYPE.PRJ_PROJECT ||
        valueSetName === appStrings.VALUESETTYPE.CRM_POCKET ||
        valueSetName === appStrings.VALUESETTYPE.PRJ_SECTOR ||
        valueSetName === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET ||
        valueSetName === appStrings.VALUESETTYPE.UNIT_SUB_CAT ||
        valueSetName === appStrings.VALUESETTYPE.LEASE_TOWER_LIST ||
        valueSetName === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST ||
        valueSetName === appStrings.VALUESETTYPE.LEASE_UNIT_LIST ||
        valueSetName === appStrings.VALUESETTYPE.PRJ_POCKET ||
        valueSetName === appStrings.VALUESETTYPE.INVENTORY_ORG_LIST ||
        valueSetName === appStrings.VALUESETTYPE.NFA_LOCATION ||
        (valueSetName === appStrings.VALUESETTYPE.LOCATION &&
          this.isDependent) ||
        valueSetName === appStrings.VALUESETTYPE.NFA_SUB_CATEGORY ||
        valueSetName === appStrings.VALUESETTYPE.DESIGNATION ||
        (valueSetName === appStrings.VALUESETTYPE.POSITION &&
          this.isDependent) ||
        valueSetName === appStrings.VALUESETTYPE.TABLE_COLUMNS ||
        valueSetName === appStrings.VALUESETTYPE.WF_CRITERIA_SUB_CAT) &&
        (this.parent_value_set_id === null ||
          this.parent_value_set_id === '' ||
          this.tablePrameter === null ||
          this.tablePrameter === '')) ||
        (valueSetName === appStrings.VALUESETTYPE.WF_CRITERIA_SPEC &&
          (this.catTypeForSpec === null || this.catTypeForSpec === ''))
        ? false
        : true;
    },
    alertByValuesetType(valueSetName) {
      switch (valueSetName) {
        case appStrings.VALUESETTYPE.WF_CRITERIA_SPEC:
          alert(appStrings.DEPENDENTVSETMSG.SUBCATEGORYMSG);
          break;
        case appStrings.VALUESETTYPE.WF_CRITERIA_SUB_CAT:
          alert(appStrings.DEPENDENTVSETMSG.CATEGORYMSG);
          break;
        case appStrings.VALUESETTYPE.OU_LIST:
          alert(appStrings.DEPENDENTVSETMSG.LEMSG);
          break;
        case appStrings.VALUESETTYPE.TABLE_COLUMNS:
          alert(appStrings.DEPENDENTVSETMSG.SYSTEMTABLESMSG);
          break;
        case appStrings.VALUESETTYPE.NFA_LOCATION:
          alert(appStrings.DEPENDENTVSETMSG.LEMSG);
          break;
        case appStrings.VALUESETTYPE.NFA_SUB_CATEGORY:
          alert(appStrings.DEPENDENTVSETMSG.CATEGORYMSG);
          break;
        case appStrings.VALUESETTYPE.DESIGNATION:
          alert(appStrings.DEPENDENTVSETMSG.BANDMSG);
          break;
        case appStrings.VALUESETTYPE.POSITION:
          alert(appStrings.DEPENDENTVSETMSG.DESIGNATIONMSG);
          break;
        case appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET:
          alert(appStrings.DEPENDENTVSETMSG.TAXGROUPMSG);
          break;
        case appStrings.VALUESETTYPE.PRJ_SECTOR:
          alert(appStrings.DEPENDENTVSETMSG.PRJMASTERPRJMSG);
          break;
        case appStrings.VALUESETTYPE.PRJ_POCKET:
          alert(appStrings.DEPENDENTVSETMSG.PRJSECTORMSG);
          break;
        case appStrings.VALUESETTYPE.PRJ_PROJECT:
          alert(appStrings.DEPENDENTVSETMSG.PRJPOCKETMSG);
          break;
        case appStrings.VALUESETTYPE.LEASE_TOWER_LIST:
          alert(appStrings.DEPENDENTVSETMSG.LEASEPRJMSG);
          break;
        case appStrings.VALUESETTYPE.LEASE_FLOOR_LIST:
          alert(appStrings.DEPENDENTVSETMSG.LEASETWRMSG);
          break;
        case appStrings.VALUESETTYPE.INVENTORY_ORG_LIST:
          alert(appStrings.DEPENDENTVSETMSG.OUMSG);
          break;
        case appStrings.VALUESETTYPE.CRM_SECTOR:
          alert(appStrings.DEPENDENTVSETMSG.CRMMASTERPRJMSG);
          break;
        case appStrings.VALUESETTYPE.CRM_POCKET:
          alert(appStrings.DEPENDENTVSETMSG.CRMSECTORMSG);
          break;
        case appStrings.VALUESETTYPE.SELECT_CRM_TOWER:
          alert(appStrings.DEPENDENTVSETMSG.CRMPROJECTMSG);
          break;
        case appStrings.VALUESETTYPE.SELECT_CRM_FLOOR:
          alert(appStrings.DEPENDENTVSETMSG.CRMTOWERMSG);
          break;
        case appStrings.VALUESETTYPE.SELECT_CRM_PROJECT:
          alert(appStrings.DEPENDENTVSETMSG.CRMPOCKETMSG);
          break;

        default:
          alert('Please select Parent LOV');
          break;
      }
    },
    rowSelected(item) {
      if (!this.multiSelect) {
        this.showModal = false;
        this.$emit('selectedDepvalueSet', item);
      }
    },
    hideModal() {
      this.showModal = false;
      this.$emit('closeValueSetModal');
      if (this.multiSelect) {
        this.$emit('selectedDepvalueSet', this.multiSelectList);
      }
    },
    getValueSetList() {
      this.getLOVBySetType(this.valueSetData);
    },
    clearSearh() {
      this.searchText = null;
      this.getLOVBySetType(this.valueSetData);
    },
    selectBoxChecked(flag, index, item) {
      if (flag) {
        this.multiSelectList.push(item);
      } else {
        this.multiSelectList.splice(index, 1);
      }
    }
  },
  destroyed() {
    this.eventBus.$off('valueSetCode');
  }
};
