import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'SearchReportGroup',
  watch: {
    currentPage: function() {
      this.getRoles();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRoles();
    }
  },
  data() {
    return {
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      loader: false,
      name: '',
      data: [],
      fields: [
        {
          key: 'report_group_id',
          class: 'd-none'
        },
        {
          key: 'report_group_name'
        }
      ]
    };
  },
  mounted() {
    this.getReportGroupList();
  },
  methods: {
    clearReportGroup(){
      this.name = null
    },
    rowSelected(item) {
      this.$emit('selectedReportGroupName', item);
    },
    getReportGroupList() {
      const payload = {
        report_group_name: this.name
      };
      this.loader = true;
      this.$store
        .dispatch('resp/getReportGroupList', payload)
        .then(response => {
          this.loader = false;
          this.data = response.data.data;
          this.totalRows = response.data.data.total_elements;
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
