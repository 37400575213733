import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getLeaseProjectsList = (context, payload) => {
  const url = URL_UTILITY.getLeaseProjectsListUrl;
  return http.getApi(url, payload);
}

const addEditLeaseProject = (context, payload) => {
  const url = URL_UTILITY.addEditLeaseProjectUrl;
  return http.postApi(url, payload);
}
export default {
  getLeaseProjectsList,
  addEditLeaseProject
};
