import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getArTransactionList = (context, payload) => {
  return http.getApi(URL_UTILITY.getArTrsansactionUrl, payload);
};

const addArTransactionDetails = (context, trxHdrId) => {
  return http.postApi(
    URL_UTILITY.getArTrsansactionUrl + '/' + trxHdrId + '/request'
  );
};

const getArTransactionDetails = (context, trxHdrId) => {
  return http.getApi(URL_UTILITY.getArTrsansactionUrl + '/' + trxHdrId);
};
const getReceiptList = (context, payload) => {
  const url = URL_UTILITY.getArReceiptsUrls;
  const response = http.postApi(
    `${url}?_page=${payload.pagination._page}&_limit=${payload.pagination._limit}`,
    payload.searchParams
  );
  return response;
};
const getReceiptDetails = (context, receiptHdrId) => {
  return http.getApi(URL_UTILITY.getArReceiptsUrls + '/' + receiptHdrId);
};
const getReceiptMethodsDetail = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getArReceiptsUrls + '/receipt-methods',
    payload
  );
};
const getReceiptAdvanceData = (context, payload) => {
  return http.getApi(URL_UTILITY.getArReceiptsUrls + '/advance', payload);
};
const addEditReceipt = (context, payload) => {
  return http.putApi(URL_UTILITY.getArReceiptsUrls, payload);
};

const getTrxEntityTypeByModuleId = (context, moduleId) => {
  return http.getApi(
    URL_UTILITY.getTrxEntityTypeByModuleIdUrl.replace('{moduleId}', moduleId)
  );
};

const getOutStandingList = (context, payload) => {
  return http.getApi(URL_UTILITY.getArReceiptsUrls + '/outstandings', payload);
};

const getReceiptCustomerDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getArReceiptsUrls + '/customer-details',
    payload
  );
};

const getReceiptAdjustmentDetails = (context, receiptHdrId) => {
  return http.getApi(
    URL_UTILITY.getArReceiptsUrls + '/adjustment/' + receiptHdrId
  );
};

const getRemainingAmountForOs = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getRemainingAmountForOsUrl +
      '?trx_type=' +
      payload.trx_type +
      '&trx_id=' +
      payload.trx_id +
      '&attribute=' +
      payload.attribute
  );
};

const getLeaseUnits = () => {
  return http.getApi(URL_UTILITY.getArReceiptsUrls + '/lease-units/lov');
};

const getLeaseProjectTowerFloor = (context, lease_prj_unit_id) => {
  return http.getApi(
    URL_UTILITY.getArReceiptsUrls +
      '/lease-unit/lov?lease_prj_unit_id=' +
      lease_prj_unit_id
  );
};

const getLeaseProjectBasedCustomerSites = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getCustomerSiteUrl +
      `/${payload.lease_prj_id}/lease-customer-sites`,
    payload.searchParams
  );
};

const setArTransactionToComplete = (context, payload) => {
  return http.postApi(URL_UTILITY.setArTransactionToCompleteUrl, payload);
};

const resendEmailForLeaseInvoices = (context, trxHdrId) => {
  return http.putApi(
    URL_UTILITY.getArTrsansactionUrl + '/' + trxHdrId + '/resend-email'
  );
};

const reverseReceipt = (context, payload) => {
  const url =
    URL_UTILITY.getArReceiptsUrls + '/' + payload.receipt_hdr_id + '/reverse';
  return http.postApi(url, payload.reverse_payload);
};

const saveAdvSearchFilters = (context, payload) => {
  context.commit('updateAdvSearchFilters', payload);
};
const getAccountingDetails = (context, trxHdrId) => {
  return http.getApi(
    URL_UTILITY.getArTrsansactionUrl + '/' + trxHdrId + '/accounting'
  );
};

const getReceiptNotesById = (context, trxHdrId) => {
  return http.getApi(
    URL_UTILITY.getReceiptNotesById + '/' + trxHdrId + '/notes'
  );
};

const addEditReceiptNotes = (context, payload) => {
  return http.postApi(URL_UTILITY.getReceiptNotesById + '/notes', payload);
};

const addEditArTransactionHdr = (context, payload) => {
  return http.postApi(
    URL_UTILITY.addEditArTransactionUrl + '/hdr/update',
    payload
  );
};
const addEditArTransactionDtl = (context, payload) => {
  return http.postApi(
    URL_UTILITY.addEditArTransactionUrl + '/dtl/update',
    payload
  );
};
const getTransactionAttributeById = (context, trxHdrId) => {
  return http.getApi(URL_UTILITY.getArTrsansactionUrl + '-attribute-category/' + trxHdrId);
};
const updateTrxnAttributes = (context, payload) => {
  return http.postApi(
    URL_UTILITY.addEditArTransactionUrl + '/hdr/attribute-category-update',
    payload
  );
};
const receiptAccUploadExcelFunc = (context, payload) => {
  const url =
    URL_UTILITY.uploadExcelReceiptAccUrl +
    '/' +
    payload.excel_import_id +
    '/excel-upload';
  return http.postApi(url);
};
const getRecieptUploadData = (context, payload) => {
  return http.getApi(URL_UTILITY.getRecieptUploadDataUrl, payload);
};
const deleteRecieptUpload = (context, payload) => {
  return http.postApi(URL_UTILITY.getArReceiptsUrls + '-loader-delete', payload);
};
const updateNarration = (context, payload) => {
  return http.postApi(URL_UTILITY.updateNarrationUrl + '/' + payload.receiptHdrId, payload);
};
const reverseTrx = (context, payload) => {
  return http.postApi(URL_UTILITY.reverseTrxUrl, payload);
};
const getCodeCombinationNew = (context, payload) => {
  return http.getApi(URL_UTILITY.getCodeCombinationNewUrl + '/' + payload.trxDtlId);
};
const cancelTrxData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.deleteArTrxDataUrl + '/' +
      payload.ar_trx_id +
      '?flag=' +
      payload.flag 
  );
};
const getInterestRateData = (context, payload) => {
  return http.getApi(URL_UTILITY.getInterestRateUrl, payload);
};
const addEditInterestRateData = (context, payload) => {
  return http.postApi(URL_UTILITY.getInterestRateUrl, payload);
};
const getInterestRateById = (context, payload) => {
  return http.getApi(URL_UTILITY.getInterestRateUrl + '/' + payload.irm_id);
};
const getCustomerIdByPartyId = (context, partyId) => {
  const url = URL_UTILITY.getCustomerIdUrl.replace(
    '{partyId}',
    partyId
  );
  return http.getApi(url);
};
export default {
  addArTransactionDetails,
  getArTransactionList,
  getArTransactionDetails,
  getReceiptList,
  getReceiptDetails,
  getReceiptMethodsDetail,
  getReceiptAdvanceData,
  addEditReceipt,
  getTrxEntityTypeByModuleId,
  getOutStandingList,
  getReceiptCustomerDetails,
  getReceiptAdjustmentDetails,
  getRemainingAmountForOs,
  getLeaseUnits,
  getLeaseProjectTowerFloor,
  getLeaseProjectBasedCustomerSites,
  setArTransactionToComplete,
  resendEmailForLeaseInvoices,
  reverseReceipt,
  saveAdvSearchFilters,
  getAccountingDetails,
  getReceiptNotesById,
  addEditReceiptNotes,
  addEditArTransactionHdr,
  addEditArTransactionDtl,
  getTransactionAttributeById,
  updateTrxnAttributes,
  receiptAccUploadExcelFunc,
  getRecieptUploadData,
  deleteRecieptUpload,
  updateNarration,
  reverseTrx,
  getCodeCombinationNew,
  cancelTrxData,
  getInterestRateData,
  addEditInterestRateData,
  getInterestRateById,
  getCustomerIdByPartyId
};
