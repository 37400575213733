import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getAccountMappingDetails = (context, taxRegimeMappingId) => {
  return http.getApi(
    URL_UTILITY.getAccountMappingUrl + '/' + taxRegimeMappingId
  );
};
const addEditAccountMappingDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.getAccountMappingUrl, payload);
};

const getTaxRegimeRegistration = (context, payload) => {
  const url = URL_UTILITY.getTaxRegimeRegistrationUrl;
  return http.getApi(url, payload);
};

const addEditTaxRegimeRegistration = (context, payload) => {
  const url = URL_UTILITY.getTaxRegimeRegistrationUrl;
  return http.postApi(url, payload);
};

export default {
  getTaxRegimeRegistration,
  addEditTaxRegimeRegistration,
  getAccountMappingDetails,
  addEditAccountMappingDetails
};
