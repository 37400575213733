import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getBankClearingModeMappingGrid = (context, payload) => {
  const url = URL_UTILITY.getBankClearingModeMappingGridUrl;
  return http.getApi(url, payload);
};

const getBankMethodDetail = (context, payload) => {
  const url = URL_UTILITY.getBankMethodDetailsUrl;
  return http.getApi(url, payload);
};

const getBankMethodDetailById = (context, methodMpnId) => {
  const url = URL_UTILITY.getBankMethodDetailsUrl + '/' + methodMpnId;
  return http.getApi(url, methodMpnId);
};

const getLegalEntity = (context, payload) => {
  const url = URL_UTILITY.getOrgannizationLovUrl.replace('{lovType}', 'LE');
  const response = http.getApi(url + '?responsibility_id=' + payload);
  return response;
};

const getFmsProjectLov = (context, payload) => {
  const url = URL_UTILITY.getFmsProjectLovUrl.replace(
    '{typeOfProject}',
    payload.typeOfProject
  );
  return http.getApi(url + '?le_id=' + payload.le_id);
};

const getBankBranchIdLov = () => {
  const url = URL_UTILITY.getBankBranchIdLovUrl;
  return http.getApi(url);
};

const getBankAccountByBranchLov = (context, payload) => {
  const url = URL_UTILITY.getBankAccountByBranchLovUrl.replace(
    '{bankBranchId}',
    payload.bankBranchId
  );
  return http.getApi(url + '?le_id=' + payload.le_id);
};

const getMethodTypeValueSetLov = (context, payload) => {
  const url = URL_UTILITY.getMethodTypeAndModeValueSetUrl.replace(
    '{valueSetName}',
    payload.valueSetType
  );
  return http.getApi(
    url + '?parent_value_set_id=' + payload.parent_value_set_id
  );
};

const getPaymentMethods = (context, payload) => {
  const url = URL_UTILITY.getPaymentMethodsUrl;
  return http.getApi(url, payload);
};

const getPaymentMethodsLov = (context, payload) => {
  const url = URL_UTILITY.getPaymentMethodsLovUrl;
  return http.getApi(url, payload);
};
const getPartyBankDetails = (context, payload) => {
  const url = URL_UTILITY.getPartyBankDetailsUrl;
  return http.getApi(
    url +
      '?le_id=' +
      payload.le_id +
      '&party_method_id=' +
      payload.party_method_id
  );
};

const addEditBankMode = (context, payload) => {
  const url = URL_UTILITY.getBankMethodDetailsUrl;
  return http.postApi(url, payload);
};
export default {
  getBankClearingModeMappingGrid,
  getBankMethodDetail,
  getBankMethodDetailById,
  getLegalEntity,
  getFmsProjectLov,
  getBankBranchIdLov,
  getBankAccountByBranchLov,
  getMethodTypeValueSetLov,
  getPaymentMethods,
  getPartyBankDetails,
  addEditBankMode,
  getPaymentMethodsLov
};
