import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getWorkdayCalendarDetails = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/workday';
  return http.getApi(url, payload);
};
const addAndEditWorkdayCalendarDetails = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/workday';
  return http.postApi(url, payload);
};
const getWorkdayCalendarDetailsById = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/workday/' + payload.pathVariables + "/workday-details";
  return http.getApi(url, payload.searchParams);
};
const saveCalendarDetails = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/workday-calendar-dtl';
  return http.putApi(url, payload);
};
const getShiftDetailsById = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/workday-shift/' + payload;
  return http.getApi(url);
};
const saveShiftDetails = (context, payload) => {
  const url = URL_UTILITY.getFmsAdminUrl + '/workday-shift/' + payload.workday_hdr_id;
  return http.postApi(url,payload.requestParams);
};
export default {
  getWorkdayCalendarDetails,
  addAndEditWorkdayCalendarDetails,
  getWorkdayCalendarDetailsById,
  saveCalendarDetails,
  getShiftDetailsById,
  saveShiftDetails
};
