import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getTemplateList = (context, payload) => {
  const url = URL_UTILITY.getTemplateUrl;
  return http.getApi(url, payload);
};
const addTemplateList = (context, payload) => {
  const url = URL_UTILITY.postTemplateUrl;
  return http.postApi(url, payload);
};
const getTemplateByIdList = (context, templateId) => {
  const url = URL_UTILITY.getTemplateByIdUrl.replace(
    '{templateId}',
    templateId
  );
  return http.getApi(url);
};
const getRequestLovList = (context, payload) => {
  const url = URL_UTILITY.getRequestLovUrl;
  return http.getApi(url, payload);
};
const getLOVBySetType = (context, setType) => {
  const url = URL_UTILITY.getLOVBySetTypeUrl.replace('{valueSetType}', setType);
  const response = http.getApi(url);
  return response;
};
const getRequestParameterList = (context, templateId) => {
  const url = URL_UTILITY.getRequestParameterUrl.replace(
    '{templateId}',
    templateId
  );
  return http.getApi(url);
};
const addParameterList = (context, payload) => {
  const url = URL_UTILITY.postRequestParameterUrl;
  return http.postApi(url, payload);
};
const getValuesetLovList = (context, payload) => {
  const url = URL_UTILITY.getLOVSetTypeUrl;
  return http.getApi(url, payload);
};

const getSubmitRequestList = (context, payload) => {
  const url = URL_UTILITY.getSubmitRequestUrl;
  return http.getApi(url, payload);
};

// const getRequestDetailsById = (context, request_num) => {
//   const url = URL_UTILITY.getRequestDetailsByIdUrl.replace(
//     '{request_id}',
//     request_num
//   );
//   return http.getApi(url, request_num);
// };

const getSubmitRequest = (context, requestNum) => {
  return http.getApi(URL_UTILITY.getSubmitRequestUrl + "/" + requestNum);
}
const saveSubmitRequest = (context, payload) => {
  const url = URL_UTILITY.postSubmitRequestUrl;
  return http.postApi(url, payload);
};

const irnSaveSubmitRequest = (context, payload) => {
  const url = URL_UTILITY.updateIrnRequestUrl;
  return http.postApi(url, payload);
};

const getTimeUnit = (context, payload) => {
  const url = URL_UTILITY.getLOVBySetTypeUrl.replace(
    '{valueSetType}',
    payload.setType
  );

  const response = http.getApi(
    `${
      !payload.PARENT_VALUE_SET_ID
        ? url
        : url + '?parent_value_set_id=' + payload.PARENT_VALUE_SET_ID
    }`
  );
  return response;
};
const getValuesetDetails = (context, valueSetName) => {
  const url = URL_UTILITY.getCommonValueSetDataByCodeUrl.replace(
    '{valueSetName}',
    valueSetName
  );
  return http.getApi(url + '/details');
};

const invalidateReqeustByRequestId = (context, requestNum) => {
  return http.postApi(URL_UTILITY.getSubmitRequestUrl + '/' + requestNum + '/invalidate');
};

const getViewReqDetail = (context, requestNum) => {
  return http.getApi(URL_UTILITY.getSubmitRequestUrl+'/'+ requestNum + '/delivery-detail')
};
const awsBackupReport = (context, payload) => {
  const url = URL_UTILITY.getGstUrl + '/gst-aws-backup-requests';
  return http.getApi(url, payload);
};
const getDigiSignVerify = (context, payload) => {
  return http.getApi(URL_UTILITY.getSubmitRequestUrl + "/verify-digital-sign/" + payload.requestHdrId);
}

export default {
  getTemplateList,
  addTemplateList,
  getTemplateByIdList,
  getRequestLovList,
  getLOVBySetType,
  getRequestParameterList,
  addParameterList,
  getValuesetLovList,
  getSubmitRequestList,
  // getRequestDetailsById,
  saveSubmitRequest,
  getTimeUnit,
  getValuesetDetails,
  invalidateReqeustByRequestId,
  getSubmitRequest,
  irnSaveSubmitRequest,
  getViewReqDetail,
  awsBackupReport,
  getDigiSignVerify
};
