import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getRequestGroupList = (context,payload) => {
    const url = URL_UTILITY.getRequestGroupUrl;
    return http.getApi(url,payload);
  };
const getRequestGroupByIdList = (context,reportGroupId) => {
  const url = URL_UTILITY.getRequestGroupByIdUrl.replace('{reportGroupId}',reportGroupId);
  return http.getApi(url);
};
  const addRequestGroupList = (context, payload) => {
    const url = URL_UTILITY.postRequestGroupUrl;
    return http.postApi(url, payload);
  };

  const getRequestList = (context,payload) => {
const url = URL_UTILITY.getRequestUrl;
return http.getApi(url,payload);
  };
  export default{
    getRequestGroupList,
    addRequestGroupList,
    getRequestGroupByIdList,
    getRequestList
  };

  