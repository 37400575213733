import XLSX from 'xlsx';
import store from '../../store';
import commonHelper from '@/app/utility/common.helper.utility';
const downloadData = (payload, apiUrl, compName, callback, requestData) => {
  payload._limit =
    payload._limit < commonHelper.perPageRecord
      ? commonHelper.perPageRecord
      : payload._limit;
  store
    .dispatch(apiUrl, payload)
    .then(response => {
      if (response.status === 200) {
        const requestedData = response.data.data.page
          ? response.data.data.page
          : requestData;
        const dataToDownload = requestedData.map(data => {
          let newData = {};
          for (const key in data) {
            const formatedKeyName = key.replace(/_/g, ' ').toUpperCase();
            Object.assign(newData, { [formatedKeyName]: data[key] });
          }
          return newData;
        });
        const data = XLSX.utils.json_to_sheet(dataToDownload);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, 'data');
        XLSX.writeFile(
          wb,
          `${compName}-${new Date().toLocaleDateString()}.xlsx`
        );
      }
    })
    .catch(() => {
      alert('something went wrong, download not completed');
    })
    .finally(() => {
      callback();
    });
};

const downloadDetails = (payload, apiUrl, compName, callback) => {
  // payload._limit = payload._limit < commonHelper.perPageRecord ? commonHelper.perPageRecord : payload._limit;
  store
    .dispatch(apiUrl, payload)
    .then(response => {
      if (response.status === 200) {
        const detailsToDownload = response.data.data.map(data => {
          let newDetails = {};
          for (const key in data) {
            const formatedKeyName = key.replace(/_/g, ' ').toUpperCase();
            Object.assign(newDetails, { [formatedKeyName]: data[key] });
          }
          return newDetails;
        });
        const details = XLSX.utils.json_to_sheet(detailsToDownload);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, details, 'details');
        XLSX.writeFile(
          wb,
          `${compName}-${new Date().toLocaleDateString()}.xlsx`
        );
      }
    })
    .catch(() => {
      alert('something went wrong, download not completed');
    })
    .finally(() => {
      callback();
    });
};
export default {
  downloadData,
  downloadDetails
};
