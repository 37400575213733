import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getMenuList = (context, payload) => {
  return http.getApi(URL_UTILITY.getMenuListUrl, payload);
};
const addEditMenu = (context, payload) => {
  return http.postApi(URL_UTILITY.getMenuListUrl, payload);
};

const getMenuMappingList = (context, payload) => {
  return http.getApi(URL_UTILITY.getMenuMappingListUrl, payload);
};

const addEditMenuMappingRecord = (context, payload) => {
  return http.postApi(URL_UTILITY.addEditMenuMappingRecordUrl, payload);
};

const getMenuDetail = (context, menuId) => {
  return http.getApi(URL_UTILITY.getMenuListUrl + '/' + menuId);
};
// --------------------------------------------------------------------------------------
export default {
  getMenuList,
  addEditMenu,
  getMenuMappingList,
  addEditMenuMappingRecord,
  getMenuDetail
};
