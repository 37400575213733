import store from '../../../../../../store';
import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
export default {
  name: 'personalInfo',
  components: {
    ModelSelect,
    DatePicker
  },
  data() {
    return {
      unsubscribe: null,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      editMode: false,
      loader: false,
      selectedLegalEntity: {
        value: null,
        text: null
      },
      legalEntityList: [],
      selectedSalutation: {
        value: null,
        text: null
      },
      salutationList: [],
      selectedMaritalStatus: {
        value: null,
        text: null
      },
      maritalStatusList: [],
      selectedGender: {
        value: null,
        text: null
      },
      genderList: [],
      selectedEmpType: {
        value: null,
        text: null
      },
      employeeTypeList: [],
      selectedEmpStatus: {
        value: null,
        text: null
      },
      empStatusList: [],
      selectedCriticality: {
        value: null,
        text: null
      },
      criticalityList: [],
      personalInfoObj: {
        country_code: 91,
        date_of_group_joining: null,
        date_of_joining: null,
        dob: null,
        emp_status_lookup: null,
        employee_type_lookup: null,
        employee_id: 0,
        employee_num: null,
        extension: 0,
        first_name: null,
        gender_lookup: null,
        last_name: null,
        le_id: null,
        marital_status_lookup: null,
        middle_name: null,
        official_mobile: null,
        office_phone: null,
        official_email_id: null,
        position_criticality_lookup: null,
        salutation_lookup: null
      }
    };
  },
  mounted() {
    this.getLegalEntity();
    this.getLookupData(appStrings.LOOKUPTYPE.SALUTATION);
    this.getLookupData(appStrings.LOOKUPTYPE.MARITAL_STATUS);
    this.getLookupData(appStrings.LOOKUPTYPE.GENDER);
    this.getLookupData(appStrings.LOOKUPTYPE.EMPLOYEE_TYPE);
    this.getLookupData(appStrings.LOOKUPTYPE.EMPLOYMENT_STATUS);
    this.getLookupData(appStrings.LOOKUPTYPE.POSITION_CRITICALITY);
    this.getPersonalInfo(store.state.shared.empId);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const action = state.shared.actionName;
        if (action === 'save') {
          this.addEditEmployee();
        } else if (action === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getPersonalInfo(empId) {
      this.loader = true;
      this.$store
        .dispatch('hrms/getEmployeeById', empId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.personalInfoObj = resp.data.data;
            this.selectedEmpStatus = {
              value: this.personalInfoObj.emp_status_lookup,
              text: this.personalInfoObj.emp_status
            };
            this.selectedSalutation = {
              value: this.personalInfoObj.salutation_lookup,
              text: this.personalInfoObj.salutation
            };
            this.selectedMaritalStatus = {
              value: this.personalInfoObj.marital_status_lookup,
              text: this.personalInfoObj.marital
            };
            this.selectedEmpType = {
              value: this.personalInfoObj.employee_type_lookup,
              text: this.personalInfoObj.emp_type
            };
            this.selectedCriticality = {
              value: this.personalInfoObj.position_criticality_lookup,
              text: this.personalInfoObj.position_criticality
            };
            this.selectedLegalEntity = {
              value: this.personalInfoObj.le_id,
              text: this.personalInfoObj.le_name
            };
            this.$store.dispatch(
              'shared/setUnsetOrgTypeId',
              this.selectedLegalEntity
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditEmployee() {
      const payload = {
        country_code: 91,
        date_of_group_joining: this.personalInfoObj.date_of_group_joining
          ? format(
              new Date(this.personalInfoObj.date_of_group_joining),
              'dd-MMM-yyyy'
            )
          : null,
        date_of_joining: this.personalInfoObj.date_of_joining
          ? format(
              new Date(this.personalInfoObj.date_of_joining),
              'dd-MMM-yyyy'
            )
          : null,
        dob: this.personalInfoObj.dob
          ? format(new Date(this.personalInfoObj.dob), 'dd-MMM-yyyy')
          : null,
        emp_status_lookup: this.selectedEmpStatus.value,
        employee_type_lookup: this.selectedEmpType.value,
        employee_id: this.personalInfoObj.employee_id,
        employee_num: this.personalInfoObj.employee_num,
        extension: this.personalInfoObj.extension,
        first_name: this.personalInfoObj.first_name,
        middle_name: this.personalInfoObj.middle_name,
        last_name: this.personalInfoObj.last_name,
        gender_lookup: this.personalInfoObj.gender_lookup,
        le_id: this.selectedLegalEntity.value,
        marital_status_lookup: this.selectedMaritalStatus.value,
        official_mobile: this.personalInfoObj.official_mobile,
        office_phone: this.personalInfoObj.office_phone,
        official_email_id: this.personalInfoObj.official_email_id,
        position_criticality_lookup: this.selectedCriticality.value,
        salutation_lookup: this.selectedSalutation.value,
        probation_period: this.personalInfoObj.probation_period,
        confirmation_date: this.personalInfoObj.confirmation_date
          ? format(
              new Date(this.personalInfoObj.confirmation_date),
              'dd-MMM-yyyy'
            )
          : null
      };
      this.loader = true;
      this.$store
        .dispatch('hrms/addEditEmpPersonalInfo', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getPersonalInfo(store.state.shared.empId);
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLegalEntity() {
      const payload = store.state.shared.responsibilityId;
      this.$store
        .dispatch('receipt/getOrganizationLov', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.org_name
              };
            });
            this.legalEntityList = leagalEntity;
            this.selectedLegalEntity =
              leagalEntity && leagalEntity.length === 1
                ? leagalEntity[0]
                : this.selectedLegalEntity;
          }
        });
    },

    getLookupData(lookupCode) {
      this.$store
        .dispatch('shared/getDataFromLookupsByLookupType', lookupCode)
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const salutationArr = result.map(type => {
              return {
                value: type.lookup_code,
                text: type.meaning
              };
            });
            if (lookupCode === appStrings.LOOKUPTYPE.SALUTATION) {
              this.salutationList = salutationArr;
              this.selectedSalutation = salutationArr && salutationArr[0];
            } else if (lookupCode === appStrings.LOOKUPTYPE.MARITAL_STATUS) {
              this.maritalStatusList = salutationArr;
              this.selectedMaritalStatus = salutationArr && salutationArr[0];
            } else if (lookupCode === appStrings.LOOKUPTYPE.GENDER) {
              this.genderList = salutationArr;
              this.selectedGender = salutationArr && salutationArr[0];
            } else if (lookupCode === appStrings.LOOKUPTYPE.EMPLOYEE_TYPE) {
              this.employeeTypeList = salutationArr;
              this.selectedEmpType = salutationArr && salutationArr[0];
            } else if (lookupCode === appStrings.LOOKUPTYPE.EMPLOYMENT_STATUS) {
              this.empStatusList = salutationArr;
              this.selectedEmpStatus = salutationArr && salutationArr[0];
            } else if (
              lookupCode === appStrings.LOOKUPTYPE.POSITION_CRITICALITY
            ) {
              this.criticalityList = salutationArr;
              this.selectedCriticality = salutationArr && salutationArr[0];
            }
          }
        })
        .catch(error => {
          alert(error.message);
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
