import store from '../../../../../../store';
import appStrings from '@/app/utility/string.utility';
import Datepicker from 'vuejs-datepicker';
import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
export default {
  name: 'professionalInfo',
  components: {
    Datepicker,
    DatePicker
  },
  data() {
    return {
      unsubscribe: null,
      profListIndex: null,
      vsetCode: null,
      setTimeVsetCode: null,
      showValueSetModal: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      editMode: false,
      loader: false,
      professionalInfoList: [
        {
          employee_id: 0,
          seq_order: 1,
          start_job: null,
          end_job: null,
          start_date: null,
          end_date: null,
          start_month: null,
          start_year: null,
          end_month: null,
          end_year: null,
          designation: null,
          employee_prof_id: 0,
          company_vset: null,
          company: null,
          other_company: null
        }
      ],
      professionalInfoFields: [
        {
          key: 'company'
        },
        {
          key: 'designation'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'seq_order'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.getHrmsProfList(store.state.shared.empId);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const action = state.shared.actionName;
        if (action === 'save' && this.editMode) {
          this.addEditProf();
        } else if (action === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditProf() {
      const profDetails = this.professionalInfoList.map(detail => {
        return {
          company_vset: detail.company_vset,
          designation: detail.designation,
          employee_prof_id:
            detail.employee_prof_id === 0 ? null : detail.employee_prof_id,
          end_month: detail.end_date
            ? format(new Date(detail.end_date), 'M')
            : null,
          end_year: detail.end_date
            ? format(new Date(detail.end_date), 'yyyy')
            : null,
          other_company: null,
          seq_order: detail.seq_order,
          start_month: detail.start_date
            ? format(new Date(detail.start_date), 'M')
            : null,
          start_year: detail.start_date
            ? format(new Date(detail.start_date), 'yyyy')
            : null
        };
      });
      const payload = {
        employee_id: store.state.shared.empId,
        employee_professional_details: profDetails
      };
      this.loader = true;
      this.$store
        .dispatch('hrms/addEditProfInfo', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getHrmsProfList(empId) {
      this.loader = true;
      this.$store
        .dispatch('hrms/getHrmsProfList', empId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length > 0) {
            const hremsList = resp.data.data.map(hrms => {
              hrms.start_date =
                hrms.start_month && hrms.start_year
                  ? new Date(hrms.start_month, hrms.start_year)
                  : null;
              hrms.end_date =
                hrms.end_month && hrms.end_year
                  ? new Date(hrms.end_month, hrms.end_year)
                  : null;
              return hrms;
            });
            this.professionalInfoList = hremsList;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'COMPANY') {
        this.professionalInfoList[this.profListIndex].company =
          item.value_meaning;
        this.professionalInfoList[this.profListIndex].company_vset =
          item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.DESIGNATION) {
        this.professionalInfoList[this.profListIndex].designation =
          item.value_code;
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.profListIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.professionalInfoList.push({
          employee_id: 0,
          seq_order: 1,
          start_date: null,
          end_date: null,
          start_job: null,
          end_job: null,
          start_month: null,
          start_year: null,
          end_month: null,
          end_year: null,
          designation: null,
          employee_prof_id: 0,
          company_vset: null,
          company: null,
          other_company: null
        });
      }
    },
    removeRow(index) {
      this.professionalInfoList.splice(index, 1);
    },
    rowSelected() {},
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'COMPANY') {
        this.professionalInfoList[index].company = null;
        this.professionalInfoList[index].company_vset = null;
      } else if (vsetCode === 'DESIGNATION') {
        this.professionalInfoList[index].designation = null;
      }
    }
  },
  destroyed() {
    clearTimeout(this.setTimeVsetCode);
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
