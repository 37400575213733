import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getLeaseSubUnitList = (context, payload) => {
  const url = URL_UTILITY.getLeaseSubUnitListUrl;
  return http.getApi(url, payload);
};

const deleteMappedSubUnit = (context, payload) => {
  return http.putApi(
    URL_UTILITY.getLeaseSubUnitDetailsByIdUrl.replace(
      '{leasePrjUnitId}',
      payload.leasePrjUnitId
    ),
    payload.assignSubUnits
  );
};

const getLeaseSubUnitDetailsById = (context, payload) => {
  const url = URL_UTILITY.getLeaseSubUnitDetailsByIdUrl.replace(
    '{leasePrjUnitId}',
    payload
  );
  return http.getApi(url);
};

const addLeaseSubUnitMapping = (context, payload) => {
  const url = URL_UTILITY.getLeaseSubUnitDetailsByIdUrl.replace(
    '{leasePrjUnitId}',
    payload.lease_prj_unit_id
  );
  return http.postApi(url, payload.subUnitMapping);
};

const getVendorDetailsById = (context, fmsSubUnitId) => {
  return http.getApi(
    URL_UTILITY.getLeaseSubUnitListUrl + '/' + fmsSubUnitId + '/vendor-details'
  );
};
export default {
  getLeaseSubUnitList,
  deleteMappedSubUnit,
  getLeaseSubUnitDetailsById,
  addLeaseSubUnitMapping,
  getVendorDetailsById
};
