import http from './httpServices';
import URL_UTILITY from '../utility/url.utility';

const validateEmail = async email => {
  const data = {
    user_email: email
  };
  let response = await http.postApi(URL_UTILITY.email, data);
  return response;
};

const validateAddressName = async addressName => {
  const url = URL_UTILITY.addressName.replace('{addressName}', addressName);
  const response = await http.getApi(url);
  return response;
};

const validateCountryName = async countryName => {
  const url = URL_UTILITY.checkCountryName.replace(
    '{countryName}',
    countryName
  );
  const response = await http.getApi(url);
  return response;
}; //c//

const validateCountryCode = async countryCode => {
  const url = URL_UTILITY.checkCountryCode.replace(
    '{countryCode}',
    countryCode
  );
  const response = await http.getApi(url);
  return response;
}; //c//

const getCountryContinents = async () => {
  const url = URL_UTILITY.getCountryContinents;
  const response = await http.getApi(url);
  return response;
}; //c//

const getCountryRegions = async () => {
  const url = URL_UTILITY.getCountryRegions;
  const response = await http.getApi(url);
  return response;
}; //cc//

const updateCountryData = (countryData, countryId) => {
  const url = URL_UTILITY.updateCountryDataUrl.replace(
    '{countryId}',
    countryId
  ); 
  const response = http.putApi(url, countryData);
  return response;
}; //c//

const addCountryData = countrydData => {
  const url = URL_UTILITY.getAndAddCountryUrl;
  const response = http.postApi(url, countrydData);
  return response;
}; //c//

const getCountryDataPagination = (page, limit, key = '') => {
  const url = URL_UTILITY.getCountryDataPaginationUrl
    .replace('{page}', page)
    .replace('{limit}', limit)
    .replace('{key}', key);
  const response = http.getApi(url);
  return response;
}; //c//

const validateStateTaxCode = async (countryId, stateTaxCode) => {
  const url = URL_UTILITY.checkStateTaxCode
    .replace('{countryId}', countryId)
    .replace('{stateTaxCode}', stateTaxCode);
  const response = await http.getApi(url);
  return response;
};

const updateStateData = (stateData, stateId) => {
  const url = URL_UTILITY.updateStateDataUrl.replace('{stateId}', stateId);
  const response = http.putApi(url, stateData);
  return response;
};

const addStateData = statedData => {
  const url = URL_UTILITY.getAndAddStateUrl;
  const response = http.postApi(url, statedData);
  return response;
};

const updateCityData = (cityData, cityId) => {
  const url = URL_UTILITY.updateCitiesDataUrl.replace('{cityId}', cityId);
  const response = http.putApi(url, cityData);
  return response;
};

const addCityData = citydData => {
  const url = URL_UTILITY.getAndAddCitiesUrl;
  const response = http.postApi(url, citydData);
  return response;
};
const updatePincodeData = (pincodeData, pincodeId) => {
  const url = URL_UTILITY.updatePincodesDataUrl.replace(
    '{pincodeId}',
    pincodeId
  );
  const response = http.putApi(url, pincodeData);
  return response;
};

const addPincodeData = pincodedData => {
  const url = URL_UTILITY.getAndAddPincodesUrl;
  const response = http.postApi(url, pincodedData);
  return response;
};

const getAddressType = async () => {
  const url = URL_UTILITY.getAddressTypesLookupUrl;
  const response = http.getApi(url);
  return response;
};

const getCountryList = async () => {
  const url = URL_UTILITY.getCountriesLovUrl;
  const response = http.getApi(url);
  return response;
};

const getCountryState = async countryId => {
  const url = URL_UTILITY.getStatesLovByCountryIdUrl.replace(
    '{countryId}',
    countryId
  );
  const response = http.getApi(url);
  return response;
};

const getAddressCountryCity = async stateId => {
  const url = URL_UTILITY.getCitiesLovByStateIdUrl.replace(
    '{stateId}',
    stateId
  );
  const response = http.getApi(url);
  return response;
};

const getPincode = async stateId => {
  const url = URL_UTILITY.getPincodesLovByStateIdUrl.replace(
    '{stateId}',
    stateId
  );
  const response = http.getApi(url);
  return response;
};

const addAdress = async addressData => {
  const data = {
    address1: addressData.address_1,
    address2: addressData.address_2,
    address3: addressData.address_3,
    address_name: addressData.address_name,
    address_type: addressData.address_type.value,
    city_id: addressData.city.value,
    country_id: addressData.country.value,
    pincode_id: addressData.pincode.value,
    state_id: addressData.state.value,
    other_city: null,
    other_state: null
  };

  const url = URL_UTILITY.getAddAddressDataUrl;
  const response = http.postApi(url, data);
  return response;
};

const updateAddress = async (addressData, addressId) => {
  const data = {
    address_id: addressData.address_id,
    address1: addressData.address_1,
    address2: addressData.address_2,
    address3: addressData.address_3,
    address_name: addressData.address_name,
    address_type: addressData.address_type.value,
    city_id: addressData.city.value,
    country_id: addressData.country.value,
    pincode_id: addressData.pincode.value,
    state_id: addressData.state.value,
    other_city: null,
    other_state: null
  };
  const url = URL_UTILITY.updateAddressDataUrl.replace(
    '{addressId}',
    addressId
  );
  const response = http.putApi(url, data);
  return response;
};

const addPreferences = async data => {
  const url = URL_UTILITY.preferencesAddDeleteDataUrl;
  const response = http.postApi(url, data);
  return response;
};

const getPreferences = async preferenceId => {
  const url = URL_UTILITY.preferencesGetDataUrl.replace(
    '{preferenceId}',
    preferenceId
  );
  const response = http.getApi(url);
  return response;
};

const deletePreferences = async data => {
  const url = URL_UTILITY.preferencesAddDeleteDataUrl;
  const response = http.putApi(url, data);
  return response;
};

const getStatesByCountryId = (countryId, page, limit, stateName = '') => {
  const url = URL_UTILITY.getStatesByCountryIdUrl
    .replace('{countryId}', countryId)
    .replace('{page}', page)
    .replace('{limit}', limit)
    .replace('{state_name}', stateName);
  const response = http.getApi(url);
  return response;
};

const getCitiesByStateId = (stateId, page, limit, cityName = '') => {
  const url = URL_UTILITY.getCitiesByStateIdUrl
    .replace('{stateId}', stateId)
    .replace('{page}', page)
    .replace('{limit}', limit)
    .replace('{city_name}', cityName);
  const response = http.getApi(url);
  return response;
};

const getPincodesByStateId = (stateId, page, limit, pincode = '') => {
  const url = URL_UTILITY.getPincodesByStateIdUrl
    .replace('{stateId}', stateId)
    .replace('{page}', page)
    .replace('{limit}', limit)
    .replace('{pincode}', pincode);
  const response = http.getApi(url);
  return response;
};


const getAddressDataPagination = (page, limit, key = '') => {
  const url = URL_UTILITY.getAddessDataPaginationUrl
    .replace('{page}', page)
    .replace('{limit}', limit)
    .replace('{key}', key);
  const response = http.getApi(url);
  return response;
};

const getAccessRightDataByMenuId = roleMenuMpngId => {
  const url = URL_UTILITY.getAccessRightUrl.replace(
    '{roleMenuMpngId}',
    roleMenuMpngId
  );
  const response = http.getApi(url);
  return response;
};

const getFilteredAddressDataPagination = (
  countryId,
  stateId,
  addressType,
  page,
  limit,
  key = '',
  address_name = ''
) => {
  const url = URL_UTILITY.getFilteredAddressDataUrl
    .replace('{country_id}', countryId)
    .replace('{state_id}', stateId)
    .replace('{address_type}', addressType)
    .replace('{page}', page)
    .replace('{limit}', limit)
    .replace('{key}', key)+
    '&address_name='+ address_name
  const response = http.getApi(url);
  return response;
};

export {
  validateEmail,
  addPreferences,
  getPreferences,
  deletePreferences,
  getAddressType,
  getCountryList,
  getCountryState,
  getAddressCountryCity,
  validateAddressName,
  getPincode,
  addAdress,
  updateAddress,
  validateCountryName,
  validateCountryCode,
  getCountryContinents,
  getCountryRegions,
  updateCountryData,
  addCountryData,
  updateStateData,
  addStateData,
  getStatesByCountryId,
  getCitiesByStateId,
  updateCityData,
  addCityData,
  getAccessRightDataByMenuId,
  updatePincodeData,
  addPincodeData,
  getPincodesByStateId,
  getCountryDataPagination,
  getAddressDataPagination,
  getFilteredAddressDataPagination,
  validateStateTaxCode
};
