import store from '../../../../../../store/';
import Currency from '../../../../admin/currency/';
import Organization from '../../../../admin/organization/';
import { required } from 'vuelidate/lib/validators';
import paymentSeries from '../../partyBank/bankAccount/paymentSeries';
export default {
  name: 'bankAccount',
  components: {
    Currency,
    Organization,
    paymentSeries
  },
  data() {
    return {
      unsubscribe: null,
      showModal: false,
      accountDetails: null,
      editMode: false,
      createAccountModel: false,
      branchIndex: 0,
      partyId: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      openCurrencyModal: false,
      openLeModal: false,
      bankAccountList: [],
      tableFields: [
        {
          key: 'account_name'
        },
        {
          key: 'short_name'
        },
        {
          key: 'account_num'
        },
        {
          key: 'currency'
        },
        {
          key: 'currency_id',
          class: 'd-none'
        },
        {
          key: 'le',
          label: 'Legal Entity'
        },
        {
          key: 'le_id',
          class: 'd-none'
        },
        {
          key: 'active'
        },
        {
          key: 'multi_currency_payment'
        },
        {
          key: 'multi_currency_receipt'
        },
        {
          key: 'payment_series'
        },
        {
          key: 'bank_branch_id',
          class: 'd-none'
        },
        {
          key: 'bank_account_id',
          class: 'd-none'
        }
      ],
      accountForm: {
        account_name: null,
        account_num: null,
        account_short_name: null,
        le_id: null,
        le_name: null,
        currency_id: null,
        currency_name: null,
        active: true,
        multi_currency_payment: false,
        multi_currency_receipt: false,
        is_tds_account: false,
        bank_branch_id: null,
        bank_account_id: 0
      }
    };
  },
  validations: {
    accountForm: {
      account_name: { required },
      account_num: { required },
      account_short_name: { required },
      le_name: { required },
      currency_name: { required }
    }
  },
  mounted() {
    this.eventBus.$off('branchItem');
    this.eventBus.$off('getCurrency');
    this.eventBus.$off('getOu');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.editMode = true;
          this.createAccountModel = true;
        }
        if (actionName === 'save' && this.editMode && !this.showModal) {
          this.submitCreateAccountForm();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    this.eventBus.$on('getCurrency', getCurrency => {
      this.accountForm.currency_name = getCurrency.currency;
      this.accountForm.currency_id = getCurrency.currency_id;
      this.openCurrencyModal = false;
    });
    this.eventBus.$on('getOu', getOu => {
      this.accountForm.le_name = getOu.ou_name;
      this.accountForm.le_id = getOu.ou_id;
      this.openLeModal = false;
    });
    this.partyId = store.state.party.partyId;
    this.getBankAccountList();
  },
  methods: {
    getBankAccountList() {
      this.loader = true;
      this.$store
        .dispatch('party/getBankAccountList', this.partyId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.bankAccountList = response.data.data.bank_accounts;
            this.bankAccountList.forEach(key => {
              key.bank_accounts.map(data => {
                data.payment_series = 'Details';
                return data;
              });
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openShowModal(flag, item) {
      this.showModal = flag;
      this.accountDetails = item;
    },
    selectedBranch(index) {
      this.branchIndex = index;
    },
    hideCreateAccountModel() {
      this.createAccountModel = false;
    },
    submitCreateAccountForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          partyId: this.partyId,
          accountData: {
            bank_accounts: [
              {
                bank_branch_id: this.bankAccountList[this.branchIndex]
                  .bank_branch_id,
                bank_account_id: this.accountForm.bank_account_id,
                account_name: this.accountForm.account_name,
                account_num: this.accountForm.account_num,
                account_short_name: this.accountForm.account_short_name,
                currency_id: this.accountForm.currency_id,
                le_id: this.accountForm.le_id,
                active: this.accountForm.active,
                multi_currency_payment: this.accountForm.multi_currency_payment,
                multi_currency_receipt: this.accountForm.multi_currency_receipt,
                is_tds_account: this.accountForm.is_tds_account
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/addEditBankAccount', payload)
          .then(response => {
            this.loader = false;
            this.createAccountModel = false;
            if (response.status === 200) {
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.bankAccountList = response.data.data.bank_accounts;
            }
          })
          .catch(error => {
            this.loader = false;
            alert(error.message);
          });
      }
    },
    openCloseCurrency(flag) {
      this.openCurrencyModal = flag;
    },
    openCloseLe(flag, orgType) {
      this.openLeModal = flag;
      const dataToGetOrg = {
        orgType: orgType,
        parentLeId: null
      };
      this.$store.dispatch('shared/setUnsetOrgType', dataToGetOrg);
    },
    accountRowSelected(item) {
      if (this.$router.currentRoute.name === 'searchParty') {
        this.accountForm = {
          account_name: item.account_name,
          account_num: item.account_num,
          account_short_name: item.short_name,
          le_id: item.le_id,
          le_name: item.le,
          currency_id: item.currency_id,
          currency_name: item.currency,
          active: item.active,
          multi_currency_payment: item.multi_currency_payment,
          multi_currency_receipt: item.multi_currency_receipt,
          is_tds_account: item.is_tds_account,
          bank_branch_id: item.bank_branch_id,
          bank_account_id: item.bank_account_id
        };
        this.editMode = false;
        this.createAccountModel = true;
      } else {
        this.$emit('selectedBankAccountDetails', item);
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
