import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getFusion = (context, payload) => {
  return http.getApi(URL_UTILITY.getFusionUrl + '/ar-fusion-invoices', payload);
};

const saveFusion = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFusionUrl + '/ar-fusion-invoices',
    payload
  );
};

const getSavedFusionData = (context, payload) => {
  return http.getApi(URL_UTILITY.getFusionUrl + '/save-Reports', payload);
};

export default {
  getFusion,
  saveFusion,
  getSavedFusionData
};
