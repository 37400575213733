
import { required } from 'vuelidate/lib/validators';
import store from '../../../../../../store/';
import Address from '../../../../admin/address';
export default {
  name: 'createBranch',
  props: {
    showBranch: String,
  },
  components: {
    Address
  },
  watch: {
    showBranch: function (showBranchForm) {
      this.createBranchModel = showBranchForm === 'show' ? true : false;
    }
  },
  data() {
    return {
      unsubscribe: null,
      createBranchModel: false,
      editMode: true,
      loader: false,
      showBankModal: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      partyId: null,
      showModal: false,
      branchForm: {
        address_name: null, 
        branch_name: null,
        ifsc_code: null,
        short_name: null,
        bank_branch_id: 0,
        address_id: null,
        active: true
      }
    };
  },
  validations: {
    branchForm: {
      address_name: { required },
      branch_name: { required },
      ifsc_code: { required },
      short_name: { required }
    }
  },
  mounted() {
    this.eventBus.$off('branchItem');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.submitCreateBranchForm();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });



    this.eventBus.$on('branchItem', branchItem => {
      this.createBranchModel = true;
      this.editMode = false;
      this.branchForm = {
        branch_name: branchItem.branch_name,
        ifsc_code: branchItem.ifsc_code,
        address_name: branchItem.address_name,
        address_id: branchItem.address_id,
        short_name: branchItem.short_name,
        bank_branch_id: branchItem.bank_branch_id,
        active: branchItem.active
      };
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'Address') {
        return (this.childName = 'Address');
      }

    },
    submitCreateBranchForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.partyId = store.state.party.partyId;
        const payload = {
          partyId: this.partyId,
          branchData: {
            bank_branches: [
              {
                active: this.branchForm.active,
                bank_branch_id: this.branchForm.bank_branch_id,
                branch_name: this.branchForm.branch_name,
                ifsc_code: this.branchForm.ifsc_code,
                address_name: this.branchForm.address_name,
                address_id: this.branchForm.address_id,
                short_name: this.branchForm.short_name
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/addEditPartyBankBranch', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.showAlert = true;
              this.isSuccess = true;
              this.$emit('bankBranchCreated');
              this.responseMsg = response.data.message;
              this.$emit('showHideCreateBranch');
              this.$emit('getBranchList', response);
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    hideCreateBranchModel() {
      this.showAlert = false;
      this.createBranchModel = false;
      this.$emit('showHideCreateBranch');
    },
    hideModalComponent(flag) {
      this.showModal = flag;

    },
    getAddressObj(item) {
      this.showModal = false;
      this.branchForm.address_id = item.address_id;
      this.branchForm.address_name = item.address_name;

    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
