import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getCountryList = async () => {
  const url = URL_UTILITY.getCountriesLovUrl;
  const response = http.getApi(url);
  return response;
};

const getAddressList = (context, payload) => {
  const apiUrl = URL_UTILITY.getFilteredAddressDataUrl;
  const mapObj = {
    '{limit}': payload._limit,
    '{page}': payload._page,
    '{state_id}': payload.state_id,
    '{country_id}': payload.country_id,
    '{key}': payload.key,
    '{address_type}': payload.address_type
  };
  const url = apiUrl.replace(
    /{limit}|{page}|{state_id}|{country_id}|{key}|{address_type}/gi,
    function(matched) {
      return mapObj[matched];
    }
  );
  const response = http.getApi(url + '&address_name=' + payload.address_name);
  return response;
};
const getFilteredCountryList = (context, payload) => {
  const apiUrl = URL_UTILITY.getCountryDataPaginationUrl;
  const mapObj = {
    '{limit}': payload._limit,
    '{page}': payload._page,
    '{key}': payload.key
  };
  const url = apiUrl.replace(
    /{limit}|{page}|{key}/gi,
    function(matched) {
      return mapObj[matched];
    }
  );
  const response = http.getApi(url);
  return response;
};
const getFilteredStateList = (context, payload) => {
  const apiUrl = URL_UTILITY.getStatesByCountryIdUrl;
  const mapObj = {
    '{limit}': payload._limit,
    '{page}': payload._page,
    '{countryId}': payload.country_id,
    '{state_name}': payload.state_name
  };
  const url = apiUrl.replace(
    /{limit}|{page}|{countryId}|{state_name}/gi,
    function(matched) {
      return mapObj[matched];
    }
  );
  const response = http.getApi(url);
  return response;
};
const getFilteredCityList = (context, payload) => {
  const apiUrl = URL_UTILITY.getCitiesByStateIdUrl;
  const mapObj = {
    '{limit}': payload._limit,
    '{page}': payload._page,
    '{stateId}': payload.state_id,
    '{city_name}': payload.city_name
  };
  const url = apiUrl.replace(
    /{limit}|{page}|{stateId}|{city_name}/gi,
    function(matched) {
      return mapObj[matched];
    }
  );
  const response = http.getApi(url);
  return response;
};

const getFilteredPincodeList = (context, payload) => {
  const apiUrl = URL_UTILITY.getPincodesByStateIdUrl;
  const mapObj = {
    '{limit}': payload._limit,
    '{page}': payload._page,
    '{stateId}': payload.state_id,
    '{pincode}': payload.pincode
  };
  const url = apiUrl.replace(
    /{limit}|{page}|{stateId}|{pincode}/gi,
    function(matched) {
      return mapObj[matched];
    }
  );
  const response = http.getApi(url);
  return response;
};

const getAddressDeatilsById = (context, addressId) => {
 const url = URL_UTILITY.updateAddressDataUrl.replace('{addressId}', addressId);
 return http.getApi(url);
}

const getStateListByCountryId = (context, countryId) => {
  const url = URL_UTILITY.getStatesLovByCountryIdUrl.replace('{countryId}', countryId);
  return http.getApi(url);
}
const getCityListByStateId = (context, stateId) => {
  const url = URL_UTILITY.getCitiesLovByStateIdUrl.replace('{stateId}', stateId);
  return http.getApi(url);
}
const getPincodeListByStateId = (context, stateId) => {
  const url = URL_UTILITY.getPincodesLovByStateIdUrl.replace('{stateId}', stateId);
  return http.getApi(url);
}
const updateAddressDetails = (context, payload) => {
  const url = URL_UTILITY.updateAddressDataUrl.replace('{addressId}', payload.address_id);
 return http.putApi(url, payload);
}
export default {
  getCountryList,
  getAddressList,
  getFilteredCountryList,
  getFilteredStateList,
  getFilteredCityList,
  getFilteredPincodeList,
  getAddressDeatilsById,
  getStateListByCountryId,
  getCityListByStateId,
  getPincodeListByStateId,
  updateAddressDetails
};
