import http from '../../../../app/services/httpServices';
import URL_UTILITY from '../../../../app/utility/url.utility';

const generateEwayBill = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getGstUrl + '/generate-ewaybill/' + payload.gebId,
    payload.data
  );
};
const getValueSetStateData = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/all-states', payload);
};
const getValueSetSupplyData = () => {
  return http.getApi(URL_UTILITY.getValueSetDataUrl + '/EWAY_BILL_SUPPLY_TYPE');
};
const getValueSetSubSupplyData = () => {
  return http.getApi(URL_UTILITY.getValueSetDataUrl + '/SUB_SUPPLY_TYPE');
};
const getValueSetTrxTypeData = () => {
  return http.getApi(URL_UTILITY.getValueSetDataUrl + '/EWAY_BILL_TRX_TYPE');
};
const getValueSetDocTypeData = () => {
  return http.getApi(URL_UTILITY.getValueSetDataUrl + '/EWAY_BILL_DOC_TYPE');
};
const getValueSetModeData = () => {
  return http.getApi(URL_UTILITY.getValueSetDataUrl + '/EWAY_BILL_MODE');
};
const getValueSetVehicleTypeData = () => {
  return http.getApi(
    URL_UTILITY.getValueSetDataUrl + '/EWAY_BILL_VEHICLE_TYPE'
  );
};
const getValueSetPartBReasonCode = () => {
  return http.getApi(
    URL_UTILITY.getValueSetDataUrl + '/UPDATE_PARTB_REASON_CODE'
  );
};
const getEwayBillGenerateData = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/get-ewaybill', payload);
};
const saveEwayBill = (context, payload) => {
  return http.postApi(URL_UTILITY.getGstUrl + '/add-edit-ewaybills', payload);
};
const getEwayBillById = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/get-ewaybills/' + payload.gebId);
};
const updateEwayBillPartB = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getGstUrl + '/update-ewaybill-partB/' + payload.gebId,
    payload.payloadData
  );
};
const getValueSetCancelRsnCode = () => {
  return http.getApi(URL_UTILITY.getValueSetDataUrl + '/EWB_CANCEL');
};
const getValueSetExtendRsnCode = () => {
  return http.getApi(URL_UTILITY.getValueSetDataUrl + '/EWB_EXTEND_RSN_CODE');
};
const getValueSetConsignStatus = () => {
  return http.getApi(URL_UTILITY.getValueSetDataUrl + '/EWB_CONSIGN_STATUS');
};
const getValueSetTransitType = () => {
  return http.getApi(URL_UTILITY.getValueSetDataUrl + '/EWB_TRANSIT_TYPE');
};
const getInvoiceDtls = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/get-invoices', payload);
};
const getValueSetSourceTypeData = () => {
  return http.getApi(URL_UTILITY.getValueSetDataUrl + '/EWB_SOURCE_TYPE');
};
const getEwayBillDtlsByInvId = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstUrl + '/get-invoices/' + payload.invoice_id
  );
};
const cancelEwayBill = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getGstUrl + '/cancel-ewaybill/' + payload.gebId,
    payload.payloadData
  );
};
const getValueSetUcqData = () => {
  return http.getApi(URL_UTILITY.getGstUrl + '/get-UCQ');
};

const getEwayBillStatusDtls = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstUrl + '/get-ewaybill-status-details?docNo=' + payload.docNum
  );
};
const ewayBillReportPrint = (context, payload) => {
  return http.postApi(URL_UTILITY.generateRequestUrl + '/generate-eway-bill/submit-requests-Req',payload);
};
export default {
  generateEwayBill,
  getValueSetStateData,
  getValueSetSubSupplyData,
  getValueSetTrxTypeData,
  getValueSetDocTypeData,
  getValueSetModeData,
  getValueSetVehicleTypeData,
  getEwayBillGenerateData,
  saveEwayBill,
  getEwayBillById,
  getValueSetSupplyData,
  getValueSetPartBReasonCode,
  updateEwayBillPartB,
  getValueSetCancelRsnCode,
  getValueSetExtendRsnCode,
  getValueSetConsignStatus,
  getValueSetTransitType,
  getInvoiceDtls,
  getValueSetSourceTypeData,
  getEwayBillDtlsByInvId,
  cancelEwayBill,
  getValueSetUcqData,
  getEwayBillStatusDtls,
  ewayBillReportPrint
};
