const grnData = (state, payload) => {
  state.grnData = payload;
};
const createModalFlag = (state, payload) => {
  state.flag = payload;
};

export default {
  grnData,
  createModalFlag
};
