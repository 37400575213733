export default {
  name: 'commonhelper',
  methods: {
    validateDob(dob) {
      const today = new Date();
      const birthDate = new Date(dob); //format is mm.dd.yyyy
      const age = today.getFullYear() - birthDate.getFullYear();
      if (age < 18) {
        return false;
      } else {
        return true;
      }
    },
    startDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    }
  }
};
