const setOrganizationType = (state, orgType) => {
  state.orgType = orgType;
};
const selectResponsibilityId = (state, respId) => {
  state.responsibilityId = respId;
};
const setRoleMenuMpngIdState = (state, roleId) => {
  state.roleMappingId = roleId;
};
const setMenuId = (state, menuId) => {
  state.menuId = menuId;
};
const setEmpId = (state, empId) => {
  state.empId = empId;
};
const setActionName = (state, actionName) => {
  state.actionName = actionName;
};
const setFlexFiledMaster = (state, masterName) => {
  state.flexFieldMaster = masterName;
};
const homeIconClicked = (state, homeIcon) => {
  state.homeIcon = homeIcon;
};
const setStandAloneSummary = (state, flag) => {
  state.isStandAlone = flag;
};
const setOrgTypeId = (state, orgDetail) => {
  state.orgDetail = orgDetail;
};
const setVendorData = (state, data) => {
  state.vendorData = data;
};
const setDataForProjectEntity = (state, data) => {
  state.entity = data;
};
const setDataForFmsUnit = (state, data) => {
  state.fmsUnitRecord = data;
};
const setDataForFmsSubunit = (state, data) => {
  state.fmsSubunitRecord = data;
};
const setDataForCrmProject = (state, data) => {
  state.CrmProjectRecord = data;
};
const setDataForPrjUnit = (state, data) => {
  state.PrjUnitRecord = data;
};
const setDataForPrjProject = (state, data) => {
  state.PrjProjectRecord = data;
};
const setDataForLeaseUnit = (state, data) => {
  state.LeaseUnitRecord = data;
};
const setUnsetSubUnitId = (state, subUnitId) => {
  state.subUnitId = subUnitId;
}
const setModuleId = (state, moduleId) => {
  state.moduleId = moduleId;
};

const updateAccessRights = (state, data) => {
  state.accessRights = data;
}
export default {
  setOrganizationType,
  selectResponsibilityId,
  setRoleMenuMpngIdState,
  setMenuId,
  setEmpId,
  setActionName,
  setFlexFiledMaster,
  homeIconClicked,
  setStandAloneSummary,
  setOrgTypeId,
  setVendorData,
  setDataForProjectEntity,
  setDataForFmsUnit,
  setDataForFmsSubunit,
  setDataForCrmProject,
  setDataForPrjUnit,
  setDataForPrjProject,
  setDataForLeaseUnit,
  setUnsetSubUnitId,
  setModuleId,
  updateAccessRights
};
