import http from '../../../../app/services/httpServices';
import URL_UTILITY from '../../../../app/utility/url.utility';

const getGstrTwoBList = (context, payload) => {
  return http.getApi(URL_UTILITY.getComplainceGridListUrl, payload);
};
const getGstrTwoList = (context, payload) => {
  return http.getApi(URL_UTILITY.getComplainceGstrTwoGridListUrl, payload);
};
const getGstrTwoBDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getComplainceGridListUrl + '/dtls', payload);
};
const getGstrData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getComplainceGridListUrl + '/dtls/scr-3',
    payload
  );
};
const getGstrTwoDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getComplainceGstrTwoGridListUrl + '/dtls',
    payload
  );
};
const getGstrDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getComplainceGstrTwoGridListUrl + '/dtls/scr-3',
    payload
  );
};
const getGstrDetailsFlag = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getComplainceGstrTwoGridListUrl + '/dtls/scr-3/nearMatch',
    payload
  );
};
const getComplainceGstr2NearMatchDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getComplainceGstrTwoGridListUrl + '/nearMatch/scr-4',
    payload
  );
};
const getGstPerformanceMatrixUser = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstPerformanceMatrix + '-user', payload);
};
const getGstPerformanceMatrixUserDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstPerformanceMatrix + '-user_scr2',
    payload
  );
};
const getGstPerformanceMatrixVendor = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstPerformanceMatrix + '-vendor', payload);
};
const getGstPerformanceMatrixVendorDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstPerformanceMatrix + '-vendor_scr2',
    payload
  );
};
const getGstPerformanceMatrixLegalEntities = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstPerformanceMatrix + '-legal_entity',
    payload
  );
};
const updateGstNearMatchDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getComplainceGstrTwoGridListUrl + '/nearMatch/scr-4/update',
    payload
  );
};
const getGstr2BDetailsFlag = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getComplainceGridListUrl + '/dtls/scr-3/nearMatch',
    payload
  );
};
const getComplainceGstr2bNearMatchDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getComplainceGridListUrl + '/scr-4', payload);
};
const updateGst2bNearMatchDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getComplainceGridListUrl + '/nearMatch/scr-4/update',
    payload
  );
};
const uploadGstPurchaseRegister = (context, payload) => {
  const url =
    URL_UTILITY.uploadPurchaseRegisterUrl +
    '/' +
    payload.excel_import_id +
    '/excel-upload';
  return http.postApi(url);
};
const complianceTab3 = (context, payload) => {
  return http.getApi(URL_UTILITY.getComplianceDashboardTab3Url, payload);
};
const performanceMatrixUserDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstPerformanceMatrix + '-user-scr1',
    payload
  );
};

const performanceMatrixUserDetailsList = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstPerformanceMatrix + '-user_scr3',
    payload
  );
};

const performanceMatrixUserDetailsNearMatch = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstPerformanceMatrix + '-user-scr-4',
    payload
  );
};

const performanceMatrixUserDetailsNearMatchUpdate = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstPerformanceMatrix + '-user-scr-4/update',
    payload
  );
};

const companyComplianceData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstPerformanceMatrix + '_company_compliance',
    payload
  );
};

const getGstAwsBackup = (context, payload) => {
  return http.getApi(URL_UTILITY.getAwsBackupUrl, payload);
};
const getGstAwsBackupDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getAwsBackupUrl + '-detail/' + payload.backup_hdr_id
  );
};
const getprIntegrationDtls = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gst-pr', payload);
};
const addEditSyncPrIntegration = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getGstUrl +
      '-file/gst-file-Excel' +
      // '?request_id=' +
      // payload.request_id +
      '?from_date=' +
      payload.from_date +
      '&to_date=' +
      payload.to_date +
      '&le_id=' +
      payload.le_id,
    payload
  );
};
const complianceTab3scr2 = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getComplianceDashboardTab3Url + '_scr2',
    payload
  );
};
const complianceTab3scr3 = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getComplianceDashboardTab3Url + '_scr3',
    payload
  );
};
const getprIntErrSuccessData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstUrl + '-file/gst-pr/success-error-data',
    payload
  );
};
const compTab3Scr2 = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/page2', payload);
};
const compTab3Scr4 = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/vendor_wise_page4', payload);
};
const syncPrRecoStatus = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getGstUrl + '-file/gst-pr/update-reco-status',
    payload
  );
};
const getGstnStatus = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gstn-status', payload);
};
const addEditGeneratePr = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getGstUrl +
      '-file/gst-generate-pr' +
      '?from_date=' +
      payload.from_date +
      '&to_date=' +
      payload.to_date +
      '&le_id=' +
      payload.le_id,
    payload
  );
};
const syncPrInvoices = (context, payload) => {
  return http.postApi(URL_UTILITY.getGstUrl + '-file/gst-sync-pr-req', payload);
};
export default {
  getGstrTwoBList,
  getGstrTwoList,
  getGstrTwoBDetails,
  getGstrTwoDetails,
  getGstrDetails,
  getGstrData,
  getComplainceGstr2NearMatchDetails,
  getGstrDetailsFlag,
  getGstPerformanceMatrixUser,
  getGstPerformanceMatrixVendor,
  updateGstNearMatchDetails,
  getGstr2BDetailsFlag,
  getComplainceGstr2bNearMatchDetails,
  updateGst2bNearMatchDetails,
  uploadGstPurchaseRegister,
  getGstPerformanceMatrixLegalEntities,
  getGstPerformanceMatrixUserDetails,
  getGstPerformanceMatrixVendorDetails,
  complianceTab3,
  performanceMatrixUserDetails,
  performanceMatrixUserDetailsList,
  performanceMatrixUserDetailsNearMatch,
  performanceMatrixUserDetailsNearMatchUpdate,
  companyComplianceData,
  getGstAwsBackup,
  getGstAwsBackupDetailsById,
  getprIntegrationDtls,
  addEditSyncPrIntegration,
  complianceTab3scr2,
  complianceTab3scr3,
  getprIntErrSuccessData,
  compTab3Scr2,
  compTab3Scr4,
  syncPrRecoStatus,
  getGstnStatus,
  addEditGeneratePr,
  syncPrInvoices
};
