import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getLeaseTowersList = (context, payload) => {
  const url = URL_UTILITY.getLeaseTowersListUrl;
  return http.getApi(url, payload);
}

const addEditLeaseTowers = (context, payload) => {
  const url = URL_UTILITY.addEditLeaseTowersListUrl;
  return http.postApi(url, payload);
}

export default {
  getLeaseTowersList,
  addEditLeaseTowers
};
