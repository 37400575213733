import actions from './action';
import getters from './getters';
import mutations from './mutations';

const state = {
  eWayBill: {}
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
