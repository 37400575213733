import { ModelSelect } from 'vue-search-select';
import { required } from 'vuelidate/lib/validators';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';

import {
  getAddressType,
  getCountryList,
  getCountryState,
  getAddressCountryCity,
  validateAddressName,
  getPincode,
  addAdress,
  updateAddress,
  getFilteredAddressDataPagination
} from '@/app/services/api';
export default {
  name: 'Address',
  props: {
    showModalState: Boolean,
    formState: String,
    openAddressModal: Boolean
  },
  components: {
    ModelSelect
  },
  watch: {
    'addressForm.country.value': function() {
      const countryId = this.addressForm.country.value;
      this.getCountryState(true, countryId);
    },
    'addressForm.state': function() {
      this.getCities();
      this.getPinCode();
    },
    defaultCountryId: function() {
      const countryId = this.defaultCountryId;
      this.getCountryState(false, countryId);
    },
    currentPage: function() {
      this.getFilteredAddress();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFilteredAddress();
    }
  },
  data() {
    return {
      loader: false,
      unsubscribe: null,
      isOrgAddressTypeSet: null,
      partyId: null,
      search: '',
      data: [],
      addressId: '',
      defaultCountryId: '',
      defaultStateId: '',
      defaultAddressTypeCode: '',
      addressName: '',
      addressFormError: {
        address_type: false,
        address_name: false,
        city: false,
        state: false,
        country: false,
        pincode: false
      },
      addressNameError: '',
      addressForm: {
        address_id: undefined,
        address_1: '',
        address_2: '',
        address_3: '',
        address_name: '',
        address_type: {
          value: '',
          type: ''
        },
        city: {
          value: '',
          text: ''
        },
        state: {
          value: '',
          text: ''
        },
        country: {
          value: '',
          text: ''
        },
        pincode: {
          value: '',
          text: ''
        }
      },
      addressStatus: 'add',
      update: false,
      showAlert: false,
      addressTypeData: [],
      addressCountryData: [],
      addressStateData: [],
      addressCityData: [],
      addressPincodeData: [],
      countryListData: [],
      stateListData: [],
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      stickyHeader: true,
      totalRows: null,
      filterOn: [],
      showModal: false,
      editMode: false,
      editAddress: false,
      responseMsg: '',
      isSuccess: false,
      isFailed: false,
      formCountryData: [],
      formStateData: [],
      fields: [
        {
          key: 'address_type',
          sortable: false
        },
        {
          key: 'address_name',
          sortable: true
        },
        {
          key: 'address_line',
          sortable: false
        },
        {
          key: 'city',
          sortable: false
        },
        {
          key: 'state',
          sortable: false
        },
        {
          key: 'country',
          sortable: false
        },
        {
          key: 'pincode',
          sortable: false
        }
      ],
      payload: null
    };
  },
  validations: {
    addressForm: {
      address_type: {
        value: {
          required
        },
        text: {
          required
        }
      },
      address_name: {
        required
      },
      address_1: {
        required
      },
      address_2: {
        required
      },
      country: {
        value: {
          required
        },
        text: {
          required
        }
      },
      state: {
        value: {
          required
        },
        text: {
          required
        }
      },
      city: {
        value: {
          required
        },
        text: {
          required
        }
      },
      pincode: {
        value: {
          required
        },
        text: {
          required
        }
      }
    }
  },
  mounted() {
    this.getCountryList();
    this.getAddressType();
    this.eventBus.$off('createPartyAddress');
    this.eventBus.$off('orgAddressType');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }

        if (actionName === 'add') {
          this.showModal = true;
          this.editMode = true;
          this.update = false;
        }

        if (actionName === 'save') {
          this.updateAddress();
        }
        if (actionName === 'upload' && this.showModal) {
          this.eventBus.$emit('commonUpload', { id: this.addressId });
        }
        if (actionName === 'download' && !this.showModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'country/getAddressList',
            'address',
            () => (this.loader = false)
          );
        }
      }
    });
    this.eventBus.$on('createPartyAddress', partyId => {
      this.partyId = partyId;
    });
    this.eventBus.$on('orgAddressType', addressType => {
      this.isOrgAddressTypeSet = addressType;
    });
  },
  methods: {
    async getCountryList() {
      try {
        const response = await getCountryList();
        const results = response.data.data;
        const addressCountry = results.map(type => {
          return {
            value: type.country_id,
            text: type.country
          };
        });
        this.addressCountryData = addressCountry;
        this.formCountryData = this.addressCountryData;
        if (
          this.addressCountryData !== null &&
          this.addressCountryData.length > 0
        ) {
          this.defaultCountryId = this.addressCountryData[0].value;
        }
      } catch (err) {
        this.err = err;
      }
    },
    async getAddressType() {
      try {
        const response = await getAddressType();
        const results = response.data.data;
        const addressType = results.map(type => {
          return {
            value: type.lookup_code.toUpperCase(),
            text: type.meaning
          };
        });
        this.addressTypeData = addressType;
        if (this.isOrgAddressTypeSet !== null) {
          this.defaultAddressTypeCode = this.isOrgAddressTypeSet.value;
        } else if (
          this.addressTypeData !== null &&
          this.addressTypeData.length > 0
        ) {
          this.defaultAddressTypeCode = this.addressTypeData[0].value;
        }
      } catch (err) {
        this.err = err;
      }
    },
    async getCountryState(isFormModified, countryId) {
      try {
        const response = await getCountryState(countryId);
        const results = response.data.data;
        const states = results.map(type => {
          return {
            value: type.state_id,
            text: type.state
          };
        });
        if (isFormModified) {
          this.formStateData = states;
        } else {
          this.addressStateData = states;
          if (
            this.addressStateData !== null &&
            this.addressStateData.length === 1
          ) {
            this.defaultStateId = this.addressStateData[0].value;
          } else if (
            this.addressStateData !== null &&
            this.addressStateData.length > 1
          ) {
            this.defaultStateId = this.defaultStateId;
          }
        }
      } catch (err) {
        this.err = err;
      }
    },
    async getFilteredAddress() {
      // if (
      //   this.defaultCountryId &&
      //   this.defaultStateId &&
      //   this.defaultAddressTypeCode
      // ) {
      this.payload = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        state_id: this.defaultStateId,
        country_id: this.defaultCountryId,
        key: this.search,
        address_type: this.defaultAddressTypeCode,
        address_name: this.addressName
      };
      try {
        this.loader = true;
        const response = await getFilteredAddressDataPagination(
          this.defaultCountryId,
          this.defaultStateId,
          this.defaultAddressTypeCode,
          this.currentPage - 1,
          this.perPage,
          this.search,
          this.addressName
        );
        this.loader = false;
        this.data = response.data.data.page;
        this.totalRows = response.data.data.total_elements;
      } catch (err) {
        this.loader = false;
        this.err = err;
      }
      // }
    },
    valueChange: function() {
      this.addressForm.state.value = '';
      this.addressForm.state.text = '';
      this.addressForm.city.value = '';
      this.addressForm.city.text = '';
      this.addressForm.pincode.value = '';
      this.addressForm.pincode.text = '';
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateAddressName: function() {
      const token = localStorage.getItem('token');
      const address = this.addressForm.address_name;
      validateAddressName(address, token)
        .then(resp => {
          if (resp.data.status === 200) {
            this.addressFormError.address_name = true;
            this.addressNameError = resp.data.message;
          } else {
            this.addressFormError.address_name = false;
            this.addressNameError = '';
          }
        })
        .catch(err => {
          this.err = err;
        });
    },

    async getCities() {
      const id = this.addressForm.state.value;
      try {
        const response = await getAddressCountryCity(id);
        const result = response.data.data;
        const cities = result.map(city => {
          return {
            value: city.city_id,
            text: city.city
          };
        });

        this.addressCityData = cities;
      } catch (err) {
        this.err = err;
      }
    },
    async getPinCode() {
      const id = this.addressForm.state.value;
      try {
        const response = await getPincode(id);
        const results = response.data.data;
        const pincode = results.map(pincode => {
          return {
            value: pincode.pincode_id,
            text: pincode.pincode
          };
        });
        this.addressPincodeData = pincode;
      } catch (err) {
        this.err = err;
      }
    },
    updateAddress: function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit('clicked', 'edit');
        this.update = false;
        return;
      } else {
        const addressData = this.addressForm;
        if (this.update) {
          updateAddress(addressData, this.addressId)
            .then(resp => {
              if (resp.status === 200) {
                this.showAlert = true;
                this.editMode = false;
                this.update = false;
                this.isSuccess = true;
                this.responseMsg = appStrings.addressUpdatedSuccessMsg;
              }
              this.$emit('clicked', 'view');
            })
            .catch(err => {
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
              this.error = err;
            });
        } else {
          addAdress(addressData)
            .then(resp => {
              if (resp.status === 201) {
                this.showAlert = true;
                this.editMode = false;
                this.update = false;
                this.isSuccess = true;
                this.responseMsg = appStrings.addressAddedSuccessMsg;
              }
              this.$emit('clicked', 'view');
            })
            .catch(err => {
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
              this.error = err;
            });
        }
        this.getFilteredAddress();
      }
    },
    rowSelected: function(data) {
      const results = data;
      this.addressId = data.address_id;
      const setAddressForm = {
        address_id: this.addressId,
        address_type: {
          text: results.address_type,
          value: results.address_type_code.toUpperCase()
        },
        address_name: results.address_name,
        address_line: results.address_line,
        address_1: results.address1,
        address_2: results.address2,
        address_3: results.address3,
        city: {
          value: results.city_id,
          text: results.city
        },
        state: {
          text: results.state,
          value: results.state_id
        },
        country: {
          text: results.country,
          value: results.country_id
        },
        pincode: {
          text: results.pincode,
          value: results.pincode_id
        },
        completeAddress: results.complete_address
      };
      this.addressForm = setAddressForm;
      if (
        this.partyId === null &&
        this.isOrgAddressTypeSet === null &&
        !this.openAddressModal
      ) {
        this.update = true;
        this.showModal = true;
      } else {
        this.$emit('getAddressObj', this.addressForm);
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.pageOptions[5].value = filteredItems.length;
    },
    clearAddressFields() {
      this.search = '';
      this.defaultCountryId = '';
      this.defaultStateId = '';
      this.defaultAddressTypeCode = '';
      this.addressName = '';
      this.data = [];
    },
    resetModal() {
      this.$emit('clicked', 'view');
      this.editMode = false;
      this.addressNameError = '';
      this.update = false;
      this.showAlert = false;
      this.addressFormError.address_type = false;
      this.addressForm = {
        address1: '',
        address2: '',
        address3: '',
        address_name: '',
        address_type: {
          value: '',
          type: ''
        },
        city: {
          value: '',
          text: ''
        },
        state: {
          value: '',
          text: ''
        },
        country: {
          value: '',
          text: ''
        },
        pincode: {
          value: '',
          text: ''
        }
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
