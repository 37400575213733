import http from '../../../app/services/httpServices'
import URL_UTILITY from '../../../app/utility/url.utility';

const getSecGroupMasterList = (context, payload) => {
const url = URL_UTILITY.getSecGroupMasterListUrl;
return http.getApi(url, payload);
}

const getSecGroupDetailById = (context, payload) => {
	const url = URL_UTILITY.getSecGroupDetailByIdUrl.replace('{securityGroupHdrId}', payload.secGroupHdrId);
	return http.getApi(url);
}

const addEditSecGroup = (context, payload) => {
	const url = URL_UTILITY.addEditSecGroupUrl;
	return http.postApi(url, payload);
}
export default {
	getSecGroupMasterList,
	getSecGroupDetailById,
	addEditSecGroup
}