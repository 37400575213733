import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getApiRegistrationGridList=(context, payload)=>{
  return http.getApi(URL_UTILITY.getApiRegistrationGridUrl+
    '/list', payload)
}

const addEditApiRegistrationDetail=(context, payload)=>{
  return http.postApi(URL_UTILITY.getApiRegistrationGridUrl+'/add-edit-data', payload)
}

export default {
  getApiRegistrationGridList,
  addEditApiRegistrationDetail
};
