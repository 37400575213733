import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getValueSetList = (context, payload) => {
  return http.getApi(URL_UTILITY.getAdminValueSetUrl, payload);
};
const getLookupList = (context, payload) => {
  return http.getApi(URL_UTILITY.getLookupUrl, payload);
};
const getLookupById = (context, lookupHdrId) => {
  return http.getApi(
    URL_UTILITY.getLookupUrl + '/' + lookupHdrId + '/lookup-type'
  );
};
const getLookupTypeDetails = (context, lookupHdrId) => {
  return http.getApi(
    URL_UTILITY.getLookupUrl + '/' + lookupHdrId + '/lookup-code-details'
  );
};
const addEditLookup = (context, payload) => {
  return http.postApi(URL_UTILITY.getLookupUrl + '/lookup-type', payload);
};
const addEditLookupDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.getLookupUrl + '/lookup-details', payload);
};
const getValidation = (context, payload) => {
  return http.getApi(URL_UTILITY.getLookupUrl + '/lookup-validation', payload);
};
const addEditValidation = (context, payload) => {
  return http.postApi(URL_UTILITY.getLookupUrl + '/lookup-validation', payload);
};
const verifyLookupQuery = (context, payload) => {
  return http.postApi(URL_UTILITY.getLookupUrl + '/check', payload);
};

export default {
  getValueSetList,
  getLookupList,
  getLookupById,
  getLookupTypeDetails,
  addEditLookup,
  addEditLookupDetails,
  getValidation,
  addEditValidation,
  verifyLookupQuery
};
