import store from '../../../../../../store';
import inDirectTaxDetails from './customerIndirectTaxDetails';
// import appStrings from '@/app/utility/string.utility';
export default {
  name: 'customerIndirectTax',
  components: {
    inDirectTaxDetails
  },
  data() {
    return {
      unsubscribe: null,
      vendorId: '',
      partyProfileType: '',
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showAlertAdd: false,
      isSuccessAdd: false,
      responseMsgAdd: '',
      customerSiteIndex: 0,
      customerId: '',
      showPartyIndirectModel: false,
      customerSiteList: [],
      tableFields: [
        {
          key: 'tax_group'
        },
        {
          key: 'tax_num',
          label: 'GSTIN/TAN No.'
        },
        {
          key: 'active'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'last_updated_by'
        }
      ],
      partyIndirectTaxList: [],
      partyIndirectTaxFields: [
        {
          key: 'tax_group'
        },
        {
          key: 'tax_num',
          label: 'GSTIN/TAN No.'
        },
        {
          key: 'issuing_authority'
        },
        {
          key: 'tax_group_id',
          class: 'd-none'
        },
        {
          key: 'party_indirect_tax_id',
          class: 'd-none'
        }
      ],
      partyIndirectSiteMpngId: 0,
      editMode: false,
      showInDirectTaxModal: false,
      inDirectTaxRowDetail: null,
      customerDtlId: null,
      customerSiteDtlList: null,
      siteType: null,
      vendorDtlId: null,
      vendorSiteDtlList: null,
      partyActive: null
    };
  },
  mounted() {
    this.eventBus.$off('indirectTax');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (store.state.party.customerTabValue === 'indirectTax') {
          if (actionName === 'add' && !this.showInDirectTaxModal) {
            this.showPartyIndirectModel = true;
            this.partyIndirectSiteMpngId = 0;
          }
        }
      }
    });
    this.eventBus.$on('indirectTax', customerSite => {
      this.getPartyIndirectTaxList();
      this.partyProfileType = store.state.party.partyProfile;
      if (store.state.party.partyProfile === 'customer') {
        this.customerId = customerSite.customer_id;
        this.getCustomerIndirectTax();
      } else if (store.state.party.partyProfile === 'vendor') {
        this.vendorId = customerSite.vendor_id;
        this.getVendorIndirectTaxList();
      }
    });
  },
  methods: {
    getPartyIndirectTaxList() {
      const partyId = store.state.party.partyId;
      this.$store
        .dispatch('party/getPartyIndirectTaxList', partyId)
        .then(response => {
          if (response.status === 200) {
            this.partyIndirectTaxList = response.data.data;
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    getCustomerIndirectTax() {
      const payload = {
        partyId: store.state.party.partyId
      };
      this.loader = true;
      this.$store
        .dispatch('party/getCustomerIndirectTax', payload)
        .then(response => {
          if (response.status === 200) {
            this.customerSiteList = response.data.data;
            this.loader = false;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    closePartyBankModel() {
      this.showPartyIndirectModel = false;
    },
    selectedSite(index) {
      this.customerSiteIndex = index;
    },
    selectedIndirectRow(item) {
      if (this.partyProfileType === 'customer') {
        const payload = {
          customerId: this.customerId,
          customerSiteIndirectTax: {
            customer_site_indirect_taxs: [
              {
                customer_site_id: this.customerSiteList[this.customerSiteIndex]
                  .customer_site_id,
                entity_type: 'Customer',
                party_indirect_site_mpng_id: this.partyIndirectSiteMpngId,
                party_indirect_tax_id: item.party_indirect_tax_id,
                active: true
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/getAddEditCustomerIndirectTax', payload)
          .then(response => {
            this.loader = false;
            this.showAlertAdd = true;
            if (response.status === 200) {
              this.isSuccessAdd = true;
              this.responseMsgAdd = response.data.message;
              this.customerSiteList = response.data.data.customer_sites;
            } else {
              this.isSuccessAdd = false;
              this.responseMsgAdd = response.response.data.message;
            }
          })
          .catch(error => {
            this.loader = false;
            alert(error.message);
          });
      } else if (this.partyProfileType === 'vendor') {
        const payload = {
          vendorId: this.vendorId,
          indirectTaxData: {
            vendor_site_indirect_taxs: [
              {
                vendor_site_id: this.customerSiteList[this.customerSiteIndex]
                  .vendor_site_id,
                entity_type: 'Vendor',
                party_indirect_site_mpng_id: this.partyIndirectSiteMpngId,
                party_indirect_tax_id: item.party_indirect_tax_id,
                active: true
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/addEditVendorIndirectTax', payload)
          .then(response => {
            this.loader = false;
            this.showAlertAdd = true;
            if (response.status === 200) {
              this.isSuccessAdd = true;
              this.responseMsgAdd = response.data.message;
              this.customerSiteList =
                response.data.data.vendor_site_indirect_taxs;
            } else {
              this.isSuccessAdd = false;
              this.responseMsgAdd = response.response.data.message;
            }
          })
          .catch(error => {
            this.loader = false;
            alert(error.message);
          });
      }
    },
    getVendorIndirectTaxList() {
      this.loader = true;
      this.$store
        .dispatch('party/getVendorIndirectTaxList', this.vendorId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.customerSiteList = response.data.data.vendor_site_indirect_taxs;
            this.partyActive = response.data.data.vendor_site_indirect_taxs.party_active
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    rowSelected(item) {
      this.partyIndirectSiteMpngId = item.party_indirect_site_mpng_id;
      this.showHideInDirectTaxModal(true);
      this.inDirectTaxRowDetail = item;
      this.customerDtlId = this.customerId;
      this.customerSiteDtlList = this.customerSiteList[this.customerSiteIndex];
      this.siteType = this.partyProfileType;
      this.vendorDtlId = this.vendorId;
      this.vendorSiteDtlList = this.customerSiteList[this.customerSiteIndex];
    },
    showHideInDirectTaxModal(flag) {
      this.showInDirectTaxModal = flag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
