<template>
  <div class="footer">
    <p>
      All trademarks are properties of their respective owners.
      {{ date }} &copy; Connaissant. All rights reserved
    </p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  mounted() {
    const date = new Date();
    const year = date.getFullYear();
    this.date = year;
  },
  data() {
    return {
      date: '',
    };
  },
};
</script>
