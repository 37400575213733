import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getMenuMappingList = (context, payload) => {
    return http.getApi(URL_UTILITY.getMenuMappingUrl, payload);
  };

  export default{
    getMenuMappingList,

  };