import PersonalInfo from './personalInfo';
import Assignment from './assignment';
import EduQualification from './eduQualification';
import ProfessionalInfo from './professionalInfo';
import HrOrgHead from './hrOrgHead';
import Bank from './bank';
import Address from './address';
import Relationship from './relationship';
import Contact from './contact';
import Planning from './planning';
export default {
  name: 'EmployeeDetails',
  components: {
    PersonalInfo,
    Assignment,
    EduQualification,
    ProfessionalInfo,
    HrOrgHead,
    Bank,
    Address,
    Relationship,
    Contact,
    Planning
  },
  props: {
    empName: String
  },
  data() {
    return {
      actionName: null,
      loader: false,
      activeTab: 'personalInfo'
    };
  },
  mounted() {},
  methods: {
    selectedTab(tabValue) {
      this.activeTab = tabValue;
    },
    closeTabs() {
      this.$emit('closeEmployeeDetail');
      this.$emit('updateEmpList');
    },
    buttonAccessPermissions(actionName) {
      this.actionName = actionName;
    }
  }
};
