import store from '../../../../../../store';
import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'hrOrgHead',
  components: {
    DatePicker,
    ModelSelect
  },
  data() {
    return {
      setTimeVsetCode: null,
      showValueSetModal: false,
      hrOrgIndex: null,
      showFlexField: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      unsubscribe: null,
      editMode: false,
      loader: false,
      legalEntityList: [],
      hrOrgHeadList: [
        {
          employee_id: null,
          le_id: null,
          start_date: null,
          end_date: null,
          segment_head_id: null,
          le_short_name: null,
          hr_org_seg_id: null,
          hr_org_seg_name: null,
          segment_id: null,
          segment_value: null
        }
      ],
      hrOrgHeadFields: [
        {
          key: 'le_short_name',
          label: 'Legal Entity'
        },
        {
          key: 'hr_org_seg_name',
          label: 'HR ORG'
        },
        {
          key: 'segment_value',
          label: 'Segment Name'
        },
        {
          key: 'start_date_end_date',
          label: 'Start Date - End Date'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      flexFieldList: [],
      fledField: [
        {
          key: 'segment_name'
        },
        {
          key: 'segment_sequence'
        }
      ]
    };
  },
  mounted() {
    this.getLegalEntity();
    this.getHrOrgHeadList(store.state.shared.empId);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const action = state.shared.actionName;
        if (action === 'save') {
          this.addEditHrOrg();
        } else if (action === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditHrOrg() {
      const hrOrgDetail = this.hrOrgHeadList.map(detail => {
        return {
          hr_org_seg_id: detail.hr_org_seg_id,
          le_id: detail.legalEntity.value
            ? detail.legalEntity.value
            : detail.legalEntity,
          segment_head_id: detail.segment_head_id,
          segment_id: detail.segment_id,
          start_date: commonHelper.formattedDate(detail.start_date_end_date[0]),
          end_date: commonHelper.formattedDate(detail.start_date_end_date[1]),
         
        };
      });
      const payload = {
        Hrorg_details: hrOrgDetail,
        employee_id: store.state.shared.empId
      };
      this.loader = true;
      this.$store
        .dispatch('hrms/addEditHrOrgHead', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getHrOrgHeadList(empId) {
      this.loader = true;
      this.$store
        .dispatch('hrms/getHrOrgHeadList', empId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length > 0) {
            const modifyHrOrgList = resp.data.data.map(org => {
              org.legalEntity = {
                value: org.le_id,
                text: org.le_short_name
              };
              return org;
            });
            this.hrOrgHeadList = modifyHrOrgList;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLegalEntity() {
      const payload = store.state.shared.responsibilityId;
      this.$store
        .dispatch('receipt/getOrganizationLov', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.org_name
              };
            });
            this.legalEntityList = leagalEntity;
          }
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.hrOrgHeadList.push({
          employee_id: null,
          le_id: null,
          start_date: null,
          end_date: null,
          start_date_end_date: null,
          segment_head_id: null,
          le_short_name: null,
          hr_org_seg_id: null,
          hr_org_seg_name: null,
          segment_id: null,
          segment_value: null
        });
      }
    },
    removeRow(index) {
      this.hrOrgHeadList.splice(index, 1);
    },
    openFlexfieldModal(index) {
      this.hrOrgIndex = index;
      this.showFlexField = true;
      const payload = {
        module_id: 2,
        le_id: this.hrOrgHeadList[index].legalEntity
      };
      this.loader = true;
      this.$store
        .dispatch('hrms/getHrOrgFlexField', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.flexFieldList = response.data.data.map(detail => {
              detail.start_date_end_date = [
                  new Date(detail.start_date),
                  new Date(detail.end_date)
                ];
              return detail;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    hideFlexField() {
      this.showFlexField = false;
    },
    selectedFlexField(item) {
      this.hrOrgHeadList[this.hrOrgIndex].hr_org_seg_id =
        item.flexfield_segment_id;
      this.hrOrgHeadList[this.hrOrgIndex].hr_org_seg_name = item.value_set_name;
      this.showFlexField = false;
    },
    selectedvalueSet(item) {
      this.hrOrgHeadList[this.hrOrgIndex].segment_value = item.value_meaning;
      this.hrOrgHeadList[this.hrOrgIndex].segment_id = item.value_code;
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.hrOrgIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
