import http from '@/app/services/httpServices';
import URL_UTILITY from '@/app/utility/url.utility';

const getItcReconciliation = (context, payload) => {
  return http.getApi(URL_UTILITY.getItcReconciliationUrl, payload);
};

const getReconcileDetails = (context, dtl_id) => {
  return http.getApi(URL_UTILITY.getReconcileDetailsUrl + '?dtl_id=' + dtl_id);
};

const getReconcileGridDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getReconcileDetailUrl +
      '?dtl_id=' +
      payload.dtl_id +
      '&le_id=' +
      payload.le_id
  );
};

const changeRecoAction = (context, payload) => {
  return http.postApi(URL_UTILITY.RecoFormActionUrl, payload);
};

const getGstAvaliableTab = (context, payload) => {
  const url = URL_UTILITY.getGstAvaliableTabUrl;
  return http.getApi(url, payload);
};

const getGstAvaliableTabAction = (context, payload) => {
  const url = URL_UTILITY.getGstAvaliableTabActionUrl;
  return http.postApi(url, payload);
};
const updateGstReco = (context, payload) => {
  return http.postApi(URL_UTILITY.updateGstRecoUrl, payload);
};
const getGstNearMatchDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstNearMatchDetailsUrl, payload);
};
const updateNearMatch = (context, payload) => {
  return http.postApi(URL_UTILITY.updateNearMatchUrl, payload);
};
const getBooks = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getComplainceGstrTwoGridListUrl + '-books-reconiliation',
    payload
  );
};
const updateBooksClaimed = (context, payload) => {
  return http.putApi(URL_UTILITY.getGstUrl + '/books/claimed', payload);
};
const updateBooksUnclaimed = (context, payload) => {
  return http.putApi(URL_UTILITY.getGstUrl + '/books/unclaimed', payload);
};
const excelUploadItcReconBooks = (context, excelImportId) => {
  return http.postApi(
    URL_UTILITY.getGstPerformanceMatrix +
      '-claim-unclaim/' +
      excelImportId +
      '/' +
      'excel-upload'
  );
};
const unRecoTabAction = (context, payload) => {
  return http.postApi(URL_UTILITY.getGstUrl + '/gst-unreco-tab', payload);
};
const updateGstAvaliableTab = (context, payload) => {
  return http.postApi(URL_UTILITY.getGstUrl + '/gst-reco-tab', payload);
};
const getGstNearMatchDetailsAll = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstNearMatchDetailsUrl +'-all', payload);
};
const getItcGstrSixAdata = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl +'/reconciliation_mannual_download', payload);
};
const getImsData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstUrl + '/ims_invoices',
    payload
  );
};
const selectedRadio = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getGstUrl + '/ims_invoices',
    payload
  );
};
const uploadDataItcReco = (context, excelImportId) => {
  return http.postApi(
    URL_UTILITY.getGstUrl +
      '/gst-ims-update-action/' +
      excelImportId +
      '/' +
      'excel-upload'
  );
};
const itcReconGstrTwo6AExcelUpload = (context, excelImportId) => {
  return http.postApi(
    URL_UTILITY.getGstPerformanceMatrix +
      '-reco_mannual_upload/' +
      excelImportId +
      '/excel-upload'
  );
};
const updatePrRecoWithGlTax = (context, payload) => {
  return http.postApi(URL_UTILITY.getGstUrl + '/gr_mapping_details', payload);
};
const getPrRecoWithGlDtls = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gl_Pr_mapping', payload);
};
export default {
  getItcReconciliation,
  getReconcileDetails,
  getReconcileGridDetails,
  changeRecoAction,
  getGstAvaliableTab,
  getGstAvaliableTabAction,
  updateGstReco,
  getGstNearMatchDetails,
  updateNearMatch,
  getBooks,
  updateBooksClaimed,
  updateBooksUnclaimed,
  excelUploadItcReconBooks,
  unRecoTabAction,
  updateGstAvaliableTab,
  getGstNearMatchDetailsAll,
  getItcGstrSixAdata,
  getImsData,
  selectedRadio,
  uploadDataItcReco,
  itcReconGstrTwo6AExcelUpload,
  updatePrRecoWithGlTax,
  getPrRecoWithGlDtls
};
