import http from '../../../../app/services/httpServices';
import URL_UTILITY from '../../../../app/utility/url.utility';

const getGstCredentialsList = (context, payload) => {
  const url = URL_UTILITY.getGstCredentialsListUrl;
  return http.getApi(url, payload);
}

const addEditgstCredentials = (context, payload) => {
  const url = URL_UTILITY.getGstCredentialsListUrl;
  return http.postApi(url, payload);
}

export default {
    getGstCredentialsList,
    addEditgstCredentials
};
