<template>
  <section id="Landing" style="z-index: -100">
    <div
      id="carouselExampleIndicators"
      class="carousel slide carousel-fade"
      data-ride="carousel"
    >
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          class="active"
        ></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
      </ol>
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item active"></div>
        <div class="carousel-item"></div>
        <div class="carousel-item"></div>
        <div class="carousel-item"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {},
  name: 'Home',

  data() {
    return {
      content: '',
      slide: 0,
      sliding: null
    };
  },
  mounted() {
    if (sessionStorage.token) {
      this.$router.push('/landing');
    }
  }
};
</script>
