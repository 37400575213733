import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getInvoiceEntryList = (context, payload) => {
  return http.getApi(URL_UTILITY.getInvoiceEntryListUrl, payload);
};
const getArTransactionDetails = (context, trxHdrId) => {
  return http.getApi(URL_UTILITY.getArTrsansactionUrl + '/' + trxHdrId);
};
const getApInvoiceDetail = (context, invoiceHdrId) => {
  return http.getApi(URL_UTILITY.getInvoiceEntryListUrl + '/' + invoiceHdrId);
};
const getPaymentBatchGrid = (context, payload) => {
  return http.getApi(URL_UTILITY.getPaymentBatchUrls, payload);
};
const addEditPaymentBatch = (context, payload) => {
  return http.postApi(URL_UTILITY.getPaymentBatchUrls, payload);
};
const getPaymentsByBatchPaymentId = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPaymentBatchUrls +
      '/' +
      payload.batchPaymentId +
      '/payments',
    payload.requestParams
  );
};
const getInvoicesByPaymentId = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getInvoicesByPaymentIdUrl.replace(
      '{paymentId}',
      payload.payment_hdr_id
    ),
    payload.paymentBatchId
  );
};
const getApDistribution = (context, invoiceHdrId) => {
  return http.getApi(
    URL_UTILITY.getInvoiceEntryListUrl +
      '/' +
      invoiceHdrId +
      '/' +
      'distributions'
  );
};
const getApSchedule = (context, invoiceHdrId) => {
  return http.getApi(
    URL_UTILITY.getInvoiceEntryListUrl + '/' + invoiceHdrId + '/' + 'schedules'
  );
};
const getInvoiceLine = (context, invoiceHdrId) => {
  return http.getApi(
    URL_UTILITY.getInvoiceEntryListUrl +
      '/' +
      invoiceHdrId +
      '/' +
      'invoice-line'
  );
};

const updateBatchPaymentStatus = (context, payload) => {
  const url =
    URL_UTILITY.getPaymentBatchUrls +
    '/' +
    payload.payment_batch_id +
    '/request';
  return http.postApi(
    `${url}?_page=${payload._page}&_limit=${payload._limit}
    &event_call=${payload.event_call}&le_id=${payload.le_id}
    &ou_id=${payload.ou_id}&batch_name=${payload.batch_name}`
  );
};

const updateInvoicesBasedOnStatus = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getPaymentBatchUrls +
      '/' +
      payload.batchPaymentId +
      '/payments',
    payload.requestBody
  );
};

const saveAdvSearchFilters = (context, payload) => {
  context.commit('updateAdvSearchFilters', payload);
};

const updateInvoiceEntry = (context, payload) => {
  const url =
    URL_UTILITY.getInvoiceEntryListUrl +
    '/' +
    payload.invoiceHdrId +
    '/' +
    'update-invoice';
  return http.postApi(url, payload.updatedFields);
};

const addEditInvoiceLineData = (context, payload) => {
  const url = URL_UTILITY.addEditInvoiceLineDataUrl.replace(
    '{invoiceId}',
    payload.invoiceId
  );
  return http.postApi(url, payload.requestParams);
};

const getInvoiceAccountingList = (context, invoiceId) => {
  const url = URL_UTILITY.getInvoiceAccountingListUrl.replace(
    '{invoiceId}',
    invoiceId
  );
  return http.getApi(url);
};

const validateInvoiceData = (context, payload) => {
  const url = URL_UTILITY.validateApInvoiceDataUrl.replace(
    '{invoiceId}',
    payload.invoiceId
  );
  return http.postApi(url, payload.requestBody);
};

const getInvoicePaymentsList = (context, invoiceId) => {
  const url = URL_UTILITY.getInvoicePaymentsListUrl.replace(
    '{invoiceId}',
    invoiceId
  );
  return http.getApi(url);
};

const cancelInvoiceData = (context, payload) => {
  const url = URL_UTILITY.cancelApInvoiceDataUrl.replace(
    '{invoiceId}',
    payload.invoiceId
  );
  return http.postApi(url, payload.requestBody);
};

const addEditScheduleLineData = (context, payload) => {
  const url = URL_UTILITY.addEditScheduleLineDataUrl.replace(
    '{invoiceId}',
    payload.invoiceId
  );
  return http.postApi(url, payload.requestParams);
};
const excelUploadDataApInvoiceEntry = (context, excelImportId) => {
  return http.postApi(
    URL_UTILITY.uploadExcelApInvoiceEntryUrl + '/' + excelImportId + '/excel-upload'
  );
};
const updateInvoiceDistributions =(context, payload) =>{
  return http.postApi(URL_UTILITY.getInvoiceEntryListUrl + '/distributions', payload)
  
}


export default {
  getInvoiceEntryList,
  getArTransactionDetails,
  getApInvoiceDetail,
  getApDistribution,
  getApSchedule,
  getInvoiceLine,
  getPaymentBatchGrid,
  addEditPaymentBatch,
  getPaymentsByBatchPaymentId,
  getInvoicesByPaymentId,
  updateBatchPaymentStatus,
  updateInvoicesBasedOnStatus,
  saveAdvSearchFilters,
  updateInvoiceEntry,
  addEditInvoiceLineData,
  getInvoiceAccountingList,
  validateInvoiceData,
  getInvoicePaymentsList,
  cancelInvoiceData,
  addEditScheduleLineData,
  excelUploadDataApInvoiceEntry,
  updateInvoiceDistributions
};
