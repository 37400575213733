import commonHelper from '@/app/utility/common.helper.utility';
import flexfieldDetails from '../flexfield/flexfieldDetails';
import { ModelSelect } from 'vue-search-select';
import legalEntityMapping from './legalEntityMapping';
export default {
  name: 'flexfield',
  components: {
    ModelSelect,
    flexfieldDetails,
    legalEntityMapping
  },
  props: {
    showModal: Boolean,
    masterFlexfieldName: String,
    moduleName: String
  },
  watch: {
    currentPage: function() {
      this.getFlexfieldList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFlexfieldList();
    },
    name: function() {
      if (this.name.value) {
        this.getFlexfieldLov();
      }
    }
  },
  data() {
    return {
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      flexfieldDetails: null,
      showFlexFieldModal: false,
      showlegalEntityMappingForm: false,
      flexfieldRowData: null,
      loader: false,
      setTimeAction: null,
      master: {
        value: null,
        text: null
      },
      defaultModuleName: '',
      ffname: null,
      moduleArray: [],
      masterArray: [],
      moduleNameData: [],
      data: [],
      name: {
        value: null,
        text: null
      },
      fields: [
        {
          key: 'module_id',
          class: 'd-none'
        },
        {
          key: 'flexfield_id',
          class: 'd-none'
        },
        {
          key: 'module_name'
        },
        {
          key: 'flexfield_master_id',
          class: 'd-none'
        },
        {
          key: 'flexfield_master_name',
          label: 'Flexfield Master'
        },
        {
          key: 'flexfield_name'
        },
        {
          key: 'freezeflag',
          label: 'Freeze Flag'
        },
        {
          key: 'auto_insertion'
        },
        {
          key: 'map'
        }
      ]
    };
  },
  mounted() {
    if (this.showModal) {
      this.name.text = this.moduleName;
      this.master.text = this.masterFlexfieldName;
    }
    this.getModuleLov();
    this.getFlexfieldList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showFlexFieldModal = true;
          setTimeout(() => {
            this.eventBus.$emit('segAdd');
          }, 0);
        }
        if (this.showFlexFieldModal) {
          this.setTimeAction = setTimeout(() => {
            this.eventBus.$emit('flexfieldFormActions', actionName);
          }, 0);
        }
        if (actionName === 'download' && !this.showFlexFieldModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'flexfield/getFlexfieldList',
            'flexfield',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getModuleLov() {
      this.loader = true;
      this.$store
        .dispatch('flexfield/getFlexfieldModuleLovList')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.module_id,
                text: type.module_name
              };
            });
            this.moduleArray = getValueAndText;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getFlexfieldLov() {
      const module_id = this.name.value;
      this.loader = true;
      this.$store
        .dispatch('flexfield/getFlexfieldMasterLovList', module_id)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.flexfield_master_name,
                text: type.flexfield_master_name
              };
            });
            this.masterArray = getValueAndText;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateFlexfieldList() {
      this.getFlexfieldList();
    },
    resetModal() {},
    rowSelected(rowData) {
      this.$emit('getFlexField', rowData);
      if (!this.showModal) {
        this.showFlexFieldModal = true;
        setTimeout(() => {
          this.eventBus.$emit('ffDetails', rowData);
        }, 0);
        this.flexfieldDetails = rowData;
      }
    },
    openLegalEntityMap(flag, item) {
      this.showlegalEntityMappingForm = flag;
      this.flexfieldRowData = item;
    },
    clearFlexfield() {
      this.name.text = null;
      this.name.value = null;
      this.ffname = null;
      this.master.text = null;
      this.master.value = null;
      this.getModuleLov();
      this.getFlexfieldLov();
      this.getFlexfieldList();
    },
    getFlexfieldList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_name: this.moduleName ? this.moduleName : this.name.text,
        flexfield_name: this.ffname,
        flexfield_master_name: this.masterFlexfieldName
          ? this.masterFlexfieldName
          : this.master.text
      };
      this.loader = true;
      this.$store
        .dispatch('flexfield/getFlexfieldList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.data = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    // clearTimeout(this.setTimeAction);
  }
};
