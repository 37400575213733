import store from '../../../../../../store';
import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import ConcatSegment from '../concatSegment';
import commonHelper from '@/app/utility/common.helper.utility';
import Organization from '@/app/components/admin/organization';
export default {
  name: 'Assignment',
  components: {
    DatePicker,
    ModelSelect,
    ConcatSegment,
    Organization
  },
  watch: {
    currentPage: function() {
      this.getEmployeeList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getEmployeeList();
    }
  },
  data() {
    return {
      showGradeModalProp: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      showSuperVisorModal: false,
      unsubscribe: null,
      assignmentListIndex: null,
      showSegmentMondal: false,
      assignmentIndex: null,
      vsetCode: null,
      setTimeVsetCode: null,
      showValueSetModal: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      editMode: false,
      loader: false,
      parent_value_set_id: null,
      assignmentList: [
        {
          band_val: null,
          band_vset: null,
          band_id: null,
          change_reason_vset: null,
          concatenated_segment: null,
          designation: null,
          designation_vset: null,
          designation_id: null,
          display_title: null,
          employee_assignment_id: 0,
          end_date: null,
          grade: {
            value: null,
            text: null
          },
          grade_lookup: null,
          grade_val: null,
          hr_org: null,
          hr_org_key_flexfield_comb_id: null,
          location: null,
          location_id: null,
          position: null,
          position_vset: null,
          reason: null,
          start_date: null,
          supervisor: null,
          supervisor_id: null
        }
      ],
      gradeList: [],
      gradeListFields: [
        {
          key: 'text',
          label: 'Grade'
        }
      ],
      assignmentFields: [
        {
          key: 'hr_org'
        },
        {
          key: 'band_val',
          label: 'Band'
        },
        {
          key: 'designation'
        },
        {
          key: 'position'
        },
        {
          key: 'display_title',
          label: 'Title'
        },
        {
          key: 'grade_val',
          label: 'Grade'
        },
        {
          key: 'location'
        },
        {
          key: 'supervisor'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'reason'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      employeesList: [],
      employeeFields: [
        {
          key: 'employee_num',
          label: 'Emp No.'
        },
        {
          key: 'name'
        },
        {
          key: 'flex_value',
          class: 'd-none'
        },
        {
          key: 'le_name'
        },
        {
          key: 'emp_status',
          label: 'status'
        }
      ]
    };
  },
  mounted() {
    this.getGradList();
    this.getEmployeeList();
    this.getAssigmentList(store.state.shared.empId);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const action = state.shared.actionName;
        if (action === 'save' && this.editMode) {
          this.addEditAsssignment();
        } else if (action === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditAsssignment() {
      const assignmentDetails = this.assignmentList.map(assignment => {
        return {
          band_vset: assignment.band_vset,
          change_reason_vset: assignment.change_reason_vset,
          designation_vset: assignment.designation_vset,
          display_title: assignment.display_title,
          employee_assignment_id: assignment.employee_assignment_id,
          start_date: assignment.start_date,
          end_date: assignment.end_date,
          grade_lookup: assignment.grade_val,
          hr_org_key_flexfield_comb_id: assignment.hr_org_key_flexfield_comb_id,
          location_id: assignment.location_id,
          position_vset: assignment.position_vset,
          supervisor_id: assignment.supervisor_id
        };
      });
      const payload = {
        assignment_details: assignmentDetails,
        employee_id: store.state.shared.empId
      };
      this.loader = true;
      this.$store
        .dispatch('hrms/addEditAssignment', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEmployeeList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        emp_status: null,
        joining_from: null,
        employee_num: null,
        hrms_org_key_flexfield_comb_id: null,
        joining_to: null,
        le_id: null,
        employee_name: null,
        designation_vset: null,
        supervisor_num: null
      };
      this.loader = true;
      this.$store
        .dispatch('hrms/getEmployeeList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.page.length > 0) {
            this.employeesList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getGradList() {
      this.$store
        .dispatch(
          'shared/getDataFromLookupsByLookupType',
          appStrings.LOOKUPTYPE.GRADE
        )
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const salutationArr = result.map(type => {
              return {
                value: type.lookup_code,
                text: type.meaning
              };
            });
            this.gradeList = salutationArr;
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    getAssigmentList(empId) {
      this.loader = true;
      this.$store
        .dispatch('hrms/getEmpAssignment', empId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length > 0) {
            const modifyAssignmentList = resp.data.data.map(assignment => {
              assignment.grade = {
                value: assignment.grade_lookup,
                text: assignment.grade_val
              };
              return assignment;
            });
            this.assignmentList = modifyAssignmentList;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected() {},
    addNewRow() {
      if (this.editMode) {
        this.assignmentList.push({
          band_val: null,
          band_vset: null,
          band_id: null,
          change_reason_vset: null,
          concatenated_segment: null,
          designation: null,
          designation_vset: null,
          designation_id: null,
          display_title: null,
          employee_assignment_id: 0,
          end_date: null,
          grade_lookup: null,
          grade_val: null,
          hr_org: null,
          hr_org_key_flexfield_comb_id: null,
          location: null,
          location_id: null,
          position: null,
          position_vset: null,
          reason: null,
          start_date_end_date: null,
          start_date: null,
          supervisor: null,
          supervisor_id: null
        });
      }
    },
    removeRow(index) {
      this.assignmentList.splice(index, 1);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.DESIGNATION) {
        this.assignmentList[this.assignmentIndex].designation =
          item.value_meaning;
        this.assignmentList[this.assignmentIndex].designation_vset =
          item.value_code;
        this.assignmentList[this.assignmentIndex].designation_id =
          item.value_set_dtl_id;
      } else if (this.vsetCode === 'CHANGE REASON') {
        this.assignmentList[this.assignmentIndex].reason = item.value_meaning;
        this.assignmentList[this.assignmentIndex].change_reason_vset =
          item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.POSITION) {
        this.assignmentList[this.assignmentIndex].position = item.value_meaning;
        this.assignmentList[this.assignmentIndex].position_vset =
          item.value_code;
      } else if (this.vsetCode === 'BAND') {
        this.assignmentList[this.assignmentIndex].band_val = item.value_meaning;
        this.assignmentList[this.assignmentIndex].band_vset = item.value_code;
        this.assignmentList[this.assignmentIndex].band_id =
          item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.assignmentList[this.assignmentIndex].location_id = item.loc_id;
        this.assignmentList[this.assignmentIndex].location = item.loc_name;
      }
    },
    openValueSetModal(vsetCode, index) {
      if (vsetCode === appStrings.VALUESETTYPE.DESIGNATION) {
        this.parent_value_set_id = this.assignmentList[index].band_id;
      } else if (vsetCode === appStrings.VALUESETTYPE.POSITION) {
        this.parent_value_set_id = this.assignmentList[index].designation_id;
        // this.isDependent = true;
      } else {
        this.parent_value_set_id = null;
      }
      this.vsetCode = vsetCode;
      this.assignmentIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    openSegment(index) {
      this.assignmentListIndex = index;
      this.showSegmentMondal = true;
    },
    concatSegDetails(segment) {
      this.assignmentList[this.assignmentListIndex].hr_org =
        segment.concatenated_segment_meaning;
      this.assignmentList[
        this.assignmentListIndex
      ].hr_org_key_flexfield_comb_id = segment.hr_org_key_flexfield_comb_id;
      this.showSegmentMondal = false;
    },
    closeSegmentModal() {
      this.showSegmentMondal = false;
    },
    resetSupervisorModal(flag, index) {
      this.showSuperVisorModal = flag;
      this.assignmentIndex = index;
    },
    selectedSuvervisor(item) {
      this.showSuperVisorModal = false;
      this.assignmentList[this.assignmentIndex].supervisor = item.name;
      this.assignmentList[this.assignmentIndex].supervisor_id =
        item.employee_id;
    },
    hideGradeModal(flag, index) {
      this.showGradeModalProp = flag;
      this.assignmentIndex = index;
    },
    setGrade(item) {
      this.assignmentList[this.assignmentIndex].grade_lookup = item.value;
      this.assignmentList[this.assignmentIndex].grade_val = item.text;
      this.hideGradeModal(false, null);
    },
    clearVsetValues(segment, index) {
      if (segment === 'DESIGNATION') {
        this.assignmentList[index].designation = null;
        this.assignmentList[index].designation_vset = null;
        this.assignmentList[index].designation_id = null;
      } else if (segment === 'CHANGE REASON') {
        this.assignmentList[index].reason = null;
        this.assignmentList[index].change_reason_vset = null;
      } else if (segment === 'POSITION') {
        this.assignmentList[index].position = null;
        this.assignmentList[index].position_vset = null;
      } else if (segment === 'BAND') {
        this.assignmentList[index].band_val = null;
        this.assignmentList[index].band_vset = null;
        this.assignmentList[index].band_id = null;
      } else if (segment === 'LOCATION') {
        this.assignmentList[index].location_id = null;
        this.assignmentList[index].location = null;
      }
    }
  },
  destroyed() {
    clearTimeout(this.setTimeVsetCode);
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
