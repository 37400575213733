import SubmittedRequest from "../request/submittedRequest";


export default {
    name: 'reports',
    components: {
        SubmittedRequest
    },
    data() {
        return {
            showOpenReportsModal: false
        }
    },
    mounted() {
    },
    methods: {
        reportsModal(flag) {
            this.showOpenUserModal = flag;
          },
          openReportsModal() {
            this.showOpenReportsModal = true;
          }
    }
}
