import http from '@/app/services/httpServices';
import URL_UTILITY from '@/app/utility/url.utility';

const getDunningCommonSetupList = (context, payload) => {
  return http.getApi(URL_UTILITY.getDunningCommonSetupListUrl, payload);
};

const getDunningRuleMasterList = (context, payload) => {
  return http.getApi(URL_UTILITY.getDunningRuleMasterListUrl, payload);
};

const getDunningRuleMasterDetailsList = (context, payload) => {
  return http.getApi(URL_UTILITY.getDunningRuleMasterDetailsListUrl, payload);
};

const getDunningCommonSetupDataById = (context, dunningSetupId) => {
  const url = URL_UTILITY.getDunningCommonSetupDataByIdUrl + '/' + dunningSetupId ;
  return http.getApi(url);
};

const addUpdateCommonSetupData = (context, payload) => {
  return http.postApi(URL_UTILITY.addUpdateCommonSetupDataUrl, payload);
};

const getDunningRuleMasterDataById = (context, dunningMasterId) => {
  const url = URL_UTILITY.getDunningRuleMasterDataByIdUrl + '/' + dunningMasterId ;
  return http.getApi(url);
};

const getDunningRuleMasterDetailsDataById = (context, dunningMasterDetailsId) => {
  const url = URL_UTILITY.getDunningRuleMasterDetailsDataByIdUrl + '/' + dunningMasterDetailsId ;
  return http.getApi(url);
};

const addUpdateRuleMasterData= (context, payload) => {
  return http.postApi(URL_UTILITY.addUpdateRuleMasterDataUrl, payload);
};

const addUpdateRuleMasterDetailsData= (context, payload) => {
  return http.postApi(URL_UTILITY.addUpdateRuleMasterDetailsDataUrl, payload);
};

const getDunningLetterMasterDetailsGridList = (context, payload) => {
  return http.getApi(URL_UTILITY.getDunningLetterMasterDetailsGridListUrl, payload);
};

const getDunningCustomerRuleMappingList = (context, payload) => {
  return http.getApi(URL_UTILITY.getDunningCustomerRuleMappingListUrl, payload);
};

const getDunningCustomerRuleMappingDataById = (context, dunningCustomerId) => {
  const url = URL_UTILITY.getDunningCustomerRuleMappingDataByIdUrl + '/' + dunningCustomerId ;
  return http.getApi(url);
};

const addEditCustomerRuleMappinngData= (context, payload) => {
  return http.postApi(URL_UTILITY.addEditCustomerRuleMappinngDataUrl, payload);
};

const getdunningHeaderSummaryGridList = (context, payload) => {
  return http.getApi(URL_UTILITY.getdunningHeaderSummaryGridListUrl, payload);
};

const addEditCustomerCriteriaMappingData= (context, payload) => {
  return http.postApi(URL_UTILITY.addEditCustomerCriteriaMappingDataUrl, payload);
};

const getDunningHeaderDetailById = (context, payload) => {
  return http.getApi(URL_UTILITY.getDunningHeaderDetailByIdUrl, payload);
};

const resendMailDunningHeaderSummary= (context, payload) => {
  return http.postApi(URL_UTILITY.resendMailDunningHeaderSummaryUrl + '/' + payload.dunningHdrId + '/resend-email');
};
export default {
  getDunningCommonSetupList,
  getDunningRuleMasterList,
  getDunningRuleMasterDetailsList,
  getDunningCustomerRuleMappingList,
  getDunningCommonSetupDataById,
  addUpdateCommonSetupData,
  getDunningRuleMasterDataById,
  getDunningRuleMasterDetailsDataById,
  addUpdateRuleMasterData,
  addUpdateRuleMasterDetailsData,
  getDunningLetterMasterDetailsGridList,
  getDunningCustomerRuleMappingDataById,
  addEditCustomerRuleMappinngData,
  getdunningHeaderSummaryGridList,
  addEditCustomerCriteriaMappingData,
  getDunningHeaderDetailById,
  resendMailDunningHeaderSummary
};
