const setPlanStatus = (state, planStatus) => {
    state.planStatus = planStatus;
  };
  const setPlanType = (state, planType) => {
    state.planType = planType;
  };
  const setFilterData = (state, payload) => {
    state.filterData = payload;
  };
  const dateData = (state, payload) => {
    state.dateData = payload;
  };
  export default {
    setPlanStatus,
    setPlanType,
    setFilterData,
    dateData
  };
  