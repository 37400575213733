import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getOracleLeList = () => {
  return http.getApi(URL_UTILITY.getOracleLeListUrl);
};

const getOracleOuList = (context, leId) => {
  return http.getApi(URL_UTILITY.getOracleOuListUrl+ '?le_id=' + leId) ;
};

const getInvoiceList = (context, payload) => {
  return http.getApi(URL_UTILITY.getInvoiceListURL, payload) ;
};

const getSavedInvoiceList = (context, payload) => {
  return http.getApi(URL_UTILITY.getSavedInvoiceListURL, payload) ;
};

const saveInvoiceApplication = (context, payload) => {
  return http.postApi(URL_UTILITY.saveInvoiceApplicationURL, payload) ;
};

const getPaymentAdviceList = (context, payload) => {
  return http.getApi(URL_UTILITY.getPaymentAdviceListUrl, payload) ;
};

const getPaymentInvoiceList = (context, payload) => {
  return http.getApi(URL_UTILITY.getPaymentInvoiceListUrl, payload) ;
};
export default {
  getOracleLeList,
  getOracleOuList,
  getInvoiceList,
  getSavedInvoiceList,
  saveInvoiceApplication,
  getPaymentAdviceList,
  getPaymentInvoiceList
};
