import store from '../../../../../../store';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
// import { required, minLength } from 'vuelidate/lib/validators';
import { ModelSelect } from 'vue-search-select';
export default {
  name: 'customerSiteAccount',
  components: {
    ModelSelect
  },
  data() {
    return {
      unsubscribe: null,
      leName: {
        value: null,
        text: null
      },
      leNameList: [],
      partyProfileType: '',
      vendorId: '',
      ccidMeaning: '',
      ccidCode: '',
      selectedCcidItem: '',
      accountKeyName: '',
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showAlertCcid: false,
      isSuccessCcid: false,
      responseMsgCcid: '',
      customerSiteIndex: 0,
      accountIndex: null,
      customerId: '',
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      showPartyIndirectModel: false,
      customerSiteList: [],
      tableFields: [],
      customerCcidList: [],
      customerCcidFields: [
        {
          key: 'segment_code'
        },
        {
          key: 'segment_meaning'
        },
        {
          key: 'ccid',
          class: 'd-none'
        }
      ]
    };
  },
  // validations: {
  //   ccidMeaning: {
  //     required,
  //     minLength: minLength(3)
  //   },
  //   ccidCode: { required }
  // },
  mounted() {
    this.getLegalEntityList();
    this.eventBus.$off('customerSiteAccount');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (store.state.party.customerTabValue === 'customerSiteAccount') {
          if (actionName === 'add') {
            this.showPartyIndirectModel = true;
            this.getCustomerCcidDetails();
          }
          if (actionName === 'save') {
            this.saveSiteAccount();
          }
        }
      }
    });
    this.eventBus.$on('customerSiteAccount', customerSite => {
      this.partyProfileType = store.state.party.partyProfile;
      this.setTableFields();
      if (store.state.party.partyProfile === 'customer') {
        this.customerId = customerSite.customer_id;
        this.getCustomerSiteAccountByPartyId();
      } else if (store.state.party.partyProfile === 'vendor') {
        this.vendorId = customerSite.vendor_id;
        this.getVendorAccountList();
      }
    });
  },
  methods: {
    getLegalEntityList() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST,
        dependentVariable: {}
      };
      payload.dependentVariable.user_id = store.state.auth.userId;
      payload.dependentVariable.res_id = store.state.shared.responsibilityId;

      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.value_code
              };
            });
            this.leNameList = leagalEntity;
            this.leName =
              leagalEntity && leagalEntity.length >= 0
                ? leagalEntity[0]
                : this.leName;
          }
        });
    },
    getCustomerSiteAccountByPartyId() {
      const payload = {
        partyId: store.state.party.partyId
      };
      this.loader = true;
      this.$store
        .dispatch('party/getCustomerSiteAccountByPartyId', payload)
        .then(response => {
          this.customerSiteList = response.data.data;
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setTableFields() {
      if (this.partyProfileType === 'customer') {
        this.tableFields = [
          {
            key: 'dabtour_desc',
            label: 'Dab Tour Account'
          },
          {
            key: 'prepayment_desc',
            label: 'PrePayment Account'
          },
          {
            key: 'prepayment_account_id',
            class: 'd-none'
          },
          {
            key: 'customer_site_account_id',
            class: 'd-none'
          },
          {
            key: 'dabtour_account_id',
            class: 'd-none'
          },
          {
            key: 'customer_site_id',
            class: 'd-none'
          },
          {
            key: 'created_by'
          },
          {
            key: 'creation_date'
          },
          {
            key: 'last_update_date'
          },
          {
            key: 'last_updated_by'
          }
        ];
      } else if (this.partyProfileType === 'vendor') {
        this.tableFields = [
          {
            key: 'liability_desc',
            label: 'Liability Account'
          },
          {
            key: 'prepayment_desc',
            label: 'PrePayment Account'
          },
          {
            key: 'prepayment_account_id',
            class: 'd-none'
          },
          {
            key: 'vendor_site_account_id',
            class: 'd-none'
          },
          {
            key: 'liability_account_id',
            class: 'd-none'
          },
          {
            key: 'customer_site_id',
            class: 'd-none'
          },
          {
            key: 'created_by'
          },
          {
            key: 'creation_date'
          },
          {
            key: 'last_update_date'
          },
          {
            key: 'last_updated_by'
          }
        ];
      }
    },
    getCustomerCcidDetails() {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      if (this.partyProfileType === 'customer') {
        this.loader = true;
        const payload = {
          // customerSiteId: this.customerSiteList[this.customerSiteIndex]
          //   .customer_site_id,
          search: {
            le_id: this.leName.value,
            segment_code: this.ccidCode,
            segment_meaning: this.ccidMeaning
          }
        };
        this.$store
          .dispatch('party/getCustomerCcidDetils', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.customerCcidList = response.data.data;
            } else {
              this.showAlertCcid = true;
              this.isSuccessCcid = false;
              this.responseMsgCcid = response.response.data.message;
            }
          })
          .catch(error => {
            alert(error.message);
          });
      } else if (this.partyProfileType === 'vendor') {
        this.loader = true;
        const payload = {
          vendorSiteId: this.customerSiteList[this.customerSiteIndex]
            .vendor_site_id,
          search: {
            le_id: this.leName.value,
            segment_code: this.ccidCode,
            segment_meaning: this.ccidMeaning
          }
        };
        this.$store
          .dispatch('party/getCustomerCcidDetils', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.customerCcidList = response.data.data;
            } else {
              this.showAlertCcid = true;
              this.isSuccessCcid = false;
              this.responseMsgCcid = response.response.data.message;
            }
          })
          .catch(error => {
            alert(error.message);
          });
      }
      // }
    },
    getVendorAccountList() {
      this.loader = true;
      this.$store
        .dispatch('party/getVendorSiteAccountList', this.vendorId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.customerSiteList = response.data.data.vendor_sites_accounts;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    closePartyBankModel() {
      this.showPartyIndirectModel = false;
    },
    selectedSite(index) {
      this.customerSiteIndex = index;
      this.leName.value = this.customerSiteList[index].le_id;
    },
    selectedCcidRow(item) {
      this.selectedCcidItem = item;
      this.customerCcidList = [];
      if (this.partyProfileType === 'customer') {
        if (this.accountKeyName === 'prepayment_desc') {
          this.customerSiteList[this.customerSiteIndex].customer_site_accounts[
            this.accountIndex
          ].prepayment_desc = item.segment_meaning;
          this.customerSiteList[this.customerSiteIndex].customer_site_accounts[
            this.accountIndex
          ].prepayment_account_id = item.ccid;
        } else {
          this.customerSiteList[this.customerSiteIndex].customer_site_accounts[
            this.accountIndex
          ].dabtour_desc = item.segment_meaning;
          this.customerSiteList[this.customerSiteIndex].customer_site_accounts[
            this.accountIndex
          ].dabtour_account_id = item.ccid;
        }
      } else if (this.partyProfileType === 'vendor') {
        if (this.accountKeyName === 'prepayment_desc') {
          this.customerSiteList[this.customerSiteIndex].vendor_site_accounts[
            this.accountIndex
          ].prepayment_desc = item.segment_meaning;
          this.customerSiteList[this.customerSiteIndex].vendor_site_accounts[
            this.accountIndex
          ].prepayment_account_id = item.ccid;
        } else {
          this.customerSiteList[this.customerSiteIndex].vendor_site_accounts[
            this.accountIndex
          ].liability_desc = item.segment_meaning;
          this.customerSiteList[this.customerSiteIndex].vendor_site_accounts[
            this.accountIndex
          ].liability_code = item.segment_code;
          this.customerSiteList[this.customerSiteIndex].vendor_site_accounts[
            this.accountIndex
          ].liability_account_id = item.ccid;
        }
      }
      this.showPartyIndirectModel = false;
    },
    enableInputs(ccidIndex, keyName) {
      this.accountIndex = ccidIndex;
      this.accountKeyName = keyName;
      // this.customerCcidList = [];
      this.showPartyIndirectModel = true;
    },
    saveSiteAccount() {
      if (this.partyProfileType === 'customerr') {
        const payload = {
          customerId: this.customerId,
          siteAccountData: {
            customer_site_accounts: [
              {
                customer_site_id: this.customerSiteList[this.customerSiteIndex]
                  .customer_site_id,
                customer_site_account_id:
                  this.customerSiteList[this.customerSiteIndex]
                    .customer_site_accounts[this.accountIndex]
                    .customer_site_account_id !== null
                    ? this.customerSiteList[this.customerSiteIndex]
                        .customer_site_accounts[this.accountIndex]
                        .customer_site_account_id
                    : 0,
                dabtour_account_id: this.customerSiteList[
                  this.customerSiteIndex
                ].customer_site_accounts[this.accountIndex].dabtour_account_id,
                prepayment_account_id: this.customerSiteList[
                  this.customerSiteIndex
                ].customer_site_accounts[this.accountIndex]
                  .prepayment_account_id
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/addEditCustomerSiteAccount', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.customerSiteList = response.data.data.customer_sites;
          })
          .catch(error => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = error.message;
          });
      } else if (this.partyProfileType === 'vendor') {
        const payload = {
          vendorId: this.vendorId,
          siteAccountData: {
            vendor_site_id: this.customerSiteList[this.customerSiteIndex]
              .vendor_site_id,
            vendor_site_account_id:
              this.customerSiteList[this.customerSiteIndex]
                .vendor_site_accounts[this.accountIndex]
                .vendor_site_account_id !== null
                ? this.customerSiteList[this.customerSiteIndex]
                    .vendor_site_accounts[this.accountIndex]
                    .vendor_site_account_id
                : 0,
            liability_account_id: this.customerSiteList[this.customerSiteIndex]
              .vendor_site_accounts[this.accountIndex].liability_account_id,
            prepayment_account_id: this.customerSiteList[this.customerSiteIndex]
              .vendor_site_accounts[this.accountIndex].prepayment_account_id
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/addEditVendorSiteAccount', payload)
          .then(response => {
            this.getVendorAccountList()
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.customerSiteList = response.data.data.customer_sites;
          })
          .catch(error => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = error.message;
          });
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
