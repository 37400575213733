import Site from '../partyCustomer/site/';
import CustomerSiteBank from '../partyCustomer/customerSiteBank/';
import CustomerSiteContact from '../partyCustomer/customerSiteContact/';
import CustomerDirectTax from '../partyCustomer/customerDirectTax/';
import customerIndirectTax from '../partyCustomer/customerIndirectTax/';
import CustomerSiteAccount from '../partyCustomer/customerSiteAccount/';
import CustomerHistory from '../partyCustomer/customerHistory';
import store from '../../../../../store/';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'partyVendor',
  props:['vendorId'],
  components: {
    Site,
    CustomerSiteBank,
    CustomerSiteContact,
    CustomerDirectTax,
    customerIndirectTax,
    CustomerSiteAccount,
    CustomerHistory
  },
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      showModal: false,
      responseMsg: '',
      partyName: '',
      partyType: '',
      shortName: '',
      activeTab: 'customerSite',
      customerSiteList: [],
      vendor_id:null,
      active: false
    };
  },
  mounted() {
    this.eventBus.$on('partyVendorData', customerData => {
      this.customerSiteList = customerData;
      this.selectedTab('customerSite');
    });
    this.partyName = store.state.party.partyName;
    this.partyType = store.state.party.partyType;
    this.shortName = store.state.party.shortName;
    this.getActive();
    if(this.vendorId){
    this.vendor_id = this.vendorId}
  },
  methods: {
    selectedTab(tab) {
      this.activeTab = tab;
      // this.eventBus.$off('customerSite');
      this.eventBus.$off('customerSiteBank');
      this.eventBus.$off('customerSiteContact');
      this.eventBus.$off('customerSiteAccount');
      this.eventBus.$off('directTax');
      this.eventBus.$off('indirectTax');
      this.$store.dispatch('party/setUnsetPartyCustomerTabValue', tab);
      setTimeout(() => {
        this.eventBus.$emit(tab, this.customerSiteList);
      }, 500); 
    },
    closeTabs() {
      this.eventBus.$emit('closeCustomer', false);
    },
    getActive(){
      const payload = {
        vendorId: this.vendorId
      };
      this.loader = true;
      this.$store
        .dispatch('party/getActive', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.active = response.data.data.active.toLowerCase();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateActive(){
      const payload = {
        vendorId: this.vendorId,
        active: this.active
      };
      this.loader = true;
      this.$store
        .dispatch('party/updateActive', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.showAlert = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
  }
};
