import appStrings from '@/app/utility/string.utility';
import { ModelSelect } from 'vue-search-select';
import { required, minLength, numeric } from 'vuelidate/lib/validators';

export default {
  name: 'bank',
  components: {
    ModelSelect
  },
  data() {
    return {
      unsubscribe: null,
      editMode: false,
      loader: false,
      showBankModal: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      partyId: null,
      bankDetailsList: [],
      bankeNameList: [
        {
          value: null,
          text: null
        }
      ],
      bankAccountTypeList: [
        {
          value: null,
          text: null
        }
      ],
      bankForm: {
        selectedbankName: {
          value: null,
          text: null
        },
        selectedBankAccountType: {
          value: null,
          text: null
        },
        bank_branch: null,
        account_num: null,
        ifsc_code: null,
        swift_code: null,
        active: null,
        party_bank_id: null
      },
      bankFields: [
        {
          key: 'party_bank_id',
          class: 'd-none'
        },
        {
          key: 'party_id',
          class: 'd-none'
        },
        {
          key: 'bank_name'
        },
        {
          key: 'bank_branch'
        },
        {
          key: 'account_type'
        },
        {
          key: 'account_num',
          label: 'Account Number'
        },
        {
          key: 'ifsc_code',
          label: 'IFSC Code'
        },
        {
          key: 'swift_code',
          label: 'SWIFT Code'
        },
        {
          key: 'active'
        }
      ]
    };
  },

  validations: {
    bankForm: {
      bank_branch: {
        required
      },
      account_num: {
        required,
        numeric
      },
      selectedbankName: {
        value: {
          required
        },
        text: {
          required
        }
      },
      selectedBankAccountType: {
        value: {
          required
        },
        text: {
          required
        }
      },
      ifsc_code: {
        required,
        minLength: minLength(11)
      }
      // swift_code: {
      //   required,
      //   minLength: minLength(11)
      // }
    }
  },
  mounted() {
    this.eventBus.$off('createPartyBank');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditBank();
        }
        if (actionName === 'add') {
          this.showBankModal = true;
          this.editMode = true;
          this.bankForm = {
            selectedbankName: {
              value: null,
              text: null
            },
            selectedBankAccountType: {
              value: null,
              text: null
            },
            bank_branch: null,
            account_num: null,
            ifsc_code: null,
            swift_code: null,
            active: null,
            party_bank_id: null
          };
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    this.eventBus.$on('createPartyBank', partyId => {
      this.partyId = partyId;
      this.loader = true;
      this.$store
        .dispatch('party/getPartyDetailsById', partyId)
        .then(response => {
          this.loader = false;
          this.bankDetailsList = response.data.data.party_drawee_banks;
        });
    });
    // this.eventBus.$on('bankList', bankList => {
    //   if (bankList) {
    //     this.bankDetailsList = bankList;
    //   }
    // });
    this.getBankNameList(appStrings.VALUESETTYPE.BANK_LIST);
    this.getBankNameList(appStrings.VALUESETTYPE.BANK_ACC_TYPE);
  },
  methods: {
    addEditBank() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          partyId: this.partyId,
          bankPayload: {
            party_drawee_banks: [
              {
                party_bank_id:
                  this.party_bank_id !== null ? this.party_bank_id : 0,
                account_num: this.bankForm.account_num,
                active: this.bankForm.active,
                bank_acc_type_vset: this.bankForm.selectedBankAccountType.value,
                bank_branch: this.bankForm.bank_branch,
                bank_vset: this.bankForm.selectedbankName.value,
                ifsc_code: this.bankForm.ifsc_code,
                swift_code: this.bankForm.swift_code,
                main: true
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/addPartyBank', payload)
          .then(response => {
            this.resetForm();
            this.showAlert = true;
            this.loader = false;
            this.showBankModal = false;
            if (response.status === 200) {
              this.isSuccess = true;
              this.bankDetailsList = response.data.data.party_drawee_banks;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(error => {
            this.loader = false;
            this.isSuccess = false;
            this.showBankModal = false;
            this.responseMsg = error.message;
          });
      }
    },
    getBankNameList(VALUESETTYPE) {
      this.$store
        .dispatch('shared/getLOVBySetType', VALUESETTYPE)
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data;
            if (VALUESETTYPE === appStrings.VALUESETTYPE.BANK_LIST) {
              const bankList = result.map(bank => {
                return {
                  value: bank.value_code,
                  text: bank.value_meaning
                };
              });
              this.bankeNameList = bankList;
            } else if (VALUESETTYPE === appStrings.VALUESETTYPE.BANK_ACC_TYPE) {
              const accountTypeList = result.map(bank => {
                return {
                  value: bank.value_code,
                  text: bank.value_meaning
                };
              });
              this.bankAccountTypeList = accountTypeList;
            }
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    bankRowSelected: function(bankItem) {
      if (bankItem) {
        this.partyId = bankItem.party_id;
        this.party_bank_id = bankItem.party_bank_id;
        this.bankForm = {
          selectedbankName: {
            value: bankItem.bank_vset,
            text: bankItem.bank_name
          },
          selectedBankAccountType: {
            value: bankItem.bank_acc_type_vset,
            text: bankItem.account_type
          },
          bank_branch: bankItem.bank_branch,
          account_num: bankItem.account_num,
          ifsc_code: bankItem.ifsc_code,
          swift_code: bankItem.swift_code,
          active: bankItem.active
        };
        this.showBankModal = true;
      }
    },
    resetForm() {
      this.bankForm = {
        selectedbankName: {
          value: null,
          text: null
        },
        selectedBankAccountType: {
          value: null,
          text: null
        },
        bank_branch: null,
        account_num: null,
        ifsc_code: null,
        swift_code: null,
        active: null,
        party_bank_id: null
      };
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
