import http from '../../../../app/services/httpServices';
import URL_UTILITY from '../../../../app/utility/url.utility';

const getGstrTwoADownloadList = (context, payload) => {
  const url = URL_UTILITY.getGstrTwoADownloadListUrl;
  return http.getApi(url, payload);
}
const getRefreshForGstrById = (context, payload) => {
  const url = URL_UTILITY.getRefreshForGstrByIdUrl.replace('{gst_credential_id}', payload);
  return http.getApi(url);
}
const getOtpForGstrDownload = (context, payload) => {
  const url = URL_UTILITY.getOtpForGstrDownloadUrl;
  return http.postApi(url, payload);
}
const getValidateOtpForGstrDownload = (context,payload) => {
  const url = URL_UTILITY.getValidateOtpForGstrDownloadUrl;
  return http.postApi(url,payload);
}
const getSyncGstrDownload = (context, payload) => {
  const url = URL_UTILITY.getSyncGstrDownloadUrl;
  return http.postApi(url, payload);
}
const getPartyVerification = (context, payload) => {
  const url = URL_UTILITY.getPartyVerificationUrl;
  return http.getApi(url, payload);
}
const verifyGstNumber = (context, payload) => {
  const url = URL_UTILITY.verifyGstNumberUrl;
  return http.postApi(url, payload);
}
const updateGstnVerification = (context, payload) => {
  return http.postApi(URL_UTILITY.updateGstnVerificationUrl, payload);
};
const updateVerificationHistory = (context, payload) => {
  return http.getApi(URL_UTILITY.getPartyVerificationUrl + '-history', payload);
};
const uploadDataUpdation = (context,excelImportExternalId) => {
  return http.postApi(URL_UTILITY.gstPartyUploadExcelUrl+'/'+excelImportExternalId+'/'+'excel-external-upload');
};
const recoErrors = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gstR2-reconiliation-errors', payload);
};
const recoErrorsSummarized = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gstR2-reconiliation-errors-summarized', payload);
};
export default {
    getGstrTwoADownloadList,
    getRefreshForGstrById,
    getOtpForGstrDownload,
    getValidateOtpForGstrDownload,
    getSyncGstrDownload,
    getPartyVerification,
    verifyGstNumber,
    updateGstnVerification,
    updateVerificationHistory,
    uploadDataUpdation,
    recoErrors,
    recoErrorsSummarized
};