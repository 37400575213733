import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getLeaseUnitList = (context, payload) => {
  const url = URL_UTILITY.getLeaseUnitListUrl;
  return http.getApi(url, payload);
};

const addEditLeaseUnit = (context, payload) => {
  const url = URL_UTILITY.addEditLeaseUnitUrl;
  return http.postApi(url, payload);
};

const getLeaseUnitDetailsById = (context, payload) => {
  const url = URL_UTILITY.getLeaseUnitDetailsByIdUrl.replace('{leasePrjUnitId}', payload.prjUnitId);
  return http.getApi(url);
}

const getLeaseUnitArea = (context, payload) => {
  const url = URL_UTILITY.getLeaseUnitAreaUrl.replace('{leasePrjUnitId}', payload.prjUnitId);
  return http.getApi(url);
}

const getLeaseUnitSpecification = (context, payload) => {
  const url = URL_UTILITY.getLeaseUnitSpecificationUrl.replace('{leasePrjUnitId}', payload.prjUnitId);
  return http.getApi(url);
}

const getLeaseUnitOtherSpecification = (context, payload) => {
  const url = URL_UTILITY.getLeaseUnitOtherSpecificationUrl.replace('{leasePrjUnitId}', payload.prjUnitId);
  return http.getApi(url);
}

const addEditLeaseUnitArea = (context, payload) => {
  const url = URL_UTILITY.addEditLeaseUnitAreaUrl;
  return http.postApi(url, payload);
};

const addEditLeaseUnitSpecification = (context, payload) => {
  const url = URL_UTILITY.addEditLeaseUnitSpecificationUrl;
  return http.postApi(url, payload);
};

const addEditLeaseUnitOtherSpecification = (context, payload) => {
  const url = URL_UTILITY.addEditLeaseUnitOtherSpecificationUrl;
  return http.postApi(url, payload);
};
const updateFmsBillableArea = (context, payload) => {
  const url = URL_UTILITY.updateFmsBillableAreaUrl;
  return http.postApi(url, payload);
};

export default {
  getLeaseUnitList,
  addEditLeaseUnit,
  getLeaseUnitArea,
  getLeaseUnitSpecification,
  getLeaseUnitOtherSpecification,
  addEditLeaseUnitArea,
  addEditLeaseUnitSpecification,
  addEditLeaseUnitOtherSpecification,
  getLeaseUnitDetailsById,
  updateFmsBillableArea
};
