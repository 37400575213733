import { ModelSelect, BasicSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'EditAddress',
  components: {
    ModelSelect,
    BasicSelect
  },
  props: ['addressId'],
  watch: {
    'selectCountry.value'() {
      if (this.selectCountry.value) {
        this.getCountryBasedStates();
      }
    },
    'selectState.value'() {
      if (this.selectState.value) {
        this.getStateBasedCity();
        this.getStateBasedPincode();
      }
    }
  },
  data() {
    return {
      unsubscribe: null,
      showModal: false,
      editMode: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      addressName: '',
      address1: '',
      address2: '',
      address3: '',
      selectAddressType: {
        value: null,
        text: null
      },
      addressTypeList: [],
      selectCountry: { value: null, text: null },
      countryList: [],
      selectState: { value: null, text: null },
      stateList: [],
      selectCity: { value: null, text: null },
      cityList: [],
      selectPincode: { value: null, text: null },
      pincodeList: []
    };
  },
  mounted() {
    this.showModal = true;
    this.editMode = true;
    this.getCountryList();
    this.getAddressType();
    if (this.addressId) {
      this.getAddressById(this.addressId);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.updateAddress();
        }
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getAddressById(addressId) {
      this.$store
        .dispatch('country/getAddressDeatilsById', addressId)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            this.addressName = results.address_name;
            this.address1 = results.address1;
            this.address2 = results.address2;
            this.address3 = results.address3;
            this.selectAddressType = {
              value: results.address_type_code,
              text: results.address_type
            };
            this.selectCountry = {
              value: results.country_id,
              text: results.country
            };
            this.selectState = {
              value: results.state_id,
              text: results.state
            };
            this.selectCity = {
              value: results.city_id,
              text: results.city
            };
            this.selectPincode = {
              value: results.pincode_id,
              text: results.pincode
            };
            this.getCountryBasedStates();
            this.getStateBasedCity();
            this.getStateBasedPincode();
          }
        });
    },
    getCountryList() {
      this.$store.dispatch('country/getCountryList').then(response => {
        if (response.status === 200) {
          const results = response.data.data.map(key => {
            return {
              value: key.country_id,
              text: key.country
            };
          });
          this.countryList = results;
        }
      });
    },
    getAddressType() {
      this.$store.dispatch('organization/getAddressType').then(response => {
        if (response.status === 200) {
          const results = response.data.data.map(type => {
            return {
              value: type.lookup_code.toUpperCase(),
              text: type.meaning
            };
          });
          this.addressTypeList = results;
        }
      });
    },
    getCountryBasedStates() {
      this.$store
        .dispatch('country/getStateListByCountryId', this.selectCountry.value)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.map(type => {
              return {
                value: type.state_id,
                text: type.state
              };
            });
            this.stateList = results;
          }
        });
    },
    getStateBasedCity() {
      this.$store
        .dispatch('country/getCityListByStateId', this.selectState.value)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.map(city => {
              return {
                value: city.city_id,
                text: city.city
              };
            });
            this.cityList = results;
          }
        });
    },
    getStateBasedPincode() {
      this.$store
        .dispatch('country/getPincodeListByStateId', this.selectState.value)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.map(pincode => {
              return {
                value: pincode.pincode_id,
                text: pincode.pincode
              };
            });
            this.pincodeList = results;
          }
        });
    },
    updateAddress() {
      const payload = {
        address_id: this.addressId,
        address1: this.address1,
        address2: this.address2,
        address3: this.address3,
        address_name: this.addressName,
        address_type: this.selectAddressType.value,
        city_id: this.selectCity.value,
        country_id: this.selectCountry.value,
        pincode_id: this.selectPincode.value,
        state_id: this.selectState.value,
        other_city: null,
        other_state: null
      };
      this.$store
        .dispatch('country/updateAddressDetails', payload)
        .then(response => {
          this.editMode = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            const results = response.data.data;
            this.addressName = results.address_name;
            this.address1 = results.address1;
            this.address2 = results.address2;
            this.address3 = results.address3;
            this.selectAddressType = {
              value: results.address_type_code,
              text: results.address_type
            };
            this.selectCountry = {
              value: results.country_id,
              text: results.country
            };
            this.selectState = {
              value: results.state_id,
              text: results.state
            };
            this.selectCity = {
              value: results.city_id,
              text: results.city
            };
            this.selectPincode = {
              value: results.pincode_id,
              text: results.pincode
            };
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    hideEditAddress() {
      this.showModal = false;
      this.$emit('hideUpdateAddress');
    },
    chooseCountry(e) {
      this.selectCountry = {
        text: e.text,
        value: e.value
      };
      this.getCountryBasedStates();
    },
    chooseState(e) {
      this.selectState = {
        text: e.text,
        value: e.value
      };
      this.getStateBasedCity();
      this.getStateBasedPincode();
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
