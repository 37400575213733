import store from '../../../../../../../store/';
export default {
  name: 'siteAddress',
  components: {},
  data() {
    return {
      name: '',
      partyId: '',
      data: [],
      search: null,
      fields: [
        {
          key: 'address'
        },
        {
          key: 'address_id',
          class: 'd-none'
        },
        {
          key: 'party_address_id',
          class: 'd-none'
        }
      ]
    };
  },
  mounted() {
      this.eventBus.$on('siteAddress', data => {
      this.name = data;
     this.getPartyAddress(store.state.party.partyId);
    });
  },
  methods: {
    getPartyAddress(partyId) {
      this.$store
        .dispatch('party/getPartyAddressByPartyId', partyId)
        .then(response => {
          this.data = response.data.data;
        })
        .catch(error => {
          alert(error.message);
        });
    },
    rowSelected(item) {
      this.eventBus.$emit('setSiteAddress', item);
    }
  },
  beforeDestroy() {
    
    this.eventBus.$off('siteAddress')
  }
};
