import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getPayableInvoiceList = (context, payload) => {
  return http.getApi(URL_UTILITY.getPayableInvoiceListUrl, payload);
};

const approvePayableInvoice = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPayableInvoiceUrl + '/approval',
    payload
  );
};
const rejectPayableInvoice = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPayableInvoiceUrl + '/rejected',
    payload
  );
};

const getPartyPortalUpdateStatus = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyPortalUpdateStatusUrl + '/update-status',
    payload
  );
};

// For Details Update
const getPartyPortalDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyPortalUpdateStatusUrl + '/details/updated',
    payload
  );
};
const approvePartyPortalDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPartyPortalDetailsUrl + '/approval',
    payload
  );
};
const rejectPartyPortalDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPartyPortalDetailsUrl + '/rejected',
    payload
  );
};
const getPartyPortalExistingDetailsRecord = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyPortalUpdateStatusUrl + '/details/existed',
    payload
  );
};
// For Address Details Update
const getPartyPortalAddressById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyPortalUpdateStatusUrl + '/addresses',
    payload
  );
};
const approvePartyPortalAddress = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPartyPortalAddressUrl + '/approval',
    payload
  );
};
const rejectPartyPortalAddress = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPartyPortalAddressUrl + '/rejected',
    payload
  );
};
const getPartyPortalExistingAddressRecord = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyPortalUpdateStatusUrl + '/addresses/existed',
    payload
  );
};
// For Bank Details Update
const getPartyPortalBankById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyPortalUpdateStatusUrl + '/drawee-banks',
    payload
  );
};
const approvePartyPortalBank = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPartyPortalBankUrl + '/approval',
    payload
  );
};
const rejectPartyPortalBank = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPartyPortalBankUrl + '/rejected',
    payload
  );
};
const getPartyPortalExistingBankRecord = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyPortalUpdateStatusUrl + '/drawee-banks/existed',
    payload
  );
};
// For Contact Details Update
const getPartyPortalContactDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyPortalUpdateStatusUrl + '/contact-details',
    payload
  );
};
const approvePartyPortalContactDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPartyPortalContactDetailsUrl + '/approval',
    payload
  );
};
const rejectPartyPortalContactDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPartyPortalContactDetailsUrl + '/rejected',
    payload
  );
};
const getPartyPortalExistedContactDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyPortalUpdateStatusUrl + '/contact-details/existed',
    payload
  );
};
const getPartyPortalUpdatedContactDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyPortalUpdateStatusUrl + '/contact-details/updated',
    payload
  );
};
//
const approvePartyPortalContactCommunication = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPartyPortalContactCommunicationUrl + '/approval',
    payload
  );
};
const rejectPartyPortalContactCommunication = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPartyPortalContactCommunicationUrl + '/rejected',
    payload
  );
};
// For Direct-Tax Details Update
const getPartyPortalDirectTaxById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyPortalUpdateStatusUrl + '/direct-tax',
    payload
  );
};
const approvePartyPortalDirectTax = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPartyPortalDirectTaxUrl + '/approval',
    payload
  );
};
const rejectPartyPortalDirectTax = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPartyPortalDirectTaxUrl + '/rejected',
    payload
  );
};
const getPartyPortalExistingDirectTaxRecord = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyPortalUpdateStatusUrl + '/direct-tax/existed',
    payload
  );
};
// For Indirect-Tax Details Update
const getPartyPortalIndirectTaxById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyPortalUpdateStatusUrl + '/indirect-tax',
    payload
  );
};
const approvePartyPortalIndirectTax = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPartyPortalIndirectTaxUrl + '/approval',
    payload
  );
};
const rejectPartyPortalIndirectTax = (context, payload) => {
  return http.postApi(
    URL_UTILITY.approveRejectPartyPortalIndirectTaxUrl + '/rejected',
    payload
  );
};
const getPartyPortalExistingIndirectTaxRecord = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPartyPortalUpdateStatusUrl + '/indirect-tax/existed',
    payload
  );
};
const getPartySubMenuId = (context, payload) => {
  return http.getApi(URL_UTILITY.getPartySubMenuIdUrl, payload);
};
export default {
  getPayableInvoiceList,
  approvePayableInvoice,
  rejectPayableInvoice,
  getPartyPortalUpdateStatus,
  getPartyPortalDetailsById,
  approvePartyPortalDetails,
  rejectPartyPortalDetails,
  getPartyPortalExistingDetailsRecord,
  getPartyPortalAddressById,
  approvePartyPortalAddress,
  rejectPartyPortalAddress,
  getPartyPortalExistingAddressRecord,
  getPartyPortalBankById,
  approvePartyPortalBank,
  rejectPartyPortalBank,
  getPartyPortalExistingBankRecord,
  getPartyPortalContactDetailsById,
  approvePartyPortalContactDetails,
  rejectPartyPortalContactDetails,
  getPartyPortalExistedContactDetailsById,
  getPartyPortalUpdatedContactDetailsById,
  approvePartyPortalContactCommunication,
  rejectPartyPortalContactCommunication,
  getPartyPortalDirectTaxById,
  approvePartyPortalDirectTax,
  rejectPartyPortalDirectTax,
  getPartyPortalExistingDirectTaxRecord,
  getPartyPortalIndirectTaxById,
  approvePartyPortalIndirectTax,
  rejectPartyPortalIndirectTax,
  getPartyPortalExistingIndirectTaxRecord,
  getPartySubMenuId
};
