import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getValueSetList = (context, payload) => {
  return http.getApi(URL_UTILITY.getAdminValueSetUrl, payload);
};

// const getValueSetById = (context, valueSetHdrId) => {
//   const url = URL_UTILITY.getAdminValueSetByIdUrl.replace(
//     '{valueSetHdrId}',
//     valueSetHdrId
//   );
//   return http.getApi(url);
// };

const getValueSetById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getAdminValueSetUrl + '/' + payload.valueSetHdrId,
    payload
  );
};

const addEditValueSet = (context, payload) => {
  return http.postApi(URL_UTILITY.getAdminValueSetUrl, payload);
};
const verifyValueSetQuery = (context, payload) => {
  return http.postApi(URL_UTILITY.getVerifyValueSetQueryUrl, payload);
};

const getParentValueSet = (context, hdrId) => {
  return http.getApi(
    URL_UTILITY.getParentValueSetUrl + '?value_set_hdr_id=' + hdrId
  );
};

const getValueSetByIdPage = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getAdminValueSetUrl + '-dtl/v2/' + payload.valueSetHdrId,
    payload
  );
};

const getValueSetByHdrDtls = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getAdminValueSetUrl + '/v2/' + payload.valueSetHdrId
  );
};

export default {
  getValueSetList,
  getValueSetById,
  addEditValueSet,
  verifyValueSetQuery,
  getParentValueSet,
  getValueSetByIdPage,
  getValueSetByHdrDtls
};
