import store from '../../../../../../store/';
export default {
  name: 'customerSiteContact',
  data() {
    return {
      unsubscribe: null,
      partyProfileType: '',
      vendorId: '',
      customerSiteContactId: 0,
      partyContactPersonId: null,
      showPartyContactCommunicationModel: false,
      customerSiteIndex: 0,
      customerId: '',
      customerSiteList: [],
      showPartyContactListModel: false,
      contactDetailIndex: null,
      communicationIndex: null,
      partyId: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      partyContactDetails: [],
      contactDetailFields: [
        {
          key: 'communication'
        },

        {
          key: 'contact_purpose'
        },
        {
          key: 'contact_name'
        },
        {
          key: 'active'
        },
        {
          key: 'primary_contact'
        },
        {
          key: 'party_contact_person_id',
          class: 'd-none'
        },
        {
          key: 'customer_site_contact_id',
          class: 'd-none'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'last_updated_by'
        }
      ],
      communicationListFieldsList: [
        {
          key: 'contact_type'
        },
        {
          key: 'comm_data'
        },
        {
          key: 'active'
        },
        {
          key: 'primary_comm'
        }
      ],
      partyContactList: [],
      partyContactFields: [
        {
          key: 'salutation'
        },
        {
          key: 'first_name'
        },
        {
          key: 'middle_name'
        },
        {
          key: 'last_name'
        },
        {
          key: 'contact_purpose'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'active'
        },
        {
          key: 'party_contact_person_id',
          class: 'd-none'
        }
      ],
      partyContactCommList: [],
      partyContactCommFields: [
        {
          key: 'comm_type'
        },
        {
          key: 'area_code'
        },
        {
          key: 'country_code'
        },
        {
          key: 'email'
        },
        {
          key: 'party_contact_comm_id',
          class: 'd-none'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$off('customerSiteContact');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (store.state.party.customerTabValue === 'customerSiteContact') {
          if (actionName === 'add') {
            this.showHideModels();
          }
        }
      }
    });
    this.eventBus.$on('customerSiteContact', customerSite => {
      this.getPartyContacts();
      this.partyProfileType = store.state.party.partyProfile;
      if (store.state.party.partyProfile === 'customer') {
        this.customerId = customerSite.customer_id;
        this.getCustomerSiteContactByPartyId();
      } else if (store.state.party.partyProfile === 'vendor') {
        this.vendorId = customerSite.vendor_id;
        this.getVendorcontactList();
      }
    });
  },
  methods: {
    showHideModels() {
      if (this.partyContactPersonId !== null) {
        this.showPartyContactCommunicationModel = true;
      } else if (this.partyContactPersonId === null) {
        this.showPartyContactListModel = true;
      }
    },
    getCustomerSiteContactByPartyId() {
      const payload = {
        partyId: store.state.party.partyId
      };
      this.loader = true;
      this.$store.dispatch('party/getCustomerSiteContactByPartyId', payload)
      .then(response => {
        this.customerSiteList = response.data.data;
        this.loader = false;
      })
      .catch(() => {
        this.loader = false;
      });
    },
    getPartyContacts() {
      const partyId = store.state.party.partyId;
      this.$store
        .dispatch('party/getPartyContactsByPartyId', partyId)
        .then(response => {
          this.partyContactList = response.data.data;
        });
    },
    closePartyCustomerModel() {
      this.showPartyContactListModel = false;
    },
    closePartyCustomerCommModel() {
      this.showPartyContactCommunicationModel = false;
    },
    selectedContactRow(item) {
      if (this.partyProfileType === 'customer') {
        const payload = {
          customerId: this.customerId,
          contactpayload: {
            customer_site_contacts: [
              {
                customer_site_id: this.customerSiteList[this.customerSiteIndex]
                  .customer_site_id,
                customer_site_contact_id: 0,
                customer_site_contact_comm_id: null,
                party_contact_person_id: item.party_contact_person_id,
                party_contact_comm_id: null,
                comm_active: false,
                contact_active: true,
                primary_comm: false,
                primary_contact: true
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/getAddEditCustomerSiteContact', payload)
          .then(response => {
            this.getCustomerSiteContactByPartyId();
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.showPartyContactListModel = false;
            this.customerSiteList = response.data.data.customer_sites;
          });
      } else if (this.partyProfileType === 'vendor') {
        let payload = {};
        payload = {
          vendorId: this.vendorId,
          contactData: {
            vendor_site_contacts: [
              {
                vendor_site_id: this.customerSiteList[this.customerSiteIndex]
                  .vendor_site_id,
                vendor_site_contact_id: 0,
                vendor_site_contact_comm_id: null,
                party_contact_person_id: item.party_contact_person_id,
                party_contact_comm_id: null,
                comm_active: false,
                contact_active: true,
                primary_comm: false,
                primary_contact: true
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/addEditVendorContact', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.showPartyContactListModel = false;
            this.customerSiteList = response.data.data.vendor_sites_contact;
          });
      }
    },
    selectedContactCommRow(item) {
      if (this.partyProfileType === 'customer') {
        const payload = {
          customerId: this.customerId,
          contactpayload: {
            customer_site_contacts: [
              {
                customer_site_id: this.customerSiteList[this.customerSiteIndex]
                  .customer_site_id,
                customer_site_contact_id: this.customerSiteContactId,
                customer_site_contact_comm_id: 0,
                party_contact_person_id: this.partyContactPersonId,
                party_contact_comm_id: item.party_contact_comm_id,
                comm_active: true,
                contact_active: true,
                primary_comm: false,
                primary_contact: true
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/getAddEditCustomerSiteContact', payload)
          .then(response => {
            this.partyContactPersonId = null;
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.showPartyContactListModel = false;
            this.customerSiteList = response.data.data.customer_sites;
            this.showPartyContactCommunicationModel = false;
            this.getCustomerSiteContactByPartyId();
          });
      } else if (this.partyProfileType === 'vendor') {
        let payload = {};
        payload = {
          vendorId: this.vendorId,
          contactData: {
            vendor_site_contacts: [
              {
                vendor_site_id: this.customerSiteList[this.customerSiteIndex]
                  .vendor_site_id,
                vendor_site_contact_id: this.customerSiteContactId,
                vendor_site_contact_comm_id: 0,
                party_contact_person_id: this.partyContactPersonId,
                party_contact_comm_id: item.party_contact_comm_id,
                comm_active: false,
                contact_active: true,
                primary_comm: false,
                primary_contact: true
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/addEditVendorContact', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.showPartyContactListModel = false;
            this.showPartyContactCommunicationModel = false;
            this.customerSiteList = response.data.data.vendor_sites_contact;
          });
      }
    },
    selectedSite(index) {
      this.customerSiteIndex = index;
    },
    selectContactRow(contactPersonId, customerSiteContactId, isShowDetails) {
      this.customerSiteContactId = customerSiteContactId;
      this.partyContactPersonId = isShowDetails ? contactPersonId : null;
      this.partyContactCommList = this.partyContactList.find(
        contact => contact.party_contact_person_id === contactPersonId
      ).communication_details;
    },
    getVendorcontactList() {
      this.loader = true;
      this.$store
        .dispatch('party/getVendorContacts', this.vendorId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.customerSiteList = response.data.data.vendor_sites_contact;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
