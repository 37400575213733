import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getOrganizationLov = (context, payload) => {
  const url = URL_UTILITY.getOrgannizationLovUrl.replace('{lovType}', 'LE');
  const response = http.getApi(url + '?responsibility_id=' + payload);
  return response;
};

const getLOVBySetType = (context, setType) => {
  const url = URL_UTILITY.getLOVBySetTypeUrl.replace('{valueSetType}', setType);
  const response = http.getApi(url);
  return response;
};

const getProjectList = (context, typeOfProject) => {
  const url = URL_UTILITY.getProjectListUrl.replace(
    '{typeOfProject}',
    typeOfProject
  );
  const response = http.getApi(url);
  return response;
};

const getOtherLovByLovFieldAndId = (contect, lovFieldAndId) => {
  const url = URL_UTILITY.getOtherLovByProjectIdUrl
    .replace('{lovField}', lovFieldAndId.lovFieldName)
    .replace('{id}', lovFieldAndId.lovId);
  const response = http.getApi(url);
  return response;
};

const getOptionProfileByProfileType = (context, profileType) => {
  const url = URL_UTILITY.getOptionProfileUrl.replace(
    '{profileType}',
    profileType
  );
  const response = http.getApi(url);
  return response;
};

export default {
  getOrganizationLov,
  getLOVBySetType,
  getProjectList,
  getOtherLovByLovFieldAndId,
  getOptionProfileByProfileType
};
