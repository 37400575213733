import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getCodeCombinationList = (context, payload) => {
  const url = URL_UTILITY.getCodeCombinationUrl;
  return http.getApi(url, payload);
};

const getCodeCombColumns = (context, flexfield_id) => {
  const url = URL_UTILITY.getCodeCombinationColumnsByFlexfieldUrl;
  return http.getApi(url + '/' + flexfield_id);
}

const getCodeCombDtlsByKeyFlexId = (context, payload) => {
  const url = URL_UTILITY.getCodeCombinationUrl + '/' +  payload.keyFlexId;
  return http.getApi(url, payload.request_params);
}

const addEditCodeCombSegments = (context, payload) => {
  return http.postApi(URL_UTILITY.getCodeCombinationUrl, payload);
};
const getValueSetForCodeCombination = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getValueSetForCodeCombinationUrl,
    payload
  );
};
export default {
  getCodeCombinationList,
  getCodeCombColumns,
  getCodeCombDtlsByKeyFlexId,
  addEditCodeCombSegments,
  getValueSetForCodeCombination
};
