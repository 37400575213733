import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getSubModuleList = (context, payload) => {
  const url = URL_UTILITY.getSubModuleListUrl;
  return http.getApi(url, payload);
};
const getSubModuleListById = (context, payload) => {
  const url = URL_UTILITY.getSubModuleListByIdUrl.replace(
    '{moduleId}',
    payload.moduleId
  );
  return http.getApi(url);
};

const addEditSubModule = (context, payload) => {
  const url = URL_UTILITY.getSubModuleListByIdUrl.replace('{moduleId}', payload.moduleId);
  return http.postApi(url, payload.sub_module_details);
}
export default {
  getSubModuleList,
  getSubModuleListById,
  addEditSubModule
};
