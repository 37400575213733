import appStrings from '@/app/utility/string.utility';
export default {
  name: 'custIndirectTaxDetails',
  components: {},
  props: ['inDirectTaxRowDetail','customerDtlId','customerSiteDtlList','siteType','vendorDtlId', 'vendorSiteDtlList'],
  data() {
    return {
      loader: false,
      unsubscribe: null,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      editMode: false,
      taxGroup: null,
      tanNo: null,
      active: false,
      partyProfileType: '',
    };
  },
  mounted() {
    if (this.inDirectTaxRowDetail) {
      this.fillRecordFromInDirectTaxParent(this.inDirectTaxRowDetail);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.partyActive();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    fillRecordFromInDirectTaxParent(item) {
      this.taxGroup = item.tax_group;
      this.tanNo = item.tax_num;
      this.active = item.active;
    },
    partyActive(){
      if(!this.inDirectTaxRowDetail.party_active) {
        const promt = confirm('Are you sure, you want to active Party?');
        if (promt) {
          this.editInDirectTaxDetail()
        }
      }else{this.editInDirectTaxDetail()}
    },
    editInDirectTaxDetail() {
      if (this.siteType === 'customer') {
        const payload = {
            customerId: this.customerDtlId,
            customerSiteIndirectTax: {
              customer_site_indirect_taxs: [
                {
                  customer_site_id: this.customerSiteDtlList.customer_site_id,
                  entity_type: 'Customer',
                  party_indirect_site_mpng_id: this.inDirectTaxRowDetail.party_indirect_site_mpng_id,
                  party_indirect_tax_id: this.inDirectTaxRowDetail.party_indirect_tax_id,
                  active: this.active
                }
              ]
            }
          };
      this.loader = true;
      this.$store
        .dispatch('party/getAddEditCustomerIndirectTax', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    } else if (this.siteType === 'vendor') {
      const payload = {
        vendorId: this.vendorDtlId,
        indirectTaxData: {
          vendor_site_indirect_taxs: [
            {
              vendor_site_id: this.vendorSiteDtlList.vendor_site_id,
              entity_type: 'Vendor',
              party_indirect_site_mpng_id: this.inDirectTaxRowDetail.party_indirect_site_mpng_id,
              party_indirect_tax_id: this.inDirectTaxRowDetail.party_indirect_tax_id,
              active: this.active
            }
          ]
        }
      };
      this.loader = true;
      this.$store
        .dispatch('party/addEditVendorIndirectTax', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
  }
  

  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
