import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getTenantScheduleList = (context, payload) => {
  return http.getApi(URL_UTILITY.getTenantScheduleUrl, payload);
};
const getTenantScheduleDetails = (context, scheduleHdrId) => {
  return http.getApi(URL_UTILITY.getTenantScheduleUrl + '/' + scheduleHdrId);
};
const addEditTenantScheduleDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getTenantScheduleUrl + '/' + payload.scheduleHdrId,
    payload.data
  );
};
const tenantScheduleHold = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getTenantScheduleUrl + '/' + payload.scheduleHdrId + '/hold',
    payload.data
  );
};
const getInvestorScheduleList = (context, payload) => {
  return http.getApi(URL_UTILITY.getInvestorScheduleUrl, payload);
};
const getInvestorScheduleDetails = (context, scheduleHdrId) => {
  return http.getApi(URL_UTILITY.getInvestorScheduleUrl + '/' + scheduleHdrId);
};
const addEditInvestorScheduleDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getInvestorScheduleUrl + '/' + payload.scheduleHdrId,
    payload.data
  );
};
const getVendorIdByPartyId = (context, partyId) => {
  const url = URL_UTILITY.getVendorIdByPartyIdUrl.replace('{partyId}', partyId);
  return http.getApi(url);
};
const getVendorSiteByVendorId = (context, vendorId) => {
  const url = URL_UTILITY.getVendorAddEditSiteUrl.replace(
    '{vendorId}',
    vendorId
  );
  return http.getApi(url);
};

const investorScheduleHold = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getInvestorScheduleUrl + '/' + payload.scheduleHdrId + '/hold',
    payload.data
  );
};

export default {
  getTenantScheduleList,
  getTenantScheduleDetails,
  addEditTenantScheduleDetails,
  tenantScheduleHold,
  getInvestorScheduleList,
  getInvestorScheduleDetails,
  addEditInvestorScheduleDetails,
  getVendorSiteByVendorId,
  getVendorIdByPartyId,
  investorScheduleHold
};
