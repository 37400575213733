export default {
  name: 'viewReqDetail',
  components: {},
  watch: {},
  props: ['reqDetails'],
  data() {
    return {
      loader: false,
      deliveryType: null,
      toEmail: null,
      ccEmail: null,
      subject: null,
      body: null
    };
  },
  mounted() {
    if (this.reqDetails) {
      this.getViewReqDetail(this.reqDetails.request_id);
    }
  },
  methods: {
    getViewReqDetail(requestId) {
      this.loader = true;
      this.$store
        .dispatch('template/getViewReqDetail', requestId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.deliveryType = resp.data.data.delivery_type_vset;
            this.toEmail = resp.data.data.to_email;
            this.ccEmail = resp.data.data.cc_email;
            this.subject = resp.data.data.subject;
            this.body = resp.data.data.body;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
