import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getTaxTypeList = (context, payload) => {
  const url = URL_UTILITY.getTaxTypeListUrl;
  return http.getApi(url, payload);
}

const addEditTaxType = (context, payload) => {
  const url = URL_UTILITY.addEditTaxTypeUrl;
  return http.postApi(url, payload);
}

export default {
  getTaxTypeList,
  addEditTaxType
};
