export default {
  name: 'SearchSecurityGroup',
  data() {
    return {
      loader: false,
      name:'',
      data: [],
      fields: [
        {
          key: 'securitygroup_hdr_id',
          class: 'd-none'
        },
        {
          key: 'securitygroup_hdr_name',
          label: 'Security Group Name'
        }
      ]
    };
  },
  mounted() {
    this.getSecurityGroupList();
  },
  methods: {
    clearSecurityGroup(){
      this.name = null
    },
    rowSelected(item) {
      this.$emit('selectedSecurityGroupName', item);
    },
    getSecurityGroupList() {
      const payload = {
        security_group_hdr_name: this.name
      };
      this.loader = true;
      this.$store
        .dispatch('resp/getSecurityGroupList', payload)
        .then(response => {
          this.loader = false;
          this.data = response.data.data;
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
