import appStrings from '@/app/utility/string.utility';
export default {
  name: 'cusDirectTaxDetails',
  components: {},
  props: ['directTaxRowDetail','customerDtlId','customerSiteDtlList','siteType','vendorDtlId', 'vendorSiteDtlList'],
  data() {
    return {
      loader: false,
      unsubscribe: null,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      editMode: false,
      taxGroup: null,
      tanNo: null,
      active: false,
      partyProfileType: '',
    };
  },
  mounted() {
    if (this.directTaxRowDetail) {
      this.fillRecordFromDirectTaxParent(this.directTaxRowDetail);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.editDirectTaxDetail();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    fillRecordFromDirectTaxParent(item) {
      this.taxGroup = item.tax_group;
      this.tanNo = item.tan_no;
      this.active = item.active;
    },
    editDirectTaxDetail() {
      if (this.siteType === 'customer') {
        const payload = {
            customerId: this.customerDtlId,
            customerSiteDirectTax: {
              customer_site_direct_taxs: [
                {
                  customer_site_id: this.customerSiteDtlList.customer_site_id,
                  entity_type: 'Customer',
                  party_direct_site_mpng_id: this.directTaxRowDetail.party_direct_site_mpng_id,
                  party_direct_tax_id: this.directTaxRowDetail.party_direct_tax_id,
                  active: this.active
                }
              ]
            }
          };
      this.loader = true;
      this.$store
        .dispatch('party/getAddEditCustomerDirectTax', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    } else if (this.siteType === 'vendor') {
      const payload = {
        vendorId: this.vendorDtlId,
        directTadData: {
          vendor_site_direct_taxs: [
            {
              vendor_site_id: this.vendorSiteDtlList.vendor_site_id,
              entity_type: 'Vendor',
              party_direct_site_mpng_id: this.directTaxRowDetail.party_direct_site_mpng_id,
              party_direct_tax_id: this.directTaxRowDetail.party_direct_tax_id,
              active: this.active
            }
          ]
        }
      };
      this.loader = true;
      this.$store
        .dispatch('party/addEditVendorDirectTax', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
  }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
