import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getTenatnInvoiceList = (context, payload) => {
  const url = URL_UTILITY.getTenantLeaseInvoiceUrl;
  const response = http.postApi(
    `${url}?_page=${payload.pagination._page}&_limit=${payload.pagination._limit}`,
    payload.searchParams
  );
  return response;
};

const getTenantInvoiceDetails = (context, billHdrId) => {
  return http.getApi(URL_UTILITY.getTenantLeaseInvoiceUrl + '/' + billHdrId);
};

const processInvoice = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseTenantInvoiceSubmitRequestUrl.replace(
      '{ouId}',
      payload.ou_id
    ),
    payload.bill_hdr_id
  );
};
const getInvestorInvoiceList = (context, payload) => {
  const url = URL_UTILITY.getInvestorLeaseInvoiceUrl;
  const response = http.postApi(
    `${url}?_page=${payload.pagination._page}&_limit=${payload.pagination._limit}`,
    payload.searchParams
  );
  return response;
};
const getInvestorInvoiceDetails = (context, billHdrId) => {
  return http.getApi(URL_UTILITY.getInvestorLeaseInvoiceUrl + '/' + billHdrId);
};

const postInvestorBillApInvoices = (context, payload) => {
  return http.postApi(
    URL_UTILITY.postApInvoicesInvestorBillRequestUrl,
    payload
  );
};

const updateInvestorBatchNumber = (context, payload) => {
  return http.putApi(URL_UTILITY.getInvestorLeaseInvoiceUrl, payload);
};

const saveAdvSearchFilters = (context, payload) => {
  context.commit('updateAdvSearchFilters', payload);
};
const updateTenantBill = (context, payload) => {
  return http.postApi(
    URL_UTILITY.updateTenantBillUrl, payload);
};
const addEditTenantBill = (context, payload) => {
  return http.postApi(
    URL_UTILITY.addEditTenantBillUrl, payload);
};
const getTenantManualBillList = (context, payload) => {
  const url=URL_UTILITY.leaseAdminUrl+'/tenant-manual-bill/all';
  return http.postApi(
    `${url}?_page=${payload.pagination._page}&_limit=${payload.pagination._limit}`, payload.searchParams);
  };
const getTenantManualBillDetails = (context, billHdrId) => {
  return http.getApi(URL_UTILITY.leaseAdminUrl + '/tenant-manual-bill/' + billHdrId);
};
const addEditTenantManualBill = (context, payload) => {
  return http.postApi(
    URL_UTILITY.leaseAdminUrl + '/tenant-manual-bill', payload);
};
const processTenantManualBill = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getTenantManualBillProcessUrl.replace(
      '{ouId}',
      payload.ou_id
    ),
    payload.bill_hdr_id
  );
};
const deleteTenantBill = (context, payload) => {
  return http.postApi(
    URL_UTILITY.leaseAdminUrl+'/lease-tenant-delete-bill/'+payload);
};

const getLeaseInventoryData =  (context, payload) => {
  return http.getApi(URL_UTILITY.leaseAdminUrl + '/inventory-dashboard/',payload);
};
const getLeaseInventoryDataForChart =  (context, payload) => {
  return http.getApi(URL_UTILITY.leaseAdminUrl + '/inventory-dashboard/chart',payload);
};
export default {
  getTenatnInvoiceList,
  getTenantInvoiceDetails,
  processInvoice,
  getInvestorInvoiceList,
  getInvestorInvoiceDetails,
  postInvestorBillApInvoices,
  updateInvestorBatchNumber,
  saveAdvSearchFilters,
  updateTenantBill,
  addEditTenantBill,
  getTenantManualBillList,
  getTenantManualBillDetails,
  addEditTenantManualBill,
  processTenantManualBill,
  deleteTenantBill,
  getLeaseInventoryData,
  getLeaseInventoryDataForChart
};
