<template>
  <div id="chart-container1"></div>
</template>

<script>
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import highchartsDark from 'highcharts/themes/dark-unica';
Highcharts3D(Highcharts);
highchartsDark(Highcharts);
export default {
  name: 'pieChart1',
  props: ['chart'],
  mounted() {
    const chart = Highcharts.chart('chart-container1', {
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 55,
          beta: 0
        }
      },
      title: {
        text: 'Total Lines'
      },
      plotOptions: {
        pie: {
          depth: 45,
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f}% {point.name}'
          }
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      series: [
        {
          name: 'Data',
          size: '60%',
          data: this.chart
        }
      ]
    });
    if(this.$route.name !== 'expense_gl_acc') {
      const renderer = chart.renderer;
    renderer.text('Unreconciled Lines', 10, 350).css({
      color: 'white',
      fontSize: '20px',
      fontWeight: 'bolder'
    }).attr({
      zIndex: 5
    }).add();
    const rendere = chart.renderer;
    rendere.text('Reconciled Lines', 180, 100).css({
      color: 'white',
      fontSize: '20px',
      fontWeight: 'bolder'
    }).attr({
      zIndex: 5
    }).add();
    }
  }
};
</script>
