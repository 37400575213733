import { format } from 'date-fns';
import store from '@/store';
import appStrings from '@/app/utility/string.utility';
import URL_UTILITY from './url.utility';
import XLSX from 'xlsx';

const setStorage = (name, value) => {
  window.localStorage.setItem(name, value);
};
const getStorage = name => {
  return window.localStorage.getItem(name) || null;
};

const removeStorage = name => {
  window.localStorage.removeItem(name);
};

const clearStorage = () => {
  window.localStorage.clear();
};

const setSessionStorage = (name, value) => {
  sessionStorage.setItem(name, value);
};

const getSessionStorage = name => {
  sessionStorage.getItem(name);
};

const clearSession = () => {
  sessionStorage.clear();
};
const getPageOption = () => {
  return [10, 20, 50, 100, 500];
};
const getPageOptionAsset = () => {
  return [10, 20];
};
const getPageOptionSelectedInvoice = () => {
  return [10, 20, 50, 100, 500, 2000, 5000];
};
const getPageOptionsList = () => {
  return [10, 50, 100, 500, 1000, 5000, 10000];
};
const perPageRecord = 10;
const perPageData = 50;
const perPageRecordData = 100;

const formattedDate = date => {
  const checkTruthy = date !== null && date !== '' && date !== undefined;
  return checkTruthy ? format(new Date(date), 'dd-MMM-yyyy') : null;
};
const unformatAmount = amt => {
  return parseFloat(amt.replace(/,/g, ''));
};

const getModuleBasedPeriodDate = async (module_id, le_id, ou_id) => {
  // get open period date based on module, le id and ou id
  const payload = {
    module_id: module_id,
    le_id: le_id,
    entity_id: ou_id
  };
  const response = await store.dispatch(
    'manualPayments/getModuleBasedPeriodDate',
    payload
  );
  if (response.status === 200) {
    return response.data.data;
  } else {
    return alert('Unable to fetch data');
  }
};

const getValidatePeriodDate = async (glDate, module_id, le_id, ou_id) => {
  const payload = {
    module_id: module_id,
    le_id: le_id,
    entity_id: ou_id,
    gl_date: glDate
  };
  const response = await store.dispatch(
    'manualPayments/getValidatePeriodDate',
    payload
  );
  if (response.status === 200) {
    return response.data.data;
  } else {
    return alert('Unable to fetch data');
  }
};

const validateInvoiceAndPeriodDate = (periodDate, InvoiceDate) => {
  const PERIOD_DATE = new Date(periodDate);
  PERIOD_DATE.setHours(0, 0, 0, 0);

  const INVOICE_DATE = new Date(InvoiceDate);
  INVOICE_DATE.setHours(0, 0, 0, 0);

  return PERIOD_DATE >= INVOICE_DATE;
};

const getMasterAgreementDetails = async (
  leaseMasterAgreementHdrId,
  tabName
) => {
  // get details (escalation, tax) for master agreement
  const payload = {
    tabName: tabName,
    leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
  };
  const resp = await store.dispatch(
    'leases/getLeaseMasterAgreementDataById',
    payload
  );
  return resp;
};

const getTenantAgreementDetails = async (leaseTenantHdrId, tabName) => {
  // get details (escalation, tax) for tenant agreement
  const payload = {
    tabName: tabName,
    leaseTenantHdrId: leaseTenantHdrId
  };
  const resp = await store.dispatch('leases/getLeaseTenantDataById', payload);
  return resp;
};

const setFutureDate = dateRange => {
  if (
    dateRange &&
    formattedDate(dateRange[0]) === formattedDate(dateRange[1])
  ) {
    return (dateRange = [dateRange[0], new Date(2050, 0)]);
  } else {
    return dateRange;
  }
};

const getValuesetData = async (valueSetName, searchKey) => {
  const payload = {
    valueSetName: valueSetName,
    dependentVariable: {
      search_key: searchKey
    }
  };
  const response = await store.dispatch('shared/getCommonVSetData', payload);
  const result = response.data.data;
  const valuesetData =
    result.data && result.data.length > 0 ? result.data : result.page;
  return valuesetData;
};

const disabledBeforeTodayAndTillEndOfMonth = (date, fromDate) => {
  const today = new Date(fromDate);
  today.setHours(0, 0, 0, 0);
  let lastDayOfMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0
  ).getDate();
  let daysUntilEndOfMonth = lastDayOfMonth - today.getDate();
  return (
    date < today ||
    date > new Date(today.getTime() + daysUntilEndOfMonth * 24 * 3600 * 1000)
  );
};

const removeExcessRecords = (permittedLength, data) => {
  // permittedLength is no. of allowed records, data is array from which records are to be removed
  const allowedRecords = data.slice(0, permittedLength);
  return allowedRecords;
};

const getUploadedDocList = async (menuId, recordHdrId) => {
  const payload = {
    _page: 0,
    _limit: 10,
    menu_id: menuId,
    sub_menu_id: 0,
    record_id: recordHdrId
  };
  const response = await store.dispatch('intWorkflow/getOpenKmDocDtl', payload);
  const records = response.data.data.page;
  return records;
};

const truncate = (source, size) => {
  if (source) {
    return source.length > size ? source.slice(0, size - 1) + '…' : source;
  }
};

const calculateTotal = array => {
  return array.reduce((preValue, curValue) => {
    return parseFloat(preValue) + parseFloat(curValue);
  });
};
const getUserName = () => {
  const userName = store.state.auth.userName;
  const name = userName.slice(userName.lastIndexOf('-') + 1);
  return name;
};
const getDefaultEndDate = (startDate, endDate) => {
  const validate = startDate && !endDate ? appStrings.DEFAULTDATE : endDate;
  return validate;
};
const disabledDates = (date, inputDate) => {
  const restrictedDate = new Date(inputDate);
  restrictedDate.setHours(0, 0, 0, 0);
  return date < restrictedDate;
};
const generatePassword = () => {
  const digits = '0123456789';
  let otp = '';
  for (let i = 0; i < 6; i++) {
    otp += digits[Math.floor(Math.random() * 10)];
  }
  return otp;
};
const isValidPanNumber = panNumber => {
  const panPattern = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
  if (panPattern.test(panNumber)) {
    return panNumber;
  } else {
    alert(appStrings.DEPENDENTVSETMSG.PANERRORMSG);
  }
};
const getResponsibility = token => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
    method: 'GET'
  });
  const options = {
    headers: headers,
    method: 'GET'
  };
  const url = 'https://realapp.in:8080/realapp/responsibilities';
  return fetch(url, options).then(response =>
    response.json().then(json => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};
const downloadFile = (url, fileName) => {
  const downloadUrl = url;
  fetch(downloadUrl)
    .then(res => res.blob())
    .then(data => {
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(data);
      a.download = fileName;
      a.click();
    });
};
const formatCurrency = num => {
  num = Math.floor(num);
  num = num.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  return num;
};
const formatCurrencyAmount = num => {
  num = +(+num).toFixed(2);
  num = num.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return num;
};

const disabledFutureDates = (date, inputDate) => {
  const restrictedDate = new Date(inputDate);
  restrictedDate.setHours(0, 0, 0, 0);
  return date > restrictedDate;
};
const formatAmount = num => {
  num = num.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return num;
};
const formatAmountWithoutCurrency = num => {
  num = num
    ? num.toLocaleString('en-IN', {
        currency: 'INR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : null;
  return num;
};

const previewImgDoc = (payload, callback) => {
  const url =
    URL_UTILITY.getOpenKmDocDeleteUrl +
    '/preview/' +
    payload.docDetailId +
    '?token=' +
    payload.token;
  const token = sessionStorage.getItem('token');
  fetch(url, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${token}`
    })
  })
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      let imgLink = null;
      if (payload.fileExtension === 'pdf') {
        imgLink = window.URL.createObjectURL(
          new Blob([blob], { type: 'application/pdf' })
        );
      } else {
        imgLink = window.URL.createObjectURL(new Blob([blob]));
      }
      document.querySelector('#prev-docs').src = imgLink + '#toolbar=0';
    })
    .finally(() => {
      callback();
    });
};
const getFileExtension = fileName =>
  fileName
    .toLowerCase()
    .split('.')
    .pop();

const getOpenKmAuthToken = async () => {
  const response = await store.dispatch(
    'intWorkflow/openKmPartyPortalAuthenticate'
  );
  return response.data.data.token;
};

const previewImgDocParty = payload => {
  const url =
    URL_UTILITY.getOpenKmDocDeleteUrl +
    '/preview/' +
    payload.docDetailId +
    '?token=' +
    payload.token;
  const token = sessionStorage.getItem('token');
  fetch(url, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${token}`
    })
  })
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      let imgLink = null;
      if (payload.fileExtension === 'pdf') {
        imgLink = window.URL.createObjectURL(
          new Blob([blob], { type: 'application/pdf' })
        );
      } else {
        imgLink = window.URL.createObjectURL(
          new Blob([blob], { type: 'image/jpeg' })
        );
      }
      imgLink = imgLink + '#toolbar=0';
      window.open(imgLink, '_blank');
    });
};
const downloadExcel = (downloadData, compName) => {
  const dataToDownload = downloadData.map(data => {
    let newData = {};
    for (const key in data) {
      const formatedKeyName = key.replace(/_/g, ' ').toUpperCase();
      Object.assign(newData, { [formatedKeyName]: data[key] });
    }
    return newData;
  });
  const data = XLSX.utils.json_to_sheet(dataToDownload);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, data, 'data');
  XLSX.writeFile(wb, `${compName}-${new Date().toLocaleDateString()}.xlsx`);
};

const convertDateFormat = inputDate => {
  if (!inputDate) {
    return;
  }
  const [year, month, day] = inputDate.split('-');
  const dateObject = new Date(`${month}/${day}/${year}`);
  const monthAbbreviation = dateObject.toLocaleString('default', {
    month: 'short'
  });
  const outputDate =
    year.length === 4 ? `${day}-${monthAbbreviation}-${year}` : inputDate;
  return outputDate;
}
const splitAmountDecimal = (amount, decimalPlace) => {
  const [integerPart, decimalPart] = amount.toString().split('.');
  const truncatedDecimal = parseFloat(
    `${integerPart}.${decimalPart.slice(0, decimalPlace)}`
  );
  return truncatedDecimal;
};

const getValuesetDataAsset = async (valueSetName, searchKey) => {
  const payload = {
    valueSetName: valueSetName,
    dependentVariable: {
      ORG_LOCATION_ID: searchKey
    }
  };
  const response = await store.dispatch('shared/getCommonVSetData', payload);
  const result = response.data.data;
  const valuesetData =
    result.data && result.data.length > 0 ? result.data : result.page;
  return valuesetData;
};
const getFirstDateOfMonth = dateString => {
  let [month, year] = dateString.split('-');
  let date = new Date(Date.parse(month + ' 1, ' + year));
  date.setDate(1);
  let formattedDate =
    ('0' + date.getDate()).slice(-2) +
    '-' +
    date.toLocaleString('default', { month: 'short' }) +
    '-' +
    date.getFullYear();
  return formattedDate;
};
const getLastDateOfMonth = dateString => {
  let [month, year] = dateString.split('-');
  let monthNumber = new Date(Date.parse(month + ' 1, ' + year)).getMonth() + 1;
  let lastDayOfMonth = new Date(year, monthNumber, 0).getDate();
  let formattedDate =
    ('0' + lastDayOfMonth).slice(-2) + '-' + month + '-' + year;
  return formattedDate;
};
export default {
  setStorage,
  getStorage,
  removeStorage,
  clearStorage,
  setSessionStorage,
  getSessionStorage,
  clearSession,
  getPageOption,
  perPageRecord,
  formattedDate,
  unformatAmount,
  getModuleBasedPeriodDate,
  getMasterAgreementDetails,
  getTenantAgreementDetails,
  setFutureDate,
  getValidatePeriodDate,
  validateInvoiceAndPeriodDate,
  getValuesetData,
  disabledBeforeTodayAndTillEndOfMonth,
  removeExcessRecords,
  getUploadedDocList,
  truncate,
  calculateTotal,
  getUserName,
  getDefaultEndDate,
  disabledDates,
  generatePassword,
  getPageOptionAsset,
  isValidPanNumber,
  getResponsibility,
  downloadFile,
  formatCurrency,
  previewImgDoc,
  getFileExtension,
  getPageOptionSelectedInvoice,
  formatAmount,
  getPageOptionsList,
  perPageData,
  getOpenKmAuthToken,
  formatAmountWithoutCurrency,
  perPageRecordData,
  formatCurrencyAmount,
  previewImgDocParty,
  disabledFutureDates,
  downloadExcel,
  convertDateFormat,
  splitAmountDecimal,
  getValuesetDataAsset,
  getFirstDateOfMonth,
  getLastDateOfMonth
};
