import store from '../../../../../../store/';
import CreateBranch from '../createBranch/';
export default {
  name: 'branch',
  components: {
    CreateBranch
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showBranch: '',
      showCreateBranch: false,
      partyId: null,
      branchList: [],
      tableFields: [
        {
          key: 'branch_name'
        },
        {
          key: 'short_name'
        },
        {
          key: 'ifsc_code',
          label: 'IFSC'
        },
        {
          key: 'address_name'
        },
        {
          key: 'active'
        },
        {
          key: 'bank_branch_id',
          class: 'd-none'
        },
       {
          key: 'address_id',
          class: 'd-none'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showCreateBranch = true;
          setTimeout(() => (this.showBranch = 'show'), 0);
        }
      }
    });
    this.partyId = store.state.party.partyId;
    this.getBankBranchList();
  },
  methods: {
    getBankBranchList() {
      this.loader = true;
      this.$store
        .dispatch('party/getBankBranchList', this.partyId)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.branchList = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    branchRowSelected(item) {
      this.showCreateBranch = true;
      setTimeout(() => {
        this.showBranch = 'show';
        this.eventBus.$emit('branchItem', item);
      }, 0);
    },
    getBranchList(response) {
      this.showAlert = true;
      this.isSuccess = true;
      this.responseMsg = response.data.message;
      this.branchList = response.data.data;
      this.showBranch = 'hide';
      this.showCreateBranch = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
