import http from '../../../app/services/httpServices'
import URL_UTILITY from '../../../app/utility/url.utility';

const getCityList = async (context, queryParams) => {
  const url = URL_UTILITY.getCitiesByStateIdUrl.replace('{stateId}', queryParams.stateId).
    replace('{page}', queryParams.page).replace('{limit}', queryParams.limit).replace('{city_name}', queryParams.search);
  const response = http.getApi(url);
  return response;
}

export default {
	getCityList
}