const authRequest = state => {
  state.status = 'loading';
};
const authSuccess = (state, token, user) => {
  state.status = 'success';
  state.token = token;
  state.user = user;
};
const loading = (state, isLoading) => {
  if (isLoading) {
    state.isLoading = true;
  }
  state.isLoading = false;
};
const setToken = (state, token) => {
  state.token = token;
};
const logout = state => {
  state.status = '';
  state.token = '';
  state.userDtl = ''; 
};
const authError = state => {
  state.status = 'error';
};
const setUserId = (state, userId) => {
  state.userId = userId;
};
const setUserName = (state, userName) => {
  state.userName = userName;
};
const setUserDtl = (state, userDtl) => {
  state.userDtl = userDtl;  
};

export default {
  authRequest,
  authSuccess,
  loading,
  setToken,
  logout,
  authError,
  setUserId,
  setUserName,
  setUserDtl
};
