import { ModelSelect } from 'vue-search-select';
import { required, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'indirectTax',
  components: {
    ModelSelect
  },
  data() {
    return {
      unsubscribe: null,
      editMode: false,
      loader: false,
      showTaxModal: false,
      // showAlert: false,
      // isSuccess: false,
      // responseMsg: '',
      showAlertAdd: false,
      isSuccessAdd: false,
      responseMsgAdd: '',
      partyId: null,
      partyIndirectTaxList: [],
      taxGroupList: [
        {
          value: null,
          text: null
        }
      ],
      taxForm: {
        selectedTaxGroup: {
          value: null,
          text: null
        },
        tax_num: null,
        issuing_authority: 'GST Tax Authority',
        party_indirect_tax_id: 0,
        active: false,
        gst: false
      },
      taxTableFields: [
        {
          key: 'tax_group'
        },
        {
          key: 'tax_group_id',
          class: 'd-none'
        },
        {
          key: 'issuing_authority'
        },
        {
          key: 'tax_num',
          label: 'GSTIN / TAN No.'
        },
        {
          key: 'party_indirect_tax_id',
          class: 'd-none'
        },
        {
          key: 'active'
        }
      ]
    };
  },
  validations: {
    taxForm: {
      selectedTaxGroup: {
        value: { required },
        text: { required }
      },
      tax_num: {
        required,
        minLength: minLength(15)
      },
      issuing_authority: { required }
    }
  },
  mounted() {
    this.eventBus.$off('indirectTaxDetils');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditTax();
        }
        if (actionName === 'add') {
          this.showTaxModal = true;
          this.editMode = true;
          this.taxForm = {
            selectedTaxGroup: {
              value: null,
              text: null
            },
            tax_num: null,
            issuing_authority: 'GST Tax Authority',
            active: true
          };
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    this.eventBus.$on('indirectTaxDetils', taxObj => {
      this.partyId = taxObj.partyId;
      this.loader = true;
      this.$store
        .dispatch('party/getPartyDetailsById', this.partyId)
        .then(response => {
          this.loader = false;
          this.partyIndirectTaxList = response.data.data.indirect_tax_details;
        });
    });
    this.getTaxGroup();
  },
  methods: {
    validateFunction() {
      if (this.taxForm.gst) {
        this.taxForm.tax_num = 'GSTNOTAVAILABLE';
      } else {
        this.taxForm.tax_num = null;
      }
    },
    addEditTax() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          partyId: this.partyId,
          indirectTaxPayload: {
            party_indirect_taxs: [
              {
                active: this.taxForm.active,
                issuing_authority: this.taxForm.issuing_authority,
                party_indirect_tax_id: this.taxForm.party_indirect_tax_id,
                tax_group_id: this.taxForm.selectedTaxGroup.value,
                tax_num: this.taxForm.tax_num
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/getIndirectTax', payload)
          .then(response => {
            this.loader = false;
            this.showAlertAdd = true;
            if (response.status === 200) {
              // this.showTaxModal = false;
              // this.showAlert = true;
              // this.loader = false;
              this.isSuccessAdd = true;
              this.showAlertAdd = true;
              this.partyIndirectTaxList = response.data.data.indirect_tax_details;
              this.responseMsgAdd = response.data.message;
            } else {
              // this.isSuccess = false;
              this.isSuccessAdd = false;
              this.showAlertAdd = true;
              this.responseMsgAdd = response.response.data.message;
            }
            // this.responseMsg = response.data.message;
          })
          .catch(error => {
            // this.showTaxModal = false;
            // this.showAlert = true;
            this.loader = false;
            // this.isSuccess = false;
            alert(error.message);
            // this.responseMsg = error.message;
          });
      }
    },
    getTaxGroup() {
      this.$store
        .dispatch('party/getTaxGroup', 'INDIRECTTAX')
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const salutationArr = result.map(type => {
              return {
                value: type.tax_group_id,
                text: type.tax_group
              };
            });
            this.taxGroupList = salutationArr;
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    rowSelected: function(item) {
      this.taxForm = {
        selectedTaxGroup: {
          value: item.tax_group_id,
          text: item.tax_group
        },
        tax_num: item.tax_num,
        issuing_authority: item.issuing_authority,
        party_indirect_tax_id: item.party_indirect_tax_id,
        active: item.active
      };
      this.showTaxModal = true;
    },
    resetModal() {}
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
