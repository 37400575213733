import Site from './site/';
import CustomerSiteBank from './customerSiteBank/';
import CustomerSiteContact from './customerSiteContact/';
import CustomerDirectTax from './customerDirectTax/';
import customerIndirectTax from './customerIndirectTax/';
import CustomerSiteAccount from './customerSiteAccount/';
import CustomerHistory from './customerHistory';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../store/';
import { ModelSelect } from 'vue-search-select';
export default {
  name: 'partyCustomer',
  components: {
    Site,
    CustomerSiteBank,
    CustomerSiteContact,
    CustomerDirectTax,
    customerIndirectTax,
    CustomerSiteAccount,
    ModelSelect,
    CustomerHistory
  },
  props: {
    isPartyCustomerEnable: Boolean,
    customerPartyId: Number
  },
  data() {
    return {
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      partyName: '',
      partyType: '',
      shortName: '',
      activeTab: 'customerSite',
      customerSiteList: [],
      showPartyCustomerDunningModal: false,
      customerDunningItems: [
        {
          customer_id: null,
          customer_name: null,
          active: false,
          dunning_enabled: false,
          dunning_criteria_vset: null,
          dunning_criteria_vset_meaning: null
        }
      ],
      customerDunningFields: [
        {
          key: 'customer_id'
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'dunning_enabled'
        },
        {
          key: 'dunning_criteria_vset_meaning',
          label: 'Dunning Criteria'
        }
      ],
      selectedIndex: null,
      showValueSetModal: false,
      customerId: null
    };
  },
  mounted() {
    this.eventBus.$on('partyCustomerData', customerData => {
      this.customerSiteList = customerData;
      this.selectedTab('customerSite');
    });
    this.partyName = store.state.party.partyName;
    this.partyType = store.state.party.partyType;
    this.shortName = store.state.party.shortName;
    this.customerId = store.state.party.customerId;
    if (this.isPartyCustomerEnable) {
      this.selectedTab('customerSite');
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.showPartyCustomerDunningModal) {
          this.editPartyCustomerDunningDetails();
        }
      }
    });
  },
  methods: {
    selectedTab(tab) {
      this.activeTab = tab;
      this.eventBus.$off('customerSiteBank');
      this.eventBus.$off('customerSiteContact');
      this.eventBus.$off('customerSiteAccount');
      this.eventBus.$off('directTax');
      this.eventBus.$off('indirectTax');
      this.$store.dispatch('party/setUnsetPartyCustomerTabValue', tab);
      setTimeout(() => {
        this.eventBus.$emit(tab, this.customerSiteList);
      }, 500);
    },
    closeTabs() {
      this.eventBus.$emit('closeCustomer', false);
    },
    showHidePartyCustomerDunningModal(flag) {
      this.showPartyCustomerDunningModal = flag;
      if (flag) {
        this.getPartyCustomerDunningDetails(this.customerPartyId);
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.selectedIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_CRITERIA) {
        this.customerDunningItems[
          this.selectedIndex
        ].dunning_criteria_vset_meaning = item.value_meaning;
        this.customerDunningItems[this.selectedIndex].dunning_criteria_vset =
          item.value_code;
      }
    },
    getPartyCustomerDunningDetails(partyId) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getPartyCustomerDunningDetails', partyId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.customerDunningItems = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    editPartyCustomerDunningDetails() {
      const dunningCriteria = this.customerDunningItems.find(
        elem => elem.dunning_criteria_vset === 'ALL'
      );
      if (dunningCriteria) {
        const filterDunningCriteria = this.customerDunningItems.filter(
          key => key.dunning_enabled && key.dunning_criteria_vset !== 'ALL'
        );
        if (filterDunningCriteria.length > 0) {
          alert('Please Check Dunning Criteria Value');
        } else {
          this.customerDunningDetails();
        }
      } else {
        this.customerDunningDetails();
      }
    },
    customerDunningDetails() {
      const dunningDetails = this.customerDunningItems.map(elem => {
        return {
          customer_id: elem.customer_id,
          dunning_enabled: elem.dunning_enabled,
          dunning_criteria_vset: elem.dunning_criteria_vset,
          active: elem.active
        };
      });
      const payload = {
        party_id: this.customerPartyId,
        customer_dunning_enabled_criteria: dunningDetails
          ? dunningDetails
          : null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/editPartyCustomerDunningDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
