import http from '../../../app/services/httpServices'
import URL_UTILITY from '../../../app/utility/url.utility';

const getResponsibilityList = (context, payload) => {
const url = URL_UTILITY.getResponsibility;
return http.getApi(url, payload);
}

const getRolesList = (context, payload) => {
const url = URL_UTILITY.getRoles;
return http.getApi(url, payload);
}

const getModulesList = (context, payload) => {
const url = URL_UTILITY.getModulesUrl;
return http.getApi(url, payload);
}

const getReportGroupList = (context, payload) => {
const url = URL_UTILITY.getReportGroupUrl;
return http.getApi(url, payload);
}

const getSecurityGroupList = (context, payload) => {
const url = URL_UTILITY.getSecurityGroupUrl;
return http.getApi(url, payload);
}

const addEditResponsibility = (context, payload) => {
const url = URL_UTILITY.addEditResponsibilityUrl;
return http.postApi(url, payload);
}

const getRespHeaderList = (context, responibilityHdrId) => {
	const url = URL_UTILITY.getRespHeaderListUrl.replace('{responibilityHdrId}', responibilityHdrId);
	return http.getApi(url);
}

export default {
	getResponsibilityList,
	getRolesList,
	getModulesList,
	getReportGroupList,
	getSecurityGroupList,
	addEditResponsibility,
	getRespHeaderList
}