import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getSOBList = (context, payload) => {
  return http.getApi(URL_UTILITY.getSOBUrl, payload);
};
const getCalenderLov = (context, payload) => {
  return http.getApi(URL_UTILITY.getSOBCalenderLov, payload);
};
const addEditSob = (context, payload) => {
  return http.postApi(URL_UTILITY.getSOBUrl, payload);
};
const getSobMappingList = (context, sobId) => {
  return http.getApi(URL_UTILITY.getSOBUrl + '/' + sobId + '/le-mapping');
};
const addEditSobMapping = (context, payload) => {
  return http.postApi(URL_UTILITY.getSOBUrl + '/le-mapping', payload);
};
const getSobLeMappingList = (context, sobLeMpngId) => {
  return http.getApi(URL_UTILITY.getSOBUrl + `/le-mapping/${sobLeMpngId}/entity-mapping`)
}
const addEditSobLeMappingList = (context, payload) => {
  return http.postApi(URL_UTILITY.getSOBUrl + `/le-mapping/${payload.le_sob_mpng_id}/entity-mapping`, payload)
}
export default {
  getSOBList,
  getCalenderLov,
  addEditSob,
  getSobMappingList,
  addEditSobMapping,
  getSobLeMappingList,
  addEditSobLeMappingList
};
