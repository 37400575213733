import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getProjectMasterList = (context, payload) => {
  const url = URL_UTILITY.getProjectMasterListUrl;
  return http.getApi(url, payload);
};

const getProjectUnitArea = (context, payload) => {
  const url = URL_UTILITY.getProjectUnitAreaUrl.replace('{prjUnitId}', payload.prjUnitId);
  return http.getApi(url);
};

const addEditProjectMaster = (context, payload) => {
  const url = URL_UTILITY.addEditProjectMasterUrl;
  return http.postApi(url, payload);
};

const getProjectSectorList = (context, payload) => {
  const url = URL_UTILITY.getProjectSectorListUrl;
  return http.getApi(url, payload);
};

const getProjectsUnitList = (context, payload) => {
  const url = URL_UTILITY.getProjectsUnitListUrl;
  return http.getApi(url, payload);
};

const addEditProjectSector = (context, payload) => {
  const url = URL_UTILITY.addEditProjectSectorUrl;
  return http.postApi(url, payload);
};

const getProjectPocketList = (context, payload) => {
  const url = URL_UTILITY.getProjectPocketListUrl;
  return http.getApi(url, payload);
};

const getProjectUnitSpec = (context, payload) => {
  const url = URL_UTILITY.getProjectUnitSpecUrl.replace('{prjUnitId}', payload.prjUnitId);
  return http.getApi(url);
};

const addEditProjectPocket = (context, payload) => {
  const url = URL_UTILITY.addEditProjectPocketUrl;
  return http.postApi(url, payload);
};
const getProjectsTowerList = (context, payload) => {
  const url = URL_UTILITY.getProjectsTowerListUrl;
  return http.getApi(url, payload);
};

const getProjectUnitOtherSpec = (context, payload) => {
    const url = URL_UTILITY.getProjectUnitOtherSpecUrl.replace('{prjUnitId}', payload.prjUnitId);
    return http.getApi(url);
}

const addEditProjectsTower = (context, payload) => {
    const url = URL_UTILITY.addEditProjectsTowerUrl;
    return http.postApi(url, payload);
}

const getProjectsFloorList = (context, payload) => {
  const url = URL_UTILITY.getProjectsFloorListUrl;
  return http.getApi(url, payload);
};

const addEditProjectsFloor = (context, payload) => {
  const url = URL_UTILITY.addEditProjectsFloorUrl;
  return http.postApi(url, payload);
};

const getProjectsProjectList = (context, payload) => {
  const url = URL_UTILITY.getProjectsProjectListUrl;
  return http.getApi(url, payload);
};

const addEditProjectsProject = (context, payload) => {
  const url = URL_UTILITY.addEditProjectsProjectUrl;
  return http.postApi(url, payload);
};

const getProjectsProjectArea = (context, payload) => {
  const url = URL_UTILITY.getProjectsProjectAreaUrl;
  return http.getApi(url, payload);
}

const addEditProjectsProjectArea = (context, payload) => {
  const url = URL_UTILITY.addEditProjectsProjectAreaUrl;
  return http.postApi(url, payload);
}

const getProjectsProjectOtherSpec = (context, payload) => {
  const url = URL_UTILITY.getProjectsProjectOtherSpecUrl;
  return http.getApi(url, payload);
}

const addEditProjectsProjectOtherSpec = (context, payload) => {
  const url = URL_UTILITY.addEditProjectsProjectOtherSpecUrl;
  return http.postApi(url, payload);
}

const addEditProjectUnit = (context, payload) => {
  const url = URL_UTILITY.addEditProjectUnitUrl;
  return http.postApi(url, payload);
};

const addEditProjectUnitArea = (context, payload) => {
  const url = URL_UTILITY.addEditProjectUnitAreaUrl;
  return http.postApi(url, payload);
}

const addEditProjectUnitSpec = (context, payload) => {
  const url = URL_UTILITY.addEditProjectUnitSpecUrl;
  return http.postApi(url, payload);
}

const addEditProjectUnitOtherSpec = (context, payload) => {
  const url = URL_UTILITY.addEditProjectUnitOtherSpecUrl;
  return http.postApi(url, payload);
}

export default {
    getProjectMasterList,
    addEditProjectMaster,
    getProjectSectorList,
    addEditProjectSector,
    getProjectPocketList,
    addEditProjectPocket,
    getProjectsProjectList,
    addEditProjectsProject,
    getProjectsProjectArea,
    addEditProjectsProjectArea,
    getProjectsProjectOtherSpec,
    addEditProjectsProjectOtherSpec,
    getProjectsTowerList,
    addEditProjectsTower,
    getProjectsFloorList,
    addEditProjectsFloor,
    getProjectsUnitList,
    getProjectUnitArea,
    getProjectUnitSpec,
    getProjectUnitOtherSpec,
    addEditProjectUnit,
    addEditProjectUnitArea,
    addEditProjectUnitSpec,
    addEditProjectUnitOtherSpec
}
