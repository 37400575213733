import commonHelper from '@/app/utility/common.helper.utility';
import store from '../../../../../../store';
export default {
  name: 'concatSegment',
  watch: {
    currentPage: function() {
      this.getCodeComb();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCodeComb();
    }
  },
  data() {
    return {
      loader: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      segment: '',
      leId: null,
      data: [],
      fields: [
        {
          key: 'hr_org_key_flexfield_comb_id',
          class: 'd-none'
        },
        {
          key: 'concatenated_segment',
          label: 'Concatenated Segment'
        },
        {
          key: 'concatenated_segment_meaning',
          label: 'Concatenated Segment Meaning'
        }
      ]
    };
  },
  mounted() {
    this.leId = store.state.shared.orgDetail.value;
    this.getCodeComb();
  },

  methods: {
    clearCodeComb() {
      this.segment = null;
    },
    getCodeComb() {
      const payload = {
        concatenated_segment: this.segment,
        le_id: this.leId,
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loader = true;
      this.$store.dispatch('hrms/getConcatSegment', payload).then(response => {
        if (response.status === 200) {
          this.loader = false;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        }
        else {
          this.loader = false;
        }
      });
    },
    resetModal() {},
    rowSelected(rowData) {
      this.$emit('concatSegDetails', rowData);
    }
  }
};
