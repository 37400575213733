import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getLOVBySetTypeWithValueSet = (context, payload) => {
  const url = URL_UTILITY.getLOVBySetTypeUrl.replace(
    '{valueSetType}',
    payload.lovType
  );

  const response = http.getApi(
    `${
      !payload.parent_value_set_id
        ? url
        : url + '?parent_value_set_id=' + payload.parent_value_set_id
    }`
  );
  return response;
};

const getDataFromLookupsByLookupType = (context, lookupType) => {
  const url = URL_UTILITY.getLookUpDataUrl.replace('{lookupType}', lookupType);
  const response = http.getApi(url);
  return response;
};

const getLOVBySetType = (context, valueSetType) => {
  const url = URL_UTILITY.getLOVBySetTypeUrl.replace(
    '{valueSetType}',
    valueSetType
  );
  return http.getApi(url);
};

const setUnsetOrgType = (context, orgType) => {
  context.commit('setOrganizationType', orgType);
};
const setRespId = (context, respId) => {
  context.commit('selectResponsibilityId', respId);
};

const setRoleMenuMpngId = (context, roleId) => {
  context.commit('setRoleMenuMpngIdState', roleId);
};
const setMenuId = (context, menuId) => {
  context.commit('setMenuId', menuId);
};
const setEmpId = (context, empId) => {
  context.commit('setEmpId', empId);
};

const setUnsetOrgTypeId = (context, orgDetail) => {
  context.commit('setOrgTypeId', orgDetail);
};

const getCommonVSetData = (context, payload) => {
  const url = URL_UTILITY.getCommonValueSetDataByCodeUrl.replace(
    '{valueSetName}',
    payload.valueSetName
  );

  const response = http.getApi(url, payload.dependentVariable);
  return response;
};

const setActionName = (context, actionName) => {
  context.commit('setActionName', actionName);
};
const setFlexFiledMaster = (context, flexfieldMaster) => {
  context.commit('setFlexFiledMaster', flexfieldMaster);
};

const setHomeIconClicked = context => {
  context.commit('homeIconClicked', 'clicked');
};
const setStandAloneSummary = context => {
  context.commit('setStandAloneSummary', true);
};
const setVendorData = (context, data) => {
  context.commit('setVendorData', data);
};
const getValueSetDetails = (context, vsetCode) => {
  return http.getApi(URL_UTILITY.getValuesetDetailsUrl + '/' + vsetCode);
};
const setDataForProjectEntity = (context, data) => {
  context.commit('setDataForProjectEntity', data);
};
const setDataForFmsUnit = (context, data) => {
  context.commit('setDataForFmsUnit', data);
};
const setDataForFmsSubunit = (context, data) => {
  context.commit('setDataForFmsSubunit', data);
};
const setDataForCrmProject = (context, data) => {
  context.commit('setDataForCrmProject', data);
};
const setDataForPrjUnit = (context, data) => {
  context.commit('setDataForPrjUnit', data);
};
const setDataForPrjProject = (context, data) => {
  context.commit('setDataForPrjProject', data);
};
const setDataForLeaseUnit = (context, data) => {
  context.commit('setDataForLeaseUnit', data);
};
const setUnsetSubUnitId = (context, subUnitId) => {
  context.commit('setUnsetSubUnitId', subUnitId);
};

const getDependentValueSetByParentValueset = (
  context,
  parent_value_set_hdr_id
) => {
  const url = URL_UTILITY.getDependentValueSetByParentValuesetUrl.replace(
    '{parentValueSetHdrId}',
    parent_value_set_hdr_id
  );
  return http.getApi(url);
};

const setModuleId = (context, moduleId) => {
  context.commit('setModuleId', moduleId);
};

const setAccessRights = (context, data) => {
  context.commit('updateAccessRights', data)
}
export default {
  getDataFromLookupsByLookupType,
  getLOVBySetType,
  setUnsetOrgType,
  setRespId,
  getLOVBySetTypeWithValueSet,
  setRoleMenuMpngId,
  setMenuId,
  getCommonVSetData,
  setEmpId,
  setActionName,
  setFlexFiledMaster,
  setHomeIconClicked,
  setStandAloneSummary,
  setUnsetOrgTypeId,
  setVendorData,
  getValueSetDetails,
  setDataForProjectEntity,
  setDataForFmsUnit,
  setDataForFmsSubunit,
  setDataForCrmProject,
  setDataForPrjUnit,
  setDataForPrjProject,
  setDataForLeaseUnit,
  setUnsetSubUnitId,
  getDependentValueSetByParentValueset,
  setModuleId,
  setAccessRights
};
