import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getSourceData = (context, payload) => {
  return http.getApi(URL_UTILITY.getAdminSourceUrl, payload);
};
const addEditSource = (context, payload) => {
  return http.postApi(URL_UTILITY.getAdminSourceUrl + 'Pos', payload);
}
export default {
    getSourceData,
    addEditSource
};
