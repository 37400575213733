import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'searchRequest',
  watch: {
    currentPage: function() {
      this.getRequestList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRequestList();
    }
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      name: '',
      data: [],
      fields: [
        {
          key: 'report_group_id',
          class: 'd-none'
        },
        {
          key:'report_group_name',
          class:'d-none'
        },
        {
          key: 'request_id',
          class: 'd-none'
        },
        {
          key: 'request_name',
          label: 'Request Name'
        },
        {
          key: 'request_type',
          label: 'Request Type'
        },
        {
          key: 'active',
          label:'Active'
          
        }
        
      ]
    };
  },
  mounted() {
    this.getRequestList();
  },
  methods: {
    rowSelected(item) {
      
     
      this.$emit('selectedSearchRequest', item);
    },
    clearRequest() {
      this.name = null;
    },
    getRequestList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        request_name:this.name
      };
      this.$store.dispatch('requestGroup/getRequestList', payload).then(response => {
        this.data = response.data.data.page;
        this.totalRows = response.data.data.total_elements;
      });
    }
  }
};

