import appStrings from '@/app/utility/string.utility';
export default {
  name: 'skipSeries',
  components: {},
  props: ['paymentSeriesDetails'],
  data() {
    return {
      showValueSetModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      paymentSeries: {
        value: null,
        text: null
      },
      paperStock: {
        value: null,
        text: null
      },
      paymentMode: {
        value: null,
        text: null
      },
      paymentFormat: {
        value: null,
        text: null
      },
      firstSeries: {
        value: null,
        text: null
      },
      lastSeries: {
        value: null,
        text: null
      },
      lastIssued: {
        value: null,
        text: null
      },
      status: false,
      skipSeriesData: [
        {
          payment_series_id: null,
          payment_skip_series_id: 0,
          skip_series_code: null,
          skip_type_meaning: null,
          skip_series_number: null
        }
      ],
      skipSeriesFields: [
        {
          key: 'skip_type_meaning',
          label: 'Skip Type'
        },
        {
          key: 'skip_series_number'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.fillRecordsForPaymentSeries();
    this.getSkipSeriesDetails(this.paymentSeriesDetails.payment_series_id);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addEditSkipSeriesList(
            this.paymentSeriesDetails.payment_series_id
          );
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    fillRecordsForPaymentSeries() {
      this.paymentSeries.text = this.paymentSeriesDetails.payment_series_name;
      this.paymentSeries.value = this.paymentSeriesDetails.payment_series_id;
      this.paperStock.text = this.paymentSeriesDetails.paper_stock_meaning;
      this.paperStock.value = this.paymentSeriesDetails.paper_stock_code;
      this.paymentMode.text = this.paymentSeriesDetails.payment_mode_meaning;
      this.paymentMode.value = this.paymentSeriesDetails.payment_mode;
      this.paymentFormat.text = this.paymentSeriesDetails.payment_format;
      this.firstSeries.text = this.paymentSeriesDetails.first_series_number;
      this.lastSeries.text = this.paymentSeriesDetails.last_series_number;
      this.lastIssued.text = this.paymentSeriesDetails.last_issued_number;
      this.status = this.paymentSeriesDetails.status;
    },
    getSkipSeriesDetails(paymentSeriesId) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getSkipSeriesList', paymentSeriesId)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length > 0) {
            this.skipSeriesData = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditSkipSeriesList(paymentSeriesId) {
      const skipSeries = this.skipSeriesData.map(elem => {
        return {
          payment_skip_series_id: elem.payment_skip_series_id,
          skip_series_code: elem.skip_series_code,
          skip_series_number: elem.skip_series_number
        };
      });
      const payload = {
        payment_series_id: paymentSeriesId,
        payment_skip_series: skipSeries ? skipSeries : null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/addEditSkipSeriesList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.skipSeriesData.push({
          payment_series_id: null,
          payment_skip_series_id: 0,
          skip_series_code: null,
          skip_type_meaning: null,
          skip_series_number: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.skipSeriesData.splice(index, 1);
      }
    },
    validateSkipNumber(rowDetails) {
      if (rowDetails.skip_series_number > this.lastSeries.text) {
        rowDetails.skip_series_number = null;
        return alert(
          'Skip Series Number Should be less than Last Series Number'
        );
      }
      if (rowDetails.skip_series_number < this.firstSeries.text) {
        rowDetails.skip_series_number = null;
        return alert(
          'Skip Series Number Should be greater than First Series Number'
        );
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'PAYMENT_SKIP_TYPE') {
        this.skipSeriesData[this.selectedIndex].skip_type_meaning =
          item.value_meaning;
        this.skipSeriesData[this.selectedIndex].skip_series_code =
          item.value_code;
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.selectedIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
