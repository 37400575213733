import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getDocSequenceList = (context, payload) => {
  const url = URL_UTILITY.getDocSequenceGridView;
  return http.getApi(url, payload);
};

const addDocSequence = (context, payload) => {
  const url = URL_UTILITY.addEditDocSequenceUrl;
  return http.postApi(url, payload.docSeqData);
};

const getSubModuleLov = (context, module_id) => {
  const url = URL_UTILITY.getSubModuleLovUrl;
  return http.getApi(url, module_id);
}

const getDocNumberingGrid =(context, payload) => {
	const url = URL_UTILITY.getDocNumberingGridUrl;
	return http.getApi(url, payload);
}

const getDocNumberingDetails = (context, docClassId) => {
	const url = URL_UTILITY.getDocNumberingDetailsUrl.replace('{docClassId}', docClassId);
	return http.getApi(url);
}

const addEditDocumentNumbering = (context, payload) => {
	const url = URL_UTILITY.addEditDocumentNumberingUrl;
	return http.postApi(url, payload);
}

const getMapSequenceDetails = (context, doc_seq_cat_id) => {
	const url = URL_UTILITY.getMapSequenceDetailsUrl.replace('{docCatId}', doc_seq_cat_id);
	return http.getApi(url);
}
  
const addEditMapSequence = (context, payload) => {
	const url = URL_UTILITY.addEditMapSequenceUrl;
	return http.postApi(url, payload);
}
  
export default {
	getDocSequenceList,
	addDocSequence,
	getSubModuleLov,
	getDocNumberingGrid,
	getDocNumberingDetails,
	addEditDocumentNumbering,
	getMapSequenceDetails,
	addEditMapSequence
}
