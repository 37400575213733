import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getTaxCategoryList = (context, payload) => {
  const url = URL_UTILITY.getTaxCategoryListUrl;
  return http.getApi(url, payload);
}

const getTaxCategoryDetails = (context, tax_cat_hdr_id) => {
  const url = URL_UTILITY.getTaxCategoryDetailsUrl.replace('{taxCatHdrId}', tax_cat_hdr_id);
  return http.getApi(url);
}

const addEditTaxCategory = (context, payload) => {
  const url = URL_UTILITY.addEditTaxCategoryUrl;
  return http.postApi(url, payload);
}
export default {
  getTaxCategoryList,
  getTaxCategoryDetails,
  addEditTaxCategory
};
