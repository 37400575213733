import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getRequestList = (context, payload) => {
  const url = URL_UTILITY.getRequestUrl;
  return http.getApi(url, payload);
};

const getRequestByUser = (context, payload) => {
  const url = URL_UTILITY.getRequestByUserUrl;
  return http.getApi(url, payload);
};
const addrequestList = (context, payload) => {
  const url = URL_UTILITY.postRequestUrl;
  return http.postApi(url,payload);
};
const getLOVBySetType = (context, setType) => {
  const url = URL_UTILITY.getLOVBySetTypeUrl.replace('{valueSetType}', setType);
  const response = http.getApi(url);
  return response;
};
const getOrganizationLov = (context, payload) => {
  const url = URL_UTILITY.getOrgannizationLovUrl.replace('{lovType}', 'LE');
  const response = http.getApi(url + '?responsibility_id='+ payload.responsibility_id);
  return response;
};
export default {
  getRequestList,
  addrequestList,
  getLOVBySetType,
  getRequestByUser,
  getOrganizationLov
};