import { required, alpha } from 'vuelidate/lib/validators';
import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import store from '../../../../store/';
export default {
  name: 'Currency',
  props: {
    showModalState: Boolean,
    formState: String
  },
  components: {
    ModelSelect
  },
  watch: {
    currentPage: function() {
      this.getCurrencyPagination();
    },
    search: function() {
      this.currentPage = 1;
      if (this.search && this.search.length >= 3) {
        this.getCurrencyPagination();
      }
      if (this.search.length === 0) {
        this.getCurrencyPagination();
      }
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCurrencyPagination();
    }
  },
  data() {
    return {
      loader: false,
      unsubscribe: null,
      search: '',
      data: [],
      currencyId: '',
      currencyForm: {
        currency_code: '',
        currency_name: ''
      },
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      stickyHeader: true,
      totalRows: null,
      filterOn: [],
      currencyData: [],
      currencyNameError: '',
      currencyCodeError: '',
      showModal: false,
      editMode: false,
      editCountry: false,
      update: false,
      responseMsg: '',
      showAlert: false,
      isSuccess: false,
      isFailed: false,
      fields: [
        {
          key: 'currency_code',
          sortable: true
        },
        {
          key: 'currency_name',
          sortable: true
        }
      ]
    };
  },
  validations: {
    currencyForm: {
      currency_code: {
        required,
        alpha
      },
      currency_name: {
        required
      }
    }
  },
  mounted() {
    this.getCurrencyPagination();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save') {
          this.updateCurrency();
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name', callback)
           */
          this.downloadExcel.downloadData(
            this.payload,
            'currency/getCurrencyPagination',
            'currency',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    async getCurrencyPagination() {
      try {
        this.payload = {
          currentPage: this.currentPage - 1,
          perPage: this.perPage,
          search: this.search
        };
        const response = await this.$store.dispatch(
          'currency/getCurrencyPagination',
          this.payload
        );
        const results = response.data.data.page;
        this.data = response.data.data.page;
        this.totalRows = response.data.data.total_elements;
        const currency = results.map(type => {
          return {
            value: type.currency_id,
            text: type.currency
          };
        });
        this.currencyData = currency;
      } catch (err) {
        this.err = err;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.pageOptions[5].value = filteredItems.length;
    },
    resetModal() {
      this.$emit('clicked', 'view');
      this.editMode = false;
      this.update = false;
      this.showAlert = false;
      this.currencyForm = {
        currency_code: '',
        currency_name: ''
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    rowSelected: function(item) {
      this.showAlert = false;
      if (store.state.party.partyId || this.showModalState) {
        this.eventBus.$emit('getCurrency', {
          currency_id: item.currency_id,
          currency: item.currency_name,
          currency_code: item.currency_code
        });
        this.$emit('getCurrency', item);
      } else {
        this.update = true;
        this.currencyId = item.currency_id;
        this.currencyForm = {
          currency_code: item.currency_code,
          currency_name: item.currency_name
        };
      }
    },
    updateCurrency: function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit('clicked', 'edit');
        this.update = false;
        return;
      } else {
        const payload = {
          currencyId: this.currencyId,
          currencyData: {
            currency_code: this.currencyForm.currency_code,
            currency_name: this.currencyForm.currency_name
          }
        };
        if (this.update) {
          this.$store
            .dispatch('currency/updateCurrencyData', payload)
            .then(response => {
              if (response && response.status === 200) {
                this.showAlert = true;
                this.isSuccess = true;
                this.editMode = false;
                this.update = false;
                this.responseMsg = appStrings.currencySuccessMsg;
              } else {
                this.isSuccess = false;
                this.responseMsg = appStrings.autoFailedMsg;
              }
            })
            .catch(err => {
              this.err = err;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        } else {
          this.$store
            .dispatch('currency/addCurrencyData', payload)
            .then(response => {
              if (response && response.status === 201) {
                this.showAlert = true;
                this.isSuccess = true;
                this.editMode = false;
                this.update = false;
                this.responseMsg = appStrings.currencyAddedMsg;
              } else {
                this.isSuccess = false;
                this.responseMsg = appStrings.autoFailedMsg;
              }
            })
            .catch(err => {
              this.err = err;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      }
    }
  },
  destryoed() {
    this.$store.dispatch('party/setUnsetPartyId', null);
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
