import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import { format } from 'date-fns';
import store from '../../../../../store';
import appStrings from '@/app/utility/string.utility';

export default {
  name: 'legalEntityMapping',
  components: {
    DatePicker,
    moment
  },
  props: ['flexfieldRowData'],
  data() {
    return {
      unsubscribe: null,
      editMode: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: null,
      showValueSetModal: false,
      loader: false,
      mappingTableIndex: 0,
      legalEntityMappingData: [
        {
          created_by: this.getUserName(),
          le_id: null,
          le_name: null,
          creation_date: format(new Date(), 'dd-MMM-yyyy'),
          le_flex_mpng_id: 0
        }
      ],
      legalEntityMappingFields: [
        {
          key: 'created_by',
          label: 'Created By'
        },
        {
          key: 'le_id',
          class: 'd-none'
        },
        {
          key: 'le_name',
          label: 'Le Name'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'le_flex_mpng_id',
          class: 'd-none'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    if (this.flexfieldRowData) {
      this.getFlexfieldLegalEntityMpngData();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditFlexFieldLegalEntityMpng();
        }
      }
    });
  },
  methods: {
    getUserName() {
      const tempName = store.state.auth.userName;
      const name = tempName.slice(tempName.lastIndexOf('-') + 1);
      return name;
    },
    addEditFlexFieldLegalEntityMpng() {
      const leFlexFieldMpngData = this.legalEntityMappingData.filter(
        key => key.le_id !== null
      );
      if (leFlexFieldMpngData.length === 0) {
        return alert('Please Map Legal Entity');
      }
      const details = leFlexFieldMpngData.map(key => {
        return {
          le_flex_mpng_id: key.le_flex_mpng_id,
          le_id: key.le_id
        };
      });
      const payload = {
        flexfieldId: this.flexfieldRowData.flexfield_id,
        leFlexfieldMpngData: { flex_le_mpng_details: details ? details : null }
      };
      this.loader = true;
      this.$store
        .dispatch('flexfield/addEditFlexfieldLegalEntityMpng', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.legalEntityMappingData = response.data.data;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getFlexfieldLegalEntityMpngData() {
      const flexfieldId = this.flexfieldRowData.flexfield_id;
      this.loader = true;
      this.$store
        .dispatch('flexfield/getFlexfieldLegalEntityMpng', flexfieldId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if(response.data.data.length > 0){
              this.legalEntityMappingData = response.data.data;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      this.legalEntityMappingData[this.mappingTableIndex].le_id =
        item.value_set_dtl_id;
      this.legalEntityMappingData[this.mappingTableIndex].le_name =
        item.value_code;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.mappingTableIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.legalEntityMappingData.push({
          created_by: this.getUserName(),
          le_id: null,
          le_name: null,
          creation_date: format(new Date(), 'dd-MMM-yyyy'),
          le_flex_mpng_id: 0
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.legalEntityMappingData.splice(index, 1);
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
