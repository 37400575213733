import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getProfileOptionGridData = (context, payload) => {
  const url = URL_UTILITY.getProfileOptionGridDataUrl;
  return http.getApi(url, payload);
};

const getProfileOptionLov = (context, payload) => {
  const url = URL_UTILITY.getProfileOptionLovUrl;
  return http.getApi(url, payload);
};

const getLevelLov = (context, payload) => {
  const url = URL_UTILITY.getLevelLovUrl;
  return http.getApi(url, payload);
};

const getProfileOptionById = (context, profileOptionHdrId) => {
  const url = URL_UTILITY.getProfileOptionByIdUrl.replace('{profileOptionHdrId}', profileOptionHdrId);
  return http.getApi(url)
}

const addEditProfileOptionHdr = (context, payload) => {
  const url = URL_UTILITY.addEditProfileOptionHdrUrl+'/hdr/details';
  return http.postApi(url, payload);
}

const addEditProfileOptionDtl = (context, payload) => {
  const url = URL_UTILITY.addEditProfileOptionDtlUrl;
  return http.postApi(url, payload);
}
export default {
  getProfileOptionGridData,
  getProfileOptionLov,
  getLevelLov,
  getProfileOptionById,
  addEditProfileOptionHdr,
  addEditProfileOptionDtl
};
