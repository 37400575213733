import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getFlexfieldList = (context, payload) => {
  const url = URL_UTILITY.getFlexfieldUrl;
  return http.getApi(url, payload);
};

const getFlexfieldHeaderList = (context, flexfieldId) => {
  const url = URL_UTILITY.getFlexfieldHeaderListUrl.replace(
    '{flexfieldId}',
    flexfieldId
  );
  return http.getApi(url);
};

const getFlexfieldModuleLovList = async () => {
  const url = URL_UTILITY.getFlexfieldModuleLovUrl;
  const response = http.getApi(url);
  return response;
};

const getFlexfieldMasterLovList = (context, payload) => {
  const url = URL_UTILITY.getFlexfieldMasterLovUrl;
  return http.getApi(url + '?module_id=' + payload);
};

const getFlexfieldSegmentLovList = (context, payload) => {
  const url = URL_UTILITY.getFlexfieldSegmentLovUrl;
  return http.getApi(url + '?flexField_master_id=' + payload);
};

const getFlexfieldValueSetLovList = (context, payload) => {
  const url = URL_UTILITY.getFlexfieldValueSetLovUrl;
  return http.getApi(url, payload);
};

const addEditFlexfield = (context, payload) => {
  const url = URL_UTILITY.addEditFlexfieldUrl;
  return http.postApi(url, payload);
  }

  const getFlexfieldLegalEntityMpng = (context, flexfieldId) => {
    const url = URL_UTILITY.getFlexfieldLegalEntityMpngUrl.replace('{flexfieldId}', flexfieldId);
    return http.getApi(url);
  }

  
  const addEditFlexfieldLegalEntityMpng = (context, payload) => {
    const url = URL_UTILITY.getFlexfieldLegalEntityMpngUrl.replace('{flexfieldId}', payload.flexfieldId);
    return http.postApi(url, payload.leFlexfieldMpngData);
  }
export default {
  getFlexfieldList,
  getFlexfieldHeaderList,
  getFlexfieldModuleLovList,
  getFlexfieldMasterLovList,
  getFlexfieldSegmentLovList,
  getFlexfieldValueSetLovList,
  addEditFlexfield,
  getFlexfieldLegalEntityMpng,
  addEditFlexfieldLegalEntityMpng
};
