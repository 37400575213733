import store from '../../../../../../store';
import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import { format } from 'date-fns';
export default {
  name: 'relationship',
  components: {
    DatePicker,
    ModelSelect
  },
  data() {
    return {
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      unsubscribe: null,
      editMode: false,
      loader: false,
      relationList: [],
      salutationList: [],
      relationshipList: [
        {
          employee_id: null,
          dob: null,
          salutation_lookup: null,
          salutation: null,
          name: null,
          email_address: null,
          employee_relation_id: null,
          relation_lookup: null,
          relation: null,
          mobile_no: null,
          landline: null,
          emergency: false,
          selectedRelation: {
            value: null,
            text: null
          },
          selectedSalutation: {
            value: null,
            text: null
          }
        }
      ],
      relationshipFields: [
        {
          key: 'relation'
        },
        {
          key: 'salutation'
        },
        {
          key: 'name'
        },
        {
          key: 'dob',
          label: 'D.O.B'
        },
        {
          key: 'mobile_no',
          label: 'Mobile No.'
        },
        {
          key: 'landline'
        },
        {
          key: 'email_address'
        },
        {
          key: 'emergency'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.getLookupData(appStrings.LOOKUPTYPE.SALUTATION);
    this.getLookupData(appStrings.LOOKUPTYPE.RELATION);
    this.getRelationshipList(store.state.shared.empId);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const action = state.shared.actionName;
        if (action === 'save') {
          this.addEditRelationship();
        } else if (action === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditRelationship() {
      const relaDetail = this.relationshipList.map(relation => {
        return {
          dob: relation.dob
            ? format(new Date(relation.dob), 'dd-MMM-yyyy')
            : null,
          email_address: relation.email_address,
          emergency: relation.emergency,
          employee_relation_id: relation.employee_relation_id,
          landline: relation.landline,
          mobile_no: relation.mobile_no,
          name: relation.name,
          relation_lookup: relation.selectedRelation.value,
          salutation_lookup: relation.selectedSalutation.value
        };
      });
      const payload = {
        employee_id: store.state.shared.empId,
        relationship_details: relaDetail
      };
      this.loader = true;
      this.$store
        .dispatch('hrms/addEditRelationship', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
            const resultData = response.data.data.map(relation => {
              relation.selectedRelation = {
                value: relation.relation_lookup,
                text: relation.relation
              };
              relation.selectedSalutation = {
                value: relation.salutation_lookup,
                text: relation.salutation
              };
              return relation;
            });
            this.relationshipList = resultData;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRelationshipList(empId) {
      this.loader = true;
      this.$store
        .dispatch('hrms/getRelationshipList', empId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length > 0) {
            const resultData = resp.data.data.map(relation => {
              relation.selectedRelation = {
                value: relation.relation_lookup,
                text: relation.relation
              };
              relation.selectedSalutation = {
                value: relation.salutation_lookup,
                text: relation.salutation
              };
              return relation;
            });
            this.relationshipList = resultData;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLookupData(lookupCode) {
      this.$store
        .dispatch('shared/getDataFromLookupsByLookupType', lookupCode)
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const salutationArr = result.map(type => {
              return {
                value: type.lookup_code,
                text: type.meaning
              };
            });
            if (lookupCode === appStrings.LOOKUPTYPE.SALUTATION) {
              this.salutationList = salutationArr;
            } else if (lookupCode === appStrings.LOOKUPTYPE.RELATION) {
              this.relationList = salutationArr;
            }
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    rowSelected() {},
    addNewRow() {
      if (this.editMode) {
        this.relationshipList.push({
          employee_id: null,
          dob: null,
          salutation_lookup: null,
          salutation: null,
          name: null,
          email_address: null,
          employee_relation_id: null,
          relation_lookup: null,
          relation: null,
          mobile_no: null,
          landline: null,
          emergency: false,
          selectedRelation: {
            value: null,
            text: null
          },
          selectedSalutation: {
            value: null,
            text: null
          }
        });
      }
    },
    removeRow(index) {
      this.relationshipList.splice(index, 1);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
