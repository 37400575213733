import { ModelSelect, BasicSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'uploadExcelDetails',
  watch: {
    currentPage: function() {
      if (this.$route.name === 'LocDash') {
          this.getAssetExcelData();
      } else if(this.$route.name === 'uploadProvisions'){
          this.getDataForMappingColumnsForProvision();
      } else if (this.$route.name === 'partyOnBoard') {
        this.getVendorUploadExcelData();
      }  else{
        this.getDataForMappingColumns();
      }
    },
    perPage: function() {
      this.currentPage = 1;
      if (this.$route.name === 'LocDash') {
        this.getAssetExcelData();
    } else if(this.$route.name === 'uploadProvisions'){
      this.getDataForMappingColumnsForProvision();
    }   else if (this.$route.name === 'partyOnBoard') {
        this.getVendorUploadExcelData();
    } else{
      this.getDataForMappingColumns();
    }
    }
  },
  components: { ModelSelect, BasicSelect },
  props: ['excelRowDtl', 'recordId'],
  data() {
    return {
      loader: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      colMapIndex: 0,
      startRow: 1,
      endRow: null,
      uploadExcelData: [],
      uploadExcelFields: [],
      mappingFieldsList: [],
      showMapButton: true,
      isBusy: true,
      excelImportHdrId: null,
    };
  },
  mounted() {
    if (this.$route.name === 'LocDash') {
      this.excelImportHdrId = this.excelRowDtl.excel_improt_hdr_id;
      if (this.excelImportHdrId) {
        this.getAssetExcelData();
      }
    } else if(this.$route.name === 'uploadProvisions'){
      this.getDataForMappingColumnsForProvision();
    } 
    else if (this.$route.name === 'partyOnBoard') {
      this.excelImportHdrId = this.excelRowDtl.excel_improt_hdr_id;
      if (this.excelImportHdrId) {
        this.getVendorUploadExcelData();
      }
    }
    else {
      this.getMappingColumns();
      this.isRecordIdAvailable();
      this.getDataForMappingColumns();
    }
  },
  methods: {
    getMappingColumns() {
      const payload = {
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: 0
      };
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/getMappingColumnsForExcelDetail', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const excelDetailsColumn = response.data.data;
            this.mappingFieldsList = excelDetailsColumn.map(item => {
              return {
                value: 'column' + item.column_id,
                text: item.display_name
              };
            });
            this.uploadExcelFields = excelDetailsColumn.map((key, index) => {
              return {
                key: 'column' + (index + 1),
                label: key.display_name
              };
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getAssetExcelData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        excel_import_hdr_id: this.excelImportHdrId,
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: 0
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetExcelData', payload)
        .then(response => {
          this.loader = false;
          this.isBusy = false;
          if (response.status === 200) {
            this.totalRows = response.data.data.total_elements;
            this.uploadExcelData = response.data.data.page;
            this.endRow = this.totalRows;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getDataForMappingColumnsForProvision() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        excel_import_hdr_id: this.excelRowDtl.excel_improt_hdr_id,
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: 0
      };
      this.loader = true;
      this.$store
        .dispatch(
          'twoSixASUpload/getMappingColumnsDataListForExcelDetail',
          payload
        )
        .then(response => {
          this.loader = false;
          this.isBusy = false;
          if (response.status === 200) {
            this.totalRows = response.data.data.total_elements;
            this.uploadExcelData = response.data.data.page;
            const loopLength = this.uploadExcelData.length > 5 ? 5 : this.uploadExcelData.length
            const tempArr = [];
            for (let i = 0; i < loopLength; i++) {
              tempArr.push(Object.keys(this.uploadExcelData[i]));
            }
            const largestArray = tempArr.reduce((maxArray, currentArray) => 
              currentArray.length > maxArray.length ? currentArray : maxArray
            , []);
            this.uploadExcelFields = largestArray;
            this.endRow = this.totalRows;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getDataForMappingColumns() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        excel_import_hdr_id: this.excelRowDtl.excel_improt_hdr_id,
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: 0
      };
      this.loader = true;
      this.$store
        .dispatch(
          'twoSixASUpload/getMappingColumnsDataListForExcelDetail',
          payload
        )
        .then(response => {
          this.loader = false;
          this.isBusy = false;
          if (response.status === 200) {
            this.totalRows = response.data.data.total_elements;
            this.uploadExcelData = response.data.data.page;
            this.endRow = this.totalRows;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    changedColumn(item, index) {
      this.colMapIndex = Number(item.slice(item.indexOf('n') + 1));
      this.uploadExcelFields[this.colMapIndex - 1].key = 'column' + (index + 1);
    },
    mapRowTable() {
      const details = this.uploadExcelFields.map(item => {
        return {
          columnNum: this.findColSequence(item.key),
          excel_imp_mpng_column_id: this.findMappingColId(item.key)
        };
      });
      const payload = {
        end_row: this.endRow,
        excel_import_hdr_id: this.excelRowDtl.excel_improt_hdr_id,
        excel_import_sheet_id: this.excelRowDtl.excel_import_sheet_id,
        start_row: this.startRow,
        mapping_details: details
      };
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/postMappedColumnsForExcel', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.noOfMappedColumns = response.data.data.mapped_column;
            this.$emit('closeExcelDetail', this.noOfMappedColumns);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    findColSequence(name) {
      return name.slice(name.indexOf('n') + 1);
    },
    findMappingColId(name) {
      return Number(name.slice(name.indexOf('n') + 1));
    },
    closeTable() {
      this.$emit('closeExcelDetail');
    },
    isRecordIdAvailable() {
      this.showMapButton = this.recordId ? false : true;
      return this.showMapButton;
    },
    downloadAssetExcel(){
      this.loader = true;
      const downloadpayload = { 
        excel_import_hdr_id: this.excelRowDtl.excel_improt_hdr_id,
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: 0
      };
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        'assets/getAssetExcelData',
        'asset-excel',
        () => (this.loader = false)
      );
    },
    getVendorUploadExcelData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        excel_import_hdr_id: this.excelImportHdrId,
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: 0
      };
      this.loader = true;
      this.$store
        .dispatch('party/getVendorUploadExcelData', payload)
        .then(response => {
          this.loader = false;
          this.isBusy = false;
          if (response.status === 200) {
            this.totalRows = response.data.data.total_elements;
            this.uploadExcelData = response.data.data.page;
            this.endRow = this.totalRows;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
  beforeDestroy() {
    // this.unsubscribe();
  }
}