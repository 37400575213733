import appStrings from '@/app/utility/string.utility';
import { BasicSelect, ModelSelect } from 'vue-search-select';
import { required, minLength } from 'vuelidate/lib/validators';
import moment from 'moment';
import { format } from 'date-fns';
import DatePicker from 'vue2-datepicker';
import commonHelper from '../../../../utility/mixin';
export default {
  name: 'CreateParty',
  mixins: [commonHelper], 
  components: {
    BasicSelect,
    ModelSelect,
    DatePicker
  },
  props: {
    showParty: String
  },
  watch: {
    showParty: function(showPartyForm) {
      this.showCreateParty = showPartyForm === 'show' ? true : false;
    }
  },
  data() {
    return {
      unsubscribe: null,
      // personNameRequired: false,
      isDobValid: true,
      showCreateParty: false,
      showEmployeeDropdown: false,
      partyTypeDataResponse: null,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showModal: true,
      lovType: appStrings.VALUESETTYPE.PARTY_TYPE,
      loader: false,
      employeeList: [],
      createPartyForm: {
        selectedParty: {
          value: null,
          text: null
        },
        selectedCateory: {
          value: null,
          text: null
        },
        shortName: '',
        panNo: '',
        firstName: '',
        middleName: '',
        lastName: '',
        partyName: '',
        aadharNo: '',
        employee: {
          name: null,
          id: null
        },
        dob: '',
        doi: '',
        nri: false,
        cinNo: '',
        selectedSalutation: {
          value: null,
          text: null
        }
      },
      patyTypeArr: [
        {
          value: null,
          text: null
        }
      ],
      partyCategory: [
        {
          value: null,
          text: null
        }
      ],

      salutationArr: [
        {
          value: null,
          text: null
        }
      ]
    };
  },
  validations: {
    createPartyForm: {
      selectedParty: {
        value: { required },
        text: {
          required
        }
      },
      selectedCateory: {
        value: { required },
        text: {
          required
        }
      },
      panNo: {
        required,
        minLength: minLength(10)
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.showCreateParty) {
          this.createParty();
        }

        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', {
            id: this.createPartyForm.employee.id
          });
        }
      }
    });
  },
  methods: {
    createParty() {
      this.$v.$touch();
      // && !this.personNameRequired-----if invalid condition
      if (!this.$v.$invalid) {
        if (
          this.createPartyForm.selectedCateory.value === 'COMP' &&
          this.createPartyForm.cinNo === ''
        ) {
          alert('Please fill the cin no');
        } else {
          const createPartyPayload = {
            party_type_vset: this.createPartyForm.selectedParty.value,
            party_cat_vset: this.createPartyForm.selectedCateory.value,
            party_short_name: this.createPartyForm.shortName
              ? this.createPartyForm.shortName
              : null,
            pan_no: this.createPartyForm.panNo
              ? this.createPartyForm.panNo
              : null,
            salutation_code: this.createPartyForm.selectedSalutation.value,
            first_name: this.createPartyForm.firstName
              ? this.createPartyForm.firstName
              : null,
            middle_name: this.createPartyForm.middleName
              ? this.createPartyForm.middleName
              : null,
            last_name: this.createPartyForm.lastName
              ? this.createPartyForm.lastName
              : null,
            party_name: this.createPartyForm.partyName
              ? this.createPartyForm.partyName
              : null,
            date_of_birth: this.createPartyForm.dob
              ? moment(this.createPartyForm.dob).format(appStrings.DATEFORMAT)
              : null,
            date_of_incorporation: this.createPartyForm.doi
              ? format(
                  new Date(this.createPartyForm.doi),
                  appStrings.DATEFNSFORMAT
                )
              : null,
            aadhar_no: this.createPartyForm.aadharNo
              ? this.createPartyForm.aadharNo
              : null,
            cin_no: this.createPartyForm.cinNo
              ? this.createPartyForm.cinNo
              : null,
            active: true,
            is_nri: this.createPartyForm.nri,
            employee_id: this.createPartyForm.employee.id
          };
          this.showAlert = false;
          this.isSuccess = false;
          this.$store
            .dispatch('party/createParty', createPartyPayload)
            .then(response => {
              this.showAlert = true;
              if (response.status === 201) {
                this.isSuccess = true;
                this.responseMsg = response.data.message;
              } else {
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(error => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = error.message;
            });
        }
      } else {
        if (this.createPartyForm.firstName === '') {
          this.personNameRequired = true;
        }
      }
    },
    getLOVBySetType() {
      this.loader = true;
      const findPartyObj =
        this.partyTypeDataResponse &&
        this.partyTypeDataResponse.find(
          partyObj =>
            partyObj.value_code === this.createPartyForm.selectedParty.value
        );
      const payload = {
        lovType: this.lovType,
        parent_value_set_id: findPartyObj ? findPartyObj.value_set_dtl_id : null
      };
      this.$store
        .dispatch('party/getLOVBySetType', payload)
        .then(response => {
          this.loader = false;
          if (response.data.data) {
            const result = response.data.data;
            const partyTypeData = result.map(type => {
              if (this.lovType === appStrings.VALUESETTYPE.PARTY_TYPE) {
                return {
                  value: type.value_code,
                  text: type.value_meaning
                };
              } else if (this.lovType === appStrings.VALUESETTYPE.PARTY_CAT) {
                return {
                  value: type.value_code,
                  text: type.value_meaning
                };
              }
            });
            if (this.lovType === appStrings.VALUESETTYPE.PARTY_TYPE) {
              this.partyTypeDataResponse = response.data.data;
              this.patyTypeArr = partyTypeData;
            } else if (this.lovType === appStrings.VALUESETTYPE.PARTY_CAT) {
              this.partyCategory = partyTypeData;
              this.createPartyForm.selectedCateory =
                partyTypeData && partyTypeData.length === 1
                  ? partyTypeData[0]
                  : { value: null, text: null };
            }
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    selectedPartyTypeFun(val) {
      this.lovType = appStrings.VALUESETTYPE.PARTY_CAT;
      this.createPartyForm.selectedParty = val;
      this.getLOVBySetType();
    },
    resetModal() {},
    getSalutation() {
      this.$store
        .dispatch(
          'shared/getDataFromLookupsByLookupType',
          appStrings.LOOKUPTYPE.SALUTATION
        )
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const salutationArr = result.map(type => {
              return {
                value: type.lookup_code,
                text: type.meaning
              };
            });
            this.salutationArr = salutationArr;
            // this.createPartyForm.selectedSalutation =
            //   salutationArr && salutationArr[0];
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    clearCreatePartyForm() {
      this.createPartyForm.selectedParty.value = null;
      this.createPartyForm.selectedCateory.value = null;
      this.createPartyForm.shortName = null;
      this.createPartyForm.panNo = null;
      this.createPartyForm.selectedSalutation.value = null;
      this.createPartyForm.firstName = null;
      this.createPartyForm.middleName = null;
      this.createPartyForm.lastName = null;
      this.createPartyForm.partyName = null;
      this.createPartyForm.dob = null;
      this.createPartyForm.doi = null;
      this.createPartyForm.aadharNo = null;
      this.createPartyForm.cinNo = null;
      this.createPartyForm.nri = false;
    },
    getEmployee(search) {
      this.$store
        .dispatch('party/getEmployee', search)
        .then(response => {
          if (response.status === 200) {
            this.showEmployeeDropdown = true;
            this.employeeList = response.data.data;
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    selectEmployee(employee) {
      this.showEmployeeDropdown = false;
      this.createPartyForm.employee.name = employee.empolyee_detail;
      this.createPartyForm.employee.id = employee.employee_id;
    },
    hideCreateParty() {
      this.showCreateParty = false;
      this.$emit('showHideCreateParty');
    },
    validateDate(date) {
      this.isDobValid = true;
      if (!this.validateDob(date)) {
        this.isDobValid = false;
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    validatePersonName(name) {
      if (name) {
        this.personNameRequired = false;
      } else {
        this.personNameRequired = true;
      }
    }
  },
  created() {
    this.getLOVBySetType();
    this.getSalutation();
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
