import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './app/App';
import AccessRightButton from './app/components/shared/accessRightButton';
import valueSetModal from './app/components/shared/valueSetModal';
import commonUploadDoc from './app/components/shared/commonUploadDoc';
import OrgMaster from './app/components/shared/orgMaster';
import UploadExcel from './app/components/shared/uploadExcel';
import router from './app/router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue2-datepicker/index.css';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VeeValidate from 'vee-validate';
import Vuex from 'vuex';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import './assets/css/style.css';
import './assets/css/custom-bootstrap.css';
require('./assets/js/custom.js');
import OtpInput from '@bachdgvn/vue-otp-input';
import 'vue-search-select/dist/VueSearchSelect.css';
import Vuelidate from 'vuelidate';
import VueHtmlToPaper from 'vue-html-to-paper';
import downloadExcel from '@/app/utility/downloadExcel';
const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true // if false, the window will not close after printings
};

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt);
Vue.use(VueHtmlToPaper, options);
Vue.config.productionTip = false;
Vue.component('v-otp-input', OtpInput);
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors'
});
Vue.use(Vuelidate);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('accessRightButton', AccessRightButton);
Vue.component('valueSetModal', valueSetModal);
Vue.component('commonUploadDoc', commonUploadDoc);
Vue.component('orgMaster', OrgMaster);
Vue.component('uploadExcel', UploadExcel);
Vue.use(BootstrapVue);
Vue.use(Vuex);
Vue.use(IconsPlugin);
Vue.use(VueMeta);

document.addEventListener('DOMContentLoaded', event => {
  if (event.target.location.href.includes('reload=true')) {
    event.target.location.reload();
  }
});

const eventBus = new Vue();

Vue.mixin({
  data() {
    return {
      eventBus,
      downloadExcel
    };
  }
});

Vue.directive('numericOnly', {
  bind(el) {
    el.addEventListener('keypress', event => {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        event.preventDefault();
      }
    });
  }
});
Vue.directive('numericOnlyPosNeg', {
  bind(el) {
    el.addEventListener('keypress', event => {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      let currentValue = el.value;
      if (
        (keyCode < 48 || keyCode > 57) &&
        keyCode !== 45 &&
        keyCode !== 46 &&
        keyCode !== 8
      ) {
        event.preventDefault();
      }
      if (
        keyCode === 45 &&
        (currentValue.includes('-') || el.selectionStart !== 0)
      ) {
        event.preventDefault();
      }
      if (keyCode === 46 && currentValue.includes('.')) {
        event.preventDefault();
      }
    });
    el.addEventListener('paste', event => {
      let paste = (event.clipboardData || window.clipboardData).getData('text');
      if (!/^[-]?\d*(\.\d*)?$/.test(paste)) {
        event.preventDefault();
      }
    });
  }
});
Vue.directive('alphaOnly', {
  bind(el) {
    el.addEventListener('keypress', event => {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 65 || keyCode > 90) && (keyCode < 97 || keyCode > 122)) {
        event.preventDefault();
      }
    });
  }
});
Vue.directive('panOnly', {
  bind(el) {
    el.addEventListener('input', event => {
      let input = event.target.value.toUpperCase();
      input = input.replace(/[^A-Z0-9]/g, '');
      if (input.length > 10) {
        input = input.slice(0, 10);
      }
      event.target.value = input;
    });
  }
});
Vue.directive('integerOnly', {
  bind(el) {
    el.addEventListener('keyup', () => {
      let regex = /^[0-9]*$/;
      if (!regex.test(el.value)) {
        el.value = el.value.slice(0, -1);
      }
    });
  }
});
Vue.directive('decimalOnly', {
  bind(el) {
    el.addEventListener('input', () => {
      let regex = /^-?\d*\.?\d*$/;
      if (!regex.test(el.value)) {
        el.value = el.value.slice(0, -1);
      }
    });
  }
});
//////////////////////////////////////////////////////////
const params = new URLSearchParams(window.location.search);
const reloadParam = params.get('reload');

if (reloadParam === 'true') {
  window.location.reload();
} else {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
}
///////////////////////////////////////////////////////////
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app');
