import store from '../../../../../../store';
import customerSiteBankDetails from './customerSiteBankDetails';
export default {
  name: 'customerSiteBank',
  components: {
    customerSiteBankDetails
  },
  data() {
    return {
      unsubscribe: null,
      partyProfileType: '',
      vendorId: '',
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      customerSiteIndex: 0,
      customerId: '',
      showPartyBankModel: false,
      customerSiteList: [],
      tableFields: [
        {
          key: 'bank'
        },
        {
          key: 'account_type',
          label: 'A/C Type'
        },
        {
          key: 'account_num',
          label: 'A/C No.'
        },
        {
          key: 'bank_branch'
        },
        {
          key: 'ifsc_code',
          label: 'IFSC Code'
        },
        {
          key: 'active'
        },
        {
          key: 'primary_bank'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'last_updated_by'
        }
      ],
      parytBankList: [],
      partyBankFields: [
        {
          key: 'bank'
        },
        {
          key: 'bank_acc_type',
          label: 'A/C Type'
        },
        {
          key: 'bank_branch'
        },
        {
          key: 'ifsc_code',
          class: 'IFSC Code'
        },
        {
          key: 'party_bank_id',
          class: 'd-none'
        },
        {
          key: 'vendor_site_bank_id',
          class: 'd-none'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'last_updated_by'
        }
      ],
      showCustSiteBankModal: false,
      bankSiteRowDetail: null,
      customerDtlId: null,
      customerSiteDtlList: null,
      siteType: null,
      vendorDtlId: null,
      vendorSiteDtlList: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (store.state.party.customerTabValue === 'customerSiteBank') {
          if (actionName === 'add' && !this.showCustSiteBankModal) {
            this.showPartyBankModel = true;
          }
        }
      }
    });
    this.eventBus.$on('customerSiteBank', customerSite => {
      this.partyProfileType = store.state.party.partyProfile;
      this.getPartyBankList();
      if (store.state.party.partyProfile === 'customer') {
        this.customerId = customerSite.customer_id;
        this.getCustomerBankByPartyId();
      } else if (store.state.party.partyProfile === 'vendor') {
        this.vendorId = customerSite.vendor_id;
        this.getVendorBankList();
      }
    });
  },
  methods: {
    getCustomerBankByPartyId() {
      const payload = {
        partyId: store.state.party.partyId
      };
      this.loader = true;
      this.$store
        .dispatch('party/getCustomerBankByPartyId', payload)
        .then(response => {
          this.customerSiteList = response.data.data;
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPartyBankList() {
      const partyId = store.state.party.partyId;
      this.$store
        .dispatch('party/getPartyBanksListUrl', partyId)
        .then(response => {
          if (response.status === 200) {
            this.parytBankList = response.data.data;
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    getVendorBankList() {
      this.loader = true;
      this.$store
        .dispatch('party/getVendorBankList', this.vendorId)
        .then(response => {
          this.customerSiteList = response.data.data.vendor_sites_banks;
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    closePartyBankModel() {
      this.showPartyBankModel = false;
    },
    selectedSite(index) {
      this.customerSiteIndex = index;
    },
    selectedBankRow(item) {
      if (this.partyProfileType === 'customer') {
        const payload = {
          customerId: this.customerId,
          customerSiteBank: {
            customer_site_banks: [
              {
                customer_site_id: this.customerSiteList[this.customerSiteIndex]
                  .customer_site_id,
                customer_site_bank_id: 0,
                party_bank_id: item.party_bank_id,
                active: true,
                primary_bank: true
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/getAddEdiCustomerSiteBank', payload)
          .then(response => {
            this.showPartyBankModel = false;
            this.customerSiteList = response.data.data.customer_sites;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.loader = false;
          });
      } else if (this.partyProfileType === 'vendor') {
        this.addEditVendorBank(item);
      }
    },
    addEditVendorBank(item) {
      this.loader = true;
      const payload = {
        vendorId: this.vendorId,
        vendorSiteData: {
          vendor_site_banks: [
            {
              vendor_site_bank_id: 0,
              vendor_site_id: this.customerSiteList[this.customerSiteIndex]
                .vendor_site_id,
              party_bank_id: item.party_bank_id,
              active: true,
              primary_bank: true
            }
          ]
        }
      };
      this.$store
        .dispatch('party/addEditVendorBank', payload)
        .then(response => {
          this.customerSiteList = response.data.data.vendor_sites_banks;
          this.showPartyBankModel = false;
          this.showAlert = true;
          this.isSuccess = true;
          this.responseMsg = response.data.message;
          this.loader = false;
        })
        .catch(error => {
          alert(error.message);
        });
    },
    showHideCustSiteBankModal(flag) {
      this.showCustSiteBankModal = flag;
    },
    rowSelected(item) {
      // this.showPartyDirectTaxModel = true;
      this.showHideCustSiteBankModal(true);
      this.bankSiteRowDetail = item;
      this.customerDtlId = this.customerId;
      this.customerSiteDtlList = this.customerSiteList[this.customerSiteIndex];
      // this.partyDirectSiteMpngId = item.party_direct_site_mpng_id;
      this.siteType = this.partyProfileType;
      this.vendorDtlId = this.vendorId;
      this.vendorSiteDtlList = this.customerSiteList[this.customerSiteIndex];
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
