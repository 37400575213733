import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getMasterBillingData = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsMasterBillingUrl, payload);
};
const getBillingCycleData = (context, billingCycleHdrId) => {
  return http.getApi(
    URL_UTILITY.getFmsMasterBillingUrl + '/' + billingCycleHdrId
  );
};
const addEditMasterBills = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsMasterBillingUrl, payload);
};
const getComponentGroupList = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsComponentGroupUrl, payload);
};
const getComponentGroupListById = (context, group_id) => {
  return http.getApi(URL_UTILITY.getFmsComponentGroupUrl + '/' + group_id);
};
const addEditComponentGroup = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsComponentGroupUrl, payload);
};
const getComponentMasterList = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsComponentMasterUrl, payload);
};

const getComponentDetailsById = (context, fmsCompId) => {
  return http.getApi(URL_UTILITY.getComponentDetailsByIdUrl + '/' + fmsCompId);
};

const addEditComponentMaster = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsComponentMasterUrl, payload);
};
const getBillingPlanList = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsBillingPlanUrl, payload);
};
const getBillingPlanDetail = (context, billingPlanHdrId) => {
  return http.getApi(URL_UTILITY.getFmsBillingPlanUrl + '/' + billingPlanHdrId);
};
const addEditBillingPlan = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsBillingPlanUrl, payload);
};
const getBillingPlanProjList = (context, billingPlanHdrId) => {
  return http.getApi(
    URL_UTILITY.getFmsBillingPlanUrl +
      '/' +
      billingPlanHdrId +
      '/billing-plan-projects'
  );
};
const getBillingSubUnitList = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsBillingPlanUrl +
      '/' +
      payload.queryParams.billingPlanId +
      '/billing-plan-projects/' +
      payload.queryParams.fmsPrjId +
      '/billing-plan-subunits',
    payload.searchParams
  );
};
const getBillingPlanCompList = (context, billingPlanHdrId) => {
  return http.getApi(
    URL_UTILITY.getFmsBillingPlanUrl +
      '/' +
      billingPlanHdrId +
      '/billing-plan-components'
  );
};
const getBillingPlanLEId = (context, billingPlanHdrId) => {
  return http.getApi(
    URL_UTILITY.getFmsBillingPlanUrl + '/' + billingPlanHdrId + '/legal-entity'
  );
};
const addEditBillingPlanProject = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFmsBillingPlanUrl +
      '/' +
      payload.billingPlanHdrId +
      '/billing-plan-projects',
    payload.billingPlanPrjDetails
  );
};
const addEditBillingPlanComponent = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFmsBillingPlanUrl +
      '/' +
      payload.billingPlanHdrId +
      '/billing-plan-components',
    payload.billingPlanPrjDetails
  );
};
const getBillingPlanCompPricingList = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsBillingPlanUrl +
      '/' +
      payload.billingPlanId +
      '/billing-plan-components/' +
      payload.billingPlanComponentMpngId +
      '/billing-plan-components-prices',
    payload.searchParam
  );
};
const addEditBillingSubUnitList = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFmsBillingPlanUrl +
      '/' +
      payload.queryParams.billingPlanId +
      '/billing-plan-projects/' +
      payload.queryParams.fmsPrjId +
      '/billing-plan-subunits',
    payload.subUnitData
  );
};
const addEditBillingPlanCompPricingList = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFmsBillingPlanUrl +
      '/' +
      payload.queryParams.billingPlanId +
      '/billing-plan-components/' +
      payload.queryParams.billingPlanComponentMpngId +
      '/billing-plan-components-prices',
    payload.compPricingData
  );
};
const getBillingPlanAllSubUnits = (context, subUnitIds) => {
  return http.getApi(URL_UTILITY.getBillingPlanSubUnitUrl, subUnitIds);
};
const getInterestRateList = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsInterestRateUrl, payload);
};
const addEditFmsInterestRate = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsInterestRateUrl, payload);
};
const getInterestRateById = (context, intRateHdrId) => {
  return http.getApi(URL_UTILITY.getFmsInterestRateUrl + '/' + intRateHdrId);
};

const getTaxCategoryLov = () => {
  return http.getApi(URL_UTILITY.getTaxCategoryLovUrl);
};

const getAutoAccountingList = (context, payload) => {
  const url = URL_UTILITY.getAutoAccountingListUrl;
  return http.getApi(url, payload);
};

const getAutoAccountingListById = (context, payload) => {
  const url = URL_UTILITY.getAutoAccountingListByIdUrl.replace(
    '{fmsAutoAccountingRuleHdrId}',
    payload
  );
  return http.getApi(url);
};

const addEditAutoAccountingData = (context, payload) => {
  const url = URL_UTILITY.getAutoAccountingListUrl;
  return http.postApi(url, payload);
};

const getMuationList = (context, payload) => {
  const url =
    URL_UTILITY.getMutationListUrl +
    `?_page=${payload.pagination._page}&_limit=${payload.pagination._limit}`;
  return http.postApi(url, payload.searchParams);
};

const getMutationDetailsById = (context, fmsMutationHdrId) => {
  const url = URL_UTILITY.getMutationListUrl + `/${fmsMutationHdrId}`;
  return http.getApi(url);
};

const getExistedOwnershipDetails = (context, fmsMutationHdrId) => {
  const url =
    URL_UTILITY.getMutationListUrl +
    `/${fmsMutationHdrId}/ownership-details/existed`;
  return http.getApi(url);
};

const getTransferMutationDetails = (context, fmsMutationHdrId) => {
  const url =
    URL_UTILITY.getMutationListUrl + `/${fmsMutationHdrId}/ownership-details`;
  return http.getApi(url);
};

const getUnitOwnerShipDetails = (context, fmsUnitId) => {
  const url = URL_UTILITY.getUnitOwnerShipDetailsUrl.replace(
    '{fmdUnitId}',
    fmsUnitId
  );
  return http.getApi(url);
};

const addEditMutationHdrOwnership = (context, payload) => {
  return http.putApi(URL_UTILITY.getMutationListUrl, payload);
};
const addEditMutationInvestorLease = (context, payload) => {
  return http.postApi(URL_UTILITY.getMutationListUrl + '/lease', payload);
};
const getMutationInvestorDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getMutationListUrl +
      '/lease' +
      '?fms_mutation_hdr_id=' +
      payload.fms_mutation_hdr_id +
      '&fms_unit_id=' +
      payload.fms_unit_id
  );
};
const billingPlanVerifyStatus = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFmsBillingPlanUrl +
      '/' +
      payload.billingPlanHdrId +
      '/verify-status'
  );
};
const billingPlanUnVerifyStatus = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFmsBillingPlanUrl +
      '/' +
      payload.billingPlanHdrId +
      '/unverify-status'
  );
};
const getBreakUpDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsAdminUrl + "/billing-plans-rate-comp/"+payload
  );
};
const saveBreakUpDetails = (context, payload) => {
  const url=URL_UTILITY.getFmsAdminUrl + "/billing-plans-rate-comp/"+ payload.billingPlanPriceMappingId
  return http.postApi(url, payload.requestPayload);
};

const getBillingPlanApplicableStatus = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsAdminUrl+"/billing-plans-comp-mpng-dtls", payload);
};
const getSavedApplicableStatus = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsAdminUrl+"/billing-plans-comp-mpng-dtl/" + payload);
};

const postBillingPlanApplicableStatus = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsAdminUrl+"/billing-plans-comp-mpng-dtl/"+payload.requestParams, payload.queryParams);
};
const deleteTransferDetails = (context, payload) => {
  return http.deleteApi(URL_UTILITY.getFmsAdminUrl+"/mutation/" + payload);
};
const deleteBillingPlanApplicableStatus = (context, payload) => {
  return http.deleteApi(URL_UTILITY.getFmsAdminUrl+'/billing-plan-dtls-delete/' + payload.requestParams);
};
const fmsCopyBillingPlan = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsAdminUrl + "/billing-plans-copy/" + payload);
};
const deleteComponent = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsBillingPlanUrl + '-copy-delete-comp', payload);
};
const getCostSheetData = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsAdminUrl+"/Cost-Sheet", payload);
};
const addEditCostSheetData = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsAdminUrl+"/Cost-Sheet-New", payload);
};
const getCostSheetDataById = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsAdminUrl+"/Cost-Sheet-By-Id/" + payload.fms_state_wise_min_wages_mpng_id);
};
const getUnitSubunitData = (context, payload) => {
  return http.getApi(URL_UTILITY.getUnitSubunitUrl + "/" + payload.Id + '?type=' + payload.type);
};
const updateUnitSubunitEnviroArea = (context, payload) => {
  return http.postApi(URL_UTILITY.updateUnitSubunitEnviroAreaUrl, payload);
};
const getFmsMeterDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsMeterDetailsUrl ,payload);
};
const postFmsMeterDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.postFmsMeterDetailsUrl, payload);
};
const getFmsMeterDetailsById = (context, payload) => {
  return http.getApi(URL_UTILITY.postFmsMeterDetailsUrl + '/'+ payload.id);
};
const getFmsMeterUploadExcel = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsAdminUrl+"/fmsmeterUploadExcel", payload);
};
const fmsMeterUploadDoc = (context, excelImportId) => {
  return http.postApi(
    URL_UTILITY.getFmsAdminUrl + '/fmsmeterUploadExcel/' + excelImportId + '/excel-upload'
  );
};
const deleteFmsMeterUploadExcel = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsAdminUrl+'/fmsmeterUploadExcel/delete-by-reading-id', payload);
};

const getFmsMeterReadingData = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsAdminUrl +"/fmsMeterReadingDgElec" +'?reading_type='+ payload.reading_type);
};
const postFmsMeterReadingData = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsAdminUrl +'/fmsMeterReadingDgElec', payload);
};
const getFmsMeterReadingDataById = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsAdminUrl +`/fmsMeterReadingDgElec/${payload.flag}/${payload.id}`);
};
const deleteMeterReading = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsAdminUrl+'/fmsMeterReadingDgElec/delete' , payload);
};
const getOwnershipDetailsDataBySd = (context, payload) => {
  return http.getApi(URL_UTILITY.getMutationListUrl +'/ownership-details/sd/'+ payload.fms_unit_id + '?vendor_id='+ payload.vendor_id + '&vendor_site_id='+payload.vendor_site_id);
};

export default {
  getMasterBillingData,
  getBillingCycleData,
  addEditMasterBills,
  getComponentGroupList,
  addEditComponentGroup,
  getComponentMasterList,
  addEditComponentMaster,
  getBillingPlanList,
  getBillingPlanDetail,
  addEditBillingPlan,
  getBillingPlanProjList,
  getBillingSubUnitList,
  getBillingPlanCompList,
  getBillingPlanLEId,
  addEditBillingPlanProject,
  addEditBillingPlanComponent,
  getBillingPlanCompPricingList,
  addEditBillingSubUnitList,
  addEditBillingPlanCompPricingList,
  getBillingPlanAllSubUnits,
  getInterestRateList,
  addEditFmsInterestRate,
  getInterestRateById,
  getComponentDetailsById,
  getTaxCategoryLov,
  getAutoAccountingList,
  getAutoAccountingListById,
  addEditAutoAccountingData,
  getMuationList,
  getMutationDetailsById,
  getUnitOwnerShipDetails,
  addEditMutationHdrOwnership,
  getExistedOwnershipDetails,
  getTransferMutationDetails,
  getMutationInvestorDetailsById,
  addEditMutationInvestorLease,
  getComponentGroupListById,
  billingPlanVerifyStatus,
  getBreakUpDetailsById,
  saveBreakUpDetails,
  getBillingPlanApplicableStatus,
  postBillingPlanApplicableStatus,
  getSavedApplicableStatus,
  billingPlanUnVerifyStatus,
  deleteTransferDetails,
  deleteBillingPlanApplicableStatus,
  fmsCopyBillingPlan,
  deleteComponent,
  getCostSheetData,
  addEditCostSheetData,
  getCostSheetDataById,
  getUnitSubunitData,
  updateUnitSubunitEnviroArea,
  getFmsMeterDetails,
  postFmsMeterDetails,
  getFmsMeterDetailsById,
  getFmsMeterUploadExcel,
  fmsMeterUploadDoc,
  deleteFmsMeterUploadExcel,
  getFmsMeterReadingData,
  postFmsMeterReadingData,
  getFmsMeterReadingDataById,
  deleteMeterReading,
  getOwnershipDetailsDataBySd
};
