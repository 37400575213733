import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getRoleHeaderList = (context, roleId) => {
    const url = URL_UTILITY.getRoleHeaderListUrl.replace(
      '{roleId}',
      roleId
    );
    return http.getApi(url);
  };
  const getMenuList = (context, payload) => {
    const url = URL_UTILITY.getMenuListUrl;
    return http.getApi(url, payload);
  };
  const addEditRole = (context, payload) => {
    const url = URL_UTILITY.addEditRoleUrl;
    return http.postApi(url, payload);
    }
  export default{
    getRoleHeaderList,
    getMenuList,
    addEditRole
  };