// import dashboardAccessRight from '../../../app/components/admin/dashboardAccessRight/dashboardAccessRight';
import actions from './action';
import getters from './getters';
import mutations from './mutations';

const state = {
  dashboardAccessRight :{}
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};