import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getTaxMasterList = (context, payload) => {
  const url = URL_UTILITY.getTaxMasterListUrl;
  return http.getApi(url, payload);
}

const getTaxMasterDetails = (context, tax_master_id) => {
  const url = URL_UTILITY.getTaxMasterDetailsUrl.replace('{taxMasterId}', tax_master_id);
  return http.getApi(url);
}

const addeditTaxMasterDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.getTaxMasterListUrl, payload);
};
export default {
  getTaxMasterList,
  getTaxMasterDetails,
  addeditTaxMasterDetails
};
