import http from '../../../../app/services/httpServices';
import URL_UTILITY from '../../../../app/utility/url.utility';

const getGstMasterList = (context, payload) => {
  const url = URL_UTILITY.getGstMasterListUrl;
  return http.getApi(url, payload);
};

const addEditgstMaster = (context, payload) => {
  const url = URL_UTILITY.getGstMasterListUrl;
  return http.postApi(url, payload);
};

const vendorEmailSetup = (context, payload) => {
  const url = URL_UTILITY.getGstUrl + '/vendor_email_list';
  return http.getApi(url, payload);
};

const updateRemoveVendorEmail = (context, payload) => {
  const url = URL_UTILITY.getGstUrl + '/vendor_email_patch';
  return http.postApi(url, payload);
};

const vendorHoldMapping = (context, payload) => {
  const url = URL_UTILITY.getGstUrl + '/vendor_mapping';
  return http.getApi(url, payload);
};
const updateVendorMapping = (context, payload) => {
  const url = URL_UTILITY.getGstUrl + '/update_vendor_mapping';
  return http.postApi(url, payload);
};

const uploadExcelVendorEmail = (context, excelImportId) => {
  return http.postApi(
    URL_UTILITY.getGstPerformanceMatrix +
      '-vendor-email/' +
      excelImportId +
      '/' +
      'excel-upload'
  );
};

const getGstSetUpDtls = (context, payload) => {
  const url = URL_UTILITY.getGstUrl + '/gst-setup';
  return http.getApi(url, payload);
};
const updateGstSetUpDtls = (context, payload) => {
  const url = URL_UTILITY.getGstUrl + '/gst-setup-update';
  return http.postApi(url, payload);
};
const addEmailSetupData = (context, payload) => {
  const url = URL_UTILITY.getGstUrl + '/insert_vendor_details';
  return http.postApi(url, payload);
};
const vendorHoldMappingExcelUpload = (context, excelImportId) => {
  return http.postApi(
    URL_UTILITY.getGstPerformanceMatrix +
      '-hold-unhold/' +
      excelImportId +
      '/' +
      'excel-upload'
  );
};
export default {
  getGstMasterList,
  addEditgstMaster,
  vendorEmailSetup,
  updateRemoveVendorEmail,
  vendorHoldMapping,
  updateVendorMapping,
  uploadExcelVendorEmail,
  getGstSetUpDtls,
  updateGstSetUpDtls,
  addEmailSetupData,
  vendorHoldMappingExcelUpload
};
