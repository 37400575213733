import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getUserGridViewList = (context, payload) => {
  const url = URL_UTILITY.getUserGridViewUrl;
  return http.getApi(url, payload);
};

const getUserTypeLov = (context, USER_TYPE) => {
  const url = URL_UTILITY.getUserTypeLovUrl.replace('{lookupType}', USER_TYPE);
  return http.getApi(url);
};

const getUserResponsibilityList = (context, user_id) => {
  const url = URL_UTILITY.getUserResponsibilityUrl.replace('{userId}', user_id);
  return http.getApi(url);
};

const addEditUserResponsibility = (context, payload) => {
  const url = URL_UTILITY.addEditUserResponsibilityUrl.replace(
    '{userId}',
    payload.user_id
  );
  return http.postApi(url, payload.responsibility);
};

const addEditUser = (context, payload) => {
  const url = URL_UTILITY.addEditUserUrl;
  return http.postApi(url, payload);
};

const getCustIdByPartyId = (context, partyId) => {
  const url = URL_UTILITY.getCustIdByPartyIdUrl.replace('{partyId}', partyId);
  return http.getApi(url);
};

const getVendorIdByPartyId = (context, partyId) => {
  const url = URL_UTILITY.getVendorIdByPartyIdUrl.replace('{partyId}', partyId);
  return http.getApi(url);
};

export default {
  getUserGridViewList,
  getUserTypeLov,
  getUserResponsibilityList,
  addEditUserResponsibility,
  addEditUser,
  getCustIdByPartyId,
  getVendorIdByPartyId
};
