import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getEmployeeList = (context, payload) => {
  return http.getApi(URL_UTILITY.getAdminEmployeeListUrl, payload);
};

const getEmployeeById = (context, employeeId) => {
  const url = URL_UTILITY.getHrmsEmployeeUrl;
  return http.getApi(url + '/' + employeeId);
};
const getEmpAssignment = (context, employeeId) => {
  const url = URL_UTILITY.getHrmsEmpAssignmentUrl;
  return http.getApi(url + '/' + employeeId);
};
const addEditAssignment = (context, payload) => {
  const url = URL_UTILITY.getHrmsEmpAssignmentUrl;
  return http.postApi(url, payload);
};

const getEduQualification = (context, employeeId) => {
  const url = URL_UTILITY.getHrmsEmpEduQualificationUrl;
  return http.getApi(url + '/' + employeeId);
};

const addEditEduQualification = (context, payload) => {
  return http.postApi(URL_UTILITY.getHrmsEmpEduQualificationUrl, payload);
};
const getHrOrgHeadList = (context, employeeId) => {
  const url = URL_UTILITY.getHrmsHrOrgHeadUrl;
  return http.getApi(url + '/' + employeeId);
};

const addEditHrOrgHead = (context, payload) => {
  return http.postApi(URL_UTILITY.getHrmsHrOrgHeadUrl, payload);
};

const getHrmsProfList = (context, employeeId) => {
  const url = URL_UTILITY.getHrmsProfUrl;
  return http.getApi(url + '/' + employeeId);
};

const addEditProfInfo = (context, payload) => {
  return http.postApi(URL_UTILITY.getHrmsProfUrl, payload);
};
const getConactList = (context, employeeId) => {
  const url = URL_UTILITY.getHrmsContactUrl;
  return http.getApi(url + '/' + employeeId);
};

const addEditContact = (context, payload) => {
  return http.postApi(URL_UTILITY.getHrmsContactUrl, payload);
};

const getBankList = (context, employeeId) => {
  const url = URL_UTILITY.getHrmsBankUrl;
  return http.getApi(url + '/' + employeeId);
};

const addEditBank = (context, payload) => {
  return http.postApi(URL_UTILITY.getHrmsBankUrl, payload);
};

const getAddressList = (context, employeeId) => {
  const url = URL_UTILITY.getHrmsAddressUrl;
  return http.getApi(url + '/' + employeeId);
};

const addEditAddress = (context, payload) => {
  return http.postApi(URL_UTILITY.getHrmsAddressUrl, payload);
};
const getRelationshipList = (context, employeeId) => {
  const url = URL_UTILITY.getHrmsRelationshipUrl;
  return http.getApi(url + '/' + employeeId);
};

const addEditRelationship = (context, payload) => {
  return http.postApi(URL_UTILITY.getHrmsRelationshipUrl, payload);
};

const addEditEmpPersonalInfo = (context, payload) => {
  return http.postApi(URL_UTILITY.getHrmsEmployeeUrl, payload);
};

const getConcatSegment = (context, payload) => {
  return http.getApi(URL_UTILITY.getConcatSegmentUrl, payload);
};

const getPlanning = (context, employeeId) => {
  return http.getApi(URL_UTILITY.getSuccPlanningUrl + '/' + employeeId);
};

const addEditPlanning = (context, payload) => {
  return http.postApi(URL_UTILITY.getSuccPlanningUrl, payload);
};

const getHrOrgFlexField = (context, payload) => {
  return http.getApi(URL_UTILITY.getHrOrgFlexfieldUrl, payload);
};

export default {
  getEmployeeList,
  getEmployeeById,
  getEmpAssignment,
  getEduQualification,
  getHrOrgHeadList,
  getHrmsProfList,
  getConactList,
  getBankList,
  getAddressList,
  getRelationshipList,
  addEditEmpPersonalInfo,
  addEditAssignment,
  addEditEduQualification,
  addEditProfInfo,
  addEditBank,
  addEditAddress,
  getConcatSegment,
  addEditRelationship,
  addEditContact,
  getPlanning,
  addEditPlanning,
  addEditHrOrgHead,
  getHrOrgFlexField
};
