import http from '@/app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getOperatingUnitData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/operating-unit-data',
    payload
  );
};
const getRequesterData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/requester_details',
    payload
  );
};
const getBuyerData = (context, payload) => {
  return http.getApi(URL_UTILITY.getPurchaseReqUrl + '/Buyer-Details', payload);
};
const getCategoriesData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/categories_details',
    payload
  );
};
const getSupplierData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/Supplier-Details',
    payload
  );
};
const getSupplierSiteData = (context, payload) => {
  return http.getApi(URL_UTILITY.getPurchaseReqUrl + '/supplier_site', payload);
};
const getInventoryItemData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/Inventory-item',
    payload
  );
};
const getCostCenterData = (context, payload) => {
  return http.getApi(URL_UTILITY.getPurchaseReqUrl + '/cost_center', payload);
};
const getOrganizationData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/organization-details',
    payload
  );
};
const getLocationDtlsData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/location-code-details',
    payload
  );
};
const getReqLineTypeData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/requisition-line-type',
    payload
  );
};
const getReqHdrTypeData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/Requisition-header-type',
    payload
  );
};
const getAvgPriceData = (context, payload) => {
  return http.getApi(URL_UTILITY.getPurchaseReqUrl + '/average-price', payload);
};
const getChargeAccData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/charge-account-details',
    payload
  );
};
const getVarrienceAccData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/varrience-account-details',
    payload
  );
};
const getAccuralAccData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/accural-account-details',
    payload
  );
};
const addEditPurReqDtls = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getPurchaseReqUrl + '/create-update-purchasing-requisition',
    payload
  );
};
const getPurReqDtls = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/requisition-header-details',
    payload
  );
};
const getPurReqDtlsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl +
      '/requisition-line-details' +
      '?user-id=' +
      payload.user_id +
      '&requisition-header-id=' +
      payload.REQUISITION_HEADER_ID
  );
};
const getReqEmailData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/requester-email',
    payload
  );
};
const deleteReqLineData = (context, payload) => {
  return http.deleteApi(
    URL_UTILITY.getPurchaseReqUrl +
      '/delete-po-requisition-line-by-id?' +
      'requisition_header_id=' +
      payload.requisition_header_id +
      '&requisition_line_id=' +
      payload.requisition_line_id
  );
};
const getRegionsDtlsData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/region-details',
    payload
  );
};
const addEditBuyMasDtls = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getPurchaseReqUrl + '/Add-update-Buyer-Master-details',
    payload
  );
};
const getBuyMasDtls = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/get-buyer-master-details',
    payload
  );
};
const getBuyerDtlsFromRealApp = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getPurchaseReqUrl + '/get-buyer-details',
    payload
  );
};
export default {
  getOperatingUnitData,
  getRequesterData,
  getBuyerData,
  getCategoriesData,
  getSupplierData,
  getSupplierSiteData,
  getInventoryItemData,
  getCostCenterData,
  getOrganizationData,
  getLocationDtlsData,
  getReqLineTypeData,
  getReqHdrTypeData,
  getAvgPriceData,
  getChargeAccData,
  getVarrienceAccData,
  getAccuralAccData,
  addEditPurReqDtls,
  getPurReqDtls,
  getPurReqDtlsById,
  getReqEmailData,
  deleteReqLineData,
  getRegionsDtlsData,
  addEditBuyMasDtls,
  getBuyMasDtls,
  getBuyerDtlsFromRealApp
};
