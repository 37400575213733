import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  state: {
		// status: '',
		// token: sessionStorage.getItem('token'),
		// user: {},
		// isLoading: false,
		// error: false,
		// message: ''
	},
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};