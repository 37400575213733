import SearchModule from '../searchModule';
import SearchRole from '../searchRole';
import SearchReportGroup from '../searchReportGroup';
import SearchSecurityGroup from '../searchSecurityGroup';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'responsibilityDetails',
  props: {},
  components: {
    SearchModule,
    SearchRole,
    SearchReportGroup,
    SearchSecurityGroup,
    DatePicker
  },
  data() {
    return {
      respTableIndex: 0,
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: '',
      showSecurityGroupForm: false,
      responsibilityDetails: null,
      showResponsibility: false,
      showModuleForm: false,
      showRoleForm: false,
      showReportGroupForm: false,
      editMode: false,
      updateMode: false,
      data: [
        {
          responsibility_dtl_id: 0,
          module_name: null,
          role_name: null,
          group_name: null,
          security_group_hdr_name: null,
          module_id: null,
          role_id: null,
          report_group_id: null,
          security_group_id: null
        }
      ],
      respForm: {
        responsibility_hdr_id: 0,
        responsibility_name: null,
        responsibility_desc: null,
        start_date: null,
        end_date: null,
        start_end_date: [],
        responsibilitie_details: null
      },
      fields: [
        {
          key: 'module_id',
          class: 'd-none'
        },
        {
          key: 'module_name'
        },
        {
          key: 'role_id',
          class: 'd-none'
        },
        {
          key: 'role_name'
        },
        {
          key: 'report_group_id',
          class: 'd-none'
        },
        {
          key: 'group_name',
          label: 'Report Group Name'
        },
        {
          key: 'responsibility_dtl_id',
          class: 'd-none'
        },
        {
          key: 'security_group_id',
          class: 'd-none'
        },
        {
          key: 'security_group_hdr_name',
          label: 'Security Group Name'
        },

        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  validations: {
    respForm: {
      responsibility_name: { required },
      responsibility_desc: { required },
      start_end_date: { required }
    }
  },
  mounted() {
    this.eventBus.$off('respDetails');
    this.eventBus.$on('respDetails', responsibilityObj => {
      this.respForm.responsibility_name = responsibilityObj.responsibility_name;
      this.respForm.responsibility_desc = responsibilityObj.responsibility_desc;
      this.respForm.start_date = responsibilityObj.start_date;
      this.respForm.end_date = responsibilityObj.end_date;
      this.respForm.start_end_date = [ new Date(responsibilityObj.start_date), new Date(responsibilityObj.end_date)];
      this.respForm.responsibility_hdr_id =
        responsibilityObj.responsibility_hdr_id;
      this.getRespHeader(responsibilityObj.responsibility_hdr_id);
    });
    this.eventBus.$off('respFormActions');
    this.eventBus.$on('respFormActions', actionName => {
      if (actionName === 'add') {
        this.editMode = true;
        this.updateMode = true;
        this.respForm.responsibility_hdr_id = 0;
      }
      if (actionName === 'edit' || actionName === 'update') {
        this.editMode = false;
        this.updateMode = true;
      }
      if (actionName === 'save' && this.updateMode) {
        this.addEditResponsibility();
      }
      if (actionName === 'upload') {
        this.eventBus.$emit('commonUpload', {
          id: this.respForm.responsibility_hdr_id
        });
      }
    });
  },
  methods: {
    addEditResponsibility() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const filterDetails = this.data.filter(
          responsibility => responsibility.module_name !== null
        );
        const details = filterDetails.map(respDetails => {
          return {
            responsibility_dtl_id: respDetails.responsibility_dtl_id,
            module_id: respDetails.module_id,
            role_id: respDetails.role_id,
            report_group_id: respDetails.report_group_id,
            security_group_id: respDetails.security_group_id
          };
        });
        const payload = {
          responsibility_hdr_id: this.respForm.responsibility_hdr_id,
          responsibility_desc: this.respForm.responsibility_desc,
          responsibility_name: this.respForm.responsibility_name,
          start_date: commonHelper.formattedDate(this.respForm.start_end_date[0]),
          end_date: commonHelper.formattedDate(this.respForm.start_end_date[1]),
          responsibilitie_details: details ? details : null
        };
        this.loader = true;
        this.$store
          .dispatch('resp/addEditResponsibility', payload)
          .then(response => {
            this.$emit('updateResponsibilityList');
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.respForm.responsibility_hdr_id =
                response.data.data.responsibility_hdr_id;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.editMode = false;
              this.updateMode = false;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
        .catch(() => {
          this.loader = false;
        });      
        }
    },
    resetModal() {},
    openModuleForm(index) {
      if (this.updateMode) {
        this.respTableIndex = index;
        this.showModuleForm = true;
      }
    },
    openRoleForm(index) {
      if (this.updateMode) {
        this.respTableIndex = index;
        this.showRoleForm = true;
      }
    },
    openReportGroupForm(index) {
      if (this.updateMode) {
        this.respTableIndex = index;
        this.showReportGroupForm = true;
      }
    },
    openSecurityGroupForm(index) {
      if (this.updateMode) {
        this.respTableIndex = index;
        this.showSecurityGroupForm = true;
      }
    },
    getRespHeader(respHeaderId) {
      this.loader = true;
      this.$store
        .dispatch('resp/getRespHeaderList', respHeaderId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const detail = response.data.data.responsibility_details;
            if (detail && detail.length > 0) {
              this.data = response.data.data.responsibility_details.map(data => {
                data.start_date_end_date = [
                  new Date(data.start_date),
                  new Date(data.end_date)
                ];
                return data;
              });
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.updateMode) {
        this.data.push({
          module_name: null,
          role_name: null,
          group_name: null,
          security_group_hdr_name: null,
          responsibility_dtl_id: 0
        });
      }
    },
    removeRow(index) {
      if (this.updateMode) {
        this.data.splice(index, 1);
      }
    },
    enableInputs(index, commIndex) {
      this.detailIndex = index;
      this.responseIndex = commIndex;
    },
    selectedSearchModule(moduleData) {
      this.data[this.respTableIndex].module_name = moduleData.module_name;
      this.data[this.respTableIndex].module_id = moduleData.module_id;
      this.showModuleForm = false;
    },
    selectedRoleName(roleName) {
      this.data[this.respTableIndex].role_name = roleName.role_name;
      this.data[this.respTableIndex].role_id = roleName.role_id;
      this.showRoleForm = false;
    },
    selectedReportGroupName(groupName) {
      this.data[this.respTableIndex].report_group_id =
        groupName.report_group_id;
      this.data[this.respTableIndex].group_name = groupName.report_group_name;
      this.showReportGroupForm = false;
    },
    selectedSecurityGroupName(groupName) {
      this.data[this.respTableIndex].security_group_hdr_name =
        groupName.securitygroup_hdr_name;
      this.data[this.respTableIndex].security_group_id =
        groupName.securitygroup_hdr_id;
      this.showSecurityGroupForm = false;
    }
  }
};
