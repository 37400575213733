import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getAdviseStatusGrid = (context, payload) => {
  return http.getApi(URL_UTILITY.getAdviseStatusGridUrl, payload);
};

const getAdvisePaymentInvoiceDetails=(context, ap_payment_advice_hdr_id)=>{
  return http.getApi(URL_UTILITY.getAdviseStatusGridUrl+'/'+ap_payment_advice_hdr_id)
}

const resendEmailForPaymentAdvice = (context, payAdvHdrId) => {
  return http.postApi(URL_UTILITY.getAdviseStatusGridUrl + '/' + payAdvHdrId + '/resend-email')
}
export default {
    getAdviseStatusGrid,
    getAdvisePaymentInvoiceDetails,
    resendEmailForPaymentAdvice
  };