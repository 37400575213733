import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getManualPaymentGrid = (context, payload) => {
  return http.getApi(URL_UTILITY.getManualPaymentGridUrl, payload);
};
const getManualPaymentById = (context, apPaymentHdrId) => {
  const url = URL_UTILITY.getManualPaymentGridUrl;
  return http.getApi(url + '/' + apPaymentHdrId + '/invoices');
};
const getModuleBasedPeriodDate = (context, payload) => {
  return http.getApi(URL_UTILITY.getPeriodDefaultDateUrl, payload);
};
const getValidatePeriodDate = (context, payload) => {
  return http.getApi(URL_UTILITY.getValidatePeriodDateUrl, payload);
};
const addVoidPaymentRequest = (context, payload) => {
  const url = URL_UTILITY.getManualPaymentGridUrl;
  return http.postApi(
    url +
      '/' +
      payload.apPaymentHdrId +
      '/request?gl_date=' +
      payload.voidParams.gl_date +
      '&reason=' +
      payload.voidParams.reason
  );
};
const addVoidManualPayment = (context, payload) => {
  return http.postApi(URL_UTILITY.getManualPaymentGridUrl + '/void', payload);
};
const saveAdvSearchFilters = (context, payload) => {
  context.commit('updateAdvSearchFilters', payload);
};

const getPaymentAccountingDataById = (context, apPaymentHdrId) => {
  const url =
    URL_UTILITY.getManualPaymentGridUrl + `/${apPaymentHdrId}/view-accounting`;
  return http.getApi(url);
};
const getCustomerSiteByOu = (context, payload) => {
  // new api add for customer sites
  return http.getApi(
    URL_UTILITY.getCustomerSiteByOuUrl +
      '/' +
      payload.customer_id +
      '/customer-sites',
    payload
  );
};
const getVendorSiteByOu = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getVendorSiteByOuUrl +
      '/' +
      payload.vendor_id +
      '/vendor-sites',
    payload
  );
};
const getManualPaymentInvoice = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getManualPaymentGridUrl + '/get-all-invoices',
    payload
  );
};
const addManualPaymentInvoice = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getManualPaymentGridUrl + '/get-all-invoices',
    payload
  );
};
const validateManualPaymentInvoice = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getManualPaymentGridUrl + '/validation',
    payload
  );
};
const errorManualPaymentInvoice = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getManualPaymentGridUrl + '/errorInvoices',
    payload
  );
};
const confirmManualPaymentInvoice = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getManualPaymentGridUrl + '/confirmation',
    payload
  );
};
const getManualPaymentInvoiceById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getManualPaymentGridUrl +
      '/' +
      payload.apPaymentBatchId +
      '?payment_hdr_id=' +
      payload.payment_hdr_id
  );
};
const deleteManualPaymentsCancel = (context, payload) => {
  return http.postApi(URL_UTILITY.getManualPaymentGridUrl + '/delete', payload);
};
const getPaymentHdrIdByBatch = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getManualPaymentGridUrl +
      '/apPaymentHdrId/' +
      payload.apPaymentBatchId
  );
};
export default {
  getManualPaymentGrid,
  getManualPaymentById,
  getModuleBasedPeriodDate,
  addVoidPaymentRequest,
  getValidatePeriodDate,
  saveAdvSearchFilters,
  getPaymentAccountingDataById,
  getCustomerSiteByOu,
  getVendorSiteByOu,
  getManualPaymentInvoice,
  addManualPaymentInvoice,
  validateManualPaymentInvoice,
  errorManualPaymentInvoice,
  confirmManualPaymentInvoice,
  getManualPaymentInvoiceById,
  deleteManualPaymentsCancel,
  addVoidManualPayment,
  getPaymentHdrIdByBatch
};
