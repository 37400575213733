export default {
  name: 'customerHistory',
  props: ['vendorId'],
  data() {
    return {
      loader: null,
      customerHistoryData: [],
      customerHistoryFields: [
        {
          key: 'created_by'
        },

        {
          key: 'creation_date'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'last_updated_by'
        }
      ]
    };
  },
  mounted() {
    if (this.$store.state.party.partyProfile === 'customer') {
      this.getCustomerSiteByPartyId();
    } else if (this.$store.state.party.partyProfile === 'vendor') {
      this.getVendorHistorybyVendorId(this.vendorId);
    }
  },
  methods: {
    getCustomerSiteByPartyId() {
      const payload = {
        partyId: this.$store.state.party.partyId
      };
      this.loader = true;
      this.$store
        .dispatch('party/getCustomerSiteByPartyId', payload)
        .then(response => {
          const customerId = response.data.data.customer_id;
          this.getCustomerHistoryByCustomerId(customerId);
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCustomerHistoryByCustomerId(customerId) {
      this.loader = true;
      this.$store
        .dispatch('party/getCustomerHistoryByCustomerId', customerId)
        .then(response => {
          this.customerHistoryData = [response.data.data];
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getVendorHistorybyVendorId(vendorId) {
      this.loader = true;
      this.$store
        .dispatch('party/getVendorHistorybyVendorId', vendorId)
        .then(response => {
          this.customerHistoryData = [response.data.data];
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
