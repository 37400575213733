import store from '../../../../../../store';
export default {
  name: 'contact',
  data() {
    return {
      setTimeVsetCode: null,
      vsetCode: null,
      showValueSetModal: null,
      contactListIndex: null,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      unsubscribe: null,
      editMode: false,
      loader: false,
      contactList: [
        {
          employee_id: null,
          active: true,
          contact_type_vset: null,
          area_code: null,
          country_code: '91',
          contact_num: null,
          email_address: null,
          employee_contact_dtl_id: null,
          contact_type_value: null
        }
      ],
      contactFields: [
        {
          key: 'contact_type_value',
          label: 'Contact Type'
        },
        {
          key: 'country_code',
          class: 'd-none'
        },
        {
          key: 'area_code'
        },
        {
          key: 'contact_num',
          label: 'Contact No.'
        },
        {
          key: 'email_address'
        },
        {
          key: 'active'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.getConactList(store.state.shared.empId);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const action = state.shared.actionName;
        if (action === 'save' && this.editMode) {
          this.addEditContact();
        } else if (action === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditContact() {
      const contactDetail = this.contactList.map(contact => {
        return {
          active: contact.active,
          area_code: contact.area_code,
          contact_num: contact.contact_num,
          contact_type_vset: contact.contact_type_vset,
          country_code: contact.country_code,
          email_address: contact.email_address,
          employee_contact_dtl_id: contact.employee_contact_dtl_id
        };
      });
      const payload = {
        address_details: contactDetail,
        employee_id: store.state.shared.empId
      };
      this.loader = true;
      this.$store
        .dispatch('hrms/addEditContact', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getConactList(empId) {
      this.loader = true;
      this.$store
        .dispatch('hrms/getConactList', empId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length > 0) {
            this.contactList = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'CONTACT_TYPE') {
        this.contactList[this.contactListIndex].contact_type_value =
          item.value_meaning;
        this.contactList[this.contactListIndex].contact_type_vset =
          item.value_code;
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.contactListIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.contactList.push({
          employee_id: null,
          active: true,
          contact_type_vset: null,
          area_code: null,
          country_code: '91',
          contact_num: null,
          email_address: null,
          employee_contact_dtl_id: null,
          contact_type_value: null
        });
      }
    },
    removeRow(index) {
      this.contactList.splice(index, 1);
    },
    rowSelected() {},
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'CONTACT_TYPE') {
        this.contactList[index].contact_type_value = null;
        this.contactList[index].contact_type_vset = null;
      }
    }
  },
  beforeDestroy() {
    this.eventBus.$off('valueSetCode');
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
