import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';
const getGoupsList = (context, payload) => {
  const url = URL_UTILITY.getWorkflowGroupUrl;
  return http.getApi(url, payload);
};
const getGroupDetail = (context, groupHdrId) => {
  const url = URL_UTILITY.getWorkflowGroupUrl;
  return http.getApi(url + '/' + groupHdrId);
};
const addEditGroup = (context, payload) => {
  return http.postApi(URL_UTILITY.getWorkflowGroupUrl, payload);
};
const getMasterRuleList = (context, payload) => {
  return http.getApi(URL_UTILITY.getWorkflowMasterRuleUrl, payload);
};
const getMasterRuleDetail = (context, ruleHdrId) => {
  return http.getApi(URL_UTILITY.getWorkflowMasterRuleUrl + '/' + ruleHdrId);
};
const addEditMasterRule = (context, payload) => {
  return http.postApi(URL_UTILITY.getWorkflowMasterRuleUrl, payload);
};
const getRulesList = (context, payload) => {
  return http.getApi(URL_UTILITY.getWorkFlowRulesUrl, payload);
};
const getRulesDetail = (context, ruleHdrId) => {
  return http.getApi(URL_UTILITY.getWorkFlowRulesUrl + '/' + ruleHdrId);
};
const addEditRules = (context, payload) => {
  return http.postApi(URL_UTILITY.getWorkFlowRulesUrl, payload);
};
const getAppCriteriaList = (context, payload) => {
  return http.getApi(URL_UTILITY.getWorkflowAppCriteriaUrl, payload);
};
const addEditAppCriteriaList = (context, payload) => {
  return http.postApi(URL_UTILITY.getWorkflowAppCriteriaUrl, payload);
};
const getAppCriteriaDetails = (context, applicationCriteriaHdrId) => {
  return http.getApi(
    URL_UTILITY.getWorkflowAppCriteriaUrl + '/' + applicationCriteriaHdrId
  );
};
const getWorkflowEventList = (context, payload) => {
  return http.getApi(URL_UTILITY.getWorkflowEventUrl, payload);
};
const getEventDetail = (context, enventId) => {
  return http.getApi(URL_UTILITY.getWorkflowEventUrl + '/' + enventId);
};
const addEditEvent = (context, payload) => {
  return http.postApi(URL_UTILITY.getWorkflowEventUrl, payload);
};
const getRuleAppCriteriaByRuleId = (context, ruleMpngId) => {
  return http.getApi(
    URL_UTILITY.getWorkFlowRuleAppCriterialUrl + '/' + ruleMpngId
  );
};
const addEditRuleAppCriteria = (context, payload) => {
  return http.postApi(URL_UTILITY.getWorkFlowRuleAppCriterialUrl, payload);
};
const getDelegationList = (context, payload) => {
  return http.getApi(URL_UTILITY.getWrokflowDelegationUrl, payload);
};
const getDelegationDefaultEmpList = () => {
  return http.getApi(URL_UTILITY.getDelegationDefaultEmpListUrl);
};
const addEditDelegation = (context, payload) => {
  return http.postApi(URL_UTILITY.getWrokflowDelegationUrl, payload);
};
const searchNotification = (context, payload) => {
  return http.getApi(URL_UTILITY.getWorkflowNotficationUrl, payload);
};
const getNotificationStatus = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getWorkflowNotficationUrl + '/' + payload.status,
    payload.searchData
  );
};

const sendNotification = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getWorkflowNotficationUrl + '/approval-history',
    payload
  );
};
const delegateEmployee = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getWrokflowDelegationUrl + '/deligated-emp',
    payload
  );
};
const addEditInvoiceDetailsFinance = (context, payload) => {
  return http.postApi(URL_UTILITY.addEditInvoiceDetailsFinanceUrl, payload);
};

const sendEmailVendor = (context, payload) => {
  return http.postApi(URL_UTILITY.getValueSetUrl + '/send-email', payload);
};
const addDefaultEvent = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getWorkflowEventUrl + '/' + payload.event_id + '/default'
  );
};
export default {
  getGoupsList,
  getGroupDetail,
  addEditGroup,
  getMasterRuleList,
  getMasterRuleDetail,
  addEditMasterRule,
  getRulesList,
  getRulesDetail,
  addEditRules,
  getAppCriteriaList,
  getWorkflowEventList,
  getRuleAppCriteriaByRuleId,
  addEditRuleAppCriteria,
  getEventDetail,
  addEditEvent,
  addEditAppCriteriaList,
  getAppCriteriaDetails,
  getDelegationList,
  getDelegationDefaultEmpList,
  addEditDelegation,
  searchNotification,
  getNotificationStatus,
  sendNotification,
  delegateEmployee,
  addEditInvoiceDetailsFinance,
  sendEmailVendor,
  addDefaultEvent
};
