import store from '../../../../../../store/';
import cusDirectTaxDetails from './customerDirectTaxDetails';
export default {
  name: 'customerDirectTax',
  components: {
    cusDirectTaxDetails
  },
  data() {
    return {
      unsubscribe: null,
      partyProfileType: '',
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showAlertAdd: false,
      isSuccessAdd: false,
      responseMsgAdd: '',
      customerSiteIndex: 0,
      customerId: '',
      vendorId: '',
      showPartyDirectTaxModel: false,
      customerSiteList: [],
      tableFields: [
        {
          key: 'tax_group'
        },
        {
          key: 'tan_no',
          label: 'TAN No.'
        },
        {
          key: 'active'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'last_updated_by'
        }
      ],
      partyDirectTaxList: [],
      partyDirectTaxFields: [
        {
          key: 'tax_group'
        },
        {
          key: 'tan_no',
          label: 'TAN No.'
        }
      ],
      partyDirectSiteMpngId: 0,
      editMode: false,
      showCustDirectTaxModal: false,
      directTaxRowDetail: null,
      customerDtlId: null,
      customerSiteDtlList: null,
      siteType: null,
      vendorDtlId: null,
      vendorSiteDtlList: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (store.state.party.customerTabValue === 'directTax') {
          if (actionName === 'add' && !this.showCustDirectTaxModal) {
            this.showPartyDirectTaxModel = true;
            this.partyDirectSiteMpngId = 0;
          }
        }
      }
    });
    this.eventBus.$on('directTax', customerSite => {
      this.getPartyDirectTaxList();
      this.partyProfileType = store.state.party.partyProfile;
      if (store.state.party.partyProfile === 'customer') {
        this.customerId = customerSite.customer_id;
        this.getCustomerDirectTaxByPartyId();
      } else if (store.state.party.partyProfile === 'vendor') {
        this.vendorId = customerSite.vendor_id;
        this.getVendorDirectTaxList();
      }
    });
  },
  methods: {
    getCustomerDirectTaxByPartyId() {
      const payload = {
        partyId: store.state.party.partyId
      };
      this.loader = true;
      this.$store
        .dispatch('party/getCustomerDirectTaxByPartyId', payload)
        .then(response => {
          this.customerSiteList = response.data.data;
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPartyDirectTaxList() {
      const partyId = store.state.party.partyId;
      this.$store
        .dispatch('party/getPartyDirectTaxList', partyId)
        .then(response => {
          if (response.status === 200) {
            this.partyDirectTaxList = response.data.data;
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
    closePartyBankModel() {
      this.showPartyDirectTaxModel = false;
    },
    selectedSite(index) {
      this.customerSiteIndex = index;
    },
    selectedDirectTaxRow(item) {
      if (this.partyProfileType === 'customer') {
        const payload = {
          customerId: this.customerId,
          customerSiteDirectTax: {
            customer_site_direct_taxs: [
              {
                customer_site_id: this.customerSiteList[this.customerSiteIndex]
                  .customer_site_id,
                entity_type: 'Customer',
                party_direct_site_mpng_id: this.partyDirectSiteMpngId,
                party_direct_tax_id: item.party_direct_tax_id,
                active: true
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/getAddEditCustomerDirectTax', payload)
          .then(response => {
            this.loader = false;
            this.showAlertAdd = true;
            if (response.status === 200) {
              this.isSuccessAdd = true;
              this.responseMsgAdd = response.data.message;
              this.customerSiteList = response.data.data.customer_sites;
              this.getCustomerDirectTaxByPartyId();
            } else {
              this.isSuccessAdd = false;
              this.responseMsgAdd = response.response.data.message;
            }
          })
          .catch(error => {
            this.loader = false;
            alert(error.message);
          });
      } else if (this.partyProfileType === 'vendor') {
        const payload = {
          vendorId: this.vendorId,
          directTadData: {
            vendor_site_direct_taxs: [
              {
                vendor_site_id: this.customerSiteList[this.customerSiteIndex]
                  .vendor_site_id,
                entity_type: 'Vendor',
                party_direct_site_mpng_id: this.partyDirectSiteMpngId,
                party_direct_tax_id: item.party_direct_tax_id,
                active: true
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/addEditVendorDirectTax', payload)
          .then(response => {
            this.loader = false;
            this.showAlertAdd = true;
            if (response.status === 200) {
              this.isSuccessAdd = true;
              this.responseMsgAdd = response.data.message;
              this.customerSiteList = response.data.data.vendor_site_indirect_taxs;
            } else {
              this.isSuccessAdd = false;
              this.responseMsgAdd = response.response.data.message;
            }
          })
          .catch(error => {
            this.loader = false;
            alert(error.message);
          });
          //   this.loader = false;
          //   this.showAlert = true;
          //   this.isSuccess = true;
          //   this.responseMsg = response.data.message;
          //   this.showPartyDirectTaxModel = false;
          //   this.customerSiteList = response.data.data.vendor_sites_direct_taxs;
          // });
      }
    },
    getVendorDirectTaxList() {
      this.loader = true;
      this.$store
        .dispatch('party/getVendorDirectTaxList', this.vendorId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.customerSiteList = response.data.data.vendor_sites_direct_taxs;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      // this.showPartyDirectTaxModel = true;
      this.showHideCustDirectTaxModal(true);
      this.directTaxRowDetail = item;
      this.customerDtlId = this.customerId;
      this.customerSiteDtlList = this.customerSiteList[this.customerSiteIndex];
      this.partyDirectSiteMpngId = item.party_direct_site_mpng_id;
      this.siteType = this.partyProfileType;
      this.vendorDtlId = this.vendorId;
      this.vendorSiteDtlList = this.customerSiteList[this.customerSiteIndex];
    },
    showHideCustDirectTaxModal(flag) {
      this.showCustDirectTaxModal = flag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.eventBus.$off('directTax');
  }
};
