import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getLeaseMasterAgreementList = (context, payload) => {
  return http.getApi(URL_UTILITY.getLeaseMasterAgreementUrl, payload);
};
const getLeaseMasterAgreementDataById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getLeaseMasterAgreementUrl +
      '/' +
      payload.leaseMasterAgreementHdrId +
      '/' +
      payload.tabName
  );
};
const addEditLeaseMasterAgreementList = (context, payload) => {
  return http.postApi(URL_UTILITY.getLeaseMasterAgreementUrl, payload);
};
const addEditMasterLeaseTabsData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseMasterAgreementUrl +
      '/' +
      payload.leaseMasterAgreementHdrId +
      '/' +
      payload.tabName,
    payload.tabData
  );
};
const getMasterAgrmentDetails = (context, leaseMasterAgreementHdrId) => {
  return http.getApi(
    URL_UTILITY.getLeaseMasterAgreementUrl + '/' + leaseMasterAgreementHdrId
  );
};
const getLeaseTenantList = (context, payload) => {
  return http.getApi(URL_UTILITY.getLeaseTenantUrl, payload);
};
const getLeaseTenantDataById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getLeaseTenantUrl +
      '/' +
      payload.leaseTenantHdrId +
      '/' +
      payload.tabName
  );
};
const addEditTenantLease = (context, payload) => {
  return http.postApi(URL_UTILITY.getLeaseTenantUrl, payload);
};
const addEditTenantLeaseTabsData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseTenantUrl +
      '/' +
      payload.leaseTenantHdrId +
      '/' +
      payload.tabName,
    payload.tabData
  );
};
const getTenantLeaseDetails = (context, leaseTenantHdrId) => {
  return http.getApi(URL_UTILITY.getLeaseTenantUrl + '/' + leaseTenantHdrId);
};
const getCustomerByPartyId = (context, partyId) => {
  const url = URL_UTILITY.getPartyCustomerCreateUrl.replace(
    '{partyId}',
    partyId
  );
  return http.getApi(url);
};
const getInvestorList = (context, payload) => {
  return http.getApi(URL_UTILITY.getLeaseInvestorUrl, payload);
};
const getLeaseInvestorDetails = (context, leaseInvestorHdrId) => {
  return http.getApi(
    URL_UTILITY.getLeaseInvestorUrl + '/' + leaseInvestorHdrId
  );
};
const getLeaseInvestorDataById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getLeaseInvestorUrl +
      '/' +
      payload.leaseInvestorHdrId +
      '/' +
      payload.tabName
  );
};
const addEditInvestorLeaseTabsData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseInvestorUrl +
      '/' +
      payload.leaseInvestorHdrId +
      '/' +
      payload.tabName,
    payload.tabData
  );
};

const getSourceNameList = (context, payload) => {
  const url = URL_UTILITY.getSourceNameListUrl;
  return http.getApi(url, payload);
};
const getTxnTypeList = (context, moduleId) => {
  const url = URL_UTILITY.getTxnTypeListUrl.replace('{moduleId}', moduleId);
  return http.getApi(url);
};
const createRentaldetails = (context, payload) => {
  return http.postApi(URL_UTILITY.getLeaseSchTemplateUrl, payload);
};

const createRentalForInvestor = (context, payload) => {
  return http.postApi(URL_UTILITY.postInvestorScheduleRequestUrl, payload);
};

const getLeBasedNaturalAccountVset = (context, le_id) => {
  return http.getApi(
    URL_UTILITY.getLeBasedNaturalAccountVsetUrl.replace('{leId}', le_id)
  );
};

const getTenantRentalForInvestor = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getLeaseTenantUrl +
      '/' +
      payload.leaseTenantHdrId +
      '/' +
      payload.tabName +
      '?lease_rental_start_date=' +
      payload.lease_rental_start_date +
      '&lease_rental_end_date=' +
      payload.lease_rental_end_date
  );
};

const getRecevingBankDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getLeaseMasterAgreementUrl +
      '/' +
      payload.leaseMasterAgreementHdrId +
      '/receiving-bank-details/' +
      payload.arProjReceiptMethodMpngId
  );
};

const leaseTermination = (context, payload) => {
  return http.postApi(URL_UTILITY.getLeaseTenantUrl + '/termination', payload);
};

const getLeaseTerminationDate = (context, leaseTenantAgreementHdrId) => {
  return http.getApi(
    URL_UTILITY.getLeaseTenantUrl +
      '/' +
      leaseTenantAgreementHdrId +
      '/termination-date'
  );
};

const getBrokerHdrDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getLeaseMasterAgreementUrl + '/broker-hdr',
    payload
  );
};
const addEditBrokerHdrDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseMasterAgreementUrl + '/broker-hdr',
    payload
  );
};
const getBrokerDtlDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getLeaseMasterAgreementUrl + '/broker-dtl',
    payload
  );
};
const addEditBrokerDtlDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseMasterAgreementUrl + '/broker-dtl',
    payload
  );
};
const getTenantLeaseBrokerHdrDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getLeaseTenantUrl + '/broker-hdr', payload);
};
const addEditTenantLeaseBrokerHdrDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.getLeaseTenantUrl + '/broker-hdr', payload);
};
const getTenantLeaseBrokerDtlDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getLeaseTenantUrl + '/broker-dtl', payload);
};
const addEditTenantLeaseBrokerDtlDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.getLeaseTenantUrl + '/broker-dtl', payload);
};

const getTermsConditionDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getLeaseMasterAgreementUrl +
      '/' +
      payload.leaseMasterAgreementHdrId +
      '/termsCon'
  );
};
const addEditTermsConditionDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseMasterAgreementUrl +
      '/' +
      payload.lease_master_agreement_hdr_id +
      '/termsCon',
    payload.tabData
  );
};

const getTermsConditionTenantLeaseDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getLeaseTenantUrl +
      '/' +
      payload.leaseTenantAgreementHdrId +
      '/termsCon'
  );
};

const addEditTenantLeaseTermsConditionDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseTenantUrl +
      '/' +
      payload.lease_tenant_agreement_hdr_id +
      '/termsCon',
    payload.tabData
  );
};

const getRentFreeDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getLeaseMasterAgreementUrl +
      '/' +
      payload.leaseMasterAgreementHdrId +
      '/rentFreePeriod'
  );
};
const addEditRentFreeDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseMasterAgreementUrl +
      '/' +
      payload.lease_master_agreement_hdr_id +
      '/rentFreePeriod',
    payload.tabData
  );
};
const getRentFreeTenantLeaseDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getLeaseTenantUrl +
      '/' +
      payload.leaseTenantAgreementHdrId +
      '/rentFreePeriod'
  );
};
const addEditRentFreeTenantLeaseDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseTenantUrl +
      '/' +
      payload.lease_tenant_agreement_hdr_id +
      '/rentFreePeriod',
    payload.tabData
  );
};
const deleteTenantLease = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getLeaseTenantUrl +
      '/' +
      payload.lease_tenant_agreement_hdr_id +
      '/delete'
  );
};
export default {
  getLeaseMasterAgreementList,
  getLeaseMasterAgreementDataById,
  addEditLeaseMasterAgreementList,
  addEditMasterLeaseTabsData,
  getMasterAgrmentDetails,
  getLeaseTenantList,
  getLeaseTenantDataById,
  addEditTenantLease,
  addEditTenantLeaseTabsData,
  getTenantLeaseDetails,
  getCustomerByPartyId,
  getInvestorList,
  getLeaseInvestorDataById,
  getLeaseInvestorDetails,
  addEditInvestorLeaseTabsData,
  getSourceNameList,
  getTxnTypeList,
  createRentaldetails,
  createRentalForInvestor,
  getLeBasedNaturalAccountVset,
  getTenantRentalForInvestor,
  getRecevingBankDetailsById,
  leaseTermination,
  getLeaseTerminationDate,
  getBrokerHdrDetails,
  getBrokerDtlDetails,
  addEditBrokerHdrDetails,
  addEditBrokerDtlDetails,
  getTenantLeaseBrokerHdrDetails,
  addEditTenantLeaseBrokerHdrDetails,
  getTenantLeaseBrokerDtlDetails,
  addEditTenantLeaseBrokerDtlDetails,
  getTermsConditionDetails,
  addEditTermsConditionDetails,
  getTermsConditionTenantLeaseDetails,
  addEditTenantLeaseTermsConditionDetails,
  getRentFreeDetails,
  addEditRentFreeDetails,
  getRentFreeTenantLeaseDetails,
  addEditRentFreeTenantLeaseDetails,
  deleteTenantLease
};
