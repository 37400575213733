import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getAdminGridDashboardData = (context, payload) => {
    return http.getApi(URL_UTILITY.getAdminDashboardUrl + '/grid-data', payload);
  };

  const postAdminDashboardData = (context, payload) => {
    return http.postApi(URL_UTILITY.getAdminDashboardUrl, payload);
  };
  const getAdminGridDashboardDataById= (context, payload) => {
    return http.getApi(URL_UTILITY.getAdminDashboardUrl +`/uax/${payload.uax}` );
  };
  export default {
    getAdminGridDashboardData,
    postAdminDashboardData,
    getAdminGridDashboardDataById
  };
  