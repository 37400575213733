import commonHelper from '@/app/utility/common.helper.utility';
import searchRequest from '../../requestGroup/searchRequest';
export default {
    name: 'templateModule',
    components:{searchRequest},
    watch: {
        currentPage: function () {
            this.getTemplate();
        },
        perPage: function () {
            this.currentPage = 1;
            this.getTemplate();
        }
    },
    data() {
        return {
            perPage: commonHelper.perPageRecord,
            currentPage: 1,
            tempObj:{},
            totalRows: null,
            name: '',
            template: '',
            data: [],
            fields: [
                {
                    key: 'request_name',
                    label: 'Request Name'
                },
                {
                    key: 'template_name',
                    label: 'Template'
                },
                {
                    key: 'report_name',
                    label: 'Report Name'
                },
                {
                    key: 'template_id',
                    class: 'd-none'
                },
                {
                    key: 'request_id',
                    class: 'd-none'
                },
                {
                    key: 'creation_date',
                    label: 'Creation Date'
                },
                {
                    key: 'active',
                    label: 'Active'
                }
            ]
        };
    },
    mounted() {
        this.eventBus.$on('dependentTemplate', obj =>{
            this.name = obj
            this.getTemplate();
        });
    },

    methods: {
        resetModal() {},
        rowSelected(rowData) {
            this.$emit('selectedtemplate', rowData);
      
        },
        clearTemplate() {
            this.name = null;
            this.template = null;
        },
        getTemplate() {
             const payload = {
                _page: this.currentPage - 1,
                _limit: this.perPage,
                request_name: this.name,
                template_name: this.template
            };
            this.$store.dispatch('template/getTemplateList', payload).then(response => {
                this.data = response.data.data.page;
                this.totalRows = response.data.data.total_elements;
            });
        }
    },
    destroyed(){        
        this.eventBus.$off('dependentTemplate');
    }
};